<app-page-loader [show]="!pageReady"></app-page-loader>
<app-header *ngIf="showHead && !featureMenuv2" [title]="header_title" (profileExpansion)="profClick()" (collapseExpansion)="siderCollapse()"></app-header>
<app-sidebar *ngIf="showHead && !featureMenuv2"></app-sidebar>

<app-header-v2 *ngIf="showHead && featureMenuv2" [title]="header_title" (profileExpansion)="profClick()" (collapseExpansion)="siderCollapse()"></app-header-v2>
<app-sidebar-v2 *ngIf="showHead && featureMenuv2"></app-sidebar-v2>

<app-profilebar *ngIf="showHead"></app-profilebar>
<router-outlet></router-outlet>
<app-footer></app-footer>


<!--<!DOCTYPE html>
<html>
<head>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width">
  <title>JS Bin</title>
</head>
<body>
<div id="left" class="column">
  <div class="top-left">
    <app-sidebar *ngIf="showHead && !featureMenuv2"></app-sidebar>
    <app-sidebar-v2 *ngIf="showHead && featureMenuv2"></app-sidebar-v2>
  </div>
  <div class="bottom">

  </div>
</div>
<div id="right" class="column" style="flex: 1;">
  <div class="top-right">
    <app-header *ngIf="showHead && !featureMenuv2" [title]="header_title" (profileExpansion)="profClick()" (collapseExpansion)="siderCollapse()"></app-header>
    <app-header-v2 style="flex: 0 0 100%;" *ngIf="showHead && featureMenuv2" [title]="header_title" (profileExpansion)="profClick()" (collapseExpansion)="siderCollapse()"></app-header-v2>
  </div>
  <div class="bottom">
    <app-profilebar *ngIf="showHead"></app-profilebar>
    <router-outlet style="flex: 0 0 100%;"></router-outlet>
    <span class="appver">{{currentApplicationVersion}}</span>
   &lt;!&ndash; <app-footer></app-footer>&ndash;&gt;
  </div>
</div>
</body>
</html>-->


<div class="approve-modal modal" id="CustomPrintModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <h1> Please select Print Template</h1>
        </div>
        <div class="approve-modal-content" style="witdh:50%;height:3vh">
          <select [(ngModel)]="selectedTemplate">
            <option>Select</option>
            <option *ngFor="let templ of templ_config"
                    [ngValue]="templ">{{templ.name}}</option>
          </select>
        </div>
        <button
          [hidden]="!selectedTemplate"
          [useExistingCss]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>

      </div>
    </div>
  </div>
</div>

<div #PrintingModal class="pol-modal pol-send invoice modal" id="PrintingModal" role="dialog">
  <div class="modal-dialog m1">
    <!-- Modal content-->
    <div class="modal-content m2">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      </div>
      <div class="m3">

        <div id="print-section-sales">
          <div  class="invoice"  style="page-break-after: always" *ngFor="let print_detail of print_details">
            <app-print *ngIf="printType !== 'Eway' && !template || (selectedTemplate && selectedTemplate.name == 'Default')"
                       [order_detail]="print_detail"></app-print>
            <app-custom-print *ngIf="printType !== 'Eway' && template && selectedTemplate && selectedTemplate.name != 'Default'"
                              [order_detail]="print_detail"
                              [custom_template]="selectedTemplate"
            ></app-custom-print>
            <app-eway-print *ngIf="printType === 'Eway'" [order_detail]="print_detail"></app-eway-print>
          </div>
        </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section-sales"
                ngxPrint >PRINT</button>
        <button
          #autoprint
          [hidden]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>
      </div>
    </div>
  </div>
</div>
<div #zipDiv *ngIf="zip_order" id="print-section-sales-zip" style=" position:absolute;
   left:-10000px;background: #FFFFFF !important; background-color: #FFFFFF !important;" >
  <div  class="invoice" >
    <app-print *ngIf="!template || (selectedTemplate && selectedTemplate.name == 'Default')"
               [order_detail]="zip_order"></app-print>
    <app-custom-print *ngIf="template && selectedTemplate && selectedTemplate.name != 'Default'"
                      [order_detail]="zip_order"
                      [custom_template]="selectedTemplate"
    ></app-custom-print>
  </div>
</div>
<div #ZipProgressModal class="pol-modal pol-send invoice modal" id="ZipProgressModal" role="dialog" style="overflow: hidden;">
  <div class="modal-dialog m1">
    <!-- Modal content-->
    <div class="modal-content m2"  style="width: 90%;height:80%;">
      <div>
        <span class="close"  style="cursor:pointer;" data-dismiss="modal" (click)="closeZipDialog()">
            <img src="../../assets/close.png">
          </span>
      </div>
      <h2 style="padding-left: 20px;padding-top: 20px;">Zip Generation in Progress</h2>
      <div style="padding: 20px;">
        <mat-progress-bar style="height:25px;margin-top: 30px;" class="progressbar" mode="determinate" [value]="progress"></mat-progress-bar>
        <span  style="display:block;float:left;">{{completed}}/{{total}}</span>
        <span  style="display:block;float:right;">{{progress}}%</span>
      </div>
    </div>
  </div>
</div>
<div #DefaultTemplateModal class="pol-modal pol-send invoice modal"  id="DefaultTemplateModal" role="dialog">
  <div class="modal-dialog m1" style="width: 60vw">
    <!-- Modal content-->
    <div class="modal-content m2" style="width: fit-content">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      </div>
      <div class="m3">

        <div id="print-section--default">
          <div  class="invoice"  style="page-break-after: always" *ngFor="let order_detail of print_details">
            <app-print *ngIf="printType !== 'Eway' && !order_detail['selectedTemplate'] || (order_detail['selectedTemplate'] && order_detail['selectedTemplate']['name'] == 'Default')"
                       [order_detail]="order_detail"></app-print>
            <app-custom-print *ngIf="printType !== 'Eway' && order_detail['selectedTemplate'] && order_detail['selectedTemplate']['name'] != 'Default'"
                              [order_detail]="order_detail"
                              [custom_template]="order_detail['selectedTemplate']"
            ></app-custom-print>
            <app-eway-print *ngIf="printType === 'Eway'" [order_detail]="order_detail"></app-eway-print>
          </div>
        </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section--default"
                ngxPrint >PRINT</button>
        <button
          #autoprint
          [hidden]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>
      </div>
    </div>
  </div>
</div>
<button #customPrintClick id="openPrint" [hidden]="true" data-toggle="modal" data-target="#CustomPrintModal">Open Modal</button>
<button #interPrint id="openModalButton1" [hidden]="true" data-toggle="modal" data-target="#PrintingModal"></button>

