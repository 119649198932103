// @ts-ignore
import {Component, OnInit} from '@angular/core';
// @ts-ignore
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from "../../error/error-handler";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class SalesmanTargetAchievementReportComponent extends ReportBase implements IReport, OnInit {

  showCustomFilter = true;
  customFilter: any = {
    name: 'Type',
    key: 'type',
    value: 'secondary',
    values: [],
    show: true
  };
  apiData: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Salesman - Month Wise');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.showDate = true;
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];
    this.customFilter.values = [
      {id: 'primary', itemName: 'Primary'},
      {id: 'secondary', itemName: 'Secondary'},
      {id: 'tertiary', itemName: 'Tertiary'}
    ];
    /*this.gridOptions.pivotMode = true;
    this.gridOptions.autoGroupColumnDef = {
      headerName: 'GROUP',
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      pinned: 'left'
    };*/
    this.gridOptions.masterDetail = true;
    this.gridOptions.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: [
          {headerName: 'MONTH', field: 'jc_name'},
          {headerName: 'PLANNED', field: 'planned'},
          {headerName: 'TARGET', field: 'target'},
          {headerName: 'ACHIEVEMENT', field: 'achievement',
          valueGetter(params) {
            if (params.data) {
              if (params.data.achievement) {
                return params.data.achievement.toFixed(2);
              } else {
                return 0;
              }
            }
          }},
          {headerName: 'TARGET TYPE', field: 'target_type'},
          {headerName: 'MEASURE TYPE', field: 'measure_type'},
          {headerName: 'RESOURCE', field: 'resource_name'},
        ],
        defaultColDef: {
          flex: 1,
        },
      },
      getDetailRowData: params => {
        if (params.data) {
          params.successCallback(params.data.details);
        }
      }
    };
    this.gridOptions.isRowMaster = dataItem => {
        return dataItem ? dataItem.details.length > 0 : false;
    };

    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getSalesmanTargetvsAchievementReport';

    this.columnDefs = [
      {
        headerName: 'SALESMAN', field: 'salesman', menuTabs: [], width: 150,
        enableRowGroup: true, cellRenderer: 'agGroupCellRenderer'
      },
      {headerName: 'MANAGER', field: 'manager', enableRowGroup: true, width: 120},
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 120},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 120},
      {
        headerName: 'SCODE', field: 'emp_code', width: 80,
        enableRowGroup: true
      },
     /* {
        headerName: 'TARGET TYPE', field: 'target_type', width: 150,
        enableRowGroup: true,
      },
      {
        headerName: 'MEASURE TYPE', field: 'measure_type', width: 150,
        enableRowGroup: true
      },
      {
        headerName: 'SUB TARGET', field: 'name', menuTabs: [], width: 150,
        enableRowGroup: true
      },
      {
        headerName: 'RESOURCE', field: 'resource_name', menuTabs: [], width: 150,
        enableRowGroup: true
      },*/
      /* {
         headerName: 'MONTH', field: 'jc_name', menuTabs: [], width: 150, pivotComparator: this.MyMonthPivotComparator,
       },*/
      {headerName: 'MAIN TARGET', field: 'main_target', enableRowGroup: true, width: 120, aggFunc: this.mainAggFunction},
      {headerName: 'MAIN ACHIEVEMENT', field: 'main_achievement', width: 120, aggFunc: this.mainAggFunction},
      {
        headerName: 'MAIN PROGRESS', field: 'progress',  minWidth: 180, menuTabs: [], aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data) {
            if (params.data.main_target === null || parseInt(params.data.main_target) === 0 || params.data.main_achievement === null || parseInt(params.data.main_achievement) === 0) {
              return 0;
            } else {
              return Math.trunc((parseInt(params.data.main_achievement) / parseInt(params.data.main_target)) * 100);
            }
          }
        },
        cellRenderer: params => {
          let displayElem = '<div class="row">\n' +
            '            <div class="progresscontainer1">\n' +
            '              <div class="progressinside1" style=" ';

          if (params.value > 50) {
            displayElem = displayElem + ' background-color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' background-color: #D97C0E;';
          } else {
            displayElem = displayElem + ' background-color: #E24260;';
          }
          let prog = params.value;
          if (params.value && parseFloat(params.value) > 100) {
            prog = 100;
          }
          displayElem = displayElem + 'width:' + prog + '%;"></div>\n' +
            '            </div>\n' +
            '            <div class="progresstext1" style="';

          if (params.value > 50) {
            displayElem = displayElem + ' color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' color: #D97C0E;';
          } else {
            displayElem = displayElem + ' color: #E24260;';
          }

          displayElem = displayElem + '">' + params.value + '%</div></div>';


          return displayElem;
        }
      },
      /*{
        headerName: 'TARGET', field: 'target', enableRowGroup: true, width: 120, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data && params.data.target) {
            return Math.round(Math.round((params.data.target + Number.EPSILON) * 100) / 100);
          }
        }
      },
      {
        headerName: 'ACHIEVEMENT', field: 'achievement', width: 120, aggFunc: 'sum',
        valueGetter: params => {
          if (params.data  && params.data.achievement) {
            return Math.round(Math.round((params.data.achievement + Number.EPSILON) * 100) / 100);
          }
        }
      },
      {
        headerName: 'PROGRESS', field: 'progress', width: 120, aggFunc: this.avgAggFunction,
        valueGetter: params => {
          if (params.data && params.data.target  && params.data.achievement) {
            if (params.data.target === null || parseInt(params.data.target) === 0 || params.data.achievement === null || parseInt(params.data.achievement) === 0) {
              return 0;
            } else {
              return Math.trunc((parseInt(params.data.achievement) / parseInt(params.data.target)) * 100);
            }
          }
        },
        cellRenderer: params => {
          let displayElem = '<div class="row">\n' +
            '            <div class="progresscontainer">\n' +
            '              <div class="progressinside" style=" ';

          if (params.value > 50) {
            displayElem = displayElem + ' background-color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' background-color: #D97C0E;';
          } else {
            displayElem = displayElem + ' background-color: #E24260;';
          }

          displayElem = displayElem + 'width:' + params.value + '%;"></div>\n' +
            '            </div>\n' +
            '            <div class="progresstext" style="';

          if (params.value > 50) {
            displayElem = displayElem + ' color: #379862;';
          } else if (params.value > 20) {
            displayElem = displayElem + ' color: #D97C0E;';
          } else {
            displayElem = displayElem + ' color: #E24260;';
          }

          displayElem = displayElem + '">' + params.value + '%</div></div>';


          return displayElem;
        }
      },*/
    ];

  }

  MyMonthPivotComparator(a, b) {
    return b - a;
  }

  loadServerData() {
 this.goClicked = true;
     //debugger;
    /*   const t = trace(this.appModule.perf, this.sharedService.headerTitleSource.value);
       t.start();
       t.putAttribute('companyKey', localStorage.getItem('company_key'));*/
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');

    if (this.showCustomFilter) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
      }
    }
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            //this.rowData = res.results.data;
            this.apiData = res.results.data;
            this.configureData();
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.msg));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));

  }

  configureData() {
    let key, key1;
    this.rowData = [];
    if (this.apiData.target != null) {

    /*  let masterdata = this.apiData.target.map(p => new Object(
        {
          salesman_id: p.salesman_id,
          salesman: p.salesman,
          region: p.region,
          state: p.state,
          team_id: p.team_id,
          details: []
        }
      )) .filter((type, index, arr) => arr.indexOf(type) === index);*/
      let masterdata = this.apiData.target.reduce(function(rv, p) {
        (rv[p.salesman_id] = rv[p.salesman_id] || []).push(
          {
            salesman_id: p.salesman_id,
            salesman: p.salesman,
            manager: p.manager,
            emp_code: p.emp_code,
            region: p.region,
            state: p.state,
            team_id: p.team_id,
            main_target: p.main_target,
            main_achievement: p.main_achievement,
            details: []
          }
        );
        return rv;
      }, {});

      let initialData = this.apiData.subtarget.reduce(function(rv, x) {
        (rv[x.salesman_id] = rv[x.salesman_id] || []).push(
          {
            jc_name: x.jc_name,
            target_type: x.target_type,
            measure_type: x.measure_type,
            resource_name: x.resource_name,
            planned: x.planned,
            target: x.target,
            achievement: x.achievement,
            salesman_id: x.salesman_id
          }
        );
        return rv;
      }, {});
      let indrow:any = {};
      for (key in masterdata) {
        if (initialData && Object.keys(initialData).length > 0) {
          const subtargetrow = Object.keys(initialData).find(subtarget => parseInt(subtarget, 10) === masterdata[key][0].salesman_id);
          if (subtargetrow) {
            indrow = masterdata[key][0];
            indrow.main_target = masterdata[key].map( v => v.main_target )
              .reduce( (sum, current) => sum + current, 0 );
            indrow.main_achievement = masterdata[key].map( v => v.main_achievement )
              .reduce( (sum, current) => sum + current, 0 );
            indrow.details = initialData[parseInt(subtargetrow, 10)];
            this.rowData.push(indrow);
          } else {
            indrow = masterdata[key][0];
            indrow.main_target = masterdata[key].map( v => v.main_target )
              .reduce( (sum, current) => sum + current, 0 );
            indrow.main_achievement = masterdata[key].map( v => v.main_achievement )
              .reduce( (sum, current) => sum + current, 0 );
           // indrow.details = subtargetrow;
            this.rowData.push(indrow);
          }
          /*for (key1 in initialData) {
            if (masterdata[key][0].salesman_id === parseInt(key1)) {
              indrow = masterdata[key][0];
              indrow.main_target = masterdata[key].map( v => v.main_target )
                .reduce( (sum, current) => sum + current, 0 );
              indrow.main_achievement = masterdata[key].map( v => v.main_achievement )
                .reduce( (sum, current) => sum + current, 0 );
              indrow.details = initialData[key1];
              this.rowData.push(indrow);
            }
          }*/
        } else {
          indrow = masterdata[key][0];
          indrow.main_target = masterdata[key].map( v => v.main_target )
            .reduce( (sum, current) => sum + current, 0 );
          indrow.main_achievement = masterdata[key].map( v => v.main_achievement )
            .reduce( (sum, current) => sum + current, 0 );
          // indrow.details = subtargetrow;
          this.rowData.push(indrow);
        }
      }

      //this.rowData = masterdata;
      let asc = [];

      /*  for (key in this.apiData.target) {

          let indrow = {};
          let targetrow: any = {};
          let subtargetrow: any = {};

          targetrow = this.apiData.target[key];
          //this.apiData.subtarget = [];
          if (this.apiData.subtarget != null && this.apiData.subtarget.length > 0) {
            subtargetrow = this.apiData.subtarget.filter(subtarget => subtarget.master_id === targetrow.id);

            for (key in subtargetrow) {
              const indrow1 = subtargetrow[key];
             /!* indrow1.details = [{
                jc_name: targetrow.jc_name,
                target: subtargetrow[key].target,
                achievement: subtargetrow[key].achievement,
              }];*!/
              indrow = {
                ...targetrow,
                ...indrow1
              };

              this.rowData.push(indrow);
            }
          } else {
            this.rowData.push(targetrow);
          }

        }
        this.rowData.sort((a, b) => b.month_id - a.month_id);*/
    }
  }

  mainAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;
    let salesmanGroup = false;
    let loopParam = params.rowNode;
    while (loopParam.hasOwnProperty('parent')) {
      if (loopParam.parent.field === 'salesman') {
        salesmanGroup = true;
      }
      loopParam = loopParam.parent;
    }
    if (params.rowNode.hasOwnProperty('rowGroupColumn') && params.rowNode.rowGroupColumn.colDef.headerName === 'SALESMAN') {
      salesmanGroup = true;
    }

    params.values.forEach(function(value) {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        if (!salesmanGroup) {
          // sum += value.avg * value.count;
          sum += value.avg;
          // count += value.count;
        } else {
          sum = value.avg;
        }
        /*  sum += value;
          count += value.count;*/
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          //sum += value;
          sum = value;
          //count++;
        } else {
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    //avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    avg = sum;
    count = sum;

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg.toFixed(2);
      },
    };

    return result;
  }

  avgAggFunction(params) {
    let avg;
// the average will be the sum / count
    let sum = 0;
    let count = 0;

    params.values.forEach(function(value) {
      const groupNode =
        value !== null && value !== undefined && typeof value === 'object';
      if (groupNode) {
        // we are aggregating groups, so we take the
        // aggregated values to calculated a weighted average
        sum += value.avg * value.count;
        count += value.count;
      } else {
        // skip values that are not numbers (ie skip empty values)
        if (typeof value === 'number') {
          sum += value;
          count++;
        }
      }
    });
    // avoid divide by zero error
    if (count !== 0) {
      avg = sum / count;
    } else {
      avg = null;
    }

    avg = Math.round(Math.round((avg + Number.EPSILON) * 100) / 100);

    // the result will be an object. when this cell is rendered, only the avg is shown.
    // however when this cell is part of another aggregation, the count is also needed
    // to create a weighted average for the next level.
    const result = {
      count,
      avg,
      // the grid by default uses toString to render values for an object, so this
      // is a trick to get the default cellRenderer to display the avg value
      toString() {
        return this.avg.toFixed(2);
      },
    };

    return result;
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllColumns().length;

    params.api.setRowData(this.rowData);
    this.preloader = false;
    const inThis = this;

    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
      });
    });

    params.columnApi.autoSizeAllColumns();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    /* const columnState = JSON.parse(localStorage.getItem('myColumnState'));
     if (columnState) {
       this.gridColumnApi.applyColumnState(
         {
           state: columnState,
           applyOrder: true,
         }
       );
     }*/

    /*fetch('C:\Users\Nivil\Downloads\pwaconfig.txt').then(response => response.text())
      .then(data => {
        // Do something with your data
        console.log(data);
      });*/
  }

  changeDate(dt) {
    const temp = this.myDate;
    if (temp !== null) {
      this.myDate = [];
      this.myDate[0] = this.datePipe.transform(new Date(temp[0].getFullYear(), temp[0].getMonth(), 1), 'yyyy-MM-dd');
      this.myDate[1] = this.datePipe.transform(new Date(temp[1].getFullYear(), temp[1].getMonth() + 1, 0), 'yyyy-MM-dd');

      this.userData.start_date = this.myDate[0];
      this.userData.end_date = this.myDate[1];

      this.changeDetector.detectChanges();
    }

  }
}
