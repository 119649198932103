import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-image-gallery-popup',
  templateUrl: './image_gallery_popup.component.html',
  styleUrls: ['./image_gallery_popup.component.scss']

})

export class ImageGalleryPopupComponent implements OnInit {

  public photos= [];
  store_fname;
  API_URL = '';
  container = '';
  gallery = [];
  user_data = {
    date: null,
    access_token: null,
    url: null,
    data: {
      start_date: null,
      end_date: null,
      id: null
    }
  };

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.gallery = dialogdata.gallery;
    this.fetchImages();

  }

  ngOnInit(): void {
  }

  async fetchImages() {
    this.user_data.url = localStorage.getItem('private_url');
    const payload = {
      access_token: localStorage.getItem('resfreshToken'),
      data: this.gallery
    };
    const inThis = this;
    this.API_URL = '/api/pwa_connects/fetch_image';
    console.log(payload);
    const res = await this.apiService.postPromise(this.API_URL, payload);
    if (res.hasOwnProperty('result') && res['result'].length > 0) {
      // console.log(res);
      const domSanitizer = this.domSanitizer;
      let img;
      res['result'].forEach(photo => {
        if (photo.type && photo.type === 'pdf') {
          img =  domSanitizer.bypassSecurityTrustUrl('data:application/pdf;base64, ' + photo.bill_datas);
        } else {
          img = domSanitizer.bypassSecurityTrustUrl('data:image/png;base64, ' + photo.bill_datas);
        }
        inThis.photos.push(
          {
            photo: img,
            type: photo.type
          });
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }


}
