import {Component, OnInit} from "@angular/core";
import {GSTPageBase} from "./gst_base.component";
import {MatDialogConfig} from "@angular/material/dialog";
import {GSTR3PopupComponent} from "./gstr3_popup/gstr3_popup.component";

@Component({
  selector: 'app-gstr3-list',
  templateUrl: './gst_base.component.html',
  styleUrls: ['./gst_base.component.scss']
})

export class GSTR3ListComponent extends GSTPageBase implements OnInit {

  ngOnInit(): void {
    this.sharedService.setHeader('GSTR 3');
    this.gstr3 = true;
    this.dateFilterType = 'period';
    this.showUpload = false;
    this.tabList = [{id: 'summary', itemName: 'Summary'}, {id: 'reconciliation', itemName: 'Reconciliation'}];
    this.checkAndLoadPreviousSate();
    this.showCustomFilter = false;
    this.showActionButton = false;
    this.showDate = true;
    this.configureGrid();
  }

  configureGrid() {
    this.columnDefs2 = [
      {headerName: "Description", field: "desc", width: 80},
      {
        headerName: "Sales",
        children: [
          {headerName: "GSTR1", field: "s_gstr1", width: 80, cellStyle: {textAlign: 'right'}, aggFunc: 'sum'},
          {headerName: "GSTR3", field: "s_gstr3", width: 80, cellStyle: {textAlign: 'right'}, aggFunc: 'sum'},
          {
            headerName: "Difference", field: "s_diff", cellStyle: {textAlign: 'right'}, width: 80, aggFunc: 'sum',
            cellRenderer(params) {
              if (params.data) {
                if (!params.data.s_diff) {
                  return `<span style="font-weight: 600; color: #379862;">0</span>`
                } else {
                  return `<span style="font-weight: 600; color: #E24260;">` + params.value + `</span>`
                }
              }
            }
          },
        ]
      },
      {
        headerName: "Purchase",
        children: [
          {headerName: "GSTR2", field: "p_gstr2", width: 80, cellStyle: {textAlign: 'right'}, aggFunc: 'sum'},
          {headerName: "GSTR3", field: "p_gstr3", width: 80, cellStyle: {textAlign: 'right'}, aggFunc: 'sum'},
          {
            headerName: "Difference", field: "p_diff", width: 80, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
            cellRenderer(params) {
              if (params.data) {
                if (!params.data.p_diff) {
                  return `<span style="font-weight: 600; color: #379862;">0</span>`
                } else {
                  return `<span style="font-weight: 600; color: #E24260;">` + params.value + `</span>`
                }
              }
            }
          },
        ]
      }
    ]
    this.rowData2 = [
      {
        desc: 'Taxable Value',
        s_gstr1: 8571063.21,
        s_gstr3: 8808971,
        s_diff: 237907.79,
        p_gstr2: 524418.35,
        p_gstr3: 555094.17,
        p_diff: 30675.82,
      },
      {
        desc: 'IGST',
        s_gstr1: 1018998.84,
        s_gstr3: 1057820,
        s_diff: 38821.16,
        p_gstr2: 64856.52,
        p_gstr3: 70072,
        p_diff: 5215.48,
      },
      {
        desc: 'CGST',
        s_gstr1: 261880.17,
        s_gstr3: 264390,
        s_diff: 2509.83,
        p_gstr2: 12147.3,
        p_gstr3: 12147,
        p_diff: -.30,
      },
      {
        desc: 'SGST',
        s_gstr1: 261880,
        s_gstr3: 264390,
        s_diff: 2509.83,
        p_gstr2: 12147.3,
        p_gstr3: 12147,
        p_diff: -.30,
      },
      {
        desc: 'Cess',
        s_gstr1: 0,
        s_gstr3: 0,
        s_diff: 0,
        p_gstr2: 0,
        p_gstr3: 0,
        p_diff: 0,
      }
    ]
  }

  loadServerData() {
    this.goClicked = true;
    this.preloader = true;
    this.dataLoaded = false;
    setTimeout(() => {
      this.preloader = false;
      this.dataLoaded = true;
    }, 1000);
  }

  openGSTFilePopup() {
    const config: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'custom-dialog-container',
      data: {
        period: this.selectedPeriod,
        file_status: this.file_status,
        summaryData: this.rowData2
        // supplier_territory_id: supp_territory_id
      },
    };
    const dialogRef = this.dialog.open(GSTR3PopupComponent, config);
    dialogRef.componentInstance.gstr3submitted.subscribe(status => {
      if (status) {
        this.dialog.closeAll();
        this.file_status = 'filed';
        this.file_date = localStorage.getItem(this.selectedPeriod.period_name + 'gstr3_file_date');
        this.file_ack = localStorage.getItem(this.selectedPeriod.period_name + 'gstr3_file_ack');
      }
    });
  }

}
