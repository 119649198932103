import {ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, Inject, NgZone, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe, DOCUMENT} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";
import {SharedService} from "../../../_services/shared_service";
import {MatDialog} from "@angular/material/dialog";
import {AgmCoreModule, MapsAPILoader} from "@agm/core";
import {ToastrService} from "ngx-toastr";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-register-meet-attendee',
  templateUrl: './register_meet_attendee.component.html',
  styleUrls: ['./register_meet_attendee.component.scss']
})

export class RegisterMeetAttendeeComponent implements OnInit {

  attendeeDetails: any = {};
  meetDetails: any = {};
  access_token;
  dataLoaded = false;
  sponsors = '';
  hours = 0;
  days = 0;
  invalidEmail = false;
  company_key;
  logo;
  meetCompleted;
  limit_days = 1;
  constructor(private apiService: ApiService,
              private sharedService: SharedService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private toastr: ToastrService,
              public dialog: MatDialog) {

    this.access_token = this.route.snapshot.queryParamMap.get('auth');
    this.company_key = this.route.snapshot.queryParamMap.get('ckey');

  }
  ngOnInit(): void {
    if (this.access_token && this.company_key) {
      localStorage.setItem('resfreshToken', this.access_token);
      localStorage.setItem('company_key', this.company_key);
      if (localStorage.getItem('attendee_details')) {
        this.attendeeDetails = JSON.parse(localStorage.getItem('attendee_details'));
      }
      this.sharedService.setHeader('Meet Attendance');
      this.fetchCompanyDetails();
    } /*else {
      this.router.navigate([this.apiService.api_url + "/pagenotfound"]);
    }*/
  }


  fetchCompanyDetails() {
    this.apiService.findCompanyInstance({companyKey: this.company_key})
      .subscribe(async res => {
        if (res.hasOwnProperty('success')) {
          if (res.success.logo_url != null) {
            this.logo = res.success.logo_url;
            localStorage.setItem('company_logo', this.logo);
          }
          localStorage.setItem('private_url', res.success.url);
          if (environment.instanceURL === 'from_login') {
            this.apiService.api_url = localStorage.getItem('private_url');
          } else {
            this.apiService.api_url = environment.instanceURL;
          }
          this.getMeetDetails();
        }
      });
  }
  getMeetDetails() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    this.apiService.post('/api/partner_meets/get_invitation_meet_details', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.dataLoaded = true;
          this.meetDetails = res.results.data[0];
          if (this.meetDetails.status === 'completed') {
            this.meetCompleted = true;
          } else {
            this.meetCompleted = false;
          }
          this.configureMeetData();
        }
      });
  }
  configureMeetData() {
    if (this.meetDetails?.pm_config?.meet_config?.limit_days) {
      this.limit_days = this.meetDetails.pm_config.meet_config.limit_days;
    }
    if (this.meetDetails.planned_date) {
      this.hours = (new Date(this.meetDetails.planned_date).getTime() - new Date(Date.now() + (new Date().getTimezoneOffset() * 60000)).getTime()) / 36e5;
      this.hours = this.hours + (this.limit_days * 24);
      this.days = parseInt(String(this.hours / 24), 10);
      this.meetDetails.planned_date = this.datePipe.transform((new Date(this.meetDetails.planned_date + ' UTC')).toString(), 'yyyy-MM-dd | h:mm a');
    }
    if (this.meetDetails.sponsor_json && this.meetDetails.sponsor_json.length > 0) {
      this.meetDetails.sponsor_json.forEach(sponsor => {
        this.sponsors = this.sponsors + ', ' + sponsor.name;
      });
      if (this.sponsors) {
        this.sponsors = this.sponsors.substring(1, this.sponsors.length);
      }
    }
  }
  validateEmail() {
    if (this.emailRegex()) {
      this.invalidEmail = false;
    } else {
      this.invalidEmail = true;
    }
  }
  emailRegex() {
    return String(this.attendeeDetails.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  verifyAttendee() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.attendeeDetails;
    this.apiService.post('/api/res_users/checkAndCreateAttendee', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data) {
          this.attendeeDetails.aid = res.results.data;
          this.redirectToOTPPage();
          /*if (res.results.data.url) {
            this.router.navigate([this.apiService.redirect_url + '/meet_att'], {
              queryParams: {
                auth: res.results.data.url,
                ckey: inThis.company_key
              }
            });
          }*/
        } else {
          this.toastr.error("Failed To Register User");
        }
      });
  }

  redirectToOTPPage() {
    localStorage.setItem('attendee_details', JSON.stringify(this.attendeeDetails));
    localStorage.setItem('mobile', this.attendeeDetails.mobile);
    this.router.navigate([this.apiService.redirect_url + '/otp_verification'], {
      state: {
        type: 'meet_attendee',
        data: this.attendeeDetails
      }
    });
  }
}
