<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section" style=" border-bottom: 2px solid #f3f3f3;padding-bottom: 10px;">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Image</h2>
    </div>
    <div class="body-section w-100" style=" text-align: center;margin-top: 10px;">
      <div class="gallerycardgrid">
        <ng-container *ngFor="let data of photos; let i = index">
          <div style="height: 80vh; width: 80vh;display: flex; margin: auto;">
            <img style="width: 100%" [src]="data.photo"/>
          </div>

        </ng-container>
      </div>
    </div>
  </div>
</section>
