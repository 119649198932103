import {Component, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GridOptions} from "../../../node_modules/@ag-grid-enterprise/all-modules";
import {ApiService} from "../_services/api.service";
import {CellEditRenderer} from "../editCell.component";
import {DatePipe} from '@angular/common';
import {PinnedCellRenderer} from "../pinnedCellRenderer.component";
import {VanDisplayRendererComponent} from "../vanShow.component";
import {SupplierDropdownComponent} from "../components/supplier_dropdown/supplier-dropdown.component";
import {SuppProdDropdownComponent} from "../components/supp_prod_dropdown/supp-prod-dropdown.component";
import {ColorCellRenderer} from "../colorCell.component";
import {CellEdit2Renderer} from "../editCell2.component";
import {AdDirective2} from "../ad2.directive";
import {AdDirective3} from "../ad3.directive";
import {ToastrService} from "ngx-toastr";
import {CustomtooltipComponent} from "../customtooltip.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../components/confirmdialog/confirmdialog.component";
import {SharedService} from "../_services/shared_service";

declare let $: any;

@Component({
  selector: 'app-prolist',
  templateUrl: './prolist-v3.component.html',
  styleUrls: ['./prolist.component.scss']
})
export class ProlistV3Component implements OnInit {
  @ViewChild('newLoadout') newLoadout: ElementRef<HTMLElement>;
  @ViewChild(AdDirective2, {static: true}) adHost2: AdDirective2;
  @ViewChild(AdDirective3, {static: true}) adHost3: AdDirective3;
  @ViewChild('interPrint') public interPrint: ElementRef;

  public all_orders = [];
  res_data;
  user_data = {
    loadout_data: null,
    date: null,
    dc_id: null,
    access_token: null,
    pc_id: null,
    po_id: null,
    url: null,
    order_id: null,
    dispatch: false,
    dc_centers: [],
    supplier_id: null,
    customer_id: 1,
    pricelist_id: 0,
    flag: false,
    feature_sug: false
  };
  public sendConfirm = false;
  public dispatch_feature = false;
  public updateConfirm = false;
  public columnDefs = [];
  public columnDefs1 = [];
  public columnDefsPO = [];
  public searchValue;
  public frameworkComponents;
  public context;
  public van_show = true;
  public frameworkComponents1;
  public context1;
  public rowData = [];
  public rowData1 = [];
  public rowDataPO = [];
  public gridOptions: GridOptions;
  public gridOptions1: GridOptions;
  public gridOptionsPO: GridOptions;
  public datavalue;
  public data = [];
  public data1;
  public uploading = false;
  public upload_loader = false;
  public loaded = false;
  public loaded1 = false;
  public loadedPO = false;
  public preloader = false;
  public preloaderPO = false;
  public empty_state = false;
  public empty_statePO = false;
  public prod_loaded = false;
  public productList = [];
  public POproductList = [];
  public passedParams;
  public saveData = [];
  public newprodData = [];
  public saveProdData = [];
  public multiple_loadout = false;
  public disable_send = false;
  public editLoadout = false;
  public stockData1 = {
    line_data: [],
    po_id: null
  };
  public stockData2 = {
    stock_data: [],
    order_ids: [],
    po_id: null,
    status: ""
  };
  public get_user_data;
  public myDate;
  public saveCaption = ["", 0];
  public uploadCaption = ["", 0];
  public all_products = {};
  public styleGrid;
  public colIds;
  public collapse_disable;
  public submit = true;
  public dispatch = false;
  public draft_total = [];
  public readjustreqpinnedData = {
    VAN: "RESET TO 0"
  };
  public batchscalepinnedData = {
    VAN: "BATCH SCALE"
  };
  public finalreqpinnedData = {
    VAN: "FINAL REQ"
  };
  public pinnedData2 = [
    {
      VAN: "AVAILABLE QTY"
    }];
  public pinnedData = [{
    VAN: "PRODUCED QTY"
  },
    {
      VAN: "AVAILABLE QTY"
    }];
  public lastpinnedData = [
    {
      VAN: "TOTAL QTY"
    }];
  public KEY_LEFT = 37;
  public KEY_UP = 38;
  public KEY_RIGHT = 39;
  public KEY_DOWN = 40;
  public edit_order_id = null;
  public pop_loader = false;
  public products;
  public status;
  public status_class;
  public product_summary = {VAN: 'REQUESTED'};
  public po_line_data = [];
  public role_features = [];
  public edit_enable = false;
  public edit_notplaced = false;
  public wait_save = true;
  public direct_dispatch = false;
  public loadoutProds = [];
  public loadout_prod = [];
  public van_code;
  public no_po;
  public vans = [];
  public confirmEnable = true;
  public enable_recommented = false;
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 2400000;
  public sugTotal;
  public mbqTotal;
  public mbqPreTotal;
  public mbqClosingTotal;
  public mbqChannelTotal;
  public dc_attr: any = {};
  public country_id = 105;
  public sapSync = false;
  public sapConfig: any = {};
  public delivery_status = false;
  public sap_mark_complete = false;
  public sap_stock_transfer_enabled = false;
  private gridApi;
  private gridApi1;
  private gridApiPO;
  private gridColumnApi;
  private gridColumnApi1;
  private gridColumnApiPO;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  private sideBar;
  private rowHeight;
  sapStockReceived = false;
  sapZeroStockConfirmation = false;
  sapPullLoader = false;
  sapShowZeroStockConfirmation = false;
  apiloaded = false;
  polineMismatch = false;
  uomlist = [];
  user_report_config: any = {};
  user_uom_config: any = {};
  channelTypes;
  featureReadjust = false;
  batchScaleDone = false;
  featureEwayGenerate = false;
  dataloaded = false;
  constructor(private apiService: ApiService,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private toastr: ToastrService,
              public dialog: MatDialog,
              private componentFactoryResolver: ComponentFactoryResolver,
              private datePipe: DatePipe,
              private sharedService: SharedService) {

    this.get_user_data = JSON.parse(localStorage.getItem('user_data'));

    this.myDate = this.get_user_data.date;

    this.user_data.date = this.myDate;
    this.user_data.dc_id = JSON.parse(localStorage.getItem('params')).dc_id;
    this.user_data.supplier_id = this.user_data.dc_id;
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.pc_id = this.get_user_data.pc_id;
    this.user_data.po_id = JSON.parse(localStorage.getItem('params')).po_id;
    this.user_data.dc_centers = JSON.parse(localStorage.getItem('user')).dc_centers;
    this.user_data.url = localStorage.getItem('private_url');
    this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);
    this.dc_attr = JSON.parse(localStorage.getItem('params')).attributes;
    this.country_id = JSON.parse(localStorage.getItem('params')).country_id;

    if (this.sharedService.role_features) {
      if (this.sharedService.ACLcheck('feature_po_edit')) {
        this.edit_enable = true;
      }
      if (this.sharedService.ACLcheck('feature_dispatch')) {
        this.dispatch_feature = true;
      }
      if (this.sharedService.ACLcheck('feature_allow_notplaced_edit')) {
        this.edit_notplaced = true;
      }
      if (this.sharedService.ACLcheck('feature_direct_dispatch')) {
        this.direct_dispatch = true;
      }
      if (this.sharedService.ACLcheck('feature_loadout_multiple')) {
        this.multiple_loadout = true;
      }
      if (this.sharedService.ACLcheck('feature_disable_sendproduction')) {
        this.disable_send = true;
      }
      if (this.sharedService.ACLcheck('feature_enable_recommented')) {
        this.enable_recommented = true;
        this.user_data.feature_sug = true;
      }
      if (this.sharedService.ACLcheck('feature_readjust_po')) {
        this.featureReadjust = true;
      }
      if (this.sharedService.ACLcheck('feature_sap_stock_transfer')) {
        this.sap_stock_transfer_enabled = true;
        this.sapSync = true;
      }
      if (this.sharedService.ACLcheck('feature_eway_generate')) {
        this.featureEwayGenerate = true;
      }
    }

    this.uomlist = [{id: 0, factor_inv: 0, uom_type: 'null', name: ''}].concat(JSON.parse(localStorage.getItem('all_uoms')));

    const integrs = JSON.parse(localStorage.getItem('integrations'));
    const updated = integrs.filter(existed => existed.integration_type === 'sap' && existed.country_id === this.country_id);
    if (updated.length > 0 && updated[0].config_json && this.sapSync) {
      this.sapConfig = updated[0].config_json;
    }


    this.no_po = this.multiple_loadout;
    this.vans = JSON.parse(localStorage.getItem('loadout_vans'));

    this.user_report_config =  JSON.parse(localStorage.getItem('user')).report_json;
    if (this.user_report_config && this.user_report_config.hasOwnProperty('po_uom') && this.user_report_config.po_uom) {
      this.user_uom_config = this.user_report_config.po_uom;
    }

    this.gridOptions1 = {
      rowHeight: 35,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark']
    } as GridOptions;


    this.gridOptions = {
      groupHeaderHeight: 30,
      headerHeight: 60,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left', padding: '20px 0px'},
      getRowStyle: this.getRowStyleFunc,
      isRowSelectable(rowNode) {
        return ((!rowNode.data.duplicate) && (rowNode.data.dispatch_status === 'new') &&
          (rowNode.data.last_eod === null) && (rowNode.data.last_started === null));
      }
    } as GridOptions;

    this.gridOptions.getRowHeight = params => {
      let row_ht = 43;
      if (params.data['VAN'] === 'PRODUCED QTY') {
        row_ht = 40;
      }
      return row_ht;
    };

    this.gridOptionsPO = {
      rowHeight: 35,
      rowSelection: 'multiple',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark'],
      rowClassRules: {
        'ag-grid-grey': (params) => {
          return params.node.rowIndex % 2 === 0;
        },
      }
    } as GridOptions;

    this.initialDataFetch();

  }

  initialDataFetch() {
    this.res_data = this.apiService.supplierProducts(this.user_data);
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
        this.loadoutProds = res['results'].data;
      }
    }, error => {
      console.log(error);
    });

    const all_prod = {};

    this.res_data = this.apiService.getProducts(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          res['results'].data.forEach(data => {
            all_prod['p_' + data.id] = {
              name: data.name,
              product_uom: data.uom_id,
              cid: data.cid,
              code: data.code,
              price: data.price,
              taxmaster_id: data.taxmaster_id,
              tax_pct: data.tax_pct,
              stock: data.stock
            };
          });
          this.all_products = all_prod;
          console.log(this.all_products);
          localStorage.setItem('products', JSON.stringify(this.all_products));
        }
      },
      error => console.log(error));


    this.res_data = this.apiService.getPOStatus(this.user_data);

    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
        let po_status = '';
        if (res['results'].data.length > 1) {
          res['results'].data.forEach(po => {
            if (po.id === JSON.parse(localStorage.getItem('params')).po_id) {
              po_status = po.status[0].toUpperCase() + po.status.slice(1);
            }
          });
        }

        if (po_status === '' || po_status === undefined) {
          this.status = res['results'].data[0].status[0].toUpperCase() + res['results'].data[0].status.slice(1);
        } else {
          this.status = po_status;
        }
        localStorage.setItem('po_status', this.status);

        if (this.status === 'Draft') {
          this.status_class = 'green-status';
        } else if (this.status === 'Completed' || this.status === 'Inprogress') {
          this.status_class = 'grey-status';
        } else {
          this.status_class = 'blue-status';
        }

        if (this.status === 'Processed') {
          this.loaded = false;
          this.preloader = true;
          this.res_data = this.apiService.getProduction(this.user_data);
          this.res_data.subscribe(res => {
              console.log(res);
              if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                this.po_line_data = res['results'].data;
                this.sendTOproduction(3);
              } else {
                this.toastr.error("Error fetching Production Order");
              }
            },
            error => {
              console.log(error);
            });
        } else if (this.status === 'Completed') {
          this.loaded = false;
          this.preloader = true;
          this.res_data = this.apiService.getProduction(this.user_data);
          this.res_data.subscribe(res => {
              console.log(res);
              if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                this.po_line_data = res['results'].data;
                this.sendTOproduction(4);
              } else {
                this.toastr.error("Error fetching Production Order");
              }
            },
            error => {
              console.log(error);
            });
        } else if (this.status === 'Inprogress') {
          this.loaded = false;
          this.preloader = true;
          this.res_data = this.apiService.getProduction(this.user_data);
          this.res_data.subscribe(res => {
              console.log(res);
              if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                this.po_line_data = res['results'].data;
                this.sendTOproduction(5);
              } else {
                this.toastr.error("Error fetching Production Order");
              }
            },
            error => {
              console.log(error);
            });
        } else if (this.status === 'Submitted') {
          this.res_data = this.apiService.getProduction(this.user_data);
          this.res_data.subscribe(res => {
              console.log(res);
              if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                this.po_line_data = res['results'].data;
                this.apiProList(1);
              }
            },
            error => {
              console.log(error);
            });
        } else {
          this.apiProList(0);
        }
      } else {
        this.status = 'Not Started';
        this.loaded = false;
        this.empty_state = true;
        this.apiloaded = true;
      }
    }, error => {
      this.apiloaded = true;
      console.log(error);
    });


  }

  getRowStyleFunc(params) {
    let dev = '0px';
    if (params.node.rowIndex !== 0) {
      dev = (-26 - 26 * (params.node.rowIndex - 1)) + 'px';
    }
    if (params.data.ostatus === 'inactive') {
      return {
        'background-color': 'FloralWhite'
      };
    }

    if (params.data.dispatch_status === 'dispatched' || params.data.dispatch_status === 'delivered') {
      return {
        'background-color': 'Gainsboro'
      };
    }
  }

  async apiProList(times) {
    this.apiloaded = false;
    this.columnDefs = [];
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    const rowdata = [];
    this.gridOptions.pinnedTopRowData = [];
    const finalRecommended = {VAN: 'FINAL REQ', QTY: 0};
    const totalData = [{VAN: 'REQUESTED', QTY: 0}];
    const batchData = { VAN: "BATCH SCALE" };
    const sugTotal = {VAN: 'SUGGESTED', QTY: 0};
    const mbqTotal = {VAN: 'SUGGESTED', QTY: 0};
    const mbqPreTotal = {VAN: 'SUGGESTED', QTY: 0};
    const mbqClosingTotal = {VAN: 'SUGGESTED', QTY: 0};
    const mbqCChannelTotal = {};
    const product_summary = {VAN: 'REQUESTED'};
    const lastPinned = this.lastpinnedData[0];
    this.draft_total = [];
    const keydata = [];
    const order_ids = [];
    const po_line_data = this.po_line_data;
    let updated_data;
    const save_data = [];
    const newprodData = [];
    let complete = true;
    let prod_categories;
    const exist_vans = {};
    let duplicate = false;
    let active_prods = [];
    let preOrders = [];
    let van_preorder = [];
    let polinedata = [];
    this.channelTypes = new Set();

    const res = await this.apiService.polistV3(this.user_data);
    const all_status = this.status;
    const inthis = this;
    this.dataloaded = true;
    const all_pro = this.all_products;
    const multiple_loadout = this.multiple_loadout;
    if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
      console.log(res);
      this.po_line_data = res['results'].polinedata;
      polinedata = res['results'].polinedata;
      prod_categories = res['results'].category_data;
      preOrders = res['results'].pre_order;

      for (const key in this.all_products) {
        keydata.push(key);
        product_summary[key] = 0;
        totalData[key] = 0;
        sugTotal[key] = 0;
        lastPinned[key] = 0;
        mbqTotal[key] = 0;
        mbqPreTotal[key] = 0;
        mbqClosingTotal[key] = 0;
        finalRecommended[key] = 0;
        batchData[key] = 0;
      }

      res['results'].data.forEach(data => {
        if (multiple_loadout) {
          duplicate = false;
        } else {
          if (exist_vans[data.name]) {
            duplicate = true;
          } else {
            duplicate = false;
            exist_vans[data.name] = true;
          }
        }
        if (data.d_status === 'delivered') {
          inthis.delivery_status = true;
        }

        const pushData = {
          duplicate,
          VAN: data.name,
          van_code: data.code,
          order_id: data.order_id,
          po: data.po,
          dispatch_status: data.d_status,
          all_status,
          ostatus: data.ostatus,
          vansale_id: data.vansale_id,
          van_id: data.van_id,
          user_territory_id: data.user_territory_id,
          user_id: data.user_id,
          last_eod: data.last_eod,
          last_started: data.last_started,
          van_status: data.status
        };

        if (data.order_id !== null) {
          order_ids.push(data.order_id);
        }
        if (data.total_qty !== null) {
          product_summary['QTY'] += parseInt(data.total_qty, 10);
        }
        for (const key in all_pro) {

          let active = true;
          if (data.act_prod) {
            active_prods = data.act_prod.filter(existed => (parseInt(key.split('_')[1], 10) === parseInt(existed, 10)));
          }
          if (active_prods.length === 0) {
            active = false;
          }
          let markzero = false;
          let qty = 0;
          let sug = 0;
          let mbq_config;
          let mbq_preorder = 0;
          let mbq_sug = 0;
          let mbq_closing = 0;
          let mbq_channel = [];
          let pre_orders;
          let pre_qty = 0;
          let act_clos = 0;
          let act_total = 0;
          let min = 0;
          let max = 0;
          const poLine = polinedata.find(existed => existed.product_id === parseInt(key.split('_')[1], 10));
          if (poLine && poLine.hasOwnProperty('config_json') && poLine.config_json) {
            if (poLine.config_json['mark_zero']) {
              markzero = true;
            } else {
              markzero = false;
            }
          } else {
            markzero = false;
          }
          const find_prod = data.products.filter(existed => (parseInt(key.split('_')[1], 10) === parseInt(existed.product_id, 10)));
          van_preorder = preOrders.filter(existed => parseInt(data.van_id, 10) === existed.id);
          if (van_preorder.length > 0 && van_preorder[0].pre_order) {
            van_preorder = van_preorder[0].pre_order;
          } else {
            van_preorder = [];
          }
          if (van_preorder) {
            pre_orders = van_preorder.filter(existed => (parseInt(key.split('_')[1], 10) === parseInt(existed.prod_id, 10)));
            if (pre_orders.length > 0 && parseInt(pre_orders[0].qty, 10) !== 0) {
              pre_qty = parseInt(pre_orders[0].qty, 10);
            }
          }
          if (inthis.enable_recommented) {
            if (data.closing) {
              updated_data = data.closing.filter(existed => parseInt(key.split('_')[1], 10) === existed.prod_id);
              if (updated_data.length > 0 && updated_data[0].live_closing) {
                act_clos = parseInt(updated_data[0].live_closing, 10);
              }
            }
            if (!data.mbq_qty) {
              data.mbq_qty = [];
            }
            updated_data = data.mbq_qty.filter(existed => parseInt(key.split('_')[1], 10) === existed.prod_id);
            if (updated_data.length > 0 && parseInt(updated_data[0].qty, 10) !== 0) {
              mbq_sug = parseInt(updated_data[0].qty, 10);
              mbq_config = updated_data[0].config;
              min = updated_data[0].min;
              max = updated_data[0].max;
              if (mbq_config) {
                if (mbq_config.preorder) {
                  mbq_preorder = parseInt(mbq_config.preorder.qty, 10);
                  if (mbq_config.expected_closing) {
                    mbq_closing = parseInt(mbq_config.expected_closing, 10);
                  }
                }
                if (mbq_config.channel_split && mbq_config.channel_split !== '{}' && Object.keys(mbq_config.channel_split).length > 0) {
                  mbq_channel = mbq_config.channel_split[0];
                  for (const [channelkey, value] of Object.entries(mbq_config.channel_split[0])) {
                    inthis.channelTypes.add(channelkey);
                    if (! mbqCChannelTotal[key + '_' + channelkey]) {
                      mbqCChannelTotal[key + '_' + channelkey] = value;
                    } else  {
                      mbqCChannelTotal[key + '_' + channelkey] += value;
                    }
                  }
                }
              }
            }
          }
          if (mbq_sug && mbq_sug > 0) {
            if (!data.last_started && data.closing) {
              if (van_preorder && van_preorder.length > 0 ) {
                if ((mbq_sug - mbq_preorder + pre_qty - act_clos) > 0) {
                  sugTotal[key] += mbq_sug - mbq_preorder + pre_qty - act_clos;
                }
              } else {
                if ((mbq_sug - act_clos) > 0) {
                  sugTotal[key] += mbq_sug - act_clos;
                }
              }
            } else {
              act_clos = 0;
              if (van_preorder && van_preorder.length > 0 ) {
                if ((mbq_sug - mbq_preorder + pre_qty - mbq_closing) > 0) {
                  sugTotal[key] += mbq_sug - mbq_preorder + pre_qty - mbq_closing;
                }
              } else {
                if ((mbq_sug - mbq_closing) > 0) {
                  sugTotal[key] += mbq_sug - mbq_closing;
                }
              }
            }
            mbqTotal[key] += mbq_sug;
            mbqPreTotal[key] += mbq_preorder;
            mbqClosingTotal[key] += mbq_closing;
          }
          if (data.d_status === 'dispatched' || data.d_status === 'delivered') {

            if (inthis.enable_recommented && mbq_sug && mbq_sug > 0) {
              if (data.last_started) {
                if (van_preorder && van_preorder.length > 0 ) {
                  act_total = mbq_sug - mbq_preorder + pre_qty - act_clos;
                  min = min - mbq_preorder + pre_qty - mbq_closing;
                  max = max - mbq_preorder + pre_qty - mbq_closing;
                } else {
                  act_total = mbq_sug - act_clos;
                  min = min - mbq_closing;
                  max = max - mbq_closing;
                }
              } else {
                if (van_preorder && van_preorder.length > 0 ) {
                  min = min - mbq_preorder + pre_qty - act_clos;
                  max = max - mbq_preorder + pre_qty - act_clos;
                } else {
                  min = min - act_clos;
                  max = max - act_clos;
                }
                act_total = qty;
              }
              if (find_prod.length > 0) {
                sug = find_prod[0].ordered_qty;
              } else {
                sug = 0;
              }
            } else {
              sug = 0;
            }
            let ordered_qty = 0;
            if (find_prod.length > 0) {
              qty = find_prod[0].delivered_qty;
              ordered_qty = find_prod[0].ordered_qty;
            } else {
              qty = 0;
            }
            if (min < 0) {
              min = 0;
            }
            if (max < 0) {
              max = 0;
            }
            let change_color = false;
            if (parseFloat(String(ordered_qty)) !== parseFloat(String(qty))) {
              change_color = true;
            }
            product_summary[key] += parseInt(String(qty), 10);
            lastPinned[key] += parseInt(String(qty), 10);
            pushData[key] = {
              qty, sug, prvs_qty: ordered_qty, active, ord_sug: mbq_sug, pre: pre_qty, pre_sug: mbq_preorder,
              clos_sug: mbq_closing, act_clos, act_total, min, max, change_color, channel: mbq_channel, van_preorder,
              last_started: data.last_started, find_prod
            };
          } else if (find_prod.length > 0) {
            if (!active) {
              pushData[keydata[key]] = {
                qty: 0, sug: 0, prvs_qty: 0, active, ord_sug: 0, pre: 0, pre_sug: 0,
                clos_sug: 0, act_clos: 0, act_total, min: 0, max: 0, van_preorder,  last_started: data.last_started, find_prod
              };
            } else {
              let change_color = false;
              if (find_prod[0].state === 'cancel' || find_prod[0].state === 'draft') {
                if (inthis.enable_recommented && mbq_sug && mbq_sug > 0) {
                  if (data.last_started) {
                    if (van_preorder && van_preorder.length > 0 ) {
                      qty = mbq_sug - mbq_preorder + pre_qty - mbq_closing;
                      act_total = mbq_sug - mbq_preorder + pre_qty - act_clos;
                      min = min - mbq_preorder + pre_qty - mbq_closing;
                      max = max - mbq_preorder + pre_qty - mbq_closing;
                    } else {
                      qty = mbq_sug - mbq_closing;
                      act_total = mbq_sug - act_clos;
                      min = min - mbq_closing;
                      max = max - mbq_closing;
                    }
                  } else {
                    if (van_preorder && van_preorder.length > 0 ) {
                      qty = mbq_sug - mbq_preorder + pre_qty - act_clos;
                      min = min - mbq_preorder + pre_qty - act_clos;
                      max = max - mbq_preorder + pre_qty - act_clos;
                    } else {
                      qty = mbq_sug - act_clos;
                      min = min - act_clos;
                      max = max - act_clos;
                    }
                    act_total = qty;
                  }
                  sug = find_prod[0].ordered_qty;
                } else {
                  qty = find_prod[0].ordered_qty;
                  sug = 0;
                }
                if (qty < 0) {
                  qty = 0;
                }
                if (min < 0) {
                  min = 0;
                }
                if (max < 0) {
                  max = 0;
                }
                if (parseFloat(find_prod[0].ordered_qty) !== parseFloat(String(qty))) {
                  change_color = true;
                }
                if (markzero) {
                  qty = 0;
                }
                pushData[key] = {
                  qty, sug, prvs_qty: find_prod[0].ordered_qty, ordered: find_prod[0].ordered_qty,
                  value: find_prod[0].price_total, active, ord_sug: mbq_sug, pre: pre_qty, pre_sug: mbq_preorder,
                  clos_sug: mbq_closing, act_clos, act_total, min, max, change_color, channel: mbq_channel, van_preorder,
                  last_started: data.last_started, find_prod
                };
                product_summary[key] += parseInt(String(qty), 10);
                lastPinned[key] += parseInt(String(qty), 10);
                if (times === 2 || times === 4) {
                  const price_subtotal = (parseFloat(all_pro[key].price) * parseInt(String(qty), 10)).toFixed(inthis.apiService.decimalPolicy);
                  save_data.push({
                    order_id: data.order_id,
                    product_id: key.split('_')[1],
                    state: 'done',
                    qty,
                    prvs_qty: find_prod[0].ordered_qty,
                    price_subtotal,
                    line_id: find_prod[0].line_id
                  });
                }
              } else if (find_prod[0].state === 'done' || find_prod[0].state === 'sale') {
                if (parseInt(find_prod[0].delivered_qty, 10) < 0) {
                  if (find_prod[0].state === 'sale' && find_prod[0].hasOwnProperty('config') && find_prod[0].config !== null
                    && find_prod[0].config.hasOwnProperty('delivered_qty')) {
                    find_prod[0].delivered_qty = find_prod[0].config.delivered_qty;
                  } else {
                    find_prod[0].delivered_qty = 0;
                  }
                }
                if (inthis.enable_recommented && mbq_sug && mbq_sug > 0 ) {
                  if (data.last_started) {
                    if (van_preorder && van_preorder.length > 0 ) {
                      qty = mbq_sug - mbq_preorder + pre_qty - mbq_closing;
                      act_total = mbq_sug - mbq_preorder + pre_qty - act_clos;
                      min = min - mbq_preorder + pre_qty - mbq_closing;
                      max = max - mbq_preorder + pre_qty - mbq_closing;
                    } else {
                      qty = mbq_sug - mbq_closing;
                      act_total = mbq_sug - act_clos;
                      min = min - mbq_closing;
                      max = max - mbq_closing;
                    }
                  } else {
                    if (van_preorder && van_preorder.length > 0 ) {
                      qty = mbq_sug - mbq_preorder + pre_qty - act_clos;
                      min = min - mbq_preorder + pre_qty - act_clos;
                      max = max - mbq_preorder + pre_qty - act_clos;
                    } else {
                      qty = mbq_sug - act_clos;
                      min = min - act_clos;
                      max = max - act_clos;
                    }
                    act_total = qty;
                  }
                  if (qty < 0) {
                    qty = 0;
                  }
                  if (min < 0) {
                    min = 0;
                  }
                  if (max < 0) {
                    max = 0;
                  }
                  if (find_prod[0].ordered_qty !== find_prod[0].delivered_qty) {
                    change_color = true;
                  }
                } else {
                  if (find_prod[0].ordered_qty !== find_prod[0].delivered_qty) {
                    change_color = true;
                  }
                }
                pushData[key] = {
                  qty: find_prod[0].delivered_qty,
                  sug: find_prod[0].ordered_qty,
                  prvs_qty: find_prod[0].delivered_qty,
                  ordered: find_prod[0].ordered_qty,
                  value: find_prod[0].price_total,
                  active,
                  ord_sug: mbq_sug,
                  pre: pre_qty,
                  pre_sug: mbq_preorder,
                  clos_sug: mbq_closing,
                  act_clos,
                  act_total,
                  change_color,
                  min,
                  max,
                  channel: mbq_channel,
                  van_preorder,
                  last_started: data.last_started,
                  find_prod
                };
                product_summary[key] += parseInt(find_prod[0].delivered_qty, 10);
                lastPinned[key] += parseInt(find_prod[0].delivered_qty, 10);
                if (times === 2 || times === 4) {
                  const price_subtotal = (parseFloat(all_pro[key].price) * parseInt(find_prod[0].delivered_qty, 10)).toFixed(inthis.apiService.decimalPolicy);
                  save_data.push({
                    order_id: data.order_id,
                    product_id: key.split('_')[1],
                    state: 'done',
                    qty: find_prod[0].delivered_qty,
                    prvs_qty: find_prod[0].delivered_qty,
                    price_subtotal,
                    line_id: find_prod[0].line_id
                  });
                }
              }
            }
          } else {
            if (inthis.enable_recommented) {
              let sug_qty = 0;
              if (mbq_sug && mbq_sug > 0) {
                if (data.last_started) {
                  act_clos = 0;
                  if (van_preorder && van_preorder.length > 0 ) {
                    sug_qty = mbq_sug - mbq_preorder + pre_qty - mbq_closing;
                    act_total = mbq_sug - mbq_preorder + pre_qty - act_clos;
                    min = min - mbq_preorder + pre_qty - mbq_closing;
                    max = max - mbq_preorder + pre_qty - mbq_closing;
                  } else {
                    sug_qty = mbq_sug - mbq_closing;
                    act_total = mbq_sug - act_clos;
                    min = min - mbq_closing;
                    max = max - mbq_closing;
                  }
                } else {
                  if (van_preorder && van_preorder.length > 0 ) {
                    sug_qty = mbq_sug - mbq_preorder + pre_qty - act_clos;
                    min = min - mbq_preorder + pre_qty - act_clos;
                    max = max - mbq_preorder + pre_qty - act_clos;
                  } else {
                    sug_qty = mbq_sug - act_clos;
                    min = min - act_clos;
                    max = max - act_clos;
                  }
                  act_total = sug_qty;
                }
                if (sug_qty < 0) {
                  sug_qty = 0;
                }
                if (min < 0) {
                  min = 0;
                }
                if (max < 0) {
                  max = 0;
                }
                if (markzero) {
                  sug_qty = 0;
                }
                pushData[key] = {
                  qty: sug_qty,
                  sug: 0,
                  prvs_qty: 0,
                  active,
                  ord_sug: mbq_sug,
                  pre: pre_qty,
                  pre_sug: mbq_preorder,
                  clos_sug: mbq_closing,
                  act_clos,
                  act_total,
                  min,
                  max,
                  channel: mbq_channel,
                  van_preorder,
                  last_started: data.last_started,
                  find_prod
                };
                if (data.order_id) {
                  product_summary[key] += sug_qty;
                  lastPinned[key] += sug_qty;
                }
                let date = (new Date().toISOString()).replace('T', ' ');
                date = date.replace('Z', ' ');
                const price_subtotal = (parseFloat(all_pro[key].price) * sug_qty).toFixed(inthis.apiService.decimalPolicy);
                const soconfig = {};
                soconfig['delivered_qty'] = sug_qty;
                newprodData.push({
                  order_id: data.order_id,
                  partner_id: data.van_id,
                  distributor_id: inthis.user_data.dc_id,
                  product_id: parseInt(key.split('_')[1], 10),
                  van_code: data.code,
                  state: "done",
                  product_uom: inthis.all_products[key].product_uom,
                  taxmaster_id: inthis.all_products[key].taxmaster_id,
                  price_unit: inthis.all_products[key].price,
                  product_code: inthis.all_products[key].code,
                  customer_lead: 0,
                  name: inthis.all_products[key].name,
                  product_uom_qty: sug_qty,
                  qty_delivered: sug_qty,
                  type: 'normal',
                  price_subtotal,
                  price_tax: 0,
                  price_total: price_subtotal,
                  create_date: date,
                  write_date: date,
                  prvs_qty: 0,
                  cid: inthis.all_products[key].cid,
                  po_id: inthis.user_data.po_id,
                  po_date: inthis.user_data.date,
                  config_json: soconfig
                });
              } else {
                if (data.last_started) {
                  act_clos = 0;
                }
                if (mbq_sug === 0 && pre_qty !== null && pre_qty !== undefined && pre_qty > 0) {
                  qty = pre_qty - act_clos;
                  min = min + pre_qty - act_clos;
                  max = max + pre_qty - act_clos;
                  act_total = qty;
                  sug = 0;
                  if (qty < 0) {
                    qty = 0;
                  }
                  if (min < 0) {
                    min = 0;
                  }
                  if (max < 0) {
                    max = 0;
                  }
                  if (markzero) {
                    qty = 0;
                  }
                  pushData[key] = {
                    qty, sug, prvs_qty: 0, active, ord_sug: mbq_sug, pre: pre_qty, pre_sug: mbq_preorder,
                    clos_sug: mbq_closing, act_clos, act_total, min, max, change_color: false, channel: mbq_channel, van_preorder,
                    last_started: data.last_started, find_prod
                  };
                  let date = (new Date().toISOString()).replace('T', ' ');
                  date = date.replace('Z', ' ');
                  const price_subtotal = (parseFloat(all_pro[key].price) * qty).toFixed(inthis.apiService.decimalPolicy);
                  const soconfig = {};
                  soconfig['delivered_qty'] = sug_qty;
                  newprodData.push({
                    order_id: data.order_id,
                    partner_id: data.van_id,
                    distributor_id: inthis.user_data.dc_id,
                    product_id: parseInt(key.split('_')[1], 10),
                    van_code: data.code,
                    state: "done",
                    product_uom: inthis.all_products[key].product_uom,
                    taxmaster_id: inthis.all_products[key].taxmaster_id,
                    price_unit: inthis.all_products[key].price,
                    product_code: inthis.all_products[key].code,
                    customer_lead: 0,
                    name: inthis.all_products[key].name,
                    product_uom_qty: qty,
                    qty_delivered: qty,
                    type: 'normal',
                    price_subtotal,
                    price_tax: 0,
                    price_total: price_subtotal,
                    create_date: date,
                    write_date: date,
                    prvs_qty: 0,
                    cid: inthis.all_products[key].cid,
                    po_id: inthis.user_data.po_id,
                    po_date: inthis.user_data.date,
                    config_json: soconfig
                  });
                } else {
                  pushData[key] = {
                    qty: 0, sug: 0, prvs_qty: 0, active, ord_sug: 0, pre: 0, pre_sug: 0,
                    clos_sug: 0, act_clos, act_sug: 0, act_total: 0, min: 0, max: 0, channel: [], van_preorder,
                    last_started: data.last_started, find_prod
                  };
                }
              }
            } else {
              pushData[key] = {
                qty: 0, sug: 0, prvs_qty: 0, active, ord_sug: 0, pre: 0, pre_sug: 0,
                clos_sug: 0, act_clos: 0, act_sug: 0, act_total: 0, min: 0, max: 0, channel: [], van_preorder,
                last_started: data.last_started, find_prod
              };
            }
          }

          updated_data = po_line_data.filter(existed => parseInt(key.split('_')[1], 10) === existed.product_id);
          if (updated_data.length > 0) {
            if (updated_data[0].qty_adjusted === 0) {
              totalData[0][key] = updated_data[0].qty_ordered;
            } else {
              totalData[0][key] = updated_data[0].qty_adjusted;
            }
            let qty_suggested = updated_data[0].qty_suggested;
            if (qty_suggested === null || qty_suggested === undefined) {
              qty_suggested = 0;
            }
          }
          batchData[key] = inthis.uomlist[0].id;
        }
        rowdata.push(pushData);
      });

      this.products = keydata;
      this.product_summary = product_summary;
      this.wait_save = true;
      this.sugTotal = sugTotal;
      this.mbqTotal = mbqTotal;
      this.mbqPreTotal = mbqPreTotal;
      this.mbqClosingTotal = mbqClosingTotal;
      this.mbqChannelTotal = mbqCChannelTotal;
      this.draft_total.push(this.product_summary);
      this.saveData = save_data;
      this.newprodData = newprodData;
      this.lastpinnedData[0] = lastPinned;
      if (times === 1) {
        this.gridOptions.pinnedTopRowData = [];
        this.gridOptions.pinnedTopRowData = totalData;
      } else if (times === 3) {
        for (let i = 0; i < keydata.length; i++) {
          if (this.lastpinnedData[0][keydata[i]] === 0) {
            delete this.lastpinnedData[0][keydata[i]];
            rowdata.forEach(data => {
              delete data[keydata[i]];
            });
          }
          keydata.splice(i, 1);
          i -= 1;
        }
        this.gridOptions.pinnedTopRowData = this.lastpinnedData;
        this.products = keydata;
      } else if (times === 4) {
        for (const prod in keydata) {
          let updated_data = [];
          updated_data = this.stockData2.stock_data.filter(existed => parseInt(keydata[prod].split('_')[1], 10) === existed.product_id);

          if (updated_data.length !== 0) {
            updated_data[0].quantity = this.product_summary[keydata[prod]];
          }

          updated_data = this.po_line_data.filter(existed => parseInt(keydata[prod].split('_')[1], 10) === existed.product_id);

          if (updated_data.length > 0) {
            this.pinnedData[0][keydata[prod]] = updated_data[0].qty_adjusted;
          }

          if (this.pinnedData[1][keydata[prod]] === undefined) {
            this.pinnedData[1][keydata[prod]] = 0;
          }

          const pinnedData = this.pinnedData;
          let sum, splitValue;

          if (typeof pinnedData[1][keydata[prod]] === 'string') {
            splitValue = pinnedData[1][keydata[prod]].split(' (');
            pinnedData[1][keydata[prod]] = parseInt(splitValue[0], 10);
          }

          rowdata.forEach(row_data => {
            if (row_data.dispatch_status === 'new') {
              complete = false;
              if (typeof pinnedData[1][keydata[prod]] === 'string') {
                splitValue = pinnedData[1][keydata[prod]].split(' (');
                pinnedData[1][keydata[prod]] = splitValue[0];
                sum = parseInt(splitValue[1].split(')')[0], 10);
              } else {
                sum = pinnedData[1][keydata[prod]];
              }
              pinnedData[1][keydata[prod]] = pinnedData[1][keydata[prod]] + " (" + (sum - parseInt(row_data[keydata[prod]].qty, 10)) + ")";
            }
          });

          this.pinnedData = pinnedData;

        }
        this.gridOptions.pinnedTopRowData = this.pinnedData;
      } else if (times === 0) {
        this.gridOptions.pinnedTopRowData = this.draft_total;
      } else if (this.pinnedData !== null) {
        for (const prod in keydata) {
          let updated_data = [];

          updated_data = this.stockData2.stock_data.filter(existed => parseInt(keydata[prod].split('_')[1], 10) === existed.product_id);

          if (updated_data.length !== 0) {
            updated_data[0].quantity = this.product_summary[keydata[prod]];
          }

          updated_data = this.po_line_data.filter(existed => parseInt(keydata[prod].split('_')[1], 10) === existed.product_id);

          if (this.direct_dispatch) {

            if (this.pinnedData2[0][keydata[prod]] === undefined) {
              this.pinnedData2[0][keydata[prod]] = 0;
            }

            this.pinnedData2[0][keydata[prod]] = this.pinnedData2[0][keydata[prod]] + " (" +
              (parseInt(this.pinnedData2[0][keydata[prod]], 10) -
                parseInt(this.product_summary[keydata[prod]], 10)) + ")";
          } else {
            if (updated_data.length > 0) {
              this.pinnedData[0][keydata[prod]] = updated_data[0].qty_adjusted;
            }

            if (this.pinnedData[1][keydata[prod]] === undefined) {
              this.pinnedData[1][keydata[prod]] = 0;
            }

            this.pinnedData[1][keydata[prod]] = this.pinnedData[1][keydata[prod]] + " (" +
              (parseInt(this.pinnedData[1][keydata[prod]], 10) -
                parseInt(this.product_summary[keydata[prod]], 10)) + ")";
          }
        }

        if (this.status === 'Completed') {
          this.gridOptions.pinnedTopRowData = [];
        } else {
          if (this.direct_dispatch) {
            this.gridOptions.pinnedTopRowData = this.pinnedData2;
          } else {
            this.gridOptions.pinnedTopRowData = this.pinnedData;
          }
        }
        this.stockData2.order_ids = order_ids;
      }
      if (this.status !== 'Inprogress' && this.status !== 'Completed' && this.featureReadjust) {
        this.gridOptions.pinnedTopRowData.push(this.readjustreqpinnedData);
      }
      if (this.status !== 'Completed' && this.featureReadjust) {
        if (this.gridOptions.pinnedTopRowData) {
          const found =  this.gridOptions.pinnedTopRowData.find(x => x.VAN === 'BATCH SCALE');
          if (!found) {
            this.gridOptions.pinnedTopRowData.push(batchData);
          }
        }
      }

      this.data = rowdata[0];
      this.rowData = rowdata;
      let hide = true;
      let cat;

      for (const categ in prod_categories) {
        cat = prod_categories[categ];
        this.columnDefs.push({
          headerName: cat.category,
          children: []
        });

        for (const prod in cat['products']) {
          if ( this.all_products['p_' + cat['products'][prod]]) {
            this.all_products['p_' + cat['products'][prod]]['categ'] = cat.category;
          }
        }
      }

      let cat_data = [];
      const all_products = this.all_products;

      for (const key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          if (key !== 'last_eod' && key !== 'last_started' && this.data[key] !== null && typeof (this.data[key]) === 'object') {

            cat_data = this.columnDefs.filter(existed => all_products[key].categ === existed.headerName);

            cat_data[0].children.push({
              headerName: all_products[key].name, field: key, sortable: true, resizable: true, width: 90,
              tooltipComponent: 'customtooltipComponent',
              tooltipField: key,
              cellRendererSelector(params) {
                if (params.node.rowPinned) {
                  return {
                    component: PinnedCellRenderer,
                  };
                } else {
                  return {
                    component: CellEditRenderer,
                  };
                }
              },
            });
          } else {
            if (key === "VAN") {
              const status = this.status;
              this.columnDefs.push({
                headerName: key,
                field: key,
                sortable: true,
                filter: false,
                unSortIcon: true,
                lockPosition: true,
                width: 150,
                resizable: true,
                pinned: 'left',
                headerCheckboxSelection(params) {
                  return (status === 'Processed' || status === 'Inprogress');
                },
                headerCheckboxSelectionFilteredOnly: false,
                checkboxSelection(params) {
                  return ((!params.data.duplicate) && (status === 'Processed' || status === 'Inprogress' || status === 'Draft') && (params.data.dispatch_status === 'new') &&
                    (params.data.last_eod === null) && (params.data.last_started === null));
                },
                cellRenderer: 'vanDisplayRenderer'
              });

            } else if (key === "order_id" || key === 'duplicate') {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "po") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "dispatch_status") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "all_status") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "ostatus") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "vansale_id") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "user_id" || key === "van_code") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "user_territory_id") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "last_eod") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "last_started") {
              this.columnDefs.push({
                headerName: key, field: key, hide: true
              });
            } else if (key === "QTY") {
              hide = (times === 0 || times === 1);
              this.columnDefs.push({
                headerName: key,
                field: key,
                sortable: true,
                filter: false,
                lockPosition: true,
                pinned: 'left',
                hide
              });
            } else if (key === "CASH") {
              hide = (times === 0 || times === 1);
              this.columnDefs.push({
                headerName: key,
                field: key,
                sortable: true,
                filter: false,
                hide,
                lockPosition: true,
                pinned: 'left',
              });
            }
          }
        }
      }
      this.loaded = true;
      this.apiloaded = true;
      this.dataloaded = true;
      this.saveCaption = ["", 0];
      if (times === 2) {
        this.submit = false;
        this.dispatch = true;
      }

      this.context = {componentParent: this};
      this.frameworkComponents = {
        cellEditRenderer: CellEditRenderer,
        pinnedCellRenderer: PinnedCellRenderer,
        vanDisplayRenderer: VanDisplayRendererComponent,
        customtooltipComponent: CustomtooltipComponent
      };

      this.checkMissingPO();

    } else {
      this.preloader = false;
      this.empty_state = true;
      this.apiloaded = true;
    }
  }

  vanList() {
    const viewContainerRef2 = this.adHost2.viewContainerRef;
    viewContainerRef2.clear();

    const componentFactory2 = this.componentFactoryResolver.resolveComponentFactory(SupplierDropdownComponent);

    const componentRef2 = viewContainerRef2.createComponent<SupplierDropdownComponent>(componentFactory2);
    componentRef2.instance.itemList = this.vans;
    componentRef2.instance.statusChange.subscribe(res => {

      if (res && res.length > 0) {
        this.van_code = res[0].code;
      }
    });
  }

  async reAdjustValues(data) {
    console.log(data);
    const column = data.column.getColId();
    const product_id = parseInt(column.split('_')[1], 10);
    this.openReAdjustConfirmationDialog(product_id, data.colDef.headerName);
  }

  storeScaleUnitValues() {
    if (!this.user_report_config) {
      this.user_report_config = [];
    }
    const batchscaleData =  this.gridOptions.pinnedTopRowData.find(x => x['VAN'] === 'BATCH SCALE');
    this.user_report_config['po_uom'] = batchscaleData;

    const paramObject: any = {};
    paramObject.cid = JSON.parse(localStorage.getItem('user')).cid;
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = this.user_report_config;
    this.apiService.post('/api/res_users/saveUsersReportJSON', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          const userconfig = JSON.parse(localStorage.getItem('user'));
          userconfig.report_json = this.user_report_config;
          localStorage.setItem('user', JSON.stringify(userconfig));
          this.user_uom_config = this.user_report_config['po_uom'];
        } else {
          console.error('Error Calling saveUsersReportJSON :' + res);
        }
      }, error => {
        console.error('Error Calling saveUsersReportJSON :' + error);
      });
  }

  prodList() {
    const viewContainerRef3 = this.adHost3.viewContainerRef;
    viewContainerRef3.clear();

    const componentFactory3 = this.componentFactoryResolver.resolveComponentFactory(SuppProdDropdownComponent);

    const componentRef3 = viewContainerRef3.createComponent<SuppProdDropdownComponent>(componentFactory3);
    componentRef3.instance.itemList = this.loadoutProds[0].products;
    componentRef3.instance.statusChange.subscribe(res => {

      if (res && res.length > 0) {
        this.loadout_prod.push(res[0]);
      }
    });
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    if (this.multiple_loadout && column === 'VAN') {
      this.confirmEnable = true;
      this.editLoadout = true;
      const data = event.data;
      if (data.dispatch_status === 'delivered') {
        this.confirmEnable = false;
      }
      this.rowData1 = [];
      this.edit_order_id = data.order_id;
      for (const key in this.products) {
        if (parseInt(data[this.products[key]].qty, 10) !== 0) {
          this.rowData1.push({
            PRODUCT: this.all_products[this.products[key]].name,
            STOCK: this.all_products[this.products[key]].stock,
            QTY: data[this.products[key]].qty,
            PRICE: "",
            code: this.all_products[this.products[key]].code,
            loadout: true
          });
        }
      }
      if (this.gridApi1) {
        this.gridApi1.setRowData(this.rowData1);
      }
      this.van_code = event.data['van_code'];
      this.van_show = false;
      this.prodList();
      this.productEntry(false);
      const el: HTMLElement = this.newLoadout.nativeElement;
      el.click();
    }
  }

  new_loadout() {
    this.van_show = true;
    this.rowData1 = [];
    this.editLoadout = false;
    this.confirmEnable = true;
    if (this.gridApi1) {
      this.gridApi1.setRowData(this.rowData1);
    }
    this.vanList();
    this.prodList();
    this.productEntry(true);
    const el: HTMLElement = this.newLoadout.nativeElement;
    el.click();
  }

  addProduct() {
    const prod = this.loadout_prod[0];
    this.rowData1.push({
      PRODUCT: prod.itemName,
      STOCK: prod.stock,
      QTY: 0,
      PRICE: 0,
      code: prod.code,
      loadout: true
    });
    this.gridApi1.setRowData(this.rowData1);
    this.loadout_prod = [];
  }

  loadConfirm() {

    this.dataloaded = false;
    const loadout_data = [];
    const van_code = this.van_code;
    this.user_data.flag = this.multiple_loadout;
    let editable = false;
    if (van_code) {
      const date = this.datePipe.transform(this.user_data.date, 'dd/MM/yyyy');
      if (this.editLoadout) {
        editable = true;
      }
      const edit_order_id = this.edit_order_id;
      this.rowData1.forEach(prod => {
        loadout_data.push({
          VanCode: van_code,
          LoadoutDate: date,
          ProductCode: prod.code,
          Qty: prod['QTY'],
          editable,
          order_id: edit_order_id
        });
      });
      if (loadout_data.length > 0) {

        this.user_data.flag = this.multiple_loadout;
        this.user_data.loadout_data = loadout_data;
        if (this.user_data.dc_centers.length === 0) {
          this.user_data.dc_centers.push(this.user_data.dc_id);
        }
        this.res_data = this.apiService.uploadLoadout(this.user_data);
        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results')) {
            window.alert(res['results'].msg);
            this.apiProList(0);
          }
        }, error => {
          this.dataloaded = true;
          console.log(error);
          if (error.error.error['msg'] === undefined) {
            window.alert("Failed");
          } else {
            window.alert(error.error.error['msg']);
          }
        });
      } else {
        this.dataloaded = true;
        window.alert('Enter Qty');
      }
    } else {
      this.dataloaded = true;
      window.alert('please select van');
    }
  }

  productEntry(new_val) {
    this.loaded1 = false;
    if (new_val) {
      this.rowData1 = [];
    }
    this.data1 = {
      PRODUCT: "",
      STOCK: "",
      QTY: "",
      PRICE: "",
      code: "",
      loadout: null
    };
    for (const key in this.data1) {
      if (this.data1.hasOwnProperty(key)) {
        if (key === 'PRICE' || key === 'code' || key === 'loadout') {
          this.columnDefs1.push({
            headerName: key,
            field: key,
            sortable: true,
            filter: false,
            unSortIcon: true,
            lockPosition: true,
            editable: false,
            hide: true
          });
        } else if (key === 'QTY') {
          this.columnDefs1.push({
            headerName: key,
            field: key,
            sortable: true,
            filter: false,
            unSortIcon: true,
            lockPosition: true,
            editable: false,
            cellRenderer: "cellEdit2Renderer",
          });
        } else {
          this.columnDefs1.push({
            headerName: key,
            field: key,
            sortable: true,
            filter: false,
            unSortIcon: true,
            lockPosition: true,
            editable: false,
          });
        }
      }
    }
    this.loaded1 = true;
    this.frameworkComponents = {
      colorCellRenderer: ColorCellRenderer,
      cellEdit2Renderer: CellEdit2Renderer
    };
  }

  removeProduct(index) {
    this.rowData1.splice(index, 1);
    this.gridApi1.setRowData(this.rowData1);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.ColumnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid');
    const columns = params.columnApi.getAllDisplayedVirtualColumns();
    this.colIds = columns.map(column => column.colId);
  }

  onGridReady1(params) {
    //new
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.ColumnApi;
    params.api.setRowData(this.rowData1);
    this.gridApi1.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions1.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi1.autoSizeColumns();
    }
  }

  onGridReadyPO(params) {
    //new
    this.gridApiPO = params.api;
    this.gridColumnApiPO = params.ColumnApi;
    params.api.setRowData(this.rowDataPO);
    //this.gridApiPO.setDomLayout("autoHeight");
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptionsPO.api.sizeColumnsToFit();
    } else {
      this.gridColumnApiPO.autoSizeColumns();
    }
  }
  generateEway() {
    this.dataloaded = false;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.date = this.get_user_data.date;
    this.apiService.post('/api/eway_bills/generateEwayBills', paramObject)
      .subscribe(res => {
        console.log(res);
        this.dataloaded = true;
        if (res.hasOwnProperty('result') && (res.result.status === 200)) {
          if (res.result.status === 200) {
            if (res.result.data && res.result.data.failures) {
              //errored records
              let count = 0;
              if (res.result.data.failures.vansale) {
                count += res.result.data.failures.vansale.length;
              }
              if (res.result.data.failures.order) {
                count += res.result.data.failures.order.length;
              }
              this.toastr.error("Eway Bills Generation Failed!! - " + count);
            } else {
              this.toastr.success("Eway Bills Generated Successfully!!");
            }
          } else if (res.result.status === 403) {
            if (res.result?.data?.msg) {
              this.toastr.error("Eway Bills Generation Failed!! - " + res.result.data.msg);
            } else {
              this.toastr.error("Eway Bills Generation Failed!!");
            }
          } else {
            this.toastr.error("Eway Bills Generation Failed!!");
          }
        }
      }, error => {
        this.toastr.error("Eway Bills Generation Failed!!");
        this.dataloaded = true;
      });
  }
  printPicklist() {
    const order_ids = [];
    const paramObject: any = {};
    paramObject.access_token =  localStorage.getItem('resfreshToken');
    paramObject.date = this.get_user_data.date;
    const stockObject = [];
    const vansale_ids = [];
    this.rowData.forEach(row => {
      if (row.dispatch_status === 'dispatched' || row.dispatch_status === 'delivered') {
        if (!paramObject.vansale_id && row.vansale_id) {
          paramObject.vansale_id = row.vansale_id;
          paramObject.van_id = row.van_id;
          paramObject.salesman_id = row.user_id;
        }
        vansale_ids.push(row.vansale_id);
        stockObject.push({
          date: this.get_user_data.date,
          vansale_id: row.vansale_id,
          van_id: row.van_id,
          salesman_id: row.user_id
        });
        order_ids.push(row.order_id);
      }
    });
    let order_data: any = {};
    if (this.featureEwayGenerate) {
      order_data = {
        access_token: this.user_data.access_token,
        type: 'Consolidated',
        id: null,
        vansale_ids
      };
      this.apiService.post('/api/eway_bills/getEwayForVansales', order_data)
        .subscribe(async res => {
          console.log(res);
          if (res.hasOwnProperty('result') && (res['result'].status === 200) && (res['result'].data.length > 0)) {
            await this.generateEwayPrint(res.result.data);
            const el: HTMLElement = this.interPrint.nativeElement;
            el.click();
          }
        });
    } else {
      order_data = {
        access_token: this.user_data.access_token,
        order_id: order_ids
      };
      this.apiService.post('/api/pwa_connects/get_picklist_print_details', order_data)
        .subscribe(async res => {
          console.log(res);
          if (res.hasOwnProperty('result') && (res['result'].status === 200) && (res['result'].data)) {
            await this.generateEwayPrint(res.result.data);
            const el: HTMLElement = this.interPrint.nativeElement;
            el.click();
          }
        });
    }
  }

  async generateEwayPrint(orderdata) {
    const all_orders = [];
    for (const data of orderdata) {
      if (data?.print_dtls) {
        all_orders.push(data.print_dtls);
      }
    }
    this.all_orders = all_orders;
    console.log(this.all_orders);
  }

  deleteOrder(order_id) {
    this.user_data.order_id = order_id;
    this.res_data = this.apiService.delete_order(this.user_data);
    this.res_data.subscribe(res => {
      console.log(res);
      window.alert('deleted successfully');
      if (this.status === 'Processed') {
        this.loaded = false;
        this.preloader = true;
        this.res_data = this.apiService.getProduction(this.user_data);
        this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
              this.po_line_data = res['results'].data;
            }
          },
          error => {
            console.log(error);
          });
        this.sendTOproduction(3);
      }  else if (this.status === 'Completed') {
        this.loaded = false;
        this.preloader = true;
        this.res_data = this.apiService.getProduction(this.user_data);
        this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
              this.po_line_data = res['results'].data;
              this.sendTOproduction(4);
            } else {
              this.toastr.error("Error fetching Production Order");
            }
          },
          error => {
            console.log(error);
          });
      } else if (this.status === 'Inprogress') {
        this.loaded = false;
        this.preloader = true;
        this.res_data = this.apiService.getProduction(this.user_data);
        this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
              this.po_line_data = res['results'].data;
              this.sendTOproduction(5);
            } else {
              this.toastr.error("Error fetching Production Order");
            }
          },
          error => {
            console.log(error);
          });
      }  else if (this.status === 'Submitted') {
        this.res_data = this.apiService.getProduction(this.user_data);
        this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
              this.po_line_data = res['results'].data;
              this.apiProList(1);
            }
          },
          error => {
            console.log(error);
          });
      } else {
        this.apiProList(0);
      }
    }, error => {
      console.log(error);
      window.alert(error);
    });
  }



  tabToNextCell() {

  }


  editCell(params, value, product, new_prod) {

    if (this.status === 'Draft' && new_prod) {
      let primary_prod_data;

      if (params.order_id === null) {
        primary_prod_data = this.newprodData.filter(existed => ((parseInt(product.split('_')[1], 10) === parseInt(existed.product_id, 10)) &&
          (params.van_code === existed.van_code)));
      } else {
        primary_prod_data = this.newprodData.filter(existed => ((parseInt(product.split('_')[1], 10) === parseInt(existed.product_id, 10)) &&
          (parseInt(params.order_id, 10) === parseInt(existed.order_id, 10))));
      }

      if (primary_prod_data.length === 0 && parseInt(value, 10) > 0) {
        const price_subtotal = (parseFloat(this.all_products[product].price) * parseInt(value, 10)).toFixed(this.apiService.decimalPolicy);
        const price_tax = 0;
        const price_total = price_subtotal;
        let date = (new Date().toISOString()).replace('T', ' ');
        date = date.replace('Z', ' ');

        this.newprodData.push({
          order_id: params.order_id,
          partner_id: params.van_id,
          distributor_id: this.user_data.dc_id,
          product_id: parseInt(product.split('_')[1], 10),
          van_code: params.van_code,
          state: "done",
          product_uom: this.all_products[product].product_uom,
          taxmaster_id: this.all_products[product].taxmaster_id,
          price_unit: this.all_products[product].price,
          product_code: this.all_products[product].code,
          customer_lead: 0,
          name: this.all_products[product].name,
          product_uom_qty: parseInt(value, 10),
          qty_delivered: parseInt(value, 10),
          type: 'normal',
          price_subtotal,
          price_tax: 0,
          price_total,
          create_date: date,
          write_date: date,
          prvs_qty: params[product].qty,
          cid: this.all_products[product].cid,
          po_id: this.user_data.po_id,
          po_date: this.user_data.date
        });
      } else {
        if (parseInt(value, 10) === 0) {
          const index = this.newprodData.indexOf(primary_prod_data[0], 0);
          if (index > -1) {
            this.newprodData.splice(index, 1);
          }
        } else {
          const price_subtotal = (parseFloat(this.all_products[product].price) * parseInt(value, 10)).toFixed(this.apiService.decimalPolicy);
          const price_tax = 0;
          const price_total = price_subtotal;
          primary_prod_data[0].product_uom_qty = parseInt(value, 10);
          primary_prod_data[0].qty_delivered = parseInt(value, 10);
          primary_prod_data[0].prvs_qty = params[product].qty;
          primary_prod_data[0].price_subtotal = price_subtotal;
          primary_prod_data[0].price_tax = price_tax;
          primary_prod_data[0].price_total = price_total;
        }
      }
    } else {
      let primary_prod_data;

      primary_prod_data = this.saveData.filter(existed => ((parseInt(product.split('_')[1], 10) === parseInt(existed.product_id, 10)) &&
        (parseInt(params.order_id, 10) === parseInt(existed.order_id, 10))));

      const price_subtotal = (parseFloat(this.all_products[product].price) * parseInt(value, 10)).toFixed(this.apiService.decimalPolicy);

      if (primary_prod_data.length === 0) {
        this.saveData.push({
          order_id: params.order_id,
          product_id: parseInt(product.split('_')[1], 10),
          qty: parseInt(value, 10),
          state: "done",
          prvs_qty: params[product].qty,
          price_subtotal
        });
      } else {
        primary_prod_data[0].qty = parseInt(value, 10);
        primary_prod_data[0].prvs_qty = params[product].qty;
        primary_prod_data[0].price_subtotal = (parseFloat(this.all_products[product].price) * parseInt(value, 10)).toFixed(this.apiService.decimalPolicy);
      }
    }
    this.product_summary[product] = parseInt(this.product_summary[product], 10) + (parseInt(value, 10) - parseInt(params[product].qty, 10));
    if (this.status === 'Processed') {
      let updated_data;
      updated_data = this.stockData2.stock_data.filter(existed => parseInt(product.split('_')[1], 10) === existed.product_id);

      updated_data[0].quantity = (updated_data[0].quantity - params[product].prvs_qty) + parseInt(value, 10);
      params[product].prvs_qty = parseInt(value, 10);

      if (new_prod) {
        updated_data[0].new_prod[params.order_id] = parseInt(value, 10);
      }

      if (this.direct_dispatch) {
        this.gridOptions.pinnedTopRowData[0][product] = parseInt(this.gridOptions.pinnedTopRowData[0][product].toString().split(" (")[0], 10);
        this.gridOptions.pinnedTopRowData[0][product] = this.gridOptions.pinnedTopRowData[0][product] + " (" + (parseInt(this.gridOptions.pinnedTopRowData[0][product], 10) - updated_data[0].quantity) + ")";
        this.gridApi.setPinnedTopRowData(this.gridOptions.pinnedTopRowData);
      } else {
        this.gridOptions.pinnedTopRowData[1][product] = parseInt(this.gridOptions.pinnedTopRowData[1][product].toString().split(" (")[0], 10);
        this.gridOptions.pinnedTopRowData[1][product] = this.gridOptions.pinnedTopRowData[1][product] + " (" + (parseInt(this.gridOptions.pinnedTopRowData[1][product], 10) - updated_data[0].quantity) + ")";
        this.gridApi.setPinnedTopRowData(this.gridOptions.pinnedTopRowData);
      }
    }

    if (this.status === 'Inprogress') {

      let updated_data;

      updated_data = this.stockData2.stock_data.filter(existed => parseInt(product.split('_')[1], 10) === existed.product_id);

      if (new_prod) {
        updated_data[0].new_prod[params.order_id] = parseInt(value, 10);
      }

      const pinnedData = this.gridOptions.pinnedTopRowData;
      const options = this.gridOptions;
      const focusedCell = options.api.getFocusedCell();
      let editcellvalue;
      const editedValues = this.saveData;
      let splitValue, sum;
      if (typeof pinnedData[1][product] === 'string') {
        splitValue = pinnedData[1][product].split(" (");
        sum = splitValue[0];
      } else {
        sum = pinnedData[1][product];
      }
      this.gridApi.forEachNode(node => {
        const cellValue = options.api.getValue(focusedCell.column, node);

        if (node.data.dispatch_status === 'new') {

          editcellvalue = editedValues.filter(existed => ((parseInt(product.split('_')[1], 10) === parseInt(existed.product_id, 10)) &&
            (parseInt(node.data.order_id, 10) === parseInt(existed.order_id, 10))));

          if (editcellvalue.length > 0) {
            editcellvalue = editcellvalue[0].qty;
          } else {
            if (cellValue.ordered === undefined) {
              editcellvalue = cellValue.qty;
            } else {
              editcellvalue = cellValue.ordered;
            }
          }
          sum = parseInt(sum, 10) - parseInt(editcellvalue, 10);
        }
      });
      pinnedData[1][product] = pinnedData[1][product].split(' (')[0] + ' (' + sum + ')';
      this.gridOptions.pinnedTopRowData = pinnedData;
      this.gridApi.setPinnedTopRowData(this.gridOptions.pinnedTopRowData);
    }
  }

  editProdCell(event, params) {

    if (event !== "") {
      let prod_data;
      prod_data = this.stockData1.line_data.filter(existed => parseInt(params.product_id, 10) === existed.product_id);

      prod_data[0].qty_ordered = event;
      prod_data[0].amount = parseInt(event, 10) * prod_data[0].price;
    }

  }

  editProdCell2(event, params) {

    if (event !== "") {
      let prod_data;

      prod_data = this.saveProdData.filter(existed => parseInt(params.product_id, 10) === existed.product_id);

      prod_data[0].quantity = event;

      let updated_data;

      updated_data = this.stockData2.stock_data.filter(existed => parseInt(params.product_id, 10) === existed.product_id);

      updated_data[0].quantity = event;
    }


  }

  confirmProd() {

    const saveData = {};

    this.stockData1.po_id = this.user_data.po_id;

    saveData['access_token'] = this.user_data.access_token;
    saveData['url'] = this.user_data.url;
    saveData['data'] = this.stockData1;
    saveData['dispatch'] = false;

    this.res_data = this.apiService.submitProductionV3(saveData);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && res['results'].status === 200) {
          this.renderer.addClass(this.elRef.nativeElement.querySelector('.sendpopup'), 'hide');
          this.renderer.removeClass(this.elRef.nativeElement.querySelector('.updatepopup'), 'hide');
          this.saveProdData = [];
          this.status = 'Submitted';
          localStorage.setItem('po_status', this.status);
          this.status_class = "blue-status";
          this.res_data = this.apiService.getProduction(this.user_data);
          this.res_data.subscribe(res => {
              console.log(res);
              if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                this.po_line_data = res['results'].data;
                this.apiProList(1);
              }
            },
            error => {
              console.log(error);
            });
        }
      },
      error => console.log(error));

  }

  confirmProd2() {

    const saveProdData = {};

    saveProdData['access_token'] = this.user_data.access_token;
    saveProdData['url'] = this.user_data.url;
    saveProdData['data'] = {
      line_data: this.POproductList,
      po_id: this.user_data.po_id
    };

    const saveData = {};

    saveData['access_token'] = this.user_data.access_token;
    saveData['url'] = this.user_data.url;
    saveData['data'] = this.POproductList;

    this.res_data = this.apiService.addDcStocks(saveData);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {

          this.res_data = this.apiService.updateProduction(saveProdData);
          this.res_data.subscribe(res => {
              console.log(res);
              if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                this.renderer.removeClass(this.elRef.nativeElement.querySelector('.updatepopup'), 'hide');
                this.renderer.addClass(this.elRef.nativeElement.querySelector('.submitpopup'), 'hide');
                this.status = 'Processed';
                localStorage.setItem('po_status', this.status);
                this.status_class = "blue-status";
                this.loaded = false;
                this.preloader = true;
                this.sendTOproduction(3);
              }
            },
            error => console.log(error));
        }
      },
      error => console.log(error));

  }

  async save() {
    this.dataloaded = false;
    this.saveCaption = ["LOADING..", 0];
    const saveData = {};
    const stockdata = this.gridApi.getPinnedTopRow(0).data;
    const polinedata = [];

    for (const key in this.all_products) {
      const p_id = parseInt(key.split('_')[1], 10);
      const qty = stockdata[key];
      polinedata.push({
        p_id,
        qty
      });
    }
    const savePassData = {
      po_id: this.user_data.po_id,
      update_data: this.saveData,
      newprod_data: this.newprodData,
      polinedata
    };

    saveData['access_token'] = this.user_data.access_token;
    saveData['url'] = this.user_data.url;
    saveData['data'] = savePassData;


    const resp = await this.apiService.updatePrimary(saveData);
    this.dataloaded = true;
    if (resp.hasOwnProperty('results') && (resp['results'].status === 200)) {
      this.saveCaption = ["SAVED SUCCESSFULLY ;)", 0];
      await this.apiProList(0);
    } else {
      this.saveCaption = ["SAVE FAILED!", 1];
    }

    this.saveData = [];
    this.newprodData = [];

  }
  async updateValuestoZero(product_id) {
    const saveData = {};
    const prodUpdate = [];
    let config_json;
    this.rowData.forEach(row => {
      for (const key in row) {
        const p_id = parseInt(key.split('_')[1], 10);
        if (p_id === product_id) {
          if (row[key].hasOwnProperty('find_prod') && row[key].find_prod && row[key].find_prod.length > 0) {
            prodUpdate.push({
              order_id: row.order_id,
              product_id: p_id
            });
          } else {

          }
        }
      }
    });
    console.log(prodUpdate);

    const poline = this.po_line_data.find(existed => existed.product_id === product_id);
    if (poline) {
      config_json = poline.config_json;
      if (config_json) {
        config_json['mark_zero'] = true;
      } else {
        config_json = {
          mark_zero: true
        };
      }
    }

    const savePassData = {
      po_id: this.user_data.po_id,
      line_data: prodUpdate,
      product_id,
      config_json
    };
    saveData['access_token'] = this.user_data.access_token;
    saveData['url'] = this.user_data.url;
    saveData['data'] = savePassData;

    const resp = await this.apiService.poLineMarkZero(saveData);
    this.dataloaded = true;
    if (resp.hasOwnProperty('results') && (resp['results'].status === 200)) {
      this.saveCaption = ["SAVED SUCCESSFULLY ;)", 0];
      //await this.apiProList(0);
      this.changeDate(null);
    } else {
      this.saveCaption = ["SAVE FAILED!", 1];
    }
  }

  sendTOproduction2() {
    this.apiloaded = true;
    this.productList = [];
    const list = [];
    const poList = [];
    const req_data = [];
    const saveProdData = [];
    const po_id = this.user_data.po_id;
    let produced_qty = 0;
    let produced_cash = 0;
    const prod_calc = {};
    let qty_ordered = 0;
    let qty_adjusted = 0;
    const date = this.user_data.date;
    this.pop_loader = true;
    this.preloaderPO = true;
    this.stockData1 = {
      line_data: [],
      po_id: null
    };
    this.stockData2 = {
      stock_data: [],
      order_ids: [],
      po_id: null,
      status: ""
    };
    const cid = JSON.parse(localStorage.getItem('user')).cid;
    let po_config: any = {};

    this.checkMissingPO();

    this.updateConfirm = false;
    console.log(this.elRef.nativeElement.querySelector('.updatepopup'), this.elRef.nativeElement.querySelector('.submitpopup'));
    this.renderer.addClass(this.elRef.nativeElement.querySelector('.updatepopup'), 'hide');
    this.renderer.removeClass(this.elRef.nativeElement.querySelector('.submitpopup'), 'hide');

    this.res_data = this.apiService.getprodOrder(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        const inthis = this;
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          res['results'].data.forEach(data => {
            const poline = inthis.po_line_data.find(existed => existed.product_id === data.product_id);
            if (poline && poline.config_json) {
              po_config = poline.config_json;
            } else {
              po_config = {};
            }
            qty_ordered = data.qty_ordered;
            if (inthis.batchScaleDone && inthis.status.toString().toLowerCase() === 'submitted') {
              if (poline && poline.qty_ordered) {
                qty_ordered = poline.qty_ordered;
              }
            }
            qty_adjusted = qty_ordered - data.in_stock;
            if (qty_adjusted < 0) {
              qty_adjusted = 0;
            }
            if (data.in_stock === null) {
              data.in_stock = 0;
            }
            po_config['sug'] = {};
            po_config.sug['qty'] = inthis.mbqTotal['p_' + data.product_id];
            po_config.sug['pre'] = inthis.mbqPreTotal['p_' + data.product_id];
            po_config.sug['clos'] = inthis.mbqClosingTotal['p_' + data.product_id];

            po_config.sug['channel'] = {};
            for (const key of inthis.channelTypes) {
              if (inthis.mbqChannelTotal['p_' +  data.product_id + '_' + key]) {
                po_config.sug['channel'][key] = inthis.mbqChannelTotal['p_' +  data.product_id + '_' + key];
              }
            }
            /*if (!data.price) {
              this.toastr.error("Price not set for " + data.name);
            }*/
            list.push({
              name: data.name,
              stock: data.in_stock,
              rem: qty_ordered,
              produced: qty_ordered,
              price: data.price.toFixed(inthis.apiService.decimalPolicy),
              product_id: data.product_id,
            });

            req_data.push({
              price: data.price,
              product_id: data.product_id,
              po_id,
              distributor_id: data.dc_id,
              territory_id: localStorage.getItem('dc_territory_id'),
              stock_date: date,
              type: 'p',
              quantity: qty_ordered,
              cid,
              amount: qty_ordered * data.price
            });

            saveProdData.push({
              product_id: data.product_id,
              po_id,
              quantity: qty_ordered
            });

            poList.push({
              price: data.price.toFixed(inthis.apiService.decimalPolicy),
              product_id: data.product_id,
              distributor_id: data.dc_id,
              territory_id: localStorage.getItem('dc_territory_id'),
              stock_date: date,
              type: 'p',
              amount: qty_ordered * data.price,
              po_id,
              category: data.categ,
              name: data.name,
              code: data.default_code,
              req: qty_ordered,
              old_stock: data.in_stock,
              rem: qty_ordered,
              suggested: data.qty_suggested,
              received: 0,
              quantity: qty_ordered,
              po_config,
              in_stock: data.in_stock
            });

            produced_cash += (data.price * data.in_stock);
            produced_qty += data.in_stock;
            prod_calc['p_' + data.product_id] = [data.in_stock, data.price];
          });
          inthis.POproductList = poList;
          inthis.rowDataPO = poList;
          inthis.preloaderPO = false;
          inthis.empty_statePO = false;
          inthis.loadedPO = true;
          if (inthis.gridApiPO !== null && inthis.gridApiPO !== undefined) {
            inthis.gridApiPO.setRowData(inthis.rowDataPO);
          }
          this.productList = list;
          this.stockData1.line_data = poList;
          this.stockData2.stock_data = poList;
          this.saveProdData = saveProdData;
          this.prod_loaded = true;
          this.pinnedData[1]['QTY'] = produced_qty;
          this.pinnedData[1]['CASH'] = (produced_cash / 1000).toFixed(this.apiService.decimalPolicy) + 'K';
          for (const key in prod_calc) {
            this.pinnedData[0][key] = prod_calc[key][1];
            this.pinnedData[1][key] = prod_calc[key][0];
          }
        }
        this.pop_loader = false;
        this.updateConfirm = true;
        this.updateProductionOrderClick();
      },
      error => console.log(error));
  }

  async sendTOproduction(times) {
    this.apiloaded = true;
    this.productList = [];
    const list = [];
    const poList = [];
    const rem = 0;
    const req_data = [];
    const save_data = [];
    const saveProdData = [];
    const po_id = this.user_data.po_id;
    const allocated_qty = 0;
    let produced_qty = 0;
    const allocated_cash = 0;
    let produced_cash = 0;
    const prod_calc = {};
    let qty_ordered = 0;
    let qty_adjusted = 0;
    const qty_allocated = 0;
    let product_summary = this.product_summary;
    const date = this.user_data.date;
    this.pop_loader = true;
    this.preloaderPO = true;
    this.stockData1 = {
      line_data: [],
      po_id: null
    };
    this.stockData2 = {
      stock_data: [],
      order_ids: [],
      po_id: null,
      status: ""
    };
    const cid = JSON.parse(localStorage.getItem('user')).cid;
    let po_config: any = {};

    this.checkMissingPO();

    if (times === 1) {
      this.sendConfirm = false;
      await this.save();
      const inside_this = this;
      inside_this.res_data = inside_this.apiService.getdcStock(inside_this.user_data);
      inside_this.res_data.subscribe(res => {
          console.log(res);
          product_summary = inside_this.product_summary;
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            res['results'].data.forEach(data => {
              const poline = inside_this.po_line_data.find(existed => existed.product_id === data.product_id);
              if (poline && poline.config_json) {
                po_config = poline.config_json;
              } else {
                po_config = {};
              }
              qty_ordered = product_summary['p_' + data.product_id];
              qty_adjusted = qty_ordered - data.in_stock;
              if (qty_adjusted < 0) {
                qty_adjusted = 0;
              }
              if (data.in_stock === null) {
                data.in_stock = 0;
              }
              po_config['sug'] = {};
              po_config.sug['qty'] = inside_this.mbqTotal['p_' + data.product_id];
              po_config.sug['pre'] = inside_this.mbqPreTotal['p_' + data.product_id];
              po_config.sug['clos'] = inside_this.mbqClosingTotal['p_' + data.product_id];

              po_config.sug['channel'] = {};
              for (const key of inside_this.channelTypes) {
                if (inside_this.mbqChannelTotal['p_' + data.product_id + '_' + key]) {
                  po_config.sug['channel'][key] = inside_this.mbqChannelTotal['p_' + data.product_id + '_' + key];
                }
              }
              list.push({
                name: data.name,
                req: qty_ordered,
                stock: data.in_stock,
                rem: qty_adjusted,
                price: data.price.toFixed(inside_this.apiService.decimalPolicy),
                product_id: data.product_id,
              });
              req_data.push({
                price: data.price,
                product_id: data.product_id,
                po_id,
                distributor_id: data.dc_id,
                territory_id: localStorage.getItem('dc_territory_id'),
                stock_date: date,
                type: 's',
                qty_ordered: qty_adjusted,
                cid,
                amount: qty_adjusted * data.price,
                qty_suggested: qty_ordered,
                po_config,
                old_stock: data.in_stock,
                in_stock: data.in_stock
              });
              saveProdData.push({
                product_id: data.product_id,
                po_id,
                quantity: qty_adjusted
              });
            });
            inside_this.productList = list;
            inside_this.stockData1.line_data = req_data;
            inside_this.saveProdData = saveProdData;
            inside_this.prod_loaded = true;
          }
          inside_this.pop_loader = false;
          inside_this.sendConfirm = true;
        },
        error => console.log(error));
    } else if (times === 2) {

      this.res_data = this.apiService.getprodOrder(this.user_data);
      this.res_data.subscribe(res => {
          console.log(res);
          const inthis = this;
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            res['results'].data.forEach(data => {
              const poline = inthis.po_line_data.find(existed => existed.product_id === data.product_id);
              if (poline && poline.config_json) {
                po_config = poline.config_json;
              } else {
                po_config = {};
              }
              qty_ordered = data.qty_ordered;
              qty_adjusted = qty_ordered - data.in_stock;
              if (qty_adjusted < 0) {
                qty_adjusted = 0;
              }
              if (data.in_stock === null) {
                data.in_stock = 0;
              }

              po_config['sug'] = {};
              po_config.sug['qty'] = inthis.mbqTotal['p_' + data.product_id];
              po_config.sug['pre'] = inthis.mbqPreTotal['p_' + data.product_id];
              po_config.sug['clos'] = inthis.mbqClosingTotal['p_' + data.product_id];

              po_config.sug['channel'] = {};
              for (const key of inthis.channelTypes) {
                if (inthis.mbqChannelTotal['p_' + data.product_id + '_' + key]) {
                  po_config.sug['channel'][key] = inthis.mbqChannelTotal['p_' + data.product_id + '_' + key];
                }
              }

              list.push({
                name: data.name,
                stock: data.in_stock,
                rem: qty_ordered,
                produced: qty_ordered,
                price: data.price.toFixed(inthis.apiService.decimalPolicy),
                product_id: data.product_id,
              });

              req_data.push({
                price: data.price,
                product_id: data.product_id,
                po_id,
                distributor_id: data.dc_id,
                territory_id: localStorage.getItem('dc_territory_id'),
                stock_date: date,
                type: 'p',
                quantity: qty_ordered,
                cid,
                amount: qty_ordered * data.price
              });

              saveProdData.push({
                product_id: data.product_id,
                po_id,
                quantity: qty_ordered
              });

              poList.push({
                price: data.price.toFixed(inthis.apiService.decimalPolicy),
                product_id: data.product_id,
                distributor_id: data.dc_id,
                territory_id: localStorage.getItem('dc_territory_id'),
                stock_date: date,
                type: 'p',
                amount: qty_ordered * data.price,
                po_id,
                category: data.categ,
                name: data.name,
                code: data.default_code,
                req: qty_ordered,
                old_stock: data.in_stock,
                rem: qty_ordered,
                suggested: data.qty_suggested,
                received: 0,
                quantity: qty_ordered,
                po_config,
                in_stock: data.in_stock
              });

              produced_cash += (data.price * data.in_stock);
              produced_qty += data.in_stock;
              prod_calc['p_' + data.product_id] = [data.in_stock, data.price];
            });
            inthis.POproductList = poList;
            inthis.rowDataPO = poList;
            inthis.preloaderPO = false;
            inthis.empty_statePO = false;
            inthis.loadedPO = true;
            this.productList = list;
            this.stockData1.line_data = poList;
            this.stockData2.stock_data = poList;
            this.saveProdData = saveProdData;
            this.prod_loaded = true;
            this.pinnedData[1]['QTY'] = produced_qty;
            this.pinnedData[1]['CASH'] = (produced_cash / 1000).toFixed(this.apiService.decimalPolicy) + 'K';
            for (const key in prod_calc) {
              this.pinnedData[0][key] = prod_calc[key][1];
              this.pinnedData[1][key] = prod_calc[key][0];
            }
          }
          this.pop_loader = false;
          this.updateConfirm = true;
        },
        error => console.log(error));
    } else if (times === 3) {
      this.res_data = this.apiService.getprodOrder(this.user_data);
      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            const inside_this = this;
            res['results'].data.forEach(data => {
              qty_ordered = data.qty_ordered;
              if (data.in_stock === null) {
                data.in_stock = 0;
              }
              req_data.push({
                price: data.price,
                product_id: data.product_id,
                po_id,
                distributor_id: data.dc_id,
                territory_id: localStorage.getItem('dc_territory_id'),
                stock_date: date,
                type: 's',
                quantity: qty_ordered,
                cid,
                amount: qty_ordered * data.price,
                new_prod: {},
                taxmaster_id: data.taxmaster_id,
                tax_pct: data.tax_pct,
                name: data.name,
                product_uom: data.product_uom,
                old_stock: data.in_stock,
                in_stock: data.in_stock
              });
              prod_calc['p_' + data.product_id] = [data.in_stock, data.price];
            });
            this.stockData2.stock_data = req_data;
            for (const key in prod_calc) {
              if (this.direct_dispatch) {
                this.pinnedData2[0][key] = prod_calc[key][0];
              } else {
                this.pinnedData[0][key] = prod_calc[key][1];
                this.pinnedData[1][key] = prod_calc[key][0];
              }
            }
            this.res_data = this.apiService.getProduction(this.user_data);
            this.res_data.subscribe(res => {
                console.log(res);
                if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                  this.po_line_data = res['results'].data;
                  this.apiProList(2);
                }
              },
              error => {
                console.log(error);
              });
          }
        },
        error => console.log(error));
    } else if (times === 4) {
      this.res_data = this.apiService.getprodOrder(this.user_data);
      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            res['results'].data.forEach(data => {
              prod_calc['p_' + data.product_id] = [data.line_id];
            });
            this.lastpinnedData[0]['QTY'] = produced_qty;
            this.lastpinnedData[0]['CASH'] = (produced_cash / 1000).toFixed(this.apiService.decimalPolicy) + 'K';
            for (const key in prod_calc) {
              this.lastpinnedData[0][key] = prod_calc[key][0];
            }
            this.apiProList(3);

          }

        },
        error => console.log(error));

    } else if (times === 5) {
      this.res_data = this.apiService.getprodOrder(this.user_data);
      this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            res['results'].data.forEach(data => {

              qty_ordered = data.qty_ordered;

              if (data.in_stock === null) {
                data.in_stock = 0;
              }

              req_data.push({
                price: data.price,
                product_id: data.product_id,
                po_id,
                distributor_id: data.dc_id,
                territory_id: localStorage.getItem('dc_territory_id'),
                stock_date: date,
                type: 's',
                quantity: qty_ordered,
                cid,
                amount: qty_ordered * data.price,
                new_prod: {},
                taxmaster_id: data.taxmaster_id,
                tax_pct: data.tax_pct,
                name: data.name,
                product_uom: data.product_uom,
                old_stock: data.in_stock,
                in_stock: data.in_stock
              });

              produced_cash += (data.price * data.in_stock);
              produced_qty += data.in_stock;
              prod_calc['p_' + data.product_id] = [data.in_stock, data.price];

            });
            this.stockData2.stock_data = req_data;
            this.pinnedData[1]['QTY'] = produced_qty;
            this.pinnedData[1]['CASH'] = (produced_cash / 1000).toFixed(this.apiService.decimalPolicy) + 'K';
            for (const key in prod_calc) {
              this.pinnedData[0][key] = prod_calc[key][1];
              this.pinnedData[1][key] = prod_calc[key][0];
            }
            this.res_data = this.apiService.getProduction(this.user_data);
            this.res_data.subscribe(res => {
                console.log(res);
                if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                  this.po_line_data = res['results'].data;
                  this.apiProList(4);
                }
              },
              error => {
                console.log(error);
              });
          }
        },
        error => console.log(error));
    }
  }

  ngOnInit() {
    this.sharedService.setHeader('Production Order');
    this.passedParams = JSON.parse(localStorage.getItem('params'));
    this.passedParams.not_placed = parseInt(this.passedParams['TOTAL VANS'], 10) - parseInt(this.passedParams['VANS SETTLED'], 10);
    this.passedParams.perc = ((parseInt(this.passedParams['VANS SETTLED'], 10) / parseInt(this.passedParams.not_placed, 10)) * 100).toFixed(this.apiService.decimalPolicy);
    if (this.passedParams.perc === 'Infinity') {
      this.passedParams.perc = '0';
    }
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable === "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');

    this.columnDefsPO = [
      {headerName: "CATEGORY", headerClass: 'myagheader', field: "category", width: 100},
      {headerName: "PCODE", headerClass: 'myagheader', field: "code", menuTabs: [], width: 70},
      {headerName: "PRODUCT", headerClass: 'myagheader', field: "name", menuTabs: [], width: 230},
      {
        headerName: "PRICE",
        headerClass: 'myagheader',
        field: "price",
        menuTabs: [],
        width: 70,
        cellStyle: {textAlign: 'right'}
      },
      {
        headerName: "REQUESTED",
        headerClass: 'myagheader',
        field: "req",
        menuTabs: [],
        width: 90,
        cellStyle: {textAlign: 'right'}
      },
      {
        headerName: "SUGGESTED",
        headerClass: 'myagheader',
        field: "suggested",
        menuTabs: [],
        width: 90,
        cellStyle: {textAlign: 'right'}
      },
      {
        headerName: "OLD STOCK",
        headerClass: 'myagheader',
        field: "old_stock",
        menuTabs: [],
        width: 80,
        cellStyle: {textAlign: 'right'}
      },
      {
        headerName: "RECEIVED",
        headerClass: 'myagheader',
        field: "received",
        menuTabs: [],
        width: 80,
        cellStyle: {textAlign: 'right'},
        cellRenderer(params) {
          let displayElem = '';
          if (params.data) {
            if (params.value > params.data.req) {
              displayElem = '<span style="color:#379862; font-weight: 500;">' + params.value + '</span>';
            }  else if (params.value < params.data.req) {
              displayElem = '<span style="color:#B82525; font-weight: 500;">' + params.value + '</span>';
            } else {
              displayElem = '<span style="font-weight: 500;">' + params.value + '</span>';
            }
          }
          return displayElem;
        },
      },
      {
        headerName: "TOTAL",
        headerClass: 'myagheader',
        field: "total",
        menuTabs: [],
        width: 80,
        cellStyle: {textAlign: 'right'}
      },
    ];
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {
    this.apiloaded = false;
    this.user_data.date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');

    const all_prod = {};
    this.gridOptions.pinnedTopRowData = [];
    this.pinnedData = [];
    this.readjustreqpinnedData = {
      VAN: "RESET TO 0"
    };
    this.batchscalepinnedData = {
      VAN: "BATCH SCALE"
    };
    this.finalreqpinnedData = {
      VAN: "FINAL REQ"
    };
    this.pinnedData2 = [
      {
        VAN: "AVAILABLE QTY"
      }];
    this.pinnedData = [{
      VAN: "PRODUCED QTY"
    },
      {
        VAN: "AVAILABLE QTY"
      }];
    this.res_data = this.apiService.getProducts(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          res['results'].data.forEach(data => {
            all_prod['p_' + data.id] = {
              name: data.name,
              product_uom: data.uom_id,
              cid: data.cid,
              code: data.code,
              price: data.price,
              taxmaster_id: data.taxmaster_id,
              tax_pct: data.tax_pct,
              stock: data.stock
            };
          });
          this.all_products = all_prod;
          console.log(this.all_products);
          localStorage.setItem('products', JSON.stringify(this.all_products));
        }
      },
      error => console.log(error));
    this.res_data = this.apiService.getPOStatus(this.user_data);

    this.res_data.subscribe(res => {

        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200) && (res['results'].data.length > 0)) {
          this.status = res['results'].data[0].status[0].toUpperCase() + res['results'].data[0].status.slice(1);
          localStorage.setItem('po_status', this.status);
          this.user_data.po_id = res['results'].data[0].id;

          if (this.status === 'Draft') {
            this.status_class = 'green-status';
          } else if (this.status === 'Completed' || this.status === 'Inprogress') {
            this.status_class = 'grey-status';
          } else {
            this.status_class = 'blue-status';
          }

          if (this.status === 'Processed') {
            this.loaded = false;
            this.preloader = true;
            this.res_data = this.apiService.getProduction(this.user_data);
            this.res_data.subscribe(res => {
                console.log(res);
                if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                  this.po_line_data = res['results'].data;
                  this.sendTOproduction(3);
                }
              },
              error => {
                console.log(error);
              });
          } else if (this.status === 'Completed') {
            this.loaded = false;
            this.preloader = true;
            this.res_data = this.apiService.getProduction(this.user_data);
            this.res_data.subscribe(res => {
                console.log(res);
                if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                  this.po_line_data = res['results'].data;
                  this.sendTOproduction(4);
                } else {
                  this.toastr.error("Error fetching Production Order");
                }
              },
              error => {
                console.log(error);
              });
          } else if (this.status === 'Inprogress') {
            this.loaded = false;
            this.preloader = true;
            this.res_data = this.apiService.getProduction(this.user_data);
            this.res_data.subscribe(res => {
                console.log(res);
                if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                  this.po_line_data = res['results'].data;
                  this.sendTOproduction(5);
                } else {
                  this.toastr.error("Error fetching Production Order");
                }
              },
              error => {
                console.log(error);
              });
          } else if (this.status === 'Submitted') {
            this.res_data = this.apiService.getProduction(this.user_data);
            this.res_data.subscribe(res => {
                console.log(res);
                if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
                  this.po_line_data = res['results'].data;
                  this.apiProList(1);
                } else {
                  this.toastr.error("Error fetching Production Order");
                }
              },
              error => {
                console.log(error);
              });
          } else {
            this.apiProList(0);
          }
          this.apiloaded = true;
        } else {
          this.status = 'Not Started';
          this.loaded = false;
          this.empty_state = true;
        }

      },
      error => {
        this.apiloaded = true;
        console.log(error);
      });
  }

  checkMissingPO() {
    this.polineMismatch = false;
    if (this.status !== 'Completed') {
      this.res_data = this.apiService.getdcStock(this.user_data);
      const inthis = this;
      let tpdata = [];
      let podata = [];
      this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          console.log(inthis.po_line_data);
          if (res.results.data && inthis.po_line_data) {
            inthis.po_line_data.forEach(poline => {
              podata.push(poline.product_id);
            });
            res.results.data.forEach(tpline => {
              tpdata.push(tpline.product_id);
            });

            let product = [];
            product = inthis.po_line_data.filter(item => !tpdata.includes(item.product_id));
            if (product !== null && product !== undefined && product.length > 0) {
              inthis.polineMismatch = true;
            } else {
              product = [];
              product = res.results.data.filter(item => !podata.includes(item.product_id));
              if (product !== null && product !== undefined && product.length > 0) {
                inthis.polineMismatch = true;
              }
            }
            if ( inthis.polineMismatch) {
              $('#polModal').modal('hide');
              $('#polModalUpdate').modal('hide');
              $('#sapPullModal').modal('hide');
              this.toastr.error("PO MISMATCH : Please fix PO Mismatch");
            }
          } else {
            inthis.polineMismatch = false;
          }
        }
      }, error => {
        console.log(error);
      });
    }
  }

  fixPOMismtach() {
    this.dataloaded = false;
    const inthis = this;
    this.apiService.post('/api/pwa_connects/fix_po_mismatch', this.user_data)
      .subscribe(res => {
        console.log(res);
        this.dataloaded = true;
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.polineMismatch = false;
          this.toastr.success("PO Mismatch fixed Successfully");
          this.changeDate(null);
        } else {
          this.toastr.error('' + res['results'].msg);
        }
      }, error => {
        console.log(error);
        this.dataloaded = true;
      });
  }

  directDispatchCall() {

    let qty_ordered = 0;
    const qty_adjusted = 0;
    const product_summary = this.product_summary;
    const po_id = this.user_data.po_id;
    const date = this.user_data.date;
    const cid = JSON.parse(localStorage.getItem('user')).cid;
    this.user_data.dispatch = this.multiple_loadout;
    let po_config: any = {};
    this.res_data = this.apiService.getdcStock(this.user_data);
    const inthis = this;
    this.res_data.subscribe(res => {
      console.log(res);
      if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
        const req_data = [];
        res['results'].data.forEach(data => {
          const poline = inthis.po_line_data.find(existed => existed.product_id === data.product_id);
          if (poline && poline.config_json) {
            po_config = poline.config_json;
          } else {
            po_config = {};
          }
          qty_ordered = product_summary['p_' + data.product_id];

          po_config['sug'] = {};
          po_config.sug['qty'] = inthis.mbqTotal['p_' + data.product_id];
          po_config.sug['pre'] = inthis.mbqPreTotal['p_' + data.product_id];
          po_config.sug['clos'] = inthis.mbqClosingTotal['p_' + data.product_id];

          po_config.sug['channel'] = {};
          for (const key of inthis.channelTypes) {
            if (inthis.mbqChannelTotal['p_' + data.product_id + '_' + key]) {
              po_config.sug['channel'][key] = inthis.mbqChannelTotal['p_' + data.product_id + '_' + key];
            }
          }

          req_data.push({
            price: data.price,
            product_id: data.product_id,
            po_id,
            distributor_id: data.dc_id,
            territory_id: localStorage.getItem('dc_territory_id'),
            stock_date: date,
            type: 's',
            qty_ordered,
            cid,
            amount: qty_ordered * data.price,
            po_config,
            old_stock: data.in_stock
          });
        });
        this.stockData1.line_data = req_data;
        const saveData = {};

        this.stockData1.po_id = this.user_data.po_id;

        saveData['access_token'] = this.user_data.access_token;
        saveData['url'] = this.user_data.url;
        saveData['data'] = this.stockData1;
        saveData['dispatch'] = true;

        this.res_data = this.apiService.submitProductionV3(saveData);
        this.res_data.subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
            this.status = 'Processed';
            localStorage.setItem('po_status', this.status);
            this.status_class = "blue-status";
            this.loaded = false;
            this.preloader = true;
            this.sendTOproduction(3);
          }
        }, error => {
          console.log(error);
        });
      }
    }, error => {
      console.log(error);
    });

  }

  fetchUpdatedStock() {
    const req_data = [];
    const po_id = this.user_data.po_id;
    let qty_ordered = 0;
    const date = this.user_data.date;
    const cid = JSON.parse(localStorage.getItem('user')).cid;
    this.res_data = this.apiService.getprodOrder(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          res['results'].data.forEach(data => {

            qty_ordered = data.qty_ordered;

            if (data.in_stock === null) {
              data.in_stock = 0;
            }
            let new_prod = this.stockData2.stock_data.find(x => x.product_id === data.product_id);
            if (!new_prod) {
              new_prod = {};
            } else {
              new_prod = new_prod.new_prod;
            }
            req_data.push({
              price: data.price,
              product_id: data.product_id,
              po_id,
              distributor_id: data.dc_id,
              territory_id: localStorage.getItem('dc_territory_id'),
              stock_date: date,
              type: 's',
              quantity: qty_ordered,
              cid,
              amount: qty_ordered * data.price,
              new_prod,
              taxmaster_id: data.taxmaster_id,
              tax_pct: data.tax_pct,
              name: data.name,
              product_uom: data.product_uom,
              old_stock: data.in_stock,
              in_stock: data.in_stock
              // "line_id": data.line_id,
              // "product_uom": data.product_uom
            });
          });
          this.stockData2.stock_data = req_data;
          this.res_data = this.apiService.getProduction(this.user_data);
          this.res_data.subscribe(res1 => {
              console.log(res1);
              if (res1.hasOwnProperty('results') && (res1['results'].status === 200)) {
                this.po_line_data = res1['results'].data;
                this.dispatchCall();
              }
            },
            error => {
              console.log(error);
            });
        }
      },
      error => console.log(error));
  }

  dispatchCall() {
    this.dataloaded = false;
    this.status = 'Processing';
    this.loaded = false;
    this.preloader = true;
    const saveData = {};
    const rows = this.gridApi.getSelectedRows();
    const nodes = this.gridApi.getSelectedNodes();
    const save_van_data = this.saveData;
    const stockData2 = this.stockData2.stock_data;
    let newstockData2 = {
      stock_data: [],
      order_ids: [],
      po_id: null,
      mark_delivery: true,
      primary_data: null
    };

    let flag = true;
    const gridoptions = this.gridOptions.pinnedTopRowData;
    const product_sum = {};
    let primaryData;
    const van_wise_data = [];
    const date = this.user_data.date;
    const products = this.products;
    const all_products = this.all_products;
    const apiServce = this.apiService;
    let res_data;
    const status = this.status;
    const saveCaption = this.saveCaption;
    const status_class = this.status_class;
    const loaded = this.loaded;
    const preloader = this.preloader;
    const sendToProduction = this.sendTOproduction;
    const all_data = [];
    let negative_products = [];
    const inside_this = this;
    let po_config:any = {};

    saveData['access_token'] = this.user_data.access_token;
    saveData['url'] = this.user_data.url;

    if (rows.length !== 0) {
      rows.forEach(van_row => {

        let selected_van;

        newstockData2 = {
          stock_data: [],
          order_ids: [],
          po_id: this.user_data.po_id,
          mark_delivery: true,
          primary_data: null
        };


        if (van_row.order_id !== null) {
          newstockData2.order_ids.push(van_row.order_id);
        }

        selected_van = save_van_data.filter(existed => parseInt(van_row.order_id, 10) === existed.order_id);


        selected_van.forEach(selected_product => {

          if (product_sum['p_' + selected_product.product_id] === undefined) {
            product_sum['p_' + selected_product.product_id] = 0;
          }

          product_sum['p_' + selected_product.product_id] += parseInt(selected_product.qty, 10);

          const stock_data = stockData2.filter(existed => parseInt(selected_product.product_id, 10) === existed.product_id);

          if (stock_data === null || stock_data === undefined || stock_data.length === 0) {
            inside_this.toastr.error('Production Order Line missing for product : ' + inside_this.all_products['p_' + selected_product.product_id].name);
            inside_this.dataloaded = true;
            inside_this.polineMismatch = true;
            return;
          }


          if (stock_data[0].in_stock < product_sum['p_' + selected_product.product_id]) {
            negative_products.push(all_products['p_' + selected_product.product_id].name);
            flag = false;
          }

          const poline = inside_this.po_line_data.find(existed => existed.product_id === stock_data[0].product_id);
          if (poline && poline.config_json) {
            po_config = poline.config_json;
          } else {
            po_config = {};
          }
          po_config['sug'] = {};
          po_config.sug['qty'] = inside_this.mbqTotal['p_' +  stock_data[0].product_id];
          po_config.sug['pre'] = inside_this.mbqPreTotal['p_' +  stock_data[0].product_id];
          po_config.sug['clos'] = inside_this.mbqClosingTotal['p_' +  stock_data[0].product_id];

          po_config.sug['channel'] = {};
          for (const key of inside_this.channelTypes) {
            if (inside_this.mbqChannelTotal['p_' +  stock_data[0].product_id + '_' + key]) {
              po_config.sug['channel'][key] = inside_this.mbqChannelTotal['p_' +  stock_data[0].product_id + '_' + key];
            }
          }

          newstockData2.stock_data.push({
            product_id: stock_data[0].product_id,
            po_id: stock_data[0].po_id,
            distributor_id: stock_data[0].distributor_id,
            territory_id: stock_data[0].territory_id,
            stock_date: stock_data[0].stock_date,
            type: 's',
            quantity: selected_product.qty,
            cid: stock_data[0].cid,
            price: all_products['p_' + stock_data[0].product_id].price,
            taxmaster_id: all_products['p_' + stock_data[0].product_id].taxmaster_id,
            tax_pct: all_products['p_' + stock_data[0].product_id].tax_pct,
            name: stock_data[0].name,
            product_uom: stock_data[0].product_uom,
            van_id: van_row.van_id,
            vansale_id: van_row.vansale_id,
            user_id: van_row.user_id,
            user_territory_id: van_row.user_territory_id,
            order_id: van_row.order_id,
            new_prod: stock_data[0].new_prod[van_row.order_id],
            line_id: selected_product.line_id,
            po_config
          });

        });

        newstockData2.stock_data.forEach(data => {
          data.quantity = 0 - parseInt(data.quantity, 10);
        });

        let temp = [];

        newstockData2.order_ids.forEach(order_id => {
          const updated_orders = save_van_data.filter(existed => parseInt(order_id, 10) === existed.order_id);

          temp = temp.concat(updated_orders);
        });

        primaryData = temp;

        newstockData2.primary_data = {
          update_data: primaryData,
          newprod_data: []
        };

        all_data.push(newstockData2);

      });
      let grdOptions;
      if (this.direct_dispatch) {
        grdOptions = gridoptions[0];
      } else {
        grdOptions = gridoptions[1];
      }
      this.products.forEach(product => {
        if (parseInt(grdOptions[product].split(' ')[0], 10) < product_sum[product]) {
          if (!negative_products.includes(all_products[product].name)) {
            negative_products.push(all_products[product].name);
            flag = false;
          }
        }
      });
      if (flag) {
        saveData['dispatch'] = this.multiple_loadout;

        if (rows.length === 1) {
          saveData['data'] = newstockData2;
          this.res_data = this.apiService.markDeliveryv3(saveData);
        } else {
          saveData['data'] = all_data;
          this.res_data = this.apiService.markDeliveryAllv3(saveData);
        }
        this.res_data.subscribe(res => {
            console.log(res);
            if (res.hasOwnProperty('results') && res['results'].status === 200) {
              this.toastr.success('Dispatched Successfully');
              this.saveCaption = ["Dispatched Successfully B)", 0];
              this.status_class = 'grey-status';
              this.status = 'Inprogress';
              localStorage.setItem('po_status', this.status);
              this.sendTOproduction(5);
            } else {
              if (res.hasOwnProperty('results') && res['results'].status === 403 && res['results'].msg === 'Already dispatched') {
                this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);
                this.saveCaption = ["Dispatch Failed!!!", 1];
                this.loaded = true;
                this.preloader = false;
                this.dataloaded = true;
              } else {
                this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);
                this.saveCaption = ["Dispatch Failed!!!", 1];
                this.loaded = false;
                this.preloader = true;
                if (this.status === 'Processed') {
                  this.sendTOproduction(3);
                } else if (this.status === 'Inprogress') {
                  this.sendTOproduction(5);
                }
              }
            }
          },
          error => {
            console.log(error);
            this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);
            this.saveCaption = ["Dispatch Failed!!!", 1];
            this.loaded = false;
            this.preloader = true;
            if (this.status === 'Processed') {
              this.sendTOproduction(3);
            } else if (this.status === 'Inprogress') {
              this.sendTOproduction(5);
            }
          });
      } else {
        this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);
        this.saveCaption = ["Dispatch Failed!!!", 1];
        window.alert('Available stock is less.. please verify for following products ' + negative_products);
        negative_products = [];
        this.dataloaded = true;
        this.loaded = true;
        this.preloader = false;
      }
    } else {
      this.status = localStorage.getItem('po_status')[0].toUpperCase() + localStorage.getItem('po_status').slice(1);
      this.saveCaption = ["Dispatch Failed!!!", 1];
      window.alert('Please select atleast one order');
      this.dataloaded = true;
      this.loaded = true;
      this.preloader = false;
    }

  }

  pullSapStock() {
    const inThis = this;
    if (this.sapSync) {
      const payload = {
        access_token: localStorage.getItem('resfreshToken'),
        data: {
          url: this.sapConfig.AuthUrl,
          payload: {
            CompanyDB: this.sapConfig.CompanyDB,
            Password: this.sapConfig.Password,
            UserName: this.sapConfig.UserName
          }
        }
      };
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
      this.sapPullLoader = true;
      this.apiService.post('/api/sap_connects/sapLogin', payload)
        .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && res.results.body) {
            const session = JSON.parse(res.results.body)['SessionId'].toString();
            let url = this.sapConfig.PullStock;
            url = url.replace('$date$', inThis.myDate.toString().replaceAll('-', ''));
            url = url.replace('$whs$', inThis.dc_attr.FromWarehouse);
            const userData = {
              access_token: localStorage.getItem('resfreshToken'),
              data: {
                sessionId: session,
                db: this.sapConfig.CompanyDB,
                url
              }
            };
            this.apiService.post('/api/sap_connects/sapPullStock', userData)
              .subscribe(res1 => {
                console.log(res1);
                this.sapPullLoader = false;
                if (res1.hasOwnProperty('results') && res1.results.hasOwnProperty('error')) {
                  inThis.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
                  if (res1.results.error.hasOwnProperty("message")) {
                    this.toastr.error(res1.results.error.message);
                  } else {
                    this.toastr.error("Sap Pull Stock failed !!");
                  }
                } else if (res1.hasOwnProperty('results') && res1.results.hasOwnProperty('value')) {
                  this.toastr.success('Sap Stock Pull Success!!');
                  const sapdata = res1.results.value;
                  let receivedData: any = [];
                  receivedData = sapdata;
                  if (receivedData === null || receivedData === undefined || receivedData.length === 0) {
                    this.sapShowZeroStockConfirmation = true;
                  }
                  for (const key in inThis.POproductList) {
                    let selectObject;
                    if (receivedData !== null && receivedData !== undefined && receivedData.length > 0) {
                      selectObject = receivedData.filter(item => item.ItemCode === inThis.POproductList[key].code);
                    }
                    inThis.POproductList[key].received = 0;
                    inThis.POproductList[key].total = inThis.POproductList[key].old_stock;
                    if (selectObject) {
                      this.sapStockReceived = true;
                      for (const i in selectObject) {
                        if (selectObject[i].WhsCode === inThis.dc_attr.FromWarehouse) {
                          inThis.POproductList[key].received += selectObject[i].Qty;
                        } else if (selectObject[i].FromWhsCod === inThis.dc_attr.FromWarehouse) {
                          inThis.POproductList[key].received -= selectObject[i].Qty;
                        }
                      }
                    }
                    inThis.POproductList[key].quantity = inThis.POproductList[key].received;
                    inThis.POproductList[key].total = inThis.POproductList[key].received + inThis.POproductList[key].old_stock;
                  }
                  inThis.rowDataPO = inThis.POproductList;
                  if (inThis.gridApiPO !== null) {
                    inThis.gridApiPO.setRowData(inThis.rowDataPO);
                  }
                  inThis.elRef.nativeElement.querySelector('#overlay').style.display = 'none';

                } else {
                  inThis.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
                  inThis.toastr.error('Authentication Error ');
                }
              }, error => {
                inThis.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
                inThis.toastr.error('Authentication Error ', error.error.error.message);
                this.sapPullLoader = false;
              });

          } else {
            this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
            this.toastr.error('Authentication Error ');
            this.sapPullLoader = false;
          }
        }, error => {
          this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
          this.toastr.error('Authentication Error ', error.error.error.message);
          this.sapPullLoader = false;
        });
    }
  }

  async batchScaleAllProducts(scaletype) {
    const inthis = this;
    const updateprod = {};
    if (this.status.toString().toLowerCase() === 'draft' || this.status.toString().toLowerCase() === 'submitted') {
      let iterableData = [];
      iterableData = this.gridOptions.pinnedTopRowData[0];
      let new_prod = false;
      Object.keys(iterableData).forEach(masterkey => {
        if (masterkey.includes('p_')) {
          iterableData[masterkey] = 0;
          this.rowData.forEach(row => {
            for (const key in row) {
              if (key === masterkey) {
                new_prod = false;
                const newqty = inthis.batchScale(key, row[key].qty);
                if (newqty !== row[key].qty) {
                  if (!row[key].ordered) {
                    new_prod = true;
                  } else {
                    new_prod = false;
                  }
                } else {
                  new_prod = false;
                }
                row[key].qty = newqty;
                if (!iterableData[key]) {
                  iterableData[key] = 0;
                }
                iterableData[key] += row[key].qty;
                this.editCell(row, newqty, key, new_prod);
              }
            }
          });
        }
      });
      Object.keys(iterableData).forEach(masterkey => {
        if (masterkey.includes('p_')) {
          const found = this.productList.find(x => x.product_id === parseInt(masterkey.split('_')[1], 10));
          if (found) {
            found['rem'] = iterableData[masterkey];
          }

          if (this.status.toString().toLowerCase() === 'submitted') {
            const poline = this.po_line_data.find(existed => existed.product_id === parseInt(masterkey.split('_')[1], 10));
            if (poline) {
              const diff = iterableData[masterkey] - poline.qty_old_stock;
              if (diff > 0) {
                iterableData[masterkey] = diff;
                poline.qty_ordered = diff;
              } else {
                iterableData[masterkey] = 0;
              }
            }
          }
        }
      });
      this.gridApi.setPinnedTopRowData(this.gridOptions.pinnedTopRowData);
    } else {
      this.stockData2.stock_data.forEach(porow => {
        this.rowData.forEach(row => {
          let factor = 1;
          if (row.dispatch_status && row.dispatch_status === 'new') {
            for (const key in row) {
              const p_id = parseInt(key.split('_')[1], 10);
              if (p_id === porow.product_id) {
                row[key].qty = inthis.batchScale(key, row[key].qty);
                const savedata = inthis.saveData.find(x => x.order_id === row.order_id && x.product_id === key.split('_')[1]);
                if (savedata) {
                  savedata.qty = row[key].qty;
                }
                if (!updateprod[key]) {
                  updateprod[key] = 0;
                }
                updateprod[key] += row[key].qty;
              }
            }
          }
        });
      });
      Object.keys(updateprod).forEach(key => {
        const found =  this.stockData2.stock_data.find(x => x.product_id === parseInt(key.split('_')[1], 10));
        if (found) {
          found['quantity'] = updateprod[key];
        }
      });
      this.stockData2.stock_data.forEach(porow => {
        this.pinnedData[1]['p_' + porow.product_id] = porow.old_stock + " (" +
          (porow.old_stock - porow.quantity) + ")";
      });
      this.gridApi.setPinnedTopRowData(this.pinnedData);
    }
    this.batchScaleDone = true;
    this.gridApi.setRowData(this.rowData);
    this.toastr.success('Loadout Values Scaled Successfully');
  }

  async adjustSapValues(scaletype) {
    const inthis = this;
    const updateprod = {};
    let scalefactor = null;
    const batchscalerow = this.gridOptions.pinnedTopRowData.find(x =>  x['VAN'] === 'BATCH SCALE');
    this.stockData2.stock_data.forEach(porow => {
      this.rowData.forEach(row => {
        let factor = 1;
        if (row.dispatch_status && row.dispatch_status === 'new') {
          for (const key in row) {
            const p_id = parseInt(key.split('_')[1], 10);
            if (batchscalerow) {
              scalefactor = this.uomlist.find(x => x.id === batchscalerow[key]);
            }
            if (p_id === porow.product_id && scalefactor && scalefactor.factor_inv > 0) {
              let qty = 0;
              if (inthis.status === 'Inprogress') {
                const availableqtyrow = inthis.gridOptions.pinnedTopRowData.find(x =>  x['VAN'] === 'AVAILABLE QTY');
                if (availableqtyrow) {
                  qty = parseInt(availableqtyrow[key].split(' ('), 10);
                }
              } else {
                qty = porow.quantity;
              }
              if (scalefactor) {
                if (qty !== porow.in_stock) {
                  if (qty && porow.quantity > 0) {
                    factor = porow.in_stock / qty;
                    row[key].qty = Math.round(row[key].qty * factor);
                  }
                }
                const savedata = inthis.saveData.find(x => x.order_id === row.order_id && x.product_id === key.split('_')[1]);
                if (savedata) {
                  savedata.qty = row[key].qty;
                }
                if (!updateprod[key]) {
                  updateprod[key] = 0;
                }
                updateprod[key] += row[key].qty;
              }
            }
          }
        }
      });
    });
    Object.keys(updateprod).forEach(key => {
      const found = this.stockData2.stock_data.find(x => x.product_id === parseInt(key.split('_')[1], 10));
      if (found) {
        found['quantity'] = updateprod[key];
      }
    });
    this.stockData2.stock_data.forEach(porow => {
      this.pinnedData[1]['p_' + porow.product_id] = porow.old_stock + " (" +
        (porow.old_stock - porow.quantity) + ")";
    });
    this.gridApi.setPinnedTopRowData(this.pinnedData);
    this.gridApi.setRowData(this.rowData);
    this.toastr.success('Loadout Values Re-Adjusted Successfully');
  }

  async scaleBatchValues(product_id, batchfactor) {
    const saveData = {};
    const poUpdate = [];
    const prodUpdate = [];
    const inthis = this;
    const updateprod = {};
    const uomlist = JSON.parse(localStorage.getItem('all_uoms'));
    this.stockData2.stock_data.forEach(porow => {
      this.rowData.forEach(row => {
        if (row.dispatch_status && row.dispatch_status === 'new') {
          for (const key in row) {
            const p_id = parseInt(key.split('_')[1], 10);
            if (p_id === product_id && p_id === porow.product_id) {
              row[key].qty = inthis.batchScale(key, row[key].qty);
              const savedata = inthis.saveData.find(x => x.order_id === row.order_id && x.product_id === key.split('_')[1]);
              if (savedata) {
                savedata.qty =  row[key].qty;
              }
              if (!updateprod[key]) {
                updateprod[key] = 0;
              }
              updateprod[key] += row[key].qty;
            }
          }
        }
      });
    });
    Object.keys(updateprod).forEach(key => {
      const found = this.stockData2.stock_data.find(x => x.product_id === parseInt(key.split('_')[1], 10));
      if (found) {
        found['quantity'] = updateprod[key];
      }
    });
    this.stockData2.stock_data.forEach(porow => {
      this.pinnedData[1]['p_' + porow.product_id] = porow.old_stock + " (" +
        (porow.old_stock - porow.quantity) + ")";
    });
    this.gridApi.setPinnedTopRowData(this.pinnedData);
    this.gridApi.setRowData(this.rowData);
    this.toastr.success('Product scaled according to batch size Successfully');
  }

  batchScale(product_key, qty) {
    let inthis = this;
    let scalefactor = null;
    let porow;
    const batchscalerow = this.gridOptions.pinnedTopRowData.find(x =>  x['VAN'] === 'BATCH SCALE');
    if (batchscalerow) {
      scalefactor = this.uomlist.find(x => x.id === batchscalerow[product_key]);
    }
    porow = this.stockData2.stock_data.filter(existed => parseInt(product_key.split('_')[1], 10) === existed.product_id);
    if (scalefactor && scalefactor.factor_inv > 0) {
      qty = Math.round(qty / scalefactor.factor_inv) * scalefactor.factor_inv;
      if (qty < 0) {
        qty = 0;
      }
    }
    return qty;
  }

  pushStocktoSAP() {
    console.log(this.rowData);
    console.log(this.saveData);
    const inThis = this;
    if (this.sapSync) {
      const payload = {
        access_token: localStorage.getItem('resfreshToken'),
        data: {
          url: this.sapConfig.AuthUrl,
          payload: {
            CompanyDB: this.sapConfig.CompanyDB,
            Password: this.sapConfig.Password,
            UserName: this.sapConfig.UserName
          }
        }
      };
      this.elRef.nativeElement.querySelector('#overlay').style.display = 'block';
      this.apiService.post('/api/sap_connects/sapLogin', payload)
        .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && res.results.body) {
            const session = JSON.parse(res.results.body)['SessionId'].toString();
            const url = this.sapConfig.PushStock;
            const deliveredvans = inThis.rowData.filter(x => x.dispatch_status === 'delivered');
            const product_sum = {};
            deliveredvans.forEach(selected_van => {
              for (const i in inThis.all_products) {
                if (selected_van[i] !== null && selected_van[i] !== undefined) {
                  if (product_sum[i] === undefined) {
                    product_sum[i] = 0;
                  }
                  product_sum[i] += parseInt(selected_van[i].qty, 10);
                }
              }
            });

            const line_data: any = [];
            let i = 0;
            for (const key in product_sum) {
              if (product_sum[key] !== null && product_sum[key] > 0) {
                line_data.push(
                  {
                    LineNum: i,
                    ItemCode: inThis.all_products[key].code,
                    Quantity: product_sum[key],
                    WarehouseCode: inThis.dc_attr.whcode,
                    FromWarehouseCode: inThis.dc_attr.FromWarehouse,
                  }
                );
              }
              i++;
            }

            let final_json;
            final_json = {
              Series: inThis.dc_attr.Series,
              DocDate: inThis.myDate,
              DueDate: inThis.myDate,
              U_SBSEXDELDATE: inThis.myDate,
              Reference1: "SD Transfer",
              JournalMemo: "Stock Transfers -",
              FromWarehouse: inThis.dc_attr.FromWarehouse,
              ToWarehouse: inThis.dc_attr.whcode,
              TaxDate: inThis.myDate,
              DocObjectCode: "67",
              StockTransferLines: line_data
            };

            const userData = {
              access_token: localStorage.getItem('resfreshToken'),
              data: {
                sessionId: session,
                db: inThis.sapConfig.CompanyDB,
                payload: final_json,
                url
              }
            };

            this.apiService.post('/api/sap_connects/sapTransferStock', userData)
              .subscribe(res1 => {
                console.log(res1);
                if (res1.hasOwnProperty('results') && res1.results.hasOwnProperty('DocEntry')) {
                  this.sap_mark_complete = true;
                  this.toastr.success('Sap Transfer Success!!');
                  this.markComplete();
                } else if (res1.hasOwnProperty('results') && res1.results.error) {
                  this.toastr.error('SAP Transfer error ', res1.results.error.message.value);
                }
                inThis.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
              }, error => {
                this.elRef.nativeElement.querySelector('#overlay').style.display = 'none';
                this.toastr.error('SAP Transfer error ', error.message.value);
              });
          }
        });
    }
  }

  checkSapPush() {
    if (this.sap_stock_transfer_enabled) {
      this.pushStocktoSAP();
    } else {
      this.markComplete();
    }
  }

  markComplete() {

    const saveData = {};

    saveData['access_token'] = this.user_data.access_token;
    saveData['data'] = {
      po_id: this.user_data.po_id
    };

    this.res_data = this.apiService.post('/api/pwa_connects/complete_production_order', saveData);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.toastr.success("Completed Successfully");
          window.location.reload();
        } else {
          this.toastr.error("Mark Complete Failed");
        }

      },
      error => console.log(error));
  }

  updateProductionOrderClick() {
    this.checkMissingPO();
    if (this.sap_stock_transfer_enabled) {
      this.sapShowZeroStockConfirmation = false;
      $('#sapPullModal').modal('show');
    } else {
      $('#polModalUpdate').modal('show');
    }
  }

  openReadjustConfirmationDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Re-adjust",
        message1: "Do you wish to Re-adjust loadout quantity and scale the values according to available stock ?",
        message2: "",
        showNoButton: true,
        yesButton: "YES",
        noButton: "CANCEL"
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      let scaletype = 'down';
      if (data === 'yes') {
        scaletype = 'down';
        this.adjustSapValues(scaletype);
      } else {
        scaletype = 'up';
      }
      this.dialog.closeAll();
    });
  }

  openBatchScaleAllConfirmationDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Pack(UOM) Scale",
        message1: "Do you wish to Re-adjust loadout quantity according to selected UOM ?",
        message2: "",
        showNoButton: true,
        yesButton: "YES",
        noButton: "CANCEL"
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      let scaletype = 'down';
      if (data === 'yes') {
        scaletype = 'down';
        this.batchScaleAllProducts(scaletype);
      } else {
        scaletype = 'up';
      }
      this.dialog.closeAll();

    });
  }

  openMarkCompleteConfirmationDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Mark Complete",
        message1: "Do you wish to mark this Production Order as Completed ?",
        message2: "",
        showNoButton: true,
        yesButton: "YES",
        noButton: "CANCEL"
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      if (data === 'yes') {
        this.checkSapPush();
      } else {
      }
      this.dialog.closeAll();
    });
  }

  openReAdjustConfirmationDialog(product_id, product_name) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Re-adjust values to ZERO ?",
        message1: "Do you wish to update all the values for " + product_name + " to ZERO ?",
        message2: "",
        showNoButton: true,
        yesButton: "YES",
        noButton: "CANCEL"
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      if (data === 'yes') {
        this.updateValuestoZero(product_id);
      } else {
      }
      this.dialog.closeAll();
    });
  }

  openBatchScaleConfirmationDialog(product_id, product_name, batchfactor) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Scale all values to batch size?",
        message1: "Do you wish to update all the values for " + product_name + " to BATCH SIZE ?",
        message2: "",
        showNoButton: true,
        yesButton: "YES",
        noButton: "CANCEL"
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      if (data === 'yes') {
        this.scaleBatchValues(product_id, batchfactor);
      } else {
      }
      this.dialog.closeAll();
    });
  }

  openRevertConfirmDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: "Revert Status?",
        message1: "Do you wish to revert the status from Completed to In Progress ?",
        message2: "",
        showNoButton: true,
        yesButton: "YES",
        noButton: "CANCEL"
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      if (data === 'yes') {
        this.revertPoStatus();
      } else {
      }
      this.dialog.closeAll();
    });
  }

  revertPoStatus() {
    const saveData = {};

    saveData['access_token'] = this.user_data.access_token;
    saveData['data'] = {
      po_id: this.user_data.po_id
    };

    this.res_data = this.apiService.post('/api/pwa_connects/revert_po_status', saveData);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.toastr.success("Reverted Successfully");
          window.location.reload();
        } else {
          this.toastr.error("Revert Failed");
        }

      },
      error => console.log(error));
  }
}



