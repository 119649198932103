import {Component, Input, OnInit} from "@angular/core";
import {MapBase} from "../../pagebase/mapbase";


@Component({
  selector: 'app-daywise-track-detail',
  templateUrl: './daywise_tracking_details.component.html',
  styleUrls: ['./daywise_tracking_details.component.scss']
})

export class DaywiseTrackingDetailComponent extends MapBase implements OnInit {
  @Input() rowData: [];
  @Input() MapViewMetaData: {};
  @Input() filteredData: any [];
  @Input() myDate;
  chartData = [
    {
      name: "Visited",
      value: 0,
      extra: {
        code: "V"
      }
    },
    {
      name: "Productive",
      value: 0,
      extra: {
        code: "P"
      }
    },
    {
      name: "Not Visited",
      value: 0,
      extra: {
        code: "NV"
      }
    }];
  view: any[] = [500, 200];
  legendData: any = [];
  showAlertCard = false;
  showActivityCard = false;
  markerData = [];
  retailerData = [];
  trackData = [];
  summaryData: any = {};
  alertData = [];
  activityData: any = [];
  chartHeader = '';
  totalDistance = 0;
  totalTime;
  startTime;
  endTime;
  searchableFilter: any = {
    name: "User",
    bindLabel: "name",
    bindValue: "id",
    value: 0,
    values: []
  };
  showBeatJump = true;
  showVisited = true;
  showNotVisited = true;
  showCompleted = true;
  showAlert = true;
  showProductive = true;
  showStart = true;
  showEnd = true;
  hideAllMarkers = false;
  hideAllTracks = false;
  showRetailerMarkers = true;
  beatJumpCount = 0;
  totalBeats = 0;
  coveredBeats = 0;
  fieldDays = 0;
  dayWiseTracks: any = [];
  user_ids: any = [];
  track_ids: any = [];


  ngOnInit(): void {
    console.log(this.filteredData);
    const user_ids = this.sharedService.unique(this.filteredData, ['user_id']);
    user_ids.forEach(user => {
      this.userData.user_ids.push(user['user_id']);
    });
    const track_ids = this.sharedService.unique(this.filteredData, ['id']);
    track_ids.forEach(track => {
      this.userData.track_ids.push(track['id']);
    });
    this.sharedService.setHeader('DayWise Tracking');
    this.API_URL = '/api/retail_so_track_logs/get_user_track_activities';
    this.dateFilterType = 'range';
    this.legendData = [
      {
        image: '/assets/map/grey.svg',
        name: "Beat Jump",
        show: this.showBeatJump,
      },
      {
        image: '/assets/map/warning_red.svg',
        name: "Not Visited",
        show: this.showNotVisited,
      },
      {
        image: '/assets/map/green_tick.svg',
        name: "Completed",
        show: this.showCompleted,
      },
      {
        image: '/assets/map/warning_orange.svg',
        name: "Alert",
        show: this.showAlert,
      },
      {
        image: '/assets/map/green.svg',
        name: "Productive",
        show: this.showProductive,
      },
      {
        image: '/assets/map/start.svg',
        name: "Start",
        show: this.showStart,
      },
      {
        image: '/assets/map/end.svg',
        name: "End",
        show: this.showEnd,
      }
    ];
    this.loadCacheValues();
    this.loadMapData();
  }



  loadCacheValues() {

  }

  setValuestoDefault() {
    this.dataLoaded = false;
    this.chartData = [];
    this.summaryData = [];
    this.markerData = [];
    this.activityData = [];
    this.trackData = [];
    this.alertData = [];
    this.retailerData = [];
    this.dayWiseTracks = [];
    this.totalDistance = 0;
    this.totalTime = 0;
    this.chartHeader = '';
    this.beatJumpCount = 0;
    this.totalBeats = 0;
    this.coveredBeats = 0;
    this.fieldDays = 0;
    this.startTime = '';
    this.endTime = '';
  }

  checkMarkerShow(item) {
    if (item['icon']) {
      if (item['icon'] === 'start_marker' && this.showStart) {
        return true;
      } else if (item['icon'] === 'end_marker' && this.showEnd) {
        return true;
      } else if (item['icon'] === 'alert_marker' && this.showAlert) {
        return true;
      } else if (item['icon'] === 'grey_marker' && this.showBeatJump) {
        return true;
      } else if (item['icon'] === 'green_tick_marker' && this.showCompleted) {
        return true;
      } else if (item['icon'] === 'green_marker' && this.showProductive) {
        return true;
      } else if (item['icon'] === 'red_warning_marker' && this.showNotVisited) {
        return true;
      } else if (item['icon'] === 'blue_marker' && this.showVisited) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  postProcessData() {
    /* this.daylist = this.sharedService.getDaysArray(new Date(this.myDate[0]), new Date(this.myDate[1]));
     this.daylist.map((v) => v.toISOString().slice(0, 10)).join('');
     let i = 0;
     for (const key in this.daylist) {
       const formatted_date = formatDate(new Date(this.daylist[key]), 'yyyy-MM-dd', 'en-US').substring(0, 10);
       this.dayWiseTracks.push({
         date: formatted_date,
         color: this.trackColorScheme[i],
         tracks: []
       });
       i++;
     }*/
    if (this.apiData.summary) {
      this.summaryData = this.apiData.summary;
      const beatset = new Set();
      this.apiData.summary.forEach(item => {
          if (item.beats) {
            item.beats.forEach(beat => {
              beatset.add(beat.bp_id);
            });
          }
      });
      this.totalBeats = beatset.size;
    }
    this.markerData = [];
    if (this.apiData.track && this.apiData.track.length > 0) {
      let distance_set = false;
      const trackid_set = new Set();
      if (this.apiData.track[0].distance_travelled) {
        distance_set = true;
        this.totalDistance = this.apiData.track[0].distance_travelled;
      }
      //generate track colors
      const uqtracks = this.sharedService.unique(this.apiData.track, ['track_id']);
      const trackColors = {};
      let i = 0;
      uqtracks.forEach(data => {
        trackColors[data['track_id']] = this.trackColorScheme[i];
        i++;
      });

      console.log(trackColors);

      //generate user colors
      const uqusers = this.sharedService.unique(this.apiData.track, ['user_id']);
      const userColors = {};
      i = 0;
      uqusers.forEach(data => {
        userColors[data['user_id']] = this.trackColorScheme[i];
        i++;
      });

      //generate beat colors
      const uqbeats = this.sharedService.unique(this.apiData.track, ['bp_id']);
      const beatColors = {};
      i = 0;
      uqbeats.forEach(data => {
        beatColors[data['bp_id']] = this.trackColorScheme[i];
        i++;
      });

      uqtracks.forEach(data => {
        const tracks = this.apiData.track.filter(x => x.track_id === data['track_id']);
        if (tracks && tracks.length > 0) {
          tracks.forEach(logs => {
            if (logs.log && logs.log.length > 0) {
              const indrow: any = {
                track_id: data['track_id'],
                track_color: trackColors[data['track_id']],
                user_color: userColors[data['user_id']],
                beat_color: beatColors[data['bp_id']],
                tracks: []
              };
              logs.log.forEach(tracklog => {
                if (parseFloat(tracklog.lat) > 0 && parseFloat(tracklog.lng) > 0) {
                  this.trackData.push({
                    t: tracklog.t,
                    time: this.datePipe.transform((new Date(tracklog.t) + 'UTC').toString(), 'yyyy-MM-dd HH:mm:ss'),
                    lat: tracklog.lat,
                    long: tracklog.lng
                  });
                  indrow.tracks.push({
                    t: tracklog.t,
                    time: this.datePipe.transform((new Date(tracklog.t) + 'UTC').toString(), 'yyyy-MM-dd HH:mm:ss'),
                    lat: tracklog.lat,
                    long: tracklog.lng
                  });
                }
              });
              this.dayWiseTracks.push(indrow);
            }
          });
        }
      });
      i = 0;
      this.dayWiseTracks.forEach(day => {
        if (day.tracks && day.tracks.length > 0) {
          i++;
          day.tracks[0]['icon'] = 'start_marker';
          day.tracks[day.tracks.length - 1]['icon'] = 'end_marker';
          this.markerData.push(day.tracks[0]);
          this.markerData.push(day.tracks[day.tracks.length - 1]);
        }
      });
      this.fieldDays = i;
      this.AlertCheck();
      if (this.totalDistance && this.totalDistance > 0) {
        this.totalDistance = this.totalDistance / 1000;
        this.totalDistance = parseFloat(this.totalDistance.toFixed(2));
      } else {
        this.totalDistance = 0;
      }
    }
    if (this.apiData.ret && this.apiData.ret.length > 0) {
      this.retailerData = this.apiData.ret;
      let visited = 0;
      let productive = 0;
      let not_visited = 0;
      let unplanned_visit  = 0;
      let obj = this.retailerData.filter(x => x.status === 'V');
      if (obj) {
        visited = obj.length;
      }
      obj = this.retailerData.filter(x => x.status === 'P');
      if (obj) {
        productive = obj.length;
      }
      obj = this.retailerData.filter(x => x.status === 'NV');
      if (obj) {
        not_visited = obj.length;
      }
      obj = this.retailerData.filter(x => x.status === 'UV');
      if (obj) {
        unplanned_visit = obj.length;
      }
      this.beatJumpCount = unplanned_visit;
      this.chartData = [
        {
          name: "Visited (" + visited + ")",
          value: visited,
          extra: {
            code: "V"
          }
        },
        {
          name: "Productive (" + productive + ")",
          value: productive,
          extra: {
            code: "P"
          }
        },
        {
          name: "Not Visited (" + not_visited + ")",
          value: not_visited,
          extra: {
            code: "NV"
          }
        }];
      const beatSet = new Set();
      this.retailerData.forEach(data => {
        if (data['status']) {
          if (data['status'] === 'P') {
            data['icon'] = 'green_marker';
            if (data.beat) {
              beatSet.add(data.beat);
            }
          } else if (data['status'] === 'V') {
            data['icon'] = 'blue_marker';
            if (data.beat) {
              beatSet.add(data.beat);
            }
          } else if (data['status'] === 'NV') {
            data['icon'] = 'red_warning_marker';
          } else if (data['status'] === 'UV') {
            data['icon'] = 'grey_marker';
            if (data.beat) {
              beatSet.add(data.beat);
            }
          } else {
            data['icon'] = 'green_marker';
          }
        } else {

        }
        if (data.device_time) {
          data['time'] = this.datePipe.transform((new Date(data.device_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
        }
      });
      this.coveredBeats = beatSet.size;
    } else {
      this.chartData = [
        {
          name: "Visited (0)",
          value: 0,
          extra: {
            code: "V"
          }
        },
        {
          name: "Productive (0)",
          value: 0,
          extra: {
            code: "P"
          }
        },
        {
          name: "Not Visited (0)",
          value: 0,
          extra: {
            code: "NV"
          }
        }];
    }
    if (this.apiData.act && this.apiData.act.length > 0) {
      this.apiData.act.forEach(data => {
        data['icon'] = 'green_marker';
        if (data.device_time) {
          data['time'] = this.datePipe.transform((new Date(data.device_time + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
        }
        this.markerData.push(data);
        /*if (data['beat'] && data['beat'] !== this.chartHeader) {
          data['icon'] = 'blue_marker';
          this.beatJumpCount++;
        }*/
      });
      this.activityData = [];
      const unique_dt = this.sharedService.unique(this.apiData.act, ['device_time']);
      unique_dt.forEach(uq => {
        const found = this.apiData.act.filter(x => x.device_time === uq['device_time']);
        if (found.length > 1) {
          this.activityData.push({
            activity: found[0].activity,
            activity_type: found[0].activity_type,
            time: found[0].time,
            ret_id: found[0].ret_id,
            ret_name: found[0].ret_name,
            ret_code: found[0].ret_code,
            activities: found
          });
        } else {
          this.activityData.push(found[0]);
        }
      });
      console.log(this.dayWiseTracks);
      console.log(this.activityData);
      console.log(this.trackData);
      console.log(this.markerData);
    } else {
      this.activityData = [];
    }
    this.dataLoaded = true;
  }


  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  AlertCheck() {
    this.alertData = [];
    if (this.trackData && this.trackData.length > 0) {
      let distance;
      let timediff;
      let speed;
      let lat = this.trackData[0].lat;
      let long = this.trackData[0].long;
      let starttime = this.trackData[0].t;
      for (let i = 1; i < this.trackData.length; i++) {
        if (this.trackData[i].lat !== lat || this.trackData[i].long !== long) {
          lat = this.trackData[i].lat;
          long = this.trackData[i].long;
          timediff = this.trackData[i].t - starttime; //i
          timediff = Math.floor(timediff / 1000 / 60 / 60);
          starttime = this.trackData[i].t;
        }
        if (timediff > 1) {
          this.alertData.push(
            {
              icon: 'alert_marker',
              type: 'time_spent',
              lat: this.trackData[i].lat,
              long: this.trackData[i].long,
              time: this.trackData[i].time
            }
          );
        } else {
          distance = this.getDistanceFromLatLonInKm(this.trackData[i - 1].lat, this.trackData[i - 1].long, this.trackData[i].lat, this.trackData[i].long);
          timediff = this.trackData[i].t - this.trackData[i - 1].t; //in ms
          timediff = Math.floor(timediff / 1000 / 60 / 60);
          if (timediff > 0) {
            speed = distance / timediff;
            console.log(new Date(this.trackData[i].time));
            if (speed > 100) {
              this.alertData.push(
                {
                  icon: 'alert_marker',
                  type: 'speed',
                  lat: this.trackData[i].lat,
                  long: this.trackData[i].long,
                  time: this.trackData[i].time
                }
              );
            }
          }
        }
      }
      console.log(this.alertData);
    }

  }

  onMapReady(event) {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    this.agm_map = event;
    let boundData = [];
    if (this.trackData && this.trackData.length > 0) {
      boundData = this.trackData;
    } else if (this.markerData && this.markerData.length > 0) {
      boundData = this.markerData;
    } else if (this.retailerData && this.retailerData.length > 0) {
      boundData = this.retailerData;
    } else {
      boundData = [];
    }
    for (const mm of boundData) {
      if (mm['lat'] && mm['long']) {
        bounds.extend(new google.maps.LatLng(mm['lat'], mm['long']));
      }
    }
    this.agm_map.fitBounds(bounds);
  }

  alertCardClick() {
    this.showAlertCard = !this.showAlertCard;
  }

  activityCardClick() {
    this.showActivityCard = !this.showActivityCard;
  }

}
