<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Feasibility Survey Approval</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div class="cardbox" style="width: 100%">
        <h3>{{assetDetails.asset_name}}</h3>
        <div class="mt-5" style="display: flex">
          <div style="flex: 1">
            <label class="detaillabel">Survey Date</label>
            <p class="detailvalue1">{{surveyDetails['date']}}</p>
          </div>
          <div style="flex: 1">
            <label class="detaillabel">Survey Status</label>
            <p class="detailvalue1">{{surveyDetails['status']}}</p>
          </div>
        </div>
        <p class="detailvaluebold mt-4" style="font-size: 12px;">Survey Details</p>
        <div >
          <div class="mt-2" style="background: #F5F7F9; padding: 20px;">
            <ng-container *ngFor="let question of surveyQuestions; let i = index">
              <p class="dialogspan">{{question.question}}</p>
              <div class="row">
                <p class="dialogspan1">Answer:</p>
                <p class="dialogspan" style="margin-left: 5px;">{{question.answer}}</p>
              </div>
            </ng-container>
            <div class="mt-2">
              <p class="dialogspan">Photos Uploaded</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-row" style="position: absolute;  right: 0px; margin-top: 20px;">
      <button class="btn reject-btn" style="margin-right:15px;" (click)="openFeasibilityRejectPopup()">Reject</button>
      <button  class="btn confirm-green-btn" style="margin-right:15px;">Approve</button>
    </div>
  </div>
</section>
