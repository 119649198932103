import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";

@Component({
  selector: 'app-components-period-dropdownv2',
  templateUrl: './period_dropdownv2.component.html',
  styleUrls: ['./period_dropdownv2.component.scss']

})

export class PeriodDropdownV2Component implements OnInit {

  periodData: any = [];
  selectedPeriod: any = {};
  @Input() periodID;
  @Output() onsetPeriod: EventEmitter<any> = new EventEmitter<any>();

  constructor(private apiService: ApiService) {

    this.periodData = JSON.parse(localStorage.getItem('jc_months'));
    this.periodData.reverse();

    /* var indPeriod = {"id":0,"start_date":null,"end_date":null,"period_name":"No Date"}
     this.periodData.push(indPeriod);
     console.log(this.periodData);*/
    if (!this.periodID) {
      if (this.periodData != null && this.periodData.length > 0) {
        this.periodID = parseInt(this.periodData[0].id, 10);
      }
    }
  }

  ngOnInit(): void {
  }

  onPeriodChange() {
    const periodid = parseInt(String(this.periodID), 10);
    this.selectedPeriod = this.periodData.filter(period => period.id === periodid);
    if (this.selectedPeriod != null && this.selectedPeriod.length > 0) {
      this.selectedPeriod = this.selectedPeriod[0];
    }
    this.onsetPeriod.emit(this.selectedPeriod);
  }
}
