
  <div style="width:250px;" >
    <app-multi-select-dropdown
      [list]="multiFilters.values"
      [name]="multiFilters.name"
      (shareCheckedList)="shareCheckedList($event)"
      (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
    </app-multi-select-dropdown>
  </div>

