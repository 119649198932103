<div>
  <app-page-loader [show]="!pageReady"></app-page-loader>
</div>
<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Payment For Invoice : {{invoiceDetails.name}}</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div style="display: flex; width: 100%;">
        <div style="flex: 1">
          <span class="detailvaluebold">Invoice Details</span>
          <div class="cardbox" style="margin-top: 10px;width: 100%; height: 130px;">
            <label class="detaillabel">Invoice Number</label>
            <span class="detaillabel2" style="display: block;">{{invoiceDetails.name}}</span>
            <div class="row">
              <div class="col-md-5 pad0">
                <label class="detaillabel">Invoice Date : </label>
              </div>
              <div class="col-md-7 pad0">
                <span class="detaillabel2">{{invoiceDetails.date_invoice}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 pad0">
                <label class="detaillabel">Invoice Amount : </label>
              </div>
              <div class="col-md-7 pad0">
                <span *ngIf="invoiceDetails['amount_total']" class="detaillabel2">{{invoiceDetails.amount_total.toFixed(apiService.decimalPolicy)}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 pad0">
                <label class="detaillabel">Amount Payable: </label>
              </div>
              <div class="col-md-7 pad0">
                <span class="detaillabel2">{{invoiceDetails.outstanding_amount}}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="flex: 1; margin-left: 10px;">
          <span class="detailvaluebold">Customer Details</span>
          <div class="cardbox" style="margin-top: 10px;width: 100%; height: 130px;">
            <span class="detailvaluebold">{{customerDetails.name}}</span>
            <div class="row">
              <div class="col-md-5 pad0">
                <label class="detaillabel">Code : </label>
              </div>
              <div class="col-md-7 pad0">
                <span class="detaillabel2">{{customerDetails.code}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 pad0">
                <label class="detaillabel">Outstanding : </label>
              </div>
              <div class="col-md-7 pad0">
                <span class="detaillabel2">{{customerDetails.balance}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 pad0">
                <label class="detaillabel">Credit Limit : </label>
              </div>
              <div class="col-md-7 pad0">
                <span class="detaillabel2">{{customerDetails.credit_limit}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5 pad0">
                <label class="detaillabel">Credit Available : </label>
              </div>
              <div class="col-md-7 pad0">
                <span class="detaillabel2">{{customerDetails.credit}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 100%; margin-top: 15px;">
        <span class="detailvalue1" style="float: left">Total Amount Collected</span>
      </div>
      <input
        id="tot_amount"
        class="forminput"
        style="display: block; color: #0762AD; font-size: 22px; font-weight: 500;margin-top: 10px;width: 100%;height:45px;background: white"
        type="number"
        name="code"
        [(ngModel)]="paymentDetails.tot_amount"
        (change)="onAmountChange()"
        #code="ngModel"
      >
      <div *ngIf="(dataLoaded && paymentDetails.tot_amount && paymentDetails.tot_amount > invoiceDetails.outstanding_amount)" style="background-color: #FBEBE6; padding: 10px;display: flex;margin-top: 10px;">
        <img src="../../../../../assets/warning.svg" style="width: 12px; height:12px; margin: 2px 10px 0px 3px;">
        <span style="font-size: 12px;color: #DC562E">Amount entered exceeds amount payable. Please use the "Advance Payment" option to record advance payments.</span>
      </div>
      <div style="margin-top: 15px;">
        <span class="detailvalue1">Payment Details</span>
        <div style="display: flex; width: 100%; margin-top: 5px; ">
          <div style="flex: 1; margin-right: 10px;" class="formselectwhitebg">
            <select
              name="paymentmode"
              [(ngModel)]="paymentDetails.payment_type"
              #paymentmode="ngModel"
              style="width: 100%;">
              <option
                *ngFor="let item of paymentModes"
                value="{{ item.id }}"
              >
                {{ item.itemName }}
              </option>
            </select>
          </div>
          <div class="date-picker-form" style="flex: 1;">
            <input [selectMode]="'single'"
                   [readonly]="true"
                   [(ngModel)]="paymentDetails.payment_date"
                   [owlDateTimeTrigger]="dt"
                   [owlDateTime]="dt" placeholder="Payment Date"
                   [max]="todayDate"
                   name="payment_date"
                   #payment_date="ngModel"
                   style="display: inline; color: #2D2D2D; font-size: 14px;width: 100%">
            <div style=" position: absolute;right: 10px;top: 12px;">
              <span style="padding-left: 5px;"><i class="far fa-calendar-alt"></i></span>
            </div>

            <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
          </div>
        </div>

        <div *ngIf="paymentDetails.payment_type === 'cheque'">
          <div style="display: inline-block; margin-top: 25px; width: 100%">
            <mat-radio-group aria-label="Select an option"
                             class="radio-group"
                             [(ngModel)]="paymentDetails.state"
                             (change)="chequeTypeSelection()">
              <mat-radio-button value="posted" checked>Cleared</mat-radio-button>
              <mat-radio-button value="draft" style="margin-left: 20px;">Not Cleared</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="row" style="display: flex;width: 100%;">
            <input
              class="forminput"
              style="display: inline; color: #2D2D2D; font-size: 14px;margin-top: 10px;margin-right: 10px;flex: 1; "
              type="text"
              name="chequeno"
              [(ngModel)]="paymentDetails.cheque_no"
              #chequeno="ngModel"
              placeholder="Cheque Number"
            >
            <div class="date-picker-form-grey" style="flex: 1;">
              <input [selectMode]="'single'"
                     [readonly]="true"
                     [(ngModel)]="paymentDetails.cheque_date"
                     [owlDateTimeTrigger]="dt"
                     [owlDateTime]="dt" placeholder="Deposit Date"
                     name="date"
                     #date="ngModel"
                     style="display: inline; color: #2D2D2D; font-size: 14px;margin-top: 10px;width: 100%;">
              <div style=" position: absolute;right: 20px;top: 20px;">
                <span style="padding-left: 5px;"><i class="far fa-calendar-alt"></i></span>
              </div>

              <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
            </div>
          </div>

          <input
            class="forminput"
            style="display: block; color: #2D2D2D; font-size: 14px;margin-top: 10px;width: 100%;"
            type="text"
            name="branch"
            [(ngModel)]="paymentDetails.branch"
            #branch="ngModel"
            placeholder="Branch Name"
          >
        </div>

      </div>
      <div style="margin-top: 15px;">
        <span class="detailvalue1">Narration</span>
        <textarea
          class="forminputwhitebg"
          style="display: block; color: #7D7D7D; font-size: 14px;margin-top: 5px;width: 100%;height:100px;"
          type="text"
          name="narration"
          [(ngModel)]="paymentDetails.comments"
          #narration="ngModel"
          placeholder="Enter Narration"
        ></textarea>
      </div>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn confirm-blue-btn"
        (click)="openConfirmDialog()"
        [disabled]="!dataLoaded || (!paymentDetails.tot_amount || paymentDetails.tot_amount === 0) ||
        (dataLoaded && paymentDetails.tot_amount && paymentDetails.tot_amount > invoiceDetails.outstanding_amount)"
      >
        Collect Payment
      </button>
    </div>
  </div>
</section>
