import {Component, Input, OnInit} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-map-container',
  templateUrl: './map-container.component.html',
  styleUrls: ['./map-container.component.scss']
})

export class MapContainerComponent implements OnInit {

  @Input() rowData: [];
  @Input() MapViewMetaData: {};
  @Input() filteredData: any [];
  agm_map;
  mapData: any = [];
  styles: any[] = [
    {
      elementType: "geometry",
      stylers: [{color: "#f5f5f5"}],
    },
    {
      elementType: "labels.icon",
      stylers: [{color: "#bdbdbd"}],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{color: "#616161"}],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{color: "#f5f5f5"}],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{color: "#bdbdbd"}],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{color: "#eeeeee"}],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{color: "#757575"}],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{color: "#e5e5e5"}],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{color: "#9e9e9e"}],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{color: "#ffffff"}],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{color: "#757575"}],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{color: "#dadada"}],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{color: "#616161"}],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{color: "#9e9e9e"}],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{color: "#e5e5e5"}],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{color: "#eeeeee"}],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{color: "#c9c9c9"}],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{color: "#9e9e9e"}],
    },
  ];
  zoom = 1;
  markerType = 'marker';
  latitude = 1.1;
  longitude = 1.1;
  title1 = 'Test map';

  testData = [{lat: this.latitude, lng: this.longitude, name: 'tet'}];
  public start_marker = {
    url: '/assets/map/start.svg',
    scaledSize: {
      width: 30,
      height: 30
    }
  };
  public end_marker = {
    url: '/assets/map/end.svg',
    scaledSize: {
      width: 30,
      height: 30
    }
  };
  public alert_marker = {
    url: '/assets/map/warning_orange.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public red_warning_marker = {
    url: '/assets/map/warning_red.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public grey_marker = {
    url: '/assets/images/Grey.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public teel_marker = {
    url: '/assets/images/Teel.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public green_marker = {
    url: '/assets/map/green.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };
  public green_tick_marker = {
    url: '/assets/map/green_tick.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };
  public blue_marker = {
    url: '/assets/map/blue.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };


  ngOnInit(): void {
    this.configureMapData();
  }

  configureMapData() {
    this.mapData = [];
    if (this.filteredData && this.filteredData.length > 0) {
      this.filteredData.forEach(data => {
        if (data[this.MapViewMetaData['latitude']] && data[this.MapViewMetaData['latitude']] !== "" && data[this.MapViewMetaData['longitude']] && data[this.MapViewMetaData['longitude']] !== "") {
          data.lat = parseFloat(data[this.MapViewMetaData['latitude']]);
          data.long = parseFloat(data[this.MapViewMetaData['longitude']]);
          this.mapData.push(data);
        }
      });
    }
    console.log(this.mapData);
  }


  onMapReady(event) {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    this.agm_map = event;

    for (const mm of this.mapData) {
      if (mm['lat'] && mm['long']) {
        bounds.extend(new google.maps.LatLng(mm['lat'], mm['long']));
      }
    }
    this.agm_map.fitBounds(bounds);

  }

  getMarker(item) {
    if (item['icon']) {
      if (item['icon'] === 'start_marker') {
        return this.start_marker;
      } else if (item['icon'] === 'end_marker') {
        return this.end_marker;
      } else if (item['icon'] === 'alert_marker') {
        return this.alert_marker;
      } else if (item['icon'] === 'blue_marker') {
        return this.blue_marker;
      } else if (item['icon'] === 'green_tick_marker') {
        return this.green_tick_marker;
      } else if (item['icon'] === 'red_warning_marker') {
        return this.red_warning_marker;
      } else {
        return this.green_marker;
      }
    } else {
      return this.green_marker;
    }
  }

  onMouseOver(infoWindow, event) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, event) {
    infoWindow.close();
  }


}
