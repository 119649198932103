<div class="maindiv">
  <h2 [ngClass]="{'approveheading': type !== 'reject',
                         'rejectheading': type === 'reject'}">{{title}}</h2>
  <span class="detail">{{message1}}</span>
  <span class="detail" style="margin-top: 15px;">{{message2}}</span>

  <div style="margin-top:20px;">
    <span class="commentlabel">Comments</span>
    <div>
      <textarea
        class="forminput"
        style="height: 90px; width: 100%;"
        type="text"
        name="comment"
        #comment="ngModel"
        [(ngModel)]="comments"
        required
      > </textarea>
      <div *ngIf="comment.errors && (comment.dirty || comment.touched)">
        <span *ngIf="comment.errors.required" class="text-danger">Please Enter Comments</span>
      </div>
    </div>
    <div style="float: right;  margin-bottom: 20px; margin-top: 20px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn"
        [ngClass]="{'confirm-green-btn': type !== 'reject',
                         'reject-btn': type === 'reject'}"
        (click)="submitClick()"
        [disabled]="!comments"
      >
        {{buttonText}}
      </button>
    </div>
  </div>
</div>
