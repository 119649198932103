import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CellEditingStartedEvent, CellEditingStoppedEvent, GridOptions} from "@ag-grid-enterprise/all-modules";
import {DatePipe} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {CellEditRendererComponent} from "../../CellEditRendererComponent";

@Component({
  selector: 'app-batch-configure-popup',
  templateUrl: './batch_configure_popup.component.html'

})

export class BatchConfigurePopupComponent implements OnInit {

  @Output() confirmAction: EventEmitter<any> = new EventEmitter<any>();

  dataLoaded = false;
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  context;
  public gridColumnApi: any;
  public gridApi: any;
  editMode = true;
  getRowId;
  showSave = true;

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.gridOptions = {
      rowHeight: 35,
      rowSelection: 'multiple',
      enableRangeSelection: true,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowClassRules: {
        'ag-grid-grey': (params) => {
          return params.node.rowIndex % 2 === 0;
        },
      }
    } as GridOptions;
    this.configureGrid();
    this.rowData = dialogdata.productData;
    this.dataLoaded = true;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: "Code", field: "code", width: 100},
      {headerName: "Product", field: "name", width: 250},
      {
        headerName: 'Batch Details', children: [
          //{headerName: "Batch Date", field: "batch_date", width: 100, editable: true, cellRenderer: BatchDateEditRendererComponent},
          {
            headerName: "Code", field: "batch_code", width: 100, cellEditor: 'agTextCellEditor', cellRenderer: CellEditRendererComponent
          }
          //{headerName: "Expiry Date", field: "expiry_date", width: 100},
          //{headerName: "Quantity", field: "qty", width: 100},
        ]
      }
    ];
    this.getRowId = (params) => params.data.name;
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeAllColumns();
          }
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }
  }

  onCellValueChanged(event) {
    const colId = event.column.getId();
  }

  onCellEditingStarted(event: CellEditingStartedEvent) {
    this.showSave = false;
  }

  async onCellEditingStopped(event: CellEditingStoppedEvent) {
    this.showSave = true;
  }

  confirm() {
    let valid = true;
    for (let i = 0; i < this.rowData.length; i++) {
      if (!this.rowData[i].batch_code || this.rowData[i].batch_code === '') {
        valid = false;
        this.toastr.error("Invalid Batch Code - " + this.rowData[i].name);
        break;
      }
    }
    if (valid) {
      localStorage.setItem('batch_products', JSON.stringify(this.rowData));
      this.confirmAction.emit(this.rowData);
      this.closeDialog();
    }
  }
}
