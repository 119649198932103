import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {ErrorHandler} from '../../error/error-handler';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class MonthlyAttendanceReportV2Component extends ReportBase implements IReport, OnInit {

  apiData: any = [];
  daylist: any = [];

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Monthly Attendance Report');
    this.API_URL = '/api/pwa_reports/get_monthly_attendance';
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.region = true;
    this.showableFilters.state = true;

    this.configureGrid();
  }

  configureGrid() {
    const inThis = this;
    this.daylist = this.getDaysArray(new Date(this.myDate[0]), new Date(this.myDate[1]));
    this.daylist.map((v) => v.toISOString().slice(0, 10)).join('');

    this.columnDefs = [
      {headerName: 'REGION', field: 'region', enableRowGroup: true, width: 80},
      {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 80},
      {headerName: 'PARENT TEAM', field: 'parent_team', enableRowGroup: true, width: 100},
      {headerName: 'TEAM', field: 'team', enableRowGroup: true, width: 100},
      {headerName: 'SALES AREA', field: 'sac', enableRowGroup: true, width: 100},
      {headerName: 'ROLE', field: 'user_role', enableRowGroup: true, width: 100},
      {headerName: 'EMP NAME', field: 'user_name', enableRowGroup: true, width: 100},
      {headerName: 'EMP CODE', field: 'emp_code', width: 100},
      {headerName: 'EMP DOJ', field: 'doj', width: 100,
        valueGetter: params => {
          if (params.data.doj) {
            return inThis.datePipe.transform((new Date(params.data.doj + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
          } else {
            return 'NA';
          }
        }},
      {headerName: 'EMP DOE', field: 'doe', width: 100,
        valueGetter: params => {
          if (params.data.doe) {
            return inThis.datePipe.transform((new Date(params.data.doe + ' UTC')).toString(), 'yyyy-MM-dd HH:mm');
          } else {
            return 'NA';
          }
        }
      }
    ];

    // tslint:disable-next-line:forin
    for (const key in this.daylist) {
      const formatted_date = formatDate(new Date(this.daylist[key]), 'dd/MM/yyyy', 'en-US').substring(0, 10);
      this.columnDefs.push(
        {
          headerName: formatted_date.substring(0, 5), field: formatted_date + 'status', menuTabs: [], width: 55,
          tooltipValueGetter(params) {
            if (params.data) {
              if (params.value === 'P') {
                return 'Present';
              } else if (params.value === 'A') {
                return 'Absent';
              } else if (params.value === 'AL') {
                return 'Cancelled Leave';
              } else if (params.value === 'H') {
                return 'Holiday';
              } else if (params.value === 'L') {
                return 'Leave';
              } else if (params.value === 'WO') {
                return 'Weekly Off';
              }
            }
          },
          cellStyle: params => {
            if (params.data) {
              if (params.value === 'P') {
                return {textAlign: 'center', backgroundColor: '#B3DFB0'};
              } else if (params.value === 'A') {
                return {textAlign: 'center', backgroundColor: '#F8D1C8'};
              } else if (params.value === 'AL') {
                return {textAlign: 'center', backgroundColor: '#F0E199'};
              } else if (params.value === 'H') {
                return {textAlign: 'center', backgroundColor: '#B7DBF3'};
              } else if (params.value === 'WO') {
                return {textAlign: 'center', backgroundColor: '#FFFFD1'};
              } else {
                return {textAlign: 'center'};
              }
            }
          }
        }
      );
    }

    const datepipe = this.datePipe;

    this.columnDefs.push(
      {headerName: 'MARKED PRESENT', field: 'present', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 80, aggFunc: 'sum'},
      {headerName: 'MISSED ATTENDANCE', field: 'absent', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 80, aggFunc: 'sum'},
      {headerName: 'LEAVE', field: 'leave', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 80, aggFunc: 'sum'},
      {headerName: 'APP LEAVE', field: 'approvedLeave', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 80, aggFunc: 'sum'},
      {headerName: 'HOLIDAY', field: 'holiday', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 80, aggFunc: 'sum'},
      {headerName: 'WEEKLY OFF', field: 'weekly_off', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 80, aggFunc: 'sum'},
      {
        headerName: 'TOTAL WORK DAYS',
        field: 'total_working_days',
        cellStyle: {textAlign: 'center'},
        menuTabs: [],
        width: 100,
        aggFunc: 'sum'
      },
      {
        headerName: 'AVG ATT TIME', field: 'avg_att_time', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 100,
        valueGetter: params => {
          if (params.data && params.data.avg_att_time) {
            return datepipe.transform((new Date('01/01/2021 ' + params.data.avg_att_time + ' UTC')).toString(), 'H:mm');
          }
        }
      },
      {
        headerName: 'YEST ATT TIME', field: 'yest_att_time', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.yest_att_time) {
              return datepipe.transform((new Date(params.data.yest_att_time + ' UTC')).toString(), 'H:mm');
            }
          }
        }
      },
      {
        headerName: 'AVG EOD TIME', field: 'avg_eod_time', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 100,
        valueGetter: params => {
          if (params.data && params.data.avg_eod_time) {
            return datepipe.transform((new Date('01/01/2021 ' + params.data.avg_eod_time + ' UTC')).toString(), 'H:mm');
          }
        }
      },
      {
        headerName: 'YEST EOD TIME', field: 'yest_eod_time', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.yest_eod_time) {
              return datepipe.transform((new Date(params.data.yest_eod_time + ' UTC')).toString(), 'H:mm');
            }
          }
        }
      },
      {
        headerName: 'AVG CHECK IN TIME', field: 'avg_check_in_time', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 100,
        valueGetter: params => {
          if (params.data && params.data.avg_check_in_time) {
            return datepipe.transform((new Date('01/01/2021 ' + params.data.avg_check_in_time + ' UTC')).toString(), 'H:mm');
          }
        }
      },
      {
        headerName: 'YEST CHECK IN TIME', field: 'yest_first_check_in', cellStyle: {textAlign: 'center'}, menuTabs: [], width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.yesterday_check_in && params.data.yesterday_check_in.length > 15) {
              return datepipe.transform((new Date(params.data.yesterday_check_in + ' UTC')).toString(), 'H:mm');
            }
          }
        }
      },
      {
        headerName: 'TOTAL STORES MAPPED',
        field: 'retailer_count',
        cellStyle: {textAlign: 'center'},
        menuTabs: [],
        width: 100,
        aggFunc: 'sum'
      },
      {
        headerName: 'TOTAL STORES VISITED',
        field: 'total_stores',
        cellStyle: {textAlign: 'center'},
        menuTabs: [],
        width: 100,
        aggFunc: 'sum'
      },
      {
        headerName: 'UNIQUE STORE VISIT',
        field: 'unique_stores',
        cellStyle: {textAlign: 'center'},
        menuTabs: [],
        width: 100,
        aggFunc: 'sum'
      },
      {headerName: 'STATUS', field: 'status', width: 80},
    );

  }

  getDaysArray(start, end) {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  loadServerData() {
    this.goClicked = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.emptyState = false;
    this.errorExists = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    /*this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');*/

    this.apiService.post(this.API_URL, this.userData)
      .subscribe(res => {
          console.log(res);
          if (res.hasOwnProperty('results') && (res.results.status === 200)) {
            // this.rowData = res.results.data;
            this.apiData = res.results;
            this.configureGrid();
            this.configureData();
            this.dataLoaded = true;
            this.preloader = false;
            this.emptyState = (this.rowData.length === 0);
          } else if (res.results.status === 203) {
            this.handleError(ErrorHandler.getErrorObject(res.results.status));
          } else {
            this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
          }
        },
        // tslint:disable-next-line:no-shadowed-variable
        error => this.handleError(ErrorHandler.getErrorObject(error)));
  }

  configureData() {

    this.rowData = [];
    const self = this;

    const d = new Date();
    d.setDate(d.getDate() - 1);
    const yesterdayDate = formatDate(d, 'yyyy-MM-dd', 'en-US').toString().substring(0, 10);
    for (const key in this.apiData.master) {
      let indRow = {};
      let masterRow: any = {};
      let activityMaster: any = {};
      let activityRow: any = {};
      let attendanceMaster: any = {};
      const attendanceRow: any = {};
      let holidayRow: any = {};

      masterRow = this.apiData.master[key];

      holidayRow = this.apiData.holiday;

      activityRow = this.apiData.activity.filter(activity => activity.salesman_id === masterRow.salesman_id);

      activityMaster = activityRow;

      if (activityRow != null && activityRow.length > 0) {
        activityRow = activityRow[0];
      }

      activityRow.total_stores = this.apiData.activity.filter(activity => activity.salesman_id === masterRow.salesman_id).length;

      activityRow.unique_stores = this.apiData.activity.filter(activity => activity.salesman_id === masterRow.salesman_id).map(p => p.retailer_id)
        .filter((type, index, arr) => arr.indexOf(type) === index).length;

      activityRow.yesterday_check_in = this.apiData.activity.filter(function(activity) {
        return activity.salesman_id === masterRow.salesman_id;
      });

      if (activityRow.yesterday_check_in != null && activityRow.yesterday_check_in.length > 0) {
        activityRow.yesterday_check_in = activityRow.yesterday_check_in[0].yest_first_check_in;
      }

      activityRow.avg_check_in_time = self.calculateAverageLoginTime(activityMaster);

      attendanceMaster = this.apiData.attendance.filter(attendance => attendance.salesman_id === masterRow.salesman_id);

      if (attendanceMaster != null && attendanceMaster.length > 0) {
        if (attendanceMaster[0].hasOwnProperty('yest_att_time')) {
          attendanceRow.yest_att_time = attendanceMaster[0].yest_att_time;
        }
        if (attendanceMaster[0].hasOwnProperty('yest_eod_time')) {
          attendanceRow.yest_eod_time = attendanceMaster[0].yest_eod_time;
        }

        for (const days in self.daylist) {
          var formattedDate = formatDate(new Date(self.daylist[days]), 'dd/MM/yyyy', 'en-US').substring(0, 10);
          attendanceRow[formattedDate + 'status'] = attendanceMaster.filter(attendance => formattedDate === formatDate(new Date(attendance.date), 'dd/MM/yyyy', 'en-US').substring(0, 10)).map(obj => obj.status)[0];

          attendanceRow[formattedDate + 'checkin'] = attendanceMaster.filter(attendance => formattedDate === formatDate(new Date(attendance.date), 'dd/MM/yyyy', 'en-US').substring(0, 10)).map(obj => obj.check_in)[0];

        }
      }
      for (var key1 in self.daylist) {
        const formattedDate = formatDate(new Date(self.daylist[key1]), 'dd/MM/yyyy', 'en-US').substring(0, 10);
        if (holidayRow !== null && holidayRow.length > 0 && attendanceRow[formattedDate + 'status'] !== 'P') {
          const holidaycheck = holidayRow.filter(holiday => formattedDate === formatDate(new Date(holiday.holiday), 'dd/MM/yyyy', 'en-US').substring(0, 10))[0];

          if (holidaycheck != null) {
            attendanceRow[formattedDate + 'status'] = 'H';
          }
        }
        if (attendanceRow[formattedDate + 'status'] === undefined || attendanceRow[formattedDate + 'status'] === '') {
          attendanceRow[formattedDate + 'status'] = 'A';
        }
      }

      if (attendanceRow != null) {
        attendanceRow.present = 0;
        attendanceRow.absent = 0;
        attendanceRow.leave = 0;
        attendanceRow.approvedLeave = 0;
        attendanceRow.holiday = 0;
        attendanceRow.weekly_off = 0;

        Object.keys(attendanceRow).forEach((key, index) => {
          // key: the name of the object key
          // index: the ordinal position of the key within the object
          if (key.includes('status') && attendanceRow[key] === 'P') {
            attendanceRow.present += 1;
          }
          if (key.includes('status') && attendanceRow[key] === 'A') {
            attendanceRow.absent += 1;
          }
          if (key.includes('status') && attendanceRow[key] === 'L') {
            attendanceRow.leave += 1;
          }
          if (key.includes('status') && attendanceRow[key] === 'AL') {
            attendanceRow.approvedLeave += 1;
          }
          if (key.includes('status') && attendanceRow[key] === 'H') {
            attendanceRow.holiday += 1;
          }
          if (key.includes('status') && attendanceRow[key] === 'WO') {
            attendanceRow.weekly_off += 1;
          }
        });

        attendanceRow.total_working_days = self.daylist.length - (attendanceRow.holiday + attendanceRow.weekly_off);


        /* attendanceRow.yesterday_att = attendanceMaster.filter(function (attendance) {
           return attendance.date === yesterdayDate;
         });

         if ( attendanceRow.yesterday_att != null &&  attendanceRow.yesterday_att.length > 0) {
           attendanceRow.yesterday_att =  attendanceRow.yesterday_att[0].check_in;
         }*/

        attendanceRow.avg_att_time = self.calculateAverageAttendanceTime(attendanceMaster, 'check_in');
        attendanceRow.avg_eod_time = self.calculateAverageAttendanceTime(attendanceMaster, 'check_out');

      }

      indRow = {
        ...masterRow,
        ...activityRow,
        ...attendanceRow,
        ...holidayRow
      };
      this.rowData.push(indRow);
    }
    console.log(this.rowData);

  }

  offsetify(t) {
    return t < 10 ? '0' + t : t;
  }

  calculateAverageAttendanceTime(attendanceMaster, type) {
    const checkintimes: any = [];
    for (const item in attendanceMaster) {
      if (attendanceMaster[item][type] != null) {
        checkintimes.push(attendanceMaster[item][type]);
      }
    }

    if (checkintimes != null) {
      for (const item in checkintimes) {
        if (checkintimes[item] != null) {
          checkintimes[item] = checkintimes[item].substring(checkintimes[item].indexOf(' '), checkintimes[item].length - 1);
        }
      }


      let date = 0;
      for (let x = 0; x < checkintimes.length; x++) {
        const tarr = checkintimes[x].split(':');
        date += new Date(0, 0, 0, tarr[0], tarr[1]).getTime();
      }
      if (date !== 0) {
        const avg = new Date(date / checkintimes.length);
        const avgtime = this.offsetify(avg.getHours()) + ':' + this.offsetify(avg.getMinutes());
        return avgtime;
      } else {
        return '';
      }
    }
  }

  calculateAverageLoginTime(activityMaster) {
    const checkintimes: any = [];
    for (const item in activityMaster) {
      if (activityMaster[item].first_check_in != null) {
        checkintimes.push(activityMaster[item].first_check_in);
      }
    }

    if (checkintimes != null) {
      for (const item in checkintimes) {
        if (checkintimes[item] != null) {
          checkintimes[item] = checkintimes[item].substring(checkintimes[item].indexOf(' '), checkintimes[item].length - 1);
        }
      }


      let date = 0;
      for (let x = 0; x < checkintimes.length; x++) {
        const tarr = checkintimes[x].split(':');
        date += new Date(0, 0, 0, tarr[0], tarr[1]).getTime();
      }
      if (date !== 0) {
        const avg = new Date(date / checkintimes.length);
        const avgtime = this.offsetify(avg.getHours()) + ':' + this.offsetify(avg.getMinutes());
        return avgtime;
      } else {
        return '';
      }
    }
  }


}
