import {ChangeDetectorRef, ComponentFactoryResolver, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';

// @ts-ignore
import {IReport} from './ireport';

import {ngxCsv} from 'ngx-csv/ngx-csv';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import {DomSanitizer} from '@angular/platform-browser';
import {MatMenuTrigger} from '@angular/material';
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../_services/api.service";
import {SharedService} from "../_services/shared_service";
import {ErrorObject} from "../error/error-model";
import {ErrorHandler} from "../error/error-handler";
import {ToastrService} from "ngx-toastr";
import * as XLSX from "xlsx-js-style";
import {LocalforageService} from "../_services/localforage.service";
import {ConfirmDialogComponent} from "../components/confirmdialog/confirmdialog.component";
import {AppComponent} from "../app.component";

export abstract class GSTPageBase implements OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('fileUp') fileUp: ElementRef;

  USER;
  apiData: any = [];
  interPrint;
  public tabIndex = 0;
  public prevIndex;
  public showApprove = false;
  public showApproveBtn = true;
  public showRejectBTn = true;
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: ''
    },
    customFilter: {},
    multiFilter: {},
    searchableFilter: 0
  };
  showSearchableFilter = false;
  showMultiFilter = false;
  showCustomFilter = false;
  showCustomFilter1 = false;
  selectedSearchableData: any = {};
  searchableFilter: any = {};
  multiFilter: any = {};
  customFilter: any = {};
  customFilter1: any = {};
  periodID: 0;
  showDate = true;
  dateFilterType: any = 'range';
  title = '';

  errorObject: ErrorObject;
  errorExists = false;


  myDate: any;
  searchValue: any;
  gridOptions1: GridOptions;
  columnDefs1 = [];
  frameworkComponents1: any = {};
  rowData1 = [];
  public gridColumnApi1: any;
  public gridApi1: any;
  preloader: any;
  dataLoaded: any;

  gridOptions2: GridOptions;
  columnDefs2 = [];
  frameworkComponents2: any = {};
  rowData2 = [];
  public gridColumnApi2: any;
  public gridApi2: any;

  gridOptions3: GridOptions;
  columnDefs3 = [];
  frameworkComponents3: any = {};
  rowData3 = [];
  public gridColumnApi3: any;
  public gridApi3: any;

  gridOptions4: GridOptions;
  columnDefs4 = [];
  frameworkComponents4: any = {};
  rowData4 = [];
  public gridColumnApi4: any;
  public gridApi4: any;

  gridOptions5: GridOptions;
  columnDefs5 = [];
  frameworkComponents5: any = {};
  rowData5 = [];
  public gridColumnApi5: any;
  public gridApi5: any;
  emptyState5: any;

  gridOptions6: GridOptions;
  columnDefs6 = [];
  frameworkComponents6: any = {};
  rowData6 = [];
  public gridColumnApi6: any;
  public gridApi6: any;

  gridOptions7: GridOptions;
  columnDefs7 = [];
  frameworkComponents7: any = {};
  rowData7 = [];
  public gridColumnApi7: any;
  public gridApi7: any;

  context: any;

  filterData: any;

  API_URL = '';
  public getRowId;
  public pinnedParams = [];
  dateSelectMode = 'range';

  showableFilters = {
    max_claim: false,
    policy: false,
    salesman: false,
    status: false,
    class: false,
    beat: false,
    trax_outlet: false,
    non_trax_outlet: false,
    exclude_trax_audit: false,
    region: false,
    cluster: false,
    team: false,
    territory: false,
    outlet_type: false,
    payment_mode: false,
    dc: false,
    state: false
  };
  filterSet = false;
  progress = 0;
  progressText = '';
  showProgress = false;
  showProgressBar = false;
  progressMode = 'indeterminate';
  showProgressCustomText = false;
  progressTotal = 0;
  keyColumn = '';
  showCreate = false;
  showUpload = false;
  public collapse_disable;
  uploadTask = false;
  showBaseUpload = false;
  showDefaultFilter = false;
  showCustomerFilter = true;
  uploadTaskwithType = false;
  uploadTypeList = [];
  selectedUploadType;
  public uploadCaption = ['', 0];
  public fileUploaded: File;
  filesize = 0;
  public storeData: any;
  public worksheet: any;
  public uploading = false;
  public upload_loader = false;
  public upload_enable = false;
  public upload_task_data;
  public order_data = [];
  public showExcelSample = false;
  public excel_sample = [];
  public excel_sample_title = '';
  matActionMenuItemDeclaration = [];
  matAuthorizedActionMenuItems = [];
  showActionButton = false;
  public subdomain: string;
  public container = '';

  collapse_disabled = false;

  viewGrid = true;
  viewGallery = false;
  viewMap = false;

  showViewSelector = false;
  showGalleryview = false;
  showMapView = false;
  selectedGalleryItem;

  galleryData = {
    pageSize: 50,
    id: 'id',
    image: '',
    name: '',
    field1: '',
    field2: '',
    field3: '',
    status: '',
    statusTypes: [],
    reviewed: '',
    reviewTypes: {
      yes: 'approved',
      no: 'rejected'
    },
    container: '',
    selectionCriteria: []
  };
  filteredData1 = [];
  pageData = [];
  goClicked = false;
  columnState = [];
  selectedCount = 0;
  allSelect = false;
  agGridFilter;


  showPinnedFooter = false;
  columnsWithAggregation = [];
  statusConfig = {
    success: 'success',
    success1: 'success',
    error: 'fail',
    error1: 'fail',
    warning: 'warning',
    warning1: 'warning',
    new: 'new',
    processed: 'processed'
  };
  public orderDetails = {label_config: {}};
  public print_details = [];
  public payment_enable: boolean;
  printType = 'Invoice';
  tabList = [];
  customerList = [];
  supplierList = [];
  agGridList = [];
  selectedPeriod;
  periodData = [];
  file_status = 'pending';
  file_date = '';
  file_ack = '';
  showViewInvoices = false;
  gstr3 = false;

  constructor(public apiService: ApiService,
              protected router: Router,
              protected route: ActivatedRoute,
              public elRef: ElementRef,
              protected renderer: Renderer2,
              public datePipe: DatePipe,
              public sharedService: SharedService,
              public dialog: MatDialog,
              public toastr: ToastrService,
              private componentFactoryResolver: ComponentFactoryResolver,
              protected changeDetector: ChangeDetectorRef,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              protected _localCacheService: LocalforageService) {

    this.USER = JSON.parse(localStorage.getItem('user'));



    const date = new Date();
    if (this.dateSelectMode === 'single') {
      this.myDate = this.datePipe.transform(date, 'yyyy-MM-dd');
      /* this.myDate[0] = this.datePipe.transform(date, 'yyyy-MM-dd');
       this.myDate[1] = this.datePipe.transform(date, 'yyyy-MM-dd');*/
      this.userData.start_date = this.myDate;
      this.userData.end_date = this.myDate;
    } else {
      this.myDate = [];
      this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
      this.myDate[1] = this.datePipe.transform(date, 'yyyy-MM-dd');
      this.userData.start_date = this.myDate[0];
      this.userData.end_date = this.myDate[1];
    }

    this.periodData = JSON.parse(localStorage.getItem('jc_months'));
    this.userData.start_date = this.myDate[0];
    this.userData.end_date = this.myDate[1];
    this.userData.offset = -1 * (new Date(this.userData.start_date)).getTimezoneOffset();
    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.userData.url = localStorage.getItem('private_url');
    this.userData.offsetID = 0;

    this.periodData.reverse();
    this.periodID = this.periodData[0].id;
    this.selectedPeriod = this.periodData.find(x => x.id === this.periodID);

    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.gridOptions2 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.gridOptions3 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.gridOptions4 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.gridOptions5 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.gridOptions6 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
        {
          id: 'header',
          interior: {
            color: "#E4AB11",
            pattern: "Solid",
          },
        }
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.gridOptions7 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      rowGroupPanelShow: 'always',
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
        {
          id: 'header',
          interior: {
            color: "#E4AB11",
            pattern: "Solid",
          },
        }
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;


    this.getSubdomain();
    this.sharedService.filterSetEvent
      .subscribe((set: boolean) => {
        if (set) {
          this.filterSet = true;
        } else {
          this.filterSet = false;
        }
      });
  }

  ngOnInit() {
    /*  const t = trace(this.appModule.perf, 'pages');
      t.start();
      t.putAttribute('reports', this.API_URL);*/
    this.dateSelectMode = 'range';


  }

  getSubdomain() {
    const domain = window.location.hostname;
    this.subdomain = domain.split('.')[0];
  }

  generateImageUrl(field: string) {
    return (params) => {
      if (params.data) {
        if (params.data[field] === null || params.data[field] === "") {
          return '';
        } else {
          const url = 'https://' + this.subdomain + '.salesdiary.in/imagerender?url=' + this.apiService.api_url +
            '&sf=' + params.data[field] + '&cont=' + this.container;
          return url;
        }
      } else {
        return '';
      }
    };
  }

  getAuthorizedActions() {
    this.matAuthorizedActionMenuItems = [];
    this.matActionMenuItemDeclaration.forEach((actionItem) => {
      if (actionItem.feature === undefined) {
        this.matAuthorizedActionMenuItems.push(actionItem);
      } else if (this.sharedService.ACLcheck(actionItem.feature)) {
        this.matAuthorizedActionMenuItems.push(actionItem);
      }
    });
  }

  public handleError(error: ErrorObject) {
    this.preloader = false;
    this.dataLoaded = false;
    this.errorExists = true;
    this.errorObject = error;
  }


  loadServerData() {
    this.goClicked = true;
    this.dataLoaded = false;
    this.preloader = true;
    this.errorExists = false;
    this.pageData = [];
    this.selectedCount = 0;
    this.showApprove = false;

    if (this.API_URL === '') {
      return this.handleError(ErrorHandler.getErrorObject('API Not Configured'));
    }

    if (this.showSearchableFilter) {
      if (!this.userData.searchableFilter && this.searchableFilter && this.searchableFilter.values && this.searchableFilter.values.length > 0) {
        this.userData.searchableFilter = this.searchableFilter.values[0][this.searchableFilter['bindValue']];
        this.searchableFilter.value = this.searchableFilter.values[0][this.searchableFilter['bindValue']];
      }
    }
    if (this.showCustomFilter) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
      }
    }
    if (this.showCustomFilter1) {
      if (this.userData.customFilter != null && !this.userData.customFilter.hasOwnProperty(this.customFilter1.key)) {
        this.userData.customFilter[this.customFilter1.key] = this.customFilter1.value;
      }
    }
    if (this.dateSelectMode === 'single') {
      this.userData.start_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    } else {
      this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    }

    if (this.showProgress === false) {

      //Load Data in one shot
      this.apiService.post(this.API_URL, this.userData)
        .subscribe(res => {
            console.log(res);
            // t.stop();
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              this.apiData = res.results.data; //TODO: check if this line is needed
              this.postProcessData();
              //this.rowData1 should be ready now
              this.preloader = false;
              this.dataLoaded = true;
            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
                console.error(res.results.errmsg);
              }
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
                console.error(res.results.errmsg);
              }
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          // tslint:disable-next-line:no-shadowed-variable
          error => this.handleError(ErrorHandler.getErrorObject(error)));
    } else {
      //Load data in batches
      this.loadInBatches(this.userData.offset, this.keyColumn);
      this.fetch(this.keyColumn);
    }
  }

  loadInBatches(limit, keyColumn) {
    this.showProgress = true;
    this.showProgressBar = true;
    this.progress = 0;
    this.progressText = '';
    this.progressTotal = 0;
    this.userData.offsetID = 0;
    this.userData.offset = limit;
    this.keyColumn = keyColumn;
    this.apiData = [];
  }

  loadInBatchesAll(limit, keyColumn) {
    this.showProgress = true;
    this.showProgressBar = true;
    /* this.progress = 0;
     this.progressText = '';
     this.progressTotal = 0;*/
    this.userData.offsetID = 0;
    this.userData.offset = limit;
    this.keyColumn = keyColumn;
  }

  async fetch(key) {
    const res = await this.apiService.postPromise(this.API_URL, this.userData);
    console.log(res);
    if (res.hasOwnProperty('results') && (res.results.status === 200)) {
      // console.log(res);
      //this.apiData.push(res.results.data);
      for (const item of res.results.data) {
        this.apiData.push(item);
      }
      if (this.apiData === null || this.apiData === undefined) {
        this.postProcessData();
        this.dataLoaded = true;
        this.preloader = false;
      } else if (this.apiData.length === 0) {
        this.postProcessData();
        this.dataLoaded = true;
        this.preloader = false;
      } else {


        if (this.apiData[0].hasOwnProperty('total_prog')) {
          this.progressTotal = this.apiData[0].total_prog;
          this.showProgressBar = true;
          this.progressMode = 'determinate';
        } else {
          this.showProgressBar = false;
          this.progressTotal = 0;
        }
        if (res.results.data.length === this.userData.offset) {
          this.userData.offsetID = this.apiData[this.apiData.length - 1][key];
          if (this.progressTotal !== 0) {
            this.progress = Math.round((this.apiData.length / this.progressTotal) * 100);
            this.progressText = this.apiData.length + '/' + this.progressTotal;
          } else {
            this.progressText = 'Downloaded ' + this.apiData.length + ' records';
          }

          await this.fetch(key);
        } else {
          this.postProcessData();
          // this.rowData1 = this.postProcessData(this.apiData);
          this.dataLoaded = true;
          this.preloader = false;
        }
      }
    } else if (res.hasOwnProperty('results') && res.results.status === 203) {
      if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
        console.error(res.results.errmsg);
      }
      this.handleError(ErrorHandler.getErrorObject(res.results.msg));
    } else {
      if (res.hasOwnProperty('results') && res.results.hasOwnProperty('errmsg')) {
        console.error(res.results.errmsg);
      }
      this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
    }
  }

  postProcessData() {
    this.rowData1 = this.apiData;
    this.filteredData1 = this.rowData1;
    return;
  }


  removeFilter(displayData: any) {
    this.sharedService.filterCancelCLick(displayData.key);
  }

  setFilter(filterdata) {
    this.userData.filterData.displayData = [...this.userData.filterData.displayData];
    this.userData.filterData = filterdata;
    this.trigger.closeMenu();
    this.loadServerData();
  }

  setMultiFilter(multifilterdata) {
    console.log(multifilterdata);

  }

  setMultiFilterString(multifilterdata) {
    console.log(multifilterdata);
    if (multifilterdata.includes("all")) {
      this.userData.multiFilter = ["all"];
    } else {
      this.userData.multiFilter = multifilterdata;
    }

  }

  setSearchableFilter(searchableFilterData) {
    this.userData.searchableFilter = searchableFilterData;
    this.searchableFilter.value = this.searchableFilter.values.find(x => x.id === searchableFilterData);
  }

  setCustomFilter(customfilterdata) {

    for (const [key, value] of Object.entries(customfilterdata)) {
      this.userData.customFilter[key] = value;
    }
  }

  changeActiveTab(event) {
    /*this.customFilter.value = this.customFilter.values[event.index].id;
    this.userData.customFilter[this.customFilter.key] = this.customFilter.value;
    this.loadServerData();*/
  }

  changeDate(dt) {
    if (this.dateSelectMode === 'single') {
      this.userData.start_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    } else {
      this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    }
  }

  onColumnVisible(event) {
    if (this.gridColumnApi1 !== null) {
      const columnState = JSON.stringify(this.gridColumnApi1.getColumnState());
      localStorage.setItem('myColumnState', columnState);
    }
  }

  onDragStopped(event) {
    if (this.gridColumnApi1 !== null) {
      const columnState = JSON.stringify(this.gridColumnApi1.getColumnState());
      localStorage.setItem('myColumnState', columnState);
    }

  }

  onFilterChanged(event) {
    this.agGridFilter = this['gridApi' + (this.tabIndex + 1)].getFilterModel();
    this.sharedService.currListData = {
      url: this.router.url,
      userdata: this.userData,
      searchText: document.getElementById('filter-text-box')['value'],
      agGridFilter: this.agGridFilter
    };
  }

  onFilterModified(event) {
    this.agGridFilter = this['gridApi' + (this.tabIndex + 1)].getFilterModel();
    this.sharedService.currListData = {
      url: this.router.url,
      userdata: this.userData,
      searchText: document.getElementById('filter-text-box')['value'],
      agGridFilter: this.agGridFilter
    };
  }

  onCellClicked(event) {

  }

  onCellClicked1(event) {

  }

  onCellClicked2(event) {
  }

  onCellClicked3(event) {
  }

  onCellClicked4(event) {
  }

  onCellClicked5(event) {
  }

  onCellClicked6(event) {
  }

  onCellClicked7(event) {
  }


  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    params.api.setColumnDefs(this.columnDefs1);
    params.api.setRowData(this.rowData1);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi1) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);

    const searchtext = document.getElementById('filter-text-box')['value'];
    if (searchtext && searchtext !== "") {
      this.quickSearch();
    }
  }

  onGridReady2(params) {
    this.gridOptions2.api.showLoadingOverlay();
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
    params.api.setColumnDefs(this.columnDefs2);
    params.api.setRowData(this.rowData2);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi2) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);

    const searchtext = document.getElementById('filter-text-box')['value'];
    if (searchtext && searchtext !== "") {
      this.quickSearch();
    }
  }

  onGridReady3(params) {
    this.gridOptions3.api.showLoadingOverlay();
    this.gridApi3 = params.api;
    this.gridColumnApi3 = params.columnApi;
    params.api.setColumnDefs(this.columnDefs3);
    params.api.setRowData(this.rowData3);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi3) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);

    const searchtext = document.getElementById('filter-text-box')['value'];
    if (searchtext && searchtext !== "") {
      this.quickSearch();
    }
  }

  onGridReady4(params) {
    this.gridOptions4.api.showLoadingOverlay();
    this.gridApi4 = params.api;
    this.gridColumnApi4 = params.columnApi;
    params.api.setColumnDefs(this.columnDefs4);
    params.api.setRowData(this.rowData4);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi4) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);

    const searchtext = document.getElementById('filter-text-box')['value'];
    if (searchtext && searchtext !== "") {
      this.quickSearch();
    }
  }

  onGridReady5(params) {
    this.gridOptions5.api.showLoadingOverlay();
    this.gridApi5 = params.api;
    this.gridColumnApi5 = params.columnApi;
    params.api.setColumnDefs(this.columnDefs5);
    params.api.setRowData(this.rowData5);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi5) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);

    const searchtext = document.getElementById('filter-text-box')['value'];
    if (searchtext && searchtext !== "") {
      this.quickSearch();
    }
  }

  onGridReady6(params) {
    this.gridOptions6.api.showLoadingOverlay();
    this.gridApi6 = params.api;
    this.gridColumnApi6 = params.columnApi;
    params.api.setColumnDefs(this.columnDefs6);
    params.api.setRowData(this.rowData6);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi6) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);

    const searchtext = document.getElementById('filter-text-box')['value'];
    if (searchtext && searchtext !== "") {
      this.quickSearch();
    }
  }

  onGridReady7(params) {
    this.gridOptions7.api.showLoadingOverlay();
    this.gridApi7 = params.api;
    this.gridColumnApi7 = params.columnApi;
    params.api.setColumnDefs(this.columnDefs7);
    params.api.setRowData(this.rowData7);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi7) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);

    const searchtext = document.getElementById('filter-text-box')['value'];
    if (searchtext && searchtext !== "") {
      this.quickSearch();
    }
  }


  quickSearch() {
    const searchtext = document.getElementById('filter-text-box')['value'];
    if (this.tabIndex === 0 && this.gridOptions1['api']) {
      this.gridApi1.setQuickFilter(searchtext);
      //this.filteredData1 = this.gridApi.rowModel.rowsToDisplay;
    }
    if (this.tabIndex === 1 && this.gridOptions2['api']) {
      this.gridApi2.setQuickFilter(searchtext);
      //this.filteredData1 = this.gridApi.rowModel.rowsToDisplay;
    }
    if (this.tabIndex === 2 && this.gridOptions3['api']) {
      this.gridApi3.setQuickFilter(searchtext);
      //this.filteredData1 = this.gridApi.rowModel.rowsToDisplay;
    }
    if (this.tabIndex === 3 && this.gridOptions4['api']) {
      this.gridApi4.setQuickFilter(searchtext);
      //this.filteredData1 = this.gridApi.rowModel.rowsToDisplay;
    }
    if (this.tabIndex === 4 && this.gridOptions5['api']) {
      this.gridApi5.setQuickFilter(searchtext);
      //this.filteredData1 = this.gridApi.rowModel.rowsToDisplay;
    }
    if (this.tabIndex === 5 && this.gridOptions6['api']) {
      this.gridApi6.setQuickFilter(searchtext);
      //this.filteredData1 = this.gridApi.rowModel.rowsToDisplay;
    }
    if (this.tabIndex === 6 && this.gridOptions7['api']) {
      this.gridApi7.setQuickFilter(searchtext);
      //this.filteredData1 = this.gridApi.rowModel.rowsToDisplay;
    }


    this.sharedService.currListData = {
      url: this.router.url,
      userdata: this.userData,
      searchText: document.getElementById('filter-text-box')['value'],
      agGridFilter: this.agGridFilter
    };
    /*if (this.rowData1) {
      this.filteredData1 = this.rowData1.filter(x =>
        (x[this.galleryData['name']] && x[this.galleryData['name']].toLocaleLowerCase().includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['field1']] && x[this.galleryData['field1'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['field2']] && x[this.galleryData['field2'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['field3']] && x[this.galleryData['field3'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())) ||
        (x[this.galleryData['status']] && x[this.galleryData['status'].toLocaleLowerCase()].includes(searchtext.toLocaleLowerCase())));
    }*/
  }

  setPeriod(selectedPeriod: any) {
    this.myDate = [];
    this.myDate[0] = selectedPeriod.start_date;
    this.myDate[1] = selectedPeriod.end_date;
    this.periodID = selectedPeriod.id;
    this.userData.period_id = this.periodID;
    this.selectedPeriod = selectedPeriod;
  }

  onBtExport() {
    const header = this.sharedService.headerTitleSource.getValue();
    const params = {
      fontSize: undefined,
      rowHeight: undefined,
      headerRowHeight: undefined,
      fileName: header + '.csv'
    };
    if (this.tabIndex === 0) {
      params.fileName = header + ' - ' + this.tabList[0].itemName + '.csv';
      this.gridApi1.exportDataAsExcel(params);
    } else if (this.tabIndex === 1) {
      params.fileName = header + ' - ' + this.tabList[1].itemName + '.csv';
      this.gridApi2.exportDataAsExcel(params);
    } else if (this.tabIndex === 2) {
      params.fileName = header + ' - ' + this.tabList[2].itemName + '.csv';
      this.gridApi3.exportDataAsExcel(params);
    } else if (this.tabIndex === 3) {
      params.fileName = header + ' - ' + this.tabList[3].itemName + '.csv';
      this.gridApi4.exportDataAsExcel(params);
    } else if (this.tabIndex === 4) {
      params.fileName = header + ' - ' + this.tabList[4].itemName + '.csv';
      this.gridApi5.exportDataAsExcel(params);
    } else if (this.tabIndex === 5) {
      params.fileName = header + ' - ' + this.tabList[5].itemName + '.csv';
      this.gridApi6.exportDataAsExcel(params);
    }

  }

  gridSearch(searchText) {
    this.gridApi1.setQuickFilter(searchText);
  }

  configureGrid() {
  }

  setTitle() {
  }

  openCreateDialog() {
  }

  showUploadPopup() {
  }

  uploadedFile(event) {

    this.uploadCaption = ['', 0];
    this.fileUploaded = event.target.files[0];
    this.filesize = Math.round(this.fileUploaded.size / 1024);
    this.readExcel();
  }

  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, {type: 'binary'});
      const first_sheet_name = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[first_sheet_name];

      this.upload_task_data = XLSX.utils.sheet_to_json(this.worksheet, {raw: true});
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  uploadexcel() {
  }

  bulkUpdateProgress(state) {
  }

  onSelectionChanged(event) {
  }

  unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }

  checkAccessControl() {
  }

  viewSelector(selectedview) {
    /*  if (this.gridOptions1.api) {
        this.filteredData1 = this.gridApi.rowModel.rowsToDisplay;
      }*/
    if (this.gridOptions1.columnApi) {
      this.columnState = this.gridColumnApi1.getColumnState();
      this.gridColumnApi1.setRowGroupColumns([]);
    }
    if (selectedview === 'gallery') {

      if (this.gridOptions1.api) {
        const filter = this.gridApi1.rowModel.rowsToDisplay;
        this.filteredData1 = [];
        for (const key in filter) {
          if (filter[key].hasOwnProperty('data')) {
            this.filteredData1.push(filter[key].data);
          }
          /*  if (filter[key].group === true) {
              for (const key1 in filter[key].childrenAfterFilter) {

              }
            } else {
              this.filteredData1.push(filter[key].data);
            }*/
        }
      } else {
        this.showApprove = false;
        this.filteredData1 = this.rowData1;
      }
      this.viewGallery = true;

      this.viewGrid = false;
      this.viewMap = false;

    } else if (selectedview === 'map') {

      this.viewMap = true;

      this.viewGrid = false;
      this.viewGallery = false;

    } else {

      this.viewGrid = true;

      this.viewGallery = false;
      this.viewMap = false;
      if (this.showGalleryview) {
        this.showApprove = false;
      }

    }
  }

  cardClick(clickObject) {
    if (clickObject.hasOwnProperty('item') && clickObject.item && clickObject.item.click === 'double') {
      this.selectedGalleryItem = clickObject.item;
      this.showDetailPopup();
    } else {
      this.pageData = clickObject.data;
      const itemSelected = this.pageData.filter(x => x['item_selected'] === true);
      if (itemSelected && itemSelected.length > 0) {
        this.showApprove = true;
        this.selectedCount = itemSelected.length;
      } else {
        this.showApprove = false;
        this.selectedCount = 0;
      }
    }
  }

  checkIfSelectAllowed(selectedItem, selectionCriteria) {
    if (selectionCriteria.matches) {
      if (!selectedItem[selectionCriteria.field]) {
        return true;
      } else if (selectedItem[selectionCriteria.field].includes(selectionCriteria.values)) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!selectedItem[selectionCriteria.field].includes(selectionCriteria.values)) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkSelectionCriteria(selectedItem) {
    if (this.galleryData.selectionCriteria) {
      let matches = true;
      for (const criteria of this.galleryData.selectionCriteria) {
        if (!this.checkIfSelectAllowed(selectedItem, criteria)) {
          matches = false;
          return false;
        }
      }
      return matches;
    } else {
      return true;
    }
  }

  selectAll() {
    let item_selected = false;
    let selectedCount = 0;
    const currentIndex = parseInt(localStorage.getItem('currentgalleryIndex'), 10);
    const currentPage = parseInt(localStorage.getItem('currentgalleryPage'), 10);

    let range = ((currentPage * this.galleryData.pageSize) + 1);
    if ((currentIndex + this.galleryData.pageSize) > this.filteredData1.length) {
      range = this.filteredData1.length + 1;
    }

    if (this.allSelect) {
      item_selected = true;
    } else {
      item_selected = false;
    }
    this.pageData = [];
    for (let i = currentIndex; i < range; i++) {
      this.pageData.push(this.filteredData1[i - 1]);
      if (item_selected) {
        if (this.checkSelectionCriteria(this.filteredData1[i - 1])) {
          selectedCount += 1;
          this.filteredData1[i - 1]['item_selected'] = true;
        } else {
          this.filteredData1[i - 1]['item_selected'] = false;
        }
      } else {
        if (item_selected) {
          selectedCount += 1;
        }
        this.filteredData1[i - 1]['item_selected'] = item_selected;
      }
    }
    if (this.allSelect) {
      this.selectedCount = selectedCount;
    } else {
      this.selectedCount = 0;
    }

    if (this.selectedCount > 0) {
      this.showApprove = true;
    } else {
      this.showApprove = false;
    }
    const clickObject = {
      item: "all",
      data: this.pageData
    };
    this.cardClick(clickObject);
  }


  showDetailPopup() {
  }

  performAction(action) {

  }

  openConfirmationDialog(row, state: string) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "550px",
      maxHeight: "250px",
      data: {
        title: state + " Retailer",
        message1: "Do you wish to " + state + "?",
        message2: "",
        showNoButton: true,
        yesButton: state,
        noButton: "CANCEL"
      }
    };

    if (state === 'REJECT') {
      config.data.deleteButton = true;
    }

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      console.log(data);
      let status = '';
      if (state === 'REJECT') {
        status = 'rejected';
      } else {
        status = 'approved';
      }
      if (data === 'yes') {
      } else {
      }
    });
  }

  checkAndLoadPreviousSate() {
    if (this.sharedService.currListData && this.sharedService.currListData.url === this.router.url) {
      this.userData = this.sharedService.currListData.userdata;
      if (this.dateSelectMode === 'single') {
        this.myDate = this.userData.start_date;
      } else {
        this.myDate[0] = this.userData.start_date;
        this.myDate[1] = this.userData.end_date;
      }

      if (this.userData.customFilter != null && this.userData.customFilter.hasOwnProperty(this.customFilter.key)) {
        this.customFilter.value = this.userData.customFilter[this.customFilter.key];
      }

      if (this.userData.customFilter != null && this.userData.customFilter.hasOwnProperty(this.customFilter1.key)) {
        this.customFilter1.value = this.userData.customFilter[this.customFilter1.key];
      }
      if (this.sharedService.currListData.searchText) {
        document.getElementById('filter-text-box')['value'] = this.sharedService.currListData.searchText;
      }
      if (this.sharedService.currListData.agGridFilter) {
        this.agGridFilter = this.sharedService.currListData.agGridFilter;
      }
    }
  }

  updateImmutableObject(original: any, newValues: any) {
    // start with new object
    const newObject: any = {};
    // copy in the old values
    Object.keys(original).forEach(key => {
      newObject[key] = original[key];
    });
    // now override with the new values
    Object.keys(newValues).forEach(key => {
      newObject[key] = newValues[key];
    });
    return newObject;
  }

  formatNumber(number) {
    if (number) {
      number = parseFloat(number).toFixed(this.apiService.decimalPolicy);
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return '';
    }
  }

  downloadSampleExcel() {
    const headers = Object.keys(this.excel_sample[0]);
    const fields = [];
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: this.excel_sample_title,
      useBom: true,
      noDownload: false,
      headers
    };

    return new ngxCsv(this.excel_sample, this.excel_sample_title, options);
  }

  findIndex(): number {
    return this.customFilter.values.findIndex(x => x.id === this.customFilter.value);
  }

  async loadPartnersForSales(userData, callback) {
    const res = await this.apiService.postPromise('/api/pwa_dms_connects/get_partners_for_sales', userData);
    if (res) {
      this.customerList = this.parseServerResponse('customers', null, true, res);
      this.supplierList = this.parseServerResponse('suppliers', null, true, res);
      if (this.showSearchableFilter) {
        this.searchableFilter.values = this.customerList;
        this.searchableFilter.value = this.searchableFilter.values[1].id;
        this.userData.searchableFilter = this.searchableFilter.values[1].id;
      }
      return callback(null, null);
    } else {
      return callback('Error', null);
    }
    callback(null, null);
  }

  parseServerResponse(key, cache, validate, res) {
    if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
      if (res['results'].data) {
        if (res['results'].data[key] && cache) {
          cache.set(key, res['results'].data[key]).then();
        } else if (res['results'].data && cache) {
          cache.set(key, res['results'].data).then();
        }
        if (res['results'].data[key]) {
          return res['results'].data[key];
        } else {
          return res['results'].data;
        }
      }
    } else {
      this.handleError(ErrorHandler.getErrorObject(res.results.msg));
      return null;
    }
  }

  onMenuItemSelected(menuitem) {

  }

  openGSTFilePopup() {

  }

  viewInvoices() {

  }

}
