<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-6 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search for DC">
          </div>
        </div>
        <div class="col-md-6 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-select [items]="itemList"
                           bindLabel="itemName"
                           multiple="true"
                           maxSelectedItems="1"
                           [(ngModel)]="selectedItems"
                           (ngModelChange)="typeChange($event)"
                >
                </ng-select>
              </li>
<!--              <li class="margLR">-->
<!--                <app-printicon [gridOption]="gridOptions" [styleGrid]="styleGrid"></app-printicon>-->
<!--              </li>-->
              <li class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Live Stock Report'"></app-excelicon>
              </li>
              <li *ngIf="upload_stock" style="margin-top: 0%;" class="margLR">
                <img data-toggle="modal" data-target="#popupModal" src="../../../assets/upload.svg"/>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div>
            <ag-grid-angular id="myGrid1" *ngIf="loaded" style="height: calc(100vh - 160px);" class="ag-theme-balham"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents" [context]="context">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <!--<div style="position:absolute;left:44%;top:200px;font-size: medium">-->
              <!--<img src="../../assets/images/preloader.gif"/>-->
              <!--</div>-->
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="approve-modal modal" id="popupModal" role="dialog">
  <div id="overlay"></div>
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img src="../../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label style="padding-bottom: 5%;">UPLOAD STOCK</label>
          <!--          <h1> Kindly update reason</h1>-->
          <input #fileUp type="file" class="form-control" (change)="uploadedFile($event)" placeholder="Upload file" accept=".xlsx">
        </div>
        <p style="font-size: medium" [style.color]="uploadCaption[1] == 0 ? 'green' : 'red'">{{uploadCaption[0]}}</p>
        <div class="approve-modal-content">
          <a *ngIf="!uploading" class="btn blue-btn" (click)="uploadexcel()">Upload</a>
          <!--          <a href="https://docs.google.com/spreadsheets/d/1l0P-pxS_vC99Ceg3rQI066MCtY_4SJqldApoKZM8Ia0/edit?usp=sharing" target="_blank"-->
          <!--             style="margin-left: 30%;" class="btn blue-btn">Download Sample</a>-->
          <div *ngIf="uploadLoader">
            <img style="width:5%;margin-top: 10%;" src="../../../assets/images/loader_icon.gif"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
