import {Component, ElementRef, Inject, Input, OnChanges, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer} from "@angular/platform-browser";
import {OverlayService} from "../../_services/overlay.service";
import {OverlayConfig, OverlayRef} from "@angular/cdk/overlay";

@Component({
  selector: 'app-page-loader',
  templateUrl: './page_loader.component.html',
  styleUrls: ['./page_loader.component.scss']

})

export class PageLoaderComponent implements OnInit, OnChanges {

  @ViewChild('progressSpinnerRef', { read: TemplateRef, static: true }) progressSpinnerRef: TemplateRef<any>;
  private progressSpinnerOverlayConfig: OverlayConfig;
  private overlayRef: OverlayRef;
  @Input() show;
  constructor(private vcRef: ViewContainerRef, private overlayService: OverlayService) {

  }

  ngOnInit(): void {
    // Config for Overlay Service
    this.progressSpinnerOverlayConfig = {
      hasBackdrop: true
    };
    this.progressSpinnerOverlayConfig['positionStrategy'] = this.overlayService.positionGloballyCenter();
    // Create Overlay for progress spinner
    this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOverlayConfig);
  }

  ngOnChanges(changes): void {
    if (this.show && this.overlayRef && !this.overlayRef.hasAttached()) {
      this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
    } else if (!this.show && this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }
}

