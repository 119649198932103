<div  class="map" id="map1" style="margin-top: 20px;border: 1px solid #D7DDE6;border-radius: 5px;height:  calc(100vh - 170px);">
  <agm-map (mapReady)="onMapReady($event)" [zoom]="zoom" [styles]="styles">
    <ng-container *ngFor="let item of mapData; let i = index">
      <agm-marker *ngIf="item.lat && item.long" [latitude]="item.lat" [longitude]="item.long"
                  [iconUrl]="getMarker(item)" (mouseOver)="onMouseOver(infoWindow, $event)"
                  (mouseOut)="onMouseOut(infoWindow, $event)">
        <agm-info-window #infoWindow
                         [latitude]="item.lat"
                         [longitude]="item.long">
          <span *ngIf="item[MapViewMetaData['title']]" class="detailvaluebold2"> {{item[MapViewMetaData['title']]}}</span>
          <div class="row centeralign" style="margin-top: 5px;">
            <span *ngIf="MapViewMetaData['field1']" class="detaillabel4">{{MapViewMetaData['field1']}}</span>
            <span *ngIf="MapViewMetaData['field1']" class="detailvalue3" style="margin-left: 5px;"> {{item[MapViewMetaData['field1']]}}</span>

            <span *ngIf="MapViewMetaData['field2']" class="detaillabel4" style="margin-left: 20px;">{{MapViewMetaData['field2']}}</span>
            <span *ngIf="MapViewMetaData['field2']" class="detailvalue3" style="margin-left: 5px;"> {{item[MapViewMetaData['field2']]}}</span>
          </div>
        </agm-info-window>>
      </agm-marker>
    </ng-container>
  </agm-map>
</div>
