<div class="common-wrapper" xmlns="http://www.w3.org/1999/html">
  <div class="common-container">
    <div class="main-wrapper">
      <div style="padding: 10px;">
        <div class="cardbox" style="width: 100%">
          <div class="row">
            <div style="width: 100%;">
              <h3>{{serviceDetails.serviceno}}</h3>
              <div class="row centeralign">
                <span class="detaillabel1 mr-2">Requested On</span>
                <span class="detailvalue1">{{serviceDetails['requested_date']}}</span>

                <span class="greyindicator" style="margin-left: 30px;"></span>
                <span class="detaillabel1 mr-2">Product Type</span>
                <span class="detailvalue1">Product Linked</span>
              </div>
              <div class="mt-5" style="display: flex">
                <div style="flex: 1">
                  <label class="detaillabel">Service Type</label>
                  <p class="detailvalue1">{{serviceDetails.service_type}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">Started On</label>
                  <p class="detailvalue1">{{serviceDetails['service_start']}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">Service Engineer</label>
                  <p *ngIf="!showServiceEngineerAssign" class="detailvalue1">{{serviceDetails['service_engineer']}}</p>
                  <p *ngIf="showServiceEngineerAssign" class="detailvaluedesc" style="cursor: pointer;font-weight: 500;" (click)="openAssignServiceEngineerPopup()">Click to Edit</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">Closed On</label>
                  <p class="detailvalue1">{{serviceDetails['service_closed']}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">Customer</label>
                  <p class="detailvalue1">{{serviceDetails['customer_name']}}</p>
                  <p class="detailvaluedesc">Code : {{serviceDetails['customer_code']}}</p>
                </div>
                <div style="flex: 1">
                  <label class="detaillabel">Address</label>
                  <p class="detailvalue1">{{serviceDetails['address']}}</p>
                </div>
              </div>
            </div>

            <div style="position: absolute;right: 30px;">
              <div class="button-row centeralign">
                <div style="display: inline-block; margin-right: 20px;">
                  <img title = "Low Priority" *ngIf="priority === 0" style="height: 22px;margin-right: 5px;cursor:pointer;" src="../../../assets/images/star_not_selected.svg"/>
                  <img title = "Low Priority" *ngIf="priority >= 1" style="height: 22px;margin-right: 5px;cursor:pointer;" src="../../../assets/images/star_selected.svg"/>

                  <img title = "Medium Priority" *ngIf="priority <= 1" style="height: 22px;margin-right: 5px;cursor:pointer;" src="../../../assets/images/star_not_selected.svg"/>
                  <img title = "Medium Priority" *ngIf="priority >=2"  style="height: 22px;margin-right: 5px;cursor:pointer;" src="../../../assets/images/star_selected.svg"/>

                  <img title = "High Priority" *ngIf="priority <= 2" style="height: 22px;margin-right: 5px;cursor:pointer;" src="../../../assets/images/star_not_selected.svg"/>
                  <img title = "High Priority" *ngIf="priority === 3"  style="height: 22px;margin-right: 5px;cursor:pointer;" src="../../../assets/images/star_not_selected.svg"/>

                </div>
                <div style="display: inline-block; margin-right: 20px;">
                  <span (click)="fetchPreviousData()"><i
                    [ngClass]="{'disabledspan': rowIndex === 0,
                     'enabledspan': rowIndex >0}"
                    class="fal fa-arrow-circle-left fa-2x"></i></span>
                  <span
                    style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 14px;">{{rowIndex + 1}}
                    /{{service_ids.length}}</span>
                  <span (click)="fetchNextData()"><i
                    [ngClass]="{'disabledspan': rowIndex === service_ids.length-1,
                     'enabledspan': rowIndex < service_ids.length-1}"
                    class="fal fa-arrow-circle-right fa-2x"></i></span>
                </div>
                <div *ngIf="serviceDetails['service_status'] !== 'REJECTED'" style="display: inline-block;"
                     class="btn light-blue-btn">
                  <span
                    style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span
                  style="color: #2D2D2D">{{serviceDetails['service_status']}}</span>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div *ngIf="dataLoaded && assetLinked" class="cardbox mt-4" style="width: 100%">
          <div class="row centeralign" style="cursor: pointer" (click)="assetCardClick()">
            <h3>Asset Details</h3>
            <img *ngIf="!showAssets" style="position: absolute; right: 30px;height: 8px;"
                 src="../../../assets/images/down_arrow_black.svg"/>
            <img *ngIf="showAssets" style="position: absolute; right: 30px;height: 8px;"
                 src="../../../assets/images/up_arrow.svg"/>
          </div>
          <div *ngIf="showAssets" style="margin-top: 20px;display: flex;">
              <div style="flex: 1">
                <label class="detaillabel">Bar Code</label>
                <p class="detailvalue1">{{serviceDetails.barcode}}</p>
              </div>
              <div style="flex: 1">
                <label class="detaillabel">Installation Date</label>
                <p class="detailvalue1">{{serviceDetails['installed_date']}}</p>
              </div>
              <div style="flex: 1">
                <label class="detaillabel">Installed By</label>
                <p class="detailvalue1">{{serviceDetails['installed_by']}}</p>
              </div>
              <div style="flex: 1">
                <label class="detaillabel">Asset Type</label>
                <p class="detailvalue1">{{serviceDetails['asset_master']}}</p>
              </div>
              <div style="flex: 1">
                <label class="detaillabel">Asset</label>
                <p class="detailvalue1">{{serviceDetails['asset_name']}}</p>
              </div>
          </div>
        </div>
        <div *ngIf="dataLoaded && !assetLinked" class="cardbox mt-4" style="width: 100%">
          <div class="row centeralign" style="cursor: pointer" (click)="productCardClick()">
            <h3>Product Details</h3>
            <img *ngIf="!showProducts" style="position: absolute; right: 30px;height: 8px;"
                 src="../../../assets/images/down_arrow_black.svg"/>
            <img *ngIf="showProducts" style="position: absolute; right: 30px;height: 8px;"
                 src="../../../assets/images/up_arrow.svg"/>
          </div>
          <div *ngIf="showProducts" style="margin-top: 20px;">
            <ag-grid-angular id="myGrid2" *ngIf="productDataLoaded" style="height: 35vh" class="ag-theme-alpine"
                             [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                             (cellClicked)="onCellClicked($event)"
                             [getRowId]="getRowId"
                             [context]="context">
            </ag-grid-angular>
            <div *ngIf="productEmptyState">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
        <div *ngIf="dataLoaded" class="cardbox mt-4" style="width: 100%">
          <div class="row centeralign" style="cursor: pointer" (click)="spareCardClick($event)">
            <h3>Spare Request</h3>
            <span class="status2" style="margin-left: 10px;" [ngClass]="{'success': serviceDetails.d_status === 'delivered',
                        'new': serviceDetails.d_status === 'approved',
                        'processed': serviceDetails.d_status === 'new'}">{{serviceDetails.d_status}}</span>
            <div style="position: absolute; right: 30px;">
              <div *ngIf="showSpares && serviceDetails.d_status" style="display: inline-block;">
                <button
                  *ngIf="!editMode && (serviceDetails.d_status === 'new' || serviceDetails.d_status === 'approved')"
                  (click)="editClick($event)"
                  mat-button
                  style="margin-right:15px;"
                  class="btn confirm-blue-btn"
                >
                  Edit
                </button>
                <div *ngIf="serviceDetails.d_status === 'new'">
                  <div *ngIf="editMode">
                    <button
                      (click)="openApproveRejectPopup('approve', $event)"
                      mat-button
                      style="margin-right:15px;"
                      class="btn confirm-green-btn"
                    >
                      Approve
                    </button>
                    <button
                      (click)="openApproveRejectPopup('reject', $event)"
                      mat-button
                      style="margin-right:15px;"
                      class="btn reject-btn"
                    >
                      Reject
                    </button>
                  </div>
                </div>
                <div *ngIf="serviceDetails.d_status === 'approved'">
                  <button
                    *ngIf="editMode"
                    (click)="openMarkDispatchPopup($event)"
                    mat-button
                    style="margin-right:15px;"
                    class="btn confirm-blue-btn"
                  >
                    Mark Dispatch
                  </button>
                </div>
              </div>
              <img *ngIf="!showSpares" style="height: 8px;"
                   src="../../../assets/images/down_arrow_black.svg"/>
              <img *ngIf="showSpares" style="height: 8px;"
                   src="../../../assets/images/up_arrow.svg"/>
            </div>

          </div>
          <div *ngIf="showSpares" >

            <div *ngIf="serviceDetails.d_status === 'approved' || serviceDetails.d_status === 'rejected' || serviceDetails.d_status === 'delivered'" class="row centeralign" style="margin-top: 10px;">
              <span class="detaillabel1 mr-2">Approved By</span>
              <span class="detailvalue1">{{serviceDetails['sp_app_by']}}</span>

              <span class="greyindicator" style="margin-left: 30px;"></span>
              <span class="detaillabel1 mr-2">Approved On</span>
              <span class="detailvalue1">{{serviceDetails['sp_app_on']}}</span>

              <span class="greyindicator" style="margin-left: 30px;"></span>
              <span class="detaillabel1 mr-2">Comments</span>
              <span class="detailvalue1">{{serviceDetails['sp_app_comments']}}</span>
            </div>

            <div *ngIf="serviceDetails.d_status === 'delivered'" class="row centeralign" style="margin-top: 10px;">
              <span class="detaillabel1 mr-2">Delivered By</span>
              <span class="detailvalue1">{{serviceDetails['sp_del_by']}}</span>

              <span class="greyindicator" style="margin-left: 30px;"></span>
              <span class="detaillabel1 mr-2">Delivered On</span>
              <span class="detailvalue1">{{serviceDetails['sp_del_on']}}</span>

              <span class="greyindicator" style="margin-left: 30px;"></span>
              <span class="detaillabel1 mr-2">Comments</span>
              <span class="detailvalue1">{{serviceDetails['sp_del_comments']}}</span>
            </div>

            <ag-grid-angular id="myGrid2" *ngIf="spareDataLoaded" style="height: 35vh; margin-top: 15px;" class="ag-theme-alpine"
                             [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                             (gridReady)="onGridReady1($event)" [frameworkComponents]="frameworkComponents1"
                             (cellClicked)="onCellClicked1($event)"
                             [getRowId]="getRowId1"
                             [context]="context1">
            </ag-grid-angular>
            <div *ngIf="spareEmptyState">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
