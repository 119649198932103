import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-outlet-last-visit-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class DaywiseSalesReport extends ReportBase implements IReport, OnInit {
  apiData: any = [];
  showCustomFilter = false;
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('DSR - Daywise Salesman Report');
    this.showDate = true;
    this.showableFilters.state = true;
    this.showableFilters.team = true;
    this.showableFilters.region = true;
    this.showableFilters.cluster = true;
    this.dateSelectMode = 'single';
    this.myDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.API_URL = '/api/pwa_v2_reports/daywiseSalesReport';
    this.configureGrid();
  }

  configureGrid() {

    this.columnDefs = [

      {headerName: 'STATE', field: 'state', width: 80},
      {headerName: 'REGION', field: 'region', width: 80},
      {headerName: 'CLUSTER', field: 'cluster', width: 80},
      {headerName: 'DATE', field: 'date', width: 80},
      {headerName: 'EMP CODE', field: 'emp_code', width: 80},
      {headerName: 'SALESMAN', field: 'salesman', width: 80},
      {headerName: 'ROLE', field: 'role', width: 80},
      {headerName: 'SAC', field: 'sac', width: 80},
      {headerName: 'MOBILE', field: 'user_mob', width: 80},
      {headerName: 'EMAIL', field: 'user_email', width: 80},
      {headerName: 'TEAM', field: 'team', width: 80},
      {headerName: 'TEAM LEAD', field: 'tl_name', width: 80},
      {headerName: 'TEAM LEAD CODE', field: 'tl_code', width: 80},
      {headerName: 'AGENDA', field: 'agenda', width: 80},
      {headerName: 'SCHEDULED  BEAT', field: 'beat', width: 80},
      {headerName: 'DISTRIBUTOR', field: 'distributors', width: 80},
      {headerName: 'START LOCATION', field: 'location_name', width: 80},

      {
        headerName: "DISCIPLINARY PARAMETER",
        children: [
          {headerName: 'Attendance Time', field: 'attendance_time',  width: 80},
          {headerName: 'EOD Time', field: 'eod_time', width: 80},
          {headerName: "First Activity Time", field: "first_activity_time",  width: 100},
          {headerName: "Last Activity Time", field: "last_activity_time",  width: 100},
          {headerName: "Retail Time", field: "avg_retail_time", width: 100},
          {headerName: "Working Time", field: "working_mins", width: 100},
          {headerName: "Time Spent / OUTLET", field: "avg_time_spent", width: 100}
        ]
      },
      {
        headerName: "COVERAGE PARAMETERS",
        children: [
          {headerName: 'TC', field: 'tc', width: 80},
          {headerName: "VC", field: "visited_count",  width: 100},
          {headerName: "Beat Jump VC", field: "beat_jump_vc", width: 100},
          {headerName: "KO VC", field: "ko_vc", width: 100},
          {headerName: "PC", field: "productive_calls",  width: 100},
          {headerName: "Beat Jump PC", field: "beat_jump_pc", width: 100},
          {headerName: "KO PC", field: "ko_pc", width: 100},
          {headerName: "Productivity% (PC/VC)",  width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.visited_count !== 0) {
                  return ((params.data.productive_calls / params.data.visited_count) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: "Coverage% (VC/TC)" , width: 100,
            valueGetter: params => {
              if (params.data) {
                if (params.data.tc !== 0) {
                  return ((params.data.visited_count / params.data.tc) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          }
        ]
      },
      {
        headerName: "SALES DETAILS",
        children: [
          {headerName: 'Secondary Sales', field: 'sec_sales',  width: 80},
          {headerName: 'Scheme Sales', field: 'schm_ords',  width: 80},
          {headerName: 'QTY(In Base UOM)', field: 'ord_qty',  width: 80},
          {headerName: 'QTY(In Reporting UOM)', field: 'report_qty',  width: 80},
          {headerName: 'Order Volume', field: 'ord_qty_volume',  width: 80},

          {headerName: 'Qty Delivered', field: 'dlr_qty',  width: 80},
          {headerName: 'Delivered Volume', field: 'dlr_qty_volume',  width: 80},
          {headerName: 'Fill Rate',   width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.ord_qty !== 0) {
                  return ((params.data.dlr_qty / params.data.ord_qty) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }},
          {headerName: 'Total Lines', field: 'total_sku',  width: 80},
          {headerName: 'ALS', field: 'als',  width: 80},
          {headerName: 'Avg Order Value', field: 'avg_value_pc',  width: 80},
        ]
      },
      {
        headerName: "Primary",
        children: [
          {headerName: 'Planned', field: 'supplier_planned_visits',  width: 80},
          {headerName: 'Completed', field: 'supplier_completed_visits',  width: 80},
          {headerName: 'Time Spent', field: 'supp_hours_spent',  width: 80},
          {headerName: 'Primary Sales', field: 'total_current_month_p_sales',  width: 80},
        ]
      },
      {
        headerName: "TARGETS",
        children: [
          {headerName: 'Monthly Target', field: 'sec_target',  width: 80},
          {headerName: 'MTD Ach', field: 'sec_ach',  width: 80},
          {headerName: 'MTD Ach%',  width: 80,
            valueGetter: params => {
              if (params.data) {
                if (params.data.sec_target !== 0) {
                  return  ((params.data.sec_ach / params.data.sec_target ) * 100).toFixed(2);
                } else {
                  return 0;
                }
              } else {
                return 0;
              }
            }
          },
          {headerName: 'DRR (Daily Required Rate)', field: 'drr',  width: 80},
          {headerName: 'CSR (Current Sales Rate)', field: 'csr',  width: 80},
          {headerName: 'Day Target', field: 'schedule_target',  width: 80},
          {headerName: 'Day Ach', field: 'beat_revenue',  width: 80},
          {headerName: 'Yesterday Target', field: 'yesterday_target',  width: 80},
          {headerName: 'Yesterday Ach', field: 'yesterday_revenue',  width: 80},
          {headerName: 'Tomorrow Target', field: 'tomo_target',  width: 80},
          {headerName: 'Schedule Target (MTD)', field: 'sch_mtd_target',  width: 80},
          {headerName: 'Schedule Ach (MTD)', field: 'sch_mtd_ach',  width: 80},
        ]
      },
      {
        headerName: "NEW OUTLETS",
        children: [
          {headerName: 'New Outlets', field: 'new_outlets',  width: 80},
          {headerName: 'New Outlets PC', field: 'new_outlet_pc',  width: 80},
          {headerName: 'New Outlets Sales', field: 'new_outlets_sales',  width: 80}
        ]
      },
      {
        headerName: "ASSETS DETAILS",
        children: [
          {headerName: 'Requested', field: 'created',  width: 80},
          {headerName: 'Approved', field: 'approved',  width: 80},
          {headerName: 'Rejected', field: 'rejected',  width: 80}
        ]
      },
      {
        headerName: "SURVEY",
        children: []
      }
    ];

    let uniqueSurveys = [];
    if (this.apiData.survey && this.apiData.survey.length > 0){
      uniqueSurveys = this.apiData.survey.map(p => p.name)
        .filter((type, index, arr) => arr.indexOf(type) === index);

      for (const key in uniqueSurveys) {
        this.columnDefs[24].children.push(
          {headerName: uniqueSurveys[key], field: uniqueSurveys[key], menuTabs: [], width: 60, cellStyle: {textAlign: 'center'}},
        );
      }
    }
  }

  postProcessData() {
    this.configureData();
    this.configureGrid();
  }

  configureData() {
    this.rowData = [];
    for (const key in this.apiData.main) {
      let indrow = {};
      let mainrow: any = {};
      let surveysrow: any = [];
      const surveyrow: any = [];

      mainrow = this.apiData.main[key];
      if (this.apiData.survey && this.apiData.survey.length > 0){
        surveysrow = this.apiData.survey.filter(survey => survey.user_id === mainrow.salesman_id);
        if (surveysrow != null && surveysrow.length > 0) {
          for (const i in surveysrow) {
            surveyrow[surveysrow[i].name] = surveysrow[i].survey_cnt;
          }
        }
      }
      indrow = {
        ...mainrow,
        ...surveyrow
      };
      this.rowData.push(indrow);

    }
  }
}
