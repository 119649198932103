import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {ImageGalleryPopupComponent} from "../../components/image_gallery_popup/image_gallery_popup.component";

@Component({
  selector: 'app-partner-asset-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PartnerAssetReportComponent extends ReportBase implements IReport, OnInit {


  customFilter: any = {
    name: 'Status',
    key: 'status',
    value: '',
    values: [],
    show: true
  };

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Partner Asset Report');

    this.showableFilters.region = true;
    this.showableFilters.cluster = true;
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.outlet_type = true;
    this.showDate = true;
    this.showCustomFilter = true;
    this.customFilter.values = [
      {id: "", itemName: "All"},
      {id: "pending", itemName: "Pending"},
      {id: "approved", itemName: "Approved"},
      {id: "rejected", itemName: "Rejected"},
      {id: "confirmed", itemName: "confirmed"},
      {id: "deployed", itemName: "Deployed"},
    ];

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/partnerAssetReport';

    this.columnDefs = [
      {
        headerName: "ASSET",
        children: [
          {headerName: 'ID', field: 'id', width: 100},
          {headerName: 'TYPE', field: 'asset_type', width: 100, enableRowGroup: true},
          {headerName: 'NAME', field: 'asset_name', width: 100, enableRowGroup: true},
          {headerName: 'STATUS', field: 'status', width: 100, enableRowGroup: true},
          {headerName: 'TAT', field: 'tat', width: 100, enableRowGroup: true},
          {headerName: 'INSTALLED ON', field: 'installed_date', enableRowGroup: true, width: 100},
          {headerName: 'BATCH ID', field: 'batch_id', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'BATCH NO', field: 'batch_no', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'BARCODE', field: 'barcode', enableRowGroup: true, width: 100},
          {
            headerName: "IMAGE", headerClass: 'myagheader', field: "image", width: 50,
            cellRenderer(params) {
              if (params && params.data && params.data.image_count) {
                if (!params.data.image_count || parseInt(params.data.image_count, 10) === 0) {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
                } else {
                  return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img><span>(' + params.data.image_count + ')</span>';
                }
              } else {
                return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
              }
            }
          },
        ]
      },
      {
        headerName: "PARTNER",
        children: [
          {headerName: 'NAME', field: 'retailer', width: 100, enableRowGroup: true},
          {headerName: 'CODE', field: 'rcode', enableRowGroup: true, width: 80},
          {headerName: 'CLASS', field: 'class_type', enableRowGroup: true, width: 80},
          {headerName: 'OUTLET TYPE', field: 'out_type', enableRowGroup: true, width: 80},
          {headerName: 'VERTICAL', field: 'vertical', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'PROGRAM TYPE', field: 'msl_town_tier', enableRowGroup: true, width: 80},
          {headerName: 'MOBILE', field: 'mobile', enableRowGroup: true, width: 80},
          {headerName: 'STREET', field: 'street', enableRowGroup: true, width: 80, hide: true},
          {headerName: 'AREA', field: 'area', enableRowGroup: true, width: 100},
          {headerName: 'CITY', field: 'city', enableRowGroup: true, width: 100},
          {headerName: 'ZIP', field: 'zip', enableRowGroup: true, width: 100},
          {headerName: 'ZONE', field: 'district', enableRowGroup: true, width: 100},
          {headerName: 'STATE', field: 'state', enableRowGroup: true, width: 100},
        ]
      },
      {
        headerName: "DEPLOYMENT",
        children: [
          {headerName: 'DEPLOYED BY', field: 'dep_by_user', enableRowGroup: true, width: 100},
          {headerName: 'DEPLOYED ON', field: 'deployed_on', enableRowGroup: true, width: 100},
          {headerName: 'DEPLOYED COMMENTS', field: 'deployer_comments', enableRowGroup: true, width: 100},
          {headerName: 'BRAND', field: 'dep_brand', enableRowGroup: true, width: 100},
          {headerName: 'CATEGORY', field: 'dep_category', enableRowGroup: true, width: 100},
          {headerName: 'TEMPLATE', field: 'dep_template', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'PRODUCT', field: 'dep_product', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'VERTICAL', field: 'dep_vertical', enableRowGroup: true, width: 100, hide: true},
        ]
      },
      {
        headerName: "REQUEST",
        children: [
          {headerName: 'REQ QTY', field: 'qty', enableRowGroup: true, width: 100},
          {headerName: 'REQ ON', field: 'create_date', enableRowGroup: true, width: 100},
          {headerName: 'REQ COMMENTS', field: 'req_comments', enableRowGroup: true, width: 100},
          {headerName: 'MOBILE', field: 'user_mobile', enableRowGroup: true, width: 100},
          {headerName: 'ECODE', field: 'scode', enableRowGroup: true, width: 100},
          {headerName: 'SALESMAN', field: 'salesman', enableRowGroup: true, width: 100},
          {headerName: 'BRAND', field: 'req_brand', enableRowGroup: true, width: 100},
          {headerName: 'CATEGORY', field: 'req_category', enableRowGroup: true, width: 100},
          {headerName: 'TEMPLATE', field: 'req_template', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'PRODUCT', field: 'req_product', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'VERTICAL', field: 'req_vertical', enableRowGroup: true, width: 100, hide: true},
        ]
      },
      {
        headerName: "APPROVAL",
        children: [
          {headerName: 'APP QTY', field: 'approved_qty', enableRowGroup: true, width: 100},
          {headerName: 'APP BY', field: 'app_by_user', enableRowGroup: true, width: 100},
          {headerName: 'APP ON', field: 'approved_on', enableRowGroup: true, width: 100},
          {headerName: 'APP COMMENTS', field: 'approved_comments', enableRowGroup: true, width: 100},
        ]
      },
      {
        headerName: "VENDOR",
        children: [
          {headerName: 'NAME', field: 'vendor', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'ASSIGNED ON', field: 'vendor_assigned_on', width: 100, hide: true},
          {headerName: 'PO AMT', field: 'po_amount', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'PO DATE', field: 'po_date', enableRowGroup: true, width: 100, hide: true},
        ]
      },
      {
        headerName: "VERIFICATION",
        children: [
          {headerName: 'DATE', field: 'dp_vf_date', enableRowGroup: true, width: 100},
          {headerName: 'COMMENT', field: 'dp_vf_comment', enableRowGroup: true, width: 100},
          {headerName: 'USER', field: 'dp_vf_user', enableRowGroup: true, width: 100}
        ]
      },
      {
        headerName: "COMPETITOR",
        children: [
          {headerName: 'NAME', field: 'competitor', enableRowGroup: true, width: 100},
          {headerName: 'BRAND', field: 'comp_brand', enableRowGroup: true, width: 100},
          {headerName: 'TEMPLATE', field: 'competitor_template', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'PRODUCT', field: 'competitor_product', enableRowGroup: true, width: 100, hide: true},
          {headerName: 'CATEGORY', field: 'competitor_category', enableRowGroup: true, width: 100, hide: true},
        ]
      },
    ];

  }


  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'image') {
      if (rows[0].image_count && parseInt(rows[0].image_count, 10) > 0) {
        this.showImagePopup(rows[0]);
      }
    } else {
    }
  }

  showImagePopup(row) {
    const gallery = [];
    row.gallery.forEach(image => {
      gallery.push({
        store_fname: image,
        container: 'asset_gallery'
      });
    });
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    }
  }

}
