import {Component, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-selectdialog',
  templateUrl: './select_dialog.component.html',
  styleUrls: ['./select_dialog.component.scss']
})
export class SelectDialogComponent implements OnInit {

  @Output() selectedAction: EventEmitter<any> = new EventEmitter<any>();
  title = "";
  message1 = "";
  message2 = "";
  yesButton = "";
  selectData: any = [];
  selectedItem: any = {};
  bindLabel;
  bindValue;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.title = dialogdata.title;
    this.message1 = dialogdata.message1;
    this.message2 = dialogdata.message2;
    this.bindLabel = dialogdata.bindLabel;
    this.bindValue = dialogdata.bindValue;
    this.selectData = dialogdata.selectData;
    this.yesButton = dialogdata.yesButton;
  }

  ngOnInit(): void {

  }

  itemChange(event) {

  }

  performAction() {
    const selectedData = this.selectData.find(x => x[this.bindValue] === this.selectedItem);
    this.selectedAction.emit(selectedData);
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
