import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class OutletVisitDayWiseReportComponent extends ReportBase implements IReport, OnInit {
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Outlet Visit (Day Wise) Report');

    this.showableFilters.salesman = true;
    //this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    const date = new Date();
    this.myDate[0] = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(date, 'yyyy-MM-dd');

    this.configureGrid();
      }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getDailyOutletVisitActivityReport';
    let inthis = this;
    this.columnDefs = [

      {headerName: 'DATE', field: 'report_date', enableRowGroup: true, width: 80},
      {headerName: 'STATE(OUTLET)', field: 'state_name', enableRowGroup: true, width: 80},
      {headerName: 'REGION', field: 'region_name', width: 100, enableRowGroup: true},
      {headerName: 'CLUSTER', field: 'cluster', width: 100, enableRowGroup: true},
      {headerName: 'ID', field: 'outlet_id', enableRowGroup: true, width: 100},
      {headerName: 'CODE', field: 'retailer_code', menuTabs: [], width: 80},
      {headerName: 'OUTLET', field: 'retailer_name', enableRowGroup: true, width: 150},
      {headerName: 'TYPE', field: 'retailer_type', width: 100},
      {headerName: 'CHANNEL', field: 'channel', width: 100},
      {headerName: 'CITY', field: 'retailer_city', width: 150},
      {headerName: 'SALESMAN', field: 'salesman_name', enableRowGroup: true, width: 150},
      {headerName: 'EMP CODE', field: 'emp_code', enableRowGroup: true, width: 80},
      {headerName: 'ROLE', field: 'salesman_role', enableRowGroup: true, width: 80},
      {headerName: 'SALESMAN STATE', field: 'salesman_state', enableRowGroup: true, width: 80, hide: true},
      {headerName: 'BEAT', field: 'beat_name', enableRowGroup: true, width: 80},
      {headerName: 'DIST CODE', field: 'dist_codes', enableRowGroup: true, width: 80},
      {headerName: 'DISTRIBUTOR', field: 'dist_name', enableRowGroup: true, width: 80},
      {headerName: 'TERRITORY', field: 'tr_name', enableRowGroup: true, width: 80},
      {headerName: 'TER MANAGER', field: 'tr_manager', enableRowGroup: true, width: 80},
      {headerName: 'CLUST MANAGER', field: 'cluster_manager', enableRowGroup: true, width: 80},
      {headerName: 'REG MANAGER', field: 'reg_manager', enableRowGroup: true, width: 80},
      {headerName: 'ACTIVITY TYPE', field: 'activity_type', enableRowGroup: true, width: 120},
      {
        headerName: 'START TIME', field: 'check_in', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.check_in) {
              return inthis.datePipe.transform((new Date(params.data.check_in + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              return params.data.check_in;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'END TIME', field: 'check_out', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.check_out) {
              return inthis.datePipe.transform((new Date(params.data.check_out + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              return params.data.check_out;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'TIME SPENT', field: 'time_spent', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.check_out && params.data.check_in) {
              const diff = new Date(params.data.check_out + ' UTC').getTime() - new Date(params.data.check_in + ' UTC').getTime();
              //return (diff / (1000 * 60)).toFixed(2);
              let msec = diff;
              const hh = Math.floor(msec / 1000 / 60 / 60);
              msec -= hh * 1000 * 60 * 60;
              const mm = Math.floor(msec / 1000 / 60);
              msec -= mm * 1000 * 60;
              const ss = Math.floor(msec / 1000);
              msec -= ss * 1000;

              return ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2);
            } else {
              return 0;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'PRODUCT', field: 'product_name', enableRowGroup: true, width: 80},
      {headerName: 'PCODE', field: 'product_code', enableRowGroup: true, width: 80},
      {headerName: 'CATEGORY', field: 'product_category', enableRowGroup: true, width: 80},
      {headerName: 'BRAND', field: 'product_brand', enableRowGroup: true, width: 80},
      {headerName: 'QTY', field: 'product_qty', enableRowGroup: true, width: 80},
      {headerName: 'QTY UOM', field: 'qty_uom', enableRowGroup: true, width: 80},
      {headerName: 'REPORTING QTY', field: 'reporting_qty', enableRowGroup: true, width: 80},
      {headerName: 'REPORTING QTY UOM', field: 'reporting_uom', enableRowGroup: true, width: 80},
      {headerName: 'ORDER VALUE', field: 'order_value', enableRowGroup: true, width: 80},
      {headerName: 'COMMENTS', field: 'comment', enableRowGroup: true, width: 80},
      {headerName: 'SCHEDULED', field: 'scheduled', enableRowGroup: true, width: 80},
      {headerName: 'CREATE DATE', field: 'create_date', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inthis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'yyyy-MM-dd');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }},
      {headerName: 'CREATE TIME', field: 'create_time', enableRowGroup: true, width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.create_date) {
              return inthis.datePipe.transform((new Date(params.data.create_date + ' UTC')).toString(), 'HH:mm:ss');
            } else {
              return params.data.create_date;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'MOBILE', field: 'retailer_mobile', enableRowGroup: true, width: 80},
      {headerName: 'STATUS', field: 'status', enableRowGroup: true, width: 80},

    ];

  }

}
