import {Component, ElementRef, NgZone, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../../_services/shared_service";
import {DomSanitizer} from "@angular/platform-browser";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {TakeSurveyPopupComponent} from "../../components/take_survey_popup/take_survey_popup.component";
import {ApproveRejectCommentsPopupComponent} from "../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";
import {ConfirmDialogComponent} from "../../components/confirmdialog/confirmdialog.component";
import {AssignServiceEngineerPopupComponent} from "../../service_request/assign_service_engineer_popup/assign_service_engineer_popup.component";
import {
  AssignServiceExecutivePopupComponent
} from "../assign_service_executive_popup/assign_service_executive_popup.component";
import {SelectDialogComponent} from "../../components/select_dialog/select_dialog.component";

@Component({
  selector: 'app-asset-service-ticket-component',
  templateUrl: './service_ticket_details.component.html',
  styleUrls: ['./service_ticket_details.component.scss']
})
export class AssetServiceDetailsComponent implements OnInit {

  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    data: {
      id: null,
      date: null
    },
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: ''
    },
    customFilter: {}
  };
  asset_ids: any = [];
  serviceDetails: any = {};
  rowIndex = 0;
  vendorDetails: any = {};
  surveyDetails: any = {};
  surveyQuestions: any = [];
  showSurveyDetails = false;
  currentStage = '';
  stageConfig = [];
  role_name = '';
  dataLoaded = false;
  showMarkComplete;
  brand;
  showPoDetails = false;
  editServiceExecutive = false;
  serviceExecutiveList = [];

  constructor(private apiService: ApiService,
              private router: Router,
              private datePipe: DatePipe,
              private renderer: Renderer2,
              private sharedService: SharedService,
              private ngzone: NgZone,
              private elRef: ElementRef,
              private domSanitizer: DomSanitizer,
              public dialog: MatDialog,
              private toastr: ToastrService) {
    this.asset_ids = JSON.parse(localStorage.getItem('asset_ids'));
    this.rowIndex = parseInt(localStorage.getItem('index'), 0);
    this.userData.data.id = this.asset_ids[this.rowIndex];
    this.role_name = JSON.parse(localStorage.getItem('user')).role.name;
    if (this.sharedService.ACLcheck('feature_assign_vendor_show_po')) {
      this.showPoDetails = true;
    } else {
      this.showPoDetails = false;
    }
    this.fetchServiceExecutives();
    this.getAssetDetails();
  }

  ngOnInit(): void {
    this.sharedService.setHeader("Asset Service Ticket Details");
  }

  fetchPreviousData() {
    if (this.rowIndex !== 0) {
      this.rowIndex = this.rowIndex - 1;
      this.userData.data.id = this.asset_ids[this.rowIndex];
      this.getAssetDetails();
    }
  }

  fetchNextData() {
    if (this.rowIndex !== this.asset_ids.length - 1) {
      this.rowIndex = this.rowIndex + 1;
      this.userData.data.id = this.asset_ids[this.rowIndex];
      this.getAssetDetails();
    }
  }

  surveyShowClick() {
    this.showSurveyDetails = !this.showSurveyDetails;
  }

  fetchServiceExecutives() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      profile_name: 'Service Executive'
    };
    this.apiService.post('/api/res_users/get_users_from_profile', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.serviceExecutiveList = res.results.data;
        }
      });
  }

  getAssetDetails() {
    const inThis = this;
    this.dataLoaded = false;
    this.stageConfig = [];
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = this.userData.data.id;
    this.apiService.post('/api/service_requests/getServiceTicketDetails', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.dataLoaded = true;
          this.serviceDetails = res.results.data[0];
          this.brand = this.serviceDetails?.config_json?.request_brand;
          if (!this.brand) {
            this.brand = this.serviceDetails?.config_json?.brand;
          }
          const stages = this.serviceDetails?.config_json?.actions;
          if (stages) {
            let survey_present = false;
            stages.forEach(stage => {
              let show_approve = false;
              let show_survey = false;
              let completed = false;
              let mobile_app = false;
              let waiting_for_approval = false;
              let waiting_text = '';
              let review_details;
              if (stage['survey_id']) {
                survey_present = true;
                if (stage['mode'] && stage['mode'] === 'pwa') {
                  show_survey = true;
                } else {
                  show_survey = false;
                }
              } else {
                show_survey = false;
              }
              if (stage['role']) {
                if (stage['user_id']) {
                  completed = true;
                  review_details = {};
                  review_details.date = stage.date;
                  review_details.status = 'Approved';
                } else {
                  completed = false;
                }
                if (stage['mode'] && stage['mode'] === 'pwa' && !stage['user_id']) {
                  if (show_survey) {
                    show_survey = stage['role'].find(x => x.toString().toLowerCase() === inThis.role_name.toString().toLowerCase());
                  } else {
                    show_approve = stage['role'].find(x => x.toString().toLowerCase() === inThis.role_name.toString().toLowerCase());
                    if (!show_approve) {
                      waiting_for_approval = true;
                      let role_string = '';
                      stage['role'].forEach(role => {
                        role_string = role_string + role.toString().toUpperCase() + ', ';
                      });
                      if (role_string && role_string.length > 1) {
                        waiting_text = 'Waiting for Approval from ' + role_string.substring(0, role_string.length - 2);
                      } else {
                        waiting_text = 'Waiting for Approval from Mobile Application User';
                      }
                    }
                  }
                } else {
                  show_approve = false;
                }
              } else {
                show_approve = false;
              }
              if (!completed && stage['mode'] === 'app') {
                mobile_app = true;
              }
              this.stageConfig.push({
                id: 'li_' + stage.label.toString().toLowerCase().replace(' ', '_'),
                stage: stage.label,
                survey_id: stage['survey_id'],
                mode: stage['mode'],
                role: stage['role'],
                completed,
                show_approve,
                show_survey,
                review_details,
                mobile_app,
                waiting_for_approval,
                waiting_text
              });
            });
            console.log(this.stageConfig);
            if (survey_present) {
              this.getSurveyData();
            } else {
              this.activeStageCheck();
            }
          }
        }
      });
  }

  checkCompleteStatus() {
    let all_complete = false;
    if (this.surveyDetails.status !== 'RESOLVED') {
      for (let i = 0; i < this.stageConfig.length; i++) {
        if (!this.stageConfig[i].complete) {
          all_complete = false;
          break;
        }
      }
      if (all_complete) {
        if (this.stageConfig[this.stageConfig.length-1].mode === 'pwa') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getSurveyData() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = this.userData.data.id;
    paramObject.data = {
      partner_asset_id: this.serviceDetails.partner_asset_id,
      service_id: this.serviceDetails.id
    };
    this.apiService.post('/api/crm_profiling_results/getResultAndAnswers', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.surveyDetails = res.results.data;
          if (this.surveyDetails && this.surveyDetails.result && this.surveyDetails.result.length > 0) {
            this.surveyDetails.result.forEach(survey => {
              const stage = this.stageConfig.find(x => x.survey_id === survey.questionnaire_id);
              if (stage) {
                stage.completed = true;
                stage.mobile_app = false;
                stage.surveyDetails = survey;
                if (this.surveyDetails.answers && this.surveyDetails.answers.length > 0) {
                  const answers = this.surveyDetails.answers.find(x => x.survey_id === stage.survey_id);
                  stage.answers = answers;
                }
              }
            });
          }
          this.activeStageCheck();
        }
      });
  }

  activeStageCheck() {
    this.showMarkComplete = this.checkCompleteStatus();
    for (let i = 0; i < this.stageConfig.length; i++) {
      if (!this.stageConfig[i].completed) {
        this.currentStage = this.stageConfig[i].id;
        break;
      }
    }
    if (this.currentStage === '') {
      for (let i = 0; i < this.stageConfig.length; i++) {
        this.renderer.removeClass(document.getElementById(this.stageConfig[i]['id']), 'todo');
        this.renderer.removeClass(document.getElementById(this.stageConfig[i]['id']), 'doing');
        this.renderer.addClass(document.getElementById(this.stageConfig[i]['id']), 'done');
      }
    } else {
      for (let i = 0; i < this.stageConfig.length; i++) {
        if (this.currentStage === this.stageConfig[i]['id']) {
          this.stageConfig[i].active = true;
          this.applyMenuActiveStyle(i);
        }
      }
    }
  }

  applyMenuActiveStyle(count) {
    const inThis = this;
    for (let i = 0; i < count; i++) {
      inThis.renderer.removeClass(document.getElementById(inThis.stageConfig[i]['id']), 'todo');
      inThis.renderer.removeClass(document.getElementById(inThis.stageConfig[i]['id']), 'doing');
      inThis.renderer.addClass(document.getElementById(inThis.stageConfig[i]['id']), 'done');
    }
    if (document.getElementById(inThis.stageConfig[count]['id'])) {
      inThis.renderer.removeClass(document.getElementById(inThis.stageConfig[count]['id']), 'todo');
      inThis.renderer.removeClass(document.getElementById(inThis.stageConfig[count]['id']), 'done');
      inThis.renderer.addClass(document.getElementById(inThis.stageConfig[count]['id']), 'doing');
    }
  }

  openTakeSurveyPopup(survey_id, edit) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "30%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          survey_id,
          partner_id: this.serviceDetails.customer_id,
          service_id: this.serviceDetails.id,
          partner_asset_id: this.serviceDetails.partner_asset_id,
          name: this.serviceDetails.serviceno,
          container: 'partner_answer',
          edit
        }
      };
      const creatediaeref = this.dialog.open(TakeSurveyPopupComponent, config);
      creatediaeref.afterClosed().subscribe(result => {
        this.getAssetDetails();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "100%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          survey_id,
          partner_id: this.serviceDetails.customer_id,
          service_id: this.serviceDetails.id,
          partner_asset_id: this.serviceDetails.partner_asset_id,
          name: this.serviceDetails.serviceno,
          container: 'partner_answer',
          edit
        }
      };
      const creatediaeref = this.dialog.open(TakeSurveyPopupComponent, config);
    }
  }

  openApproveDialog(stage) {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "650px",
      maxHeight: "250px",
      data: {
        title: stage.stage,
        message1: "Are you sure you want to Approve the " + stage.stage + " ?",
        showNoButton: true,
        yesButton: "APPROVE",
        noButton: "CANCEL",
        deleteButton: false
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      if (data === 'yes') {
        this.approveServiceTicket(stage);
      } else {

      }
    });
  }

  openRejectPopup(stage) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "50%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title: "Reject Service Ticket",
          message1: "Are you sure you want to Reject this Service Ticket?",
          message2: "Please provide a detailed reason for the rejection",
          type: 'reject',
          buttonText: 'Reject'
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.rejectServiceTicket(stage, data);
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "95%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title: "Reject Service Ticket",
          message1: "Are you sure you want to Reject Service Ticket",
          message2: "Please provide a detailed reason for the rejection",
          type: 'reject',
          buttonText: 'Reject'
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
      creatediaeref.componentInstance.submitClicked.subscribe(data => {
        this.rejectServiceTicket(stage, data);
      });
    }
  }

  approveServiceTicket(stage) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    const config_json = this.serviceDetails.config_json;
    const action = config_json.actions.find(x => x.label === stage.stage);
    action.user_name = JSON.parse(localStorage.getItem('user')).user_name;
    action.user_id = JSON.parse(localStorage.getItem('user')).id;
    action.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    paramObject.data = {
      id: this.serviceDetails.id,
      config_json
    };

    this.apiService.post('/api/service_requests/approve_action', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(stage.stage + " Completed Successfully");
          this.getAssetDetails();
        } else {
          this.toastr.error("Failed to Approve " + stage.stage);
        }
      });
  }

  rejectServiceTicket(stage, reason) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      id: this.serviceDetails.id,
      reason
    };

    this.apiService.post('/api/service_requests/rejectServiceTicket', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success("Rejected Service Ticket");
          this.getAssetDetails();
        } else {
          this.toastr.error("Failed to Reject Service Ticket");
        }
      });
  }

  openConfirmDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "650px",
      maxHeight: "250px",
      data: {
        title: "Mark Service Complete",
        message1: "Are you sure you want to Mark The Service as Completed ?",
        showNoButton: true,
        yesButton: "COMPLETE",
        noButton: "CANCEL",
        deleteButton: false
      }
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);

    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {

      if (data === 'yes') {
        this.markComplete();
      } else {

      }
    });
  }

  markComplete() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      id: this.serviceDetails.id,
      partner_asset_id: this.serviceDetails.partner_asset_id
    };

    this.apiService.post('/api/service_requests/markServiceComplete', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success(" Service Completed Successfully");
          this.getAssetDetails();
        } else {
          this.toastr.error("Failed to Complete Service");
        }
      });
  }

  openAssignServiceExecutivePopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "450px",
        height: 'auto',
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title: ' ReAssign Service Executive',
          message1: 'Select Service Executive from the below List',
          yesButton: 'ReAssign',
          selectData: this.serviceExecutiveList,
          bindLabel: "user_name",
          bindValue: "id"
        }
      };
      const creatediaeref = this.dialog.open(SelectDialogComponent, config);
      creatediaeref.componentInstance.selectedAction.subscribe(data => {
        this.assignServiceExecutive(data);
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title: ' ReAssign Service Executive',
          message1: 'Select Service Executive from the below List',
          yesButton: 'ReAssign',
          selectData: this.serviceExecutiveList,
          bindLabel: "user_name",
          bindValue: "id"
        }
      };
      const creatediaeref = this.dialog.open(SelectDialogComponent, config);
      creatediaeref.componentInstance.selectedAction.subscribe(data => {
        this.assignServiceExecutive(data);
      });
    }
  }

  assignServiceExecutive(data) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = {
      ids: [this.serviceDetails.id],
      se_id: data.id
    };
    this.apiService.post('/api/service_requests/reassign_service_executive', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200)) {
          this.toastr.success("Service Executive Assigned Successfully");
          this.getAssetDetails()
        } else {
          this.toastr.error("Failed to Assign Service Executive");
        }
      });
  }

}
