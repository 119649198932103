import {Component, OnInit} from "@angular/core";
import {GST_STATE_CODES} from "../../assets/api/gst_state_codes.json";
import * as XLSX from "xlsx-js-style";
import {GSTPageBase} from "./gst_base.component";
import {MatDialogConfig} from "@angular/material/dialog";
import {GSTR1PopupComponent} from "./gstr1_popup/gstr1_popup.component";

@Component({
  selector: 'app-gstr1-list',
  templateUrl: './gst_base.component.html',
  styleUrls: ['./gst_base.component.scss']
})

export class GSTReInvoiceComponent extends GSTPageBase implements OnInit {

  b2bData = [];
  b2CLData = [];
  b2CSData = [];
  hsnData = [];
  cdnRData = [];
  cdnURData = [];
  summaryData = [
    {desc: "B2B", tot_inv: 0, tot_taxable: 0, igst_amt: 0, cgst_amt: 0, sgst_amt: 0, cess_amt: 0, tot_amt: 0},
    {desc: "B2CL", tot_inv: 0, tot_taxable: 0, igst_amt: 0, cgst_amt: 0, sgst_amt: 0, cess_amt: 0, tot_amt: 0},
    {desc: "B2CS", tot_inv: 0, tot_taxable: 0, igst_amt: 0, cgst_amt: 0, sgst_amt: 0, cess_amt: 0, tot_amt: 0},
    {desc: "HSN", tot_inv: 0, tot_taxable: 0, igst_amt: 0, cgst_amt: 0, sgst_amt: 0, cess_amt: 0, tot_amt: 0},
    {desc: "CDNR", tot_inv: 0, tot_taxable: 0, igst_amt: 0, cgst_amt: 0, sgst_amt: 0, cess_amt: 0, tot_amt: 0},
    {desc: "CDNUR", tot_inv: 0, tot_taxable: 0, igst_amt: 0, cgst_amt: 0, sgst_amt: 0, cess_amt: 0, tot_amt: 0},
  ];

  ngOnInit(): void {
    this.sharedService.setHeader('GSTR1');
    this.dateFilterType = 'period';
    this.showUpload = false;
    this.showViewInvoices = true;
    this.tabList = [{id: 'summary', itemName: 'Summary'}, {id: 'b2b', itemName: 'B2B'}, {
      id: 'b2cl',
      itemName: 'B2CL'
    }, {id: 'b2cs', itemName: 'B2CS'}, {id: 'hsn', itemName: 'HSN'},
      {id: 'cdnr', itemName: 'CDNR'}, {id: 'cdnur', itemName: 'CDNUR'}];
    this.checkAndLoadPreviousSate();
    this.showCustomFilter = false;
    this.showActionButton = false;
    this.showDate = true;
    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_v2_reports/getGstr1Report';

    //summary
    this.columnDefs1 = [
      {headerName: "Description", field: "desc", width: 200},
      {headerName: "Total Invoices", field: "tot_inv", width: 100, aggFunc: 'sum'},
      {headerName: "Taxable", field: "tot_taxable", width: 100, aggFunc: 'sum'},
      {headerName: "IGST", field: "igst_amt", width: 100, aggFunc: 'sum'},
      {headerName: "CGST", field: "cgst_amt", width: 100, aggFunc: 'sum'},
      {headerName: "SGST", field: "sgst_amt", width: 100, aggFunc: 'sum'},
      {headerName: "CESS", field: "cess_amt", width: 100, aggFunc: 'sum'},
      {headerName: "Invoice Value", field: "tot_amt", width: 100, aggFunc: 'sum'},
    ];

    //b2b
    this.columnDefs2 = [
      {headerName: "Receiver GSTIN", field: "cust_gstin", width: 80},
      {headerName: "Receiver Name", field: "cust_name", width: 80},
      {headerName: "Invoice Number", field: "invoice_no", width: 80},
      {headerName: "Invoice date", field: "invoice_date", width: 80},
      {headerName: "Invoice Value", field: "invoice_amount", width: 80, aggFunc: 'sum'},
      {headerName: "Place Of Supply", field: "state_code", width: 80},
      {headerName: "Reverse Charge", field: "reverse_charge", width: 80},
      {headerName: "Applicable % of Tax Rate", field: "app_tax_amount", width: 80},
      {headerName: "Invoice Type", field: "type", width: 80},
      {headerName: "E-Commerce GSTIN", field: "ecommerce_gstin", width: 80},
      {headerName: "Rate", field: "tax_amount", width: 80, aggFunc: 'sum'},
      {headerName: "Taxable Value", field: "taxable_amount", width: 80, aggFunc: 'sum'},
      {headerName: "Cess Amount", field: "cess_amt", width: 80, aggFunc: 'sum'},
    ];

    //b2cl
    this.columnDefs3 = [
      {headerName: "Invoice Number", field: "invoice_no", width: 80},
      {headerName: "Invoice date", field: "invoice_date", width: 80},
      {headerName: "Invoice Value", field: "invoice_amount", width: 80},
      {headerName: "Place Of Supply", field: "state_code", width: 80},
      {headerName: "Applicable % of Tax Rate", field: "app_tax_amount", width: 80},
      {headerName: "Rate", field: "tax_amount", width: 80},
      {headerName: "Taxable Value", field: "taxable_amount", width: 80, aggFunc: 'sum'},
      {headerName: "Cess Amount", field: "cess_amt", width: 80, aggFunc: 'sum'},
      {headerName: "E-Commerce GSTIN", field: "ecommerce_gstin", width: 80}
    ];

    //b2cs
    this.columnDefs4 = [
      {headerName: "Type", field: "type", width: 80},
      {headerName: "Place Of Supply", field: "state_code", width: 80},
      {headerName: "Applicable % of Tax Rate", field: "app_tax_tax_amount", width: 80},
      {headerName: "Rate", field: "tax_amount", width: 80},
      {headerName: "Taxable Value", field: "taxable_amount", width: 80, aggFunc: 'sum'},
      {headerName: "Cess Amount", field: "cess_amt", width: 80, aggFunc: 'sum'},
      {headerName: "E-Commerce GSTIN", field: "ecommerce_gstin", width: 80}
    ];

    //hsn
    this.columnDefs5 = [
      {headerName: "HSN", field: "hsn", width: 80},
      {headerName: "Description", field: "prod_name", width: 80},
      {headerName: "UQC", field: "uqc", width: 80},
      {headerName: "Total Quantity", field: "qty", width: 80, aggFunc: 'sum'},
      {headerName: "Total Value", field: "price_total", width: 80, aggFunc: 'sum'},
      {headerName: "Rate", field: "tax_amount", width: 80},
      {headerName: "Taxable Value", field: "price_subtotal", width: 80, aggFunc: 'sum'},
      {headerName: "Integrated Tax Amount", field: "igst_amt", width: 80, aggFunc: 'sum'},
      {headerName: "Central Tax Amount", field: "cgst_amt", width: 80, aggFunc: 'sum'},
      {headerName: "State/UT Tax Amount", field: "sgst_amt", width: 80, aggFunc: 'sum'},
      {headerName: "Cess Amount", field: "cess_amt", width: 80, aggFunc: 'sum'},
    ];

    //cdnr
    this.columnDefs6 = [
      {headerName: "Receiver GSTIN", field: "cust_gstin", width: 80},
      {headerName: "Receiver Name", field: "cust_name", width: 80},
      {headerName: "Note Number", field: "name", width: 80},
      {headerName: "Note Date", field: "cn_date", width: 80},
      {headerName: "Note Type", field: "cn_type", width: 80},
      {headerName: "Place Of Supply", field: "state_code", width: 80},
      {headerName: "Reverse Charge", field: "reverse_charge", width: 80},
      {headerName: "Note Supply Type", field: "type", width: 80},
      {headerName: "Note Value", field: "amount", width: 80, aggFunc: 'sum'},
      {headerName: "Applicable % of Tax Rate", field: "app_tax_amount", width: 80},
      {headerName: "Rate", field: "tax_amount", width: 80},
      {headerName: "Taxable Value", field: "taxable_amount", width: 80, aggFunc: 'sum'},
      {headerName: "Cess Amount", field: "cess_amt", width: 80, aggFunc: 'sum'},
    ];

    //cdnur
    this.columnDefs7 = [
      {headerName: "UR Type", field: "ur_type", width: 80},
      {headerName: "Note Number", field: "name", width: 80},
      {headerName: "Note Date", field: "cn_date", width: 80},
      {headerName: "Note Type", field: "cn_type", width: 80},
      {headerName: "Place Of Supply", field: "state_code", width: 80},
      {headerName: "Note Value", field: "amount", width: 80, aggFunc: 'sum'},
      {headerName: "Applicable % of Tax Rate", field: "app_tax_amount", width: 80},
      {headerName: "Rate", field: "tax_amount", width: 80},
      {headerName: "Taxable Value", field: "taxable_amount", width: 80, aggFunc: 'sum'},
      {headerName: "Cess Amount", field: "cess_amt", width: 80, aggFunc: 'sum'},
    ];

  }

  postProcessData() {
    this.b2bData = this.rowData2;
    if (localStorage.getItem(this.selectedPeriod.period_name + 'gstr1_file_status')) {
      this.file_status = localStorage.getItem(this.selectedPeriod.period_name + 'gstr1_file_status')
    } else {
      this.file_status = 'pending';
    }
    this.file_date = localStorage.getItem(this.selectedPeriod.period_name + 'gstr1_file_date');
    this.file_ack = localStorage.getItem(this.selectedPeriod.period_name + 'gstr1_file_ack');
    for (let i = 0; i < 6; i++) {
      this.summaryData[i].tot_taxable = 0;
      this.summaryData[i].tot_amt = 0;
      this.summaryData[i].igst_amt = 0;
      this.summaryData[i].cgst_amt = 0;
      this.summaryData[i].sgst_amt = 0;
      this.summaryData[i].cess_amt = 0;
    }
    if (this.apiData.sales && this.apiData.sales.length > 0) {
      // types = ['Regular B2B', 'SEZ supplies with payment', 'SEZ supplies without payment', 'Deemed Exp', 'Intra-State supplies attracting IGST']
      this.b2bData = this.apiData.sales.filter(x => x.cust_gstin !== null && x.cust_gstin !== undefined && x.cust_gstin !== '');
      if (this.b2bData && this.b2bData.length > 0) {
        this.b2bData.forEach(sale => {
          sale.reverse_charge = 'N';
          if (sale.taxable_amount && sale.taxable_amount > 0) {
            this.summaryData[0].tot_taxable += sale.taxable_amount;
          }
          if (sale.invoice_amount && sale.invoice_amount > 0) {
            this.summaryData[0].tot_amt += sale.invoice_amount;
          }
          if (sale.cust_gstin) {
            const state = GST_STATE_CODES.find(x => x.code === parseInt(sale.cust_gstin.toString().substring(0, 2), 10));
            if (state) {
              sale.state_code = state.code + '-' + state.state;
            }
          }
          if (sale.cust_state && sale.dist_state && sale.dist_state.toUpperCase() !== sale.dist_state.toUpperCase()) {
            sale.type = 'Intra-State supplies attracting IGST';
          } else {
            sale.type = 'Regular B2B';
          }
          sale['invoice_date'] = this.datePipe.transform((new Date(sale['invoice_date'] + ' UTC')).toString(), 'dd-MMM-yyyy');
          this.configureTax(0, sale);
        });
      }
      this.b2CLData = this.apiData.sales.filter(x => (x.cust_gstin === null || x.cust_gstin === undefined || x.cust_gstin === '') && x.invoice_amount >= 250000);
      if (this.b2CLData && this.b2CLData.length > 0) {
        this.b2CLData.forEach(sale => {
          sale.reverse_charge = 'N';
          if (sale.taxable_amount && sale.taxable_amount > 0) {
            this.summaryData[1].tot_taxable += sale.taxable_amount;
          }
          if (sale.invoice_amount && sale.invoice_amount > 0) {
            this.summaryData[1].tot_amt += sale.invoice_amount;
          }
          if (sale.cust_gstin) {
            const state = GST_STATE_CODES.find(x => x.code === parseInt(sale.cust_gstin.toString().substring(0, 2), 10));
            if (state) {
              sale.state_code = state.code + '-' + state.state;
            }
          }
          if (sale.cust_state && sale.dist_state && sale.dist_state.toUpperCase() !== sale.dist_state.toUpperCase()) {
            sale.type = 'Intra-State supplies attracting IGST';
          } else {
            sale.type = 'Regular B2B';
          }
          sale['invoice_date'] = this.datePipe.transform((new Date(sale['invoice_date'] + ' UTC')).toString(), 'dd-MMM-yyyy');
          this.configureTax(1, sale);
        });
      }

      this.b2CSData = this.apiData.sales.filter(x => (x.cust_gstin === null || x.cust_gstin === undefined || x.cust_gstin === '') && x.invoice_amount < 250000);
      if (this.b2CSData && this.b2CSData.length > 0) {
        this.b2CSData.forEach(sale => {
          sale.reverse_charge = 'N';
          if (sale.taxable_amount && sale.taxable_amount > 0) {
            this.summaryData[2].tot_taxable += sale.taxable_amount;
          }
          if (sale.invoice_amount && sale.invoice_amount > 0) {
            this.summaryData[2].tot_amt += sale.invoice_amount;
          }
          if (sale.cust_gstin) {
            const state = GST_STATE_CODES.find(x => x.code === parseInt(sale.cust_gstin.toString().substring(0, 2), 10));
            if (state) {
              sale.state_code = state.code + '-' + state.state;
            }
          }
          if (sale.cust_state && sale.dist_state && sale.dist_state.toUpperCase() !== sale.dist_state.toUpperCase()) {
            sale.type = 'Intra-State supplies attracting IGST';
          } else {
            sale.type = 'Regular B2B';
          }
          sale['invoice_date'] = this.datePipe.transform((new Date(sale['invoice_date'] + ' UTC')).toString(), 'dd-MMM-yyyy');
          this.configureTax(2, sale);
        });
      }

      if (this.b2bData) {
        this.rowData2 = this.b2bData;
      }
      if (this.b2CLData) {
        this.rowData3 = this.b2CLData;
      }
      if (this.b2CSData) {
        this.rowData4 = this.b2CSData;
      }
    }

    this.hsnData = this.apiData.prodLines;
    if (this.hsnData) {
      this.configureProducts();
    }

    if (this.apiData.notes && this.apiData.notes.length > 0) {
      this.cdnRData = this.apiData.notes.filter(x => x.cust_gstin !== null && x.cust_gstin !== undefined && x.cust_gstin !== '');
      if (this.cdnRData && this.cdnRData.length > 0) {
        this.summaryData[4].tot_inv = this.cdnRData.length;
        this.cdnRData.forEach(sale => {
          sale.reverse_charge = 'N';
          if (sale.taxable_amount && sale.taxable_amount > 0) {
            this.summaryData[4].tot_taxable += sale.taxable_amount;
          }
          if (sale.invoice_amount && sale.invoice_amount > 0) {
            this.summaryData[4].tot_amt += sale.invoice_amount;
          }
          if (sale.cust_gstin) {
            const state = GST_STATE_CODES.find(x => x.code === parseInt(sale.cust_gstin.toString().substring(0, 2), 10));
            if (state) {
              sale.state_code = state.code + '-' + state.state;
            }
          }
          if (sale.cust_state && sale.dist_state && sale.dist_state.toUpperCase() !== sale.dist_state.toUpperCase()) {
            sale.type = 'Intra-State supplies attracting IGST';
          } else {
            sale.type = 'Regular B2B';
          }
          sale['cn_date'] = this.datePipe.transform((new Date(sale['cn_date'] + ' UTC')).toString(), 'dd-MMM-yyyy');
          this.configureTax(4, sale);
        });
      }
      this.cdnURData = this.apiData.notes.filter(x => x.cust_gstin === null || x.cust_gstin === undefined || x.cust_gstin === '');
      if (this.cdnURData && this.cdnURData.length > 0) {
        //ur_type = ['B2CL', 'EXPWP', 'EXPWOP']
        this.cdnURData.forEach(sale => {
          sale.ur_type = 'B2CL';
          sale.reverse_charge = 'N';
          if (sale.taxable_amount && sale.taxable_amount > 0) {
            this.summaryData[5].tot_taxable += sale.taxable_amount;
          }
          if (sale.taxable_amount && sale.taxable_amount > 0) {
            this.summaryData[5].tot_amt += sale.invoice_amount;
          }
          if (sale.cust_gstin) {
            const state = GST_STATE_CODES.find(x => x.code === parseInt(sale.cust_gstin.toString().substring(0, 2), 10));
            if (state) {
              sale.state_code = state.code + '-' + state.state;
            }
          }
          if (sale.cust_state && sale.dist_state && sale.dist_state.toUpperCase() !== sale.dist_state.toUpperCase()) {
            sale.type = 'Intra-State supplies attracting IGST';
          } else {
            sale.type = 'Regular B2B';
          }
          sale['cn_date'] = this.datePipe.transform((new Date(sale['cn_date'] + ' UTC')).toString(), 'dd-MMM-yyyy');
          this.configureTax(5, sale);
        });

      }

      if (this.cdnRData) {
        this.rowData6 = this.cdnRData;
      }
      if (this.cdnURData) {
        this.rowData7 = this.cdnURData;
      }
    }

    this.rowData1 = this.summaryData;

    this.summaryData[0].tot_inv = this.rowData2.length;
    this.summaryData[1].tot_inv = this.rowData3.length;
    this.summaryData[2].tot_inv = this.rowData4.length;
    this.summaryData[3].tot_inv = this.rowData5.length;
    this.summaryData[4].tot_inv = this.rowData6.length;
    this.summaryData[5].tot_inv = this.rowData7.length;

    for (let i = 0; i < 6; i++) {
      this.summaryData[i].tot_taxable = parseFloat(this.summaryData[i].tot_taxable.toFixed(2));
      this.summaryData[i].tot_amt = parseFloat(this.summaryData[i].tot_amt.toFixed(2));
      this.summaryData[i].igst_amt = parseFloat(this.summaryData[i].igst_amt.toFixed(2));
      this.summaryData[i].cgst_amt = parseFloat(this.summaryData[i].cgst_amt.toFixed(2));
      this.summaryData[i].sgst_amt = parseFloat(this.summaryData[i].sgst_amt.toFixed(2));
      this.summaryData[i].cess_amt = parseFloat(this.summaryData[i].cess_amt.toFixed(2));
    }
  }

  configureTax(i, item) {
    let tax_amount = 0;
    let spli;
    const breaks = [];
    if (item['tax_notes'] && item['tax_notes'].constructor !== Object) {
      item['tax_notes'] = JSON.parse(item['tax_notes']);
      if (typeof item['tax_notes'] === 'string' && item['tax_notes'] !== "") {
        item['tax_notes'] = JSON.parse(item['tax_notes']);
      }
    }
    if (item['tax_notes'] && item['tax_notes'].constructor === Object) {
      Object.keys(item['tax_notes']).forEach(each_tax => {
        if (item['tax_notes'][each_tax] && item['tax_notes'][each_tax].toString().includes('(')) {
          spli = item['tax_notes'][each_tax].split('(');
          tax_amount = spli[1].split('%')[0].split(')')[0];
          if (each_tax.includes(' ')) {
            each_tax = each_tax.split(' ')[0];
          }
          spli[0] = parseFloat(parseFloat(String(spli[0])).toFixed(2));
          item[each_tax.toString().toLowerCase() + '_rate'] = tax_amount;
          item[each_tax.toString().toLowerCase() + '_amt'] = spli[0];

          this.summaryData[i][each_tax.toString().toLowerCase() + '_amt'] += spli[0];
          breaks.push({name: each_tax, tax: spli[0], tax_amount});
        } else {
          if (item.hasOwnProperty('tax')) {
            spli = item['tax'].split('(');
            tax_amount = spli[1].split('%')[0].split(')')[0];
          } else {
          }
          const tax = parseFloat(parseFloat(String(item['tax_notes'][each_tax])).toFixed(2));
          if (each_tax.includes(' ')) {
            each_tax = each_tax.split(' ')[0];
          }
          item['tax_notes'][each_tax] = tax;
          item[each_tax.toString().toLowerCase() + '_rate'] = tax_amount;
          item[each_tax.toString().toLowerCase() + '_amt'] = item['tax_notes'][each_tax];

          this.summaryData[i][each_tax.toString().toLowerCase() + '_amt'] += tax;
          breaks.push({name: each_tax, tax, tax_amount});
        }
      });
    }
  }

  configureProducts() {
    this.hsnData.forEach(x => {
      if (x.price_subtotal && x.price_subtotal > 0) {
        this.summaryData[3].tot_taxable += x.price_subtotal;
      }
      if (x.price_total && x.price_total > 0) {
        this.summaryData[3].tot_amt += x.price_total;
      }
      this.configureTax(3, x);
    });
    this.rowData5 = this.hsnData;
    console.log(this.rowData5);
  }

  onBtExport() {
    const wb = XLSX.utils.book_new();

    const summaryData = this.sharedService.constructDataFromColumnDefs(this.columnDefs1, this.rowData1);
    const ws1 = XLSX.utils.json_to_sheet(summaryData);
    const wscols1 = [{wch: 30}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}];
    ws1['!cols'] = wscols1;
    this.sharedService.applyExcelColumnStyle(ws1, 1, wscols1.length);

    const b2bData = this.sharedService.constructDataFromColumnDefs(this.columnDefs2, this.rowData2);
    const ws2 = XLSX.utils.json_to_sheet(b2bData);
    const wscols2 = [{wch: 16}, {wch: 30}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 30}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}];
    ws2['!cols'] = wscols2;
    this.sharedService.applyExcelColumnStyle(ws2, 1, wscols2.length);

    const b2CLData = this.sharedService.constructDataFromColumnDefs(this.columnDefs3, this.rowData3);
    const ws3 = XLSX.utils.json_to_sheet(b2CLData);
    const wscols3 = [{wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 30}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}];
    ws3['!cols'] = wscols3;
    this.sharedService.applyExcelColumnStyle(ws3, 1, wscols3.length);

    const b2CSData = this.sharedService.constructDataFromColumnDefs(this.columnDefs4, this.rowData4);
    const ws4 = XLSX.utils.json_to_sheet(b2CSData);
    const wscols4 = [{wch: 16}, {wch: 16}, {wch: 30}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}];
    ws4['!cols'] = wscols4;
    this.sharedService.applyExcelColumnStyle(ws4, 1, wscols4.length);

    const hsnData = this.sharedService.constructDataFromColumnDefs(this.columnDefs5, this.rowData5);
    const ws5 = XLSX.utils.json_to_sheet(hsnData);
    const wscols5 = [{wch: 16}, {wch: 30}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 18}, {wch: 18}, {wch: 18}, {wch: 16}];
    ws5['!cols'] = wscols5;
    this.sharedService.applyExcelColumnStyle(ws5, 1, wscols5.length);

    const cdnrData = this.sharedService.constructDataFromColumnDefs(this.columnDefs6, this.rowData6);
    const ws6 = XLSX.utils.json_to_sheet(cdnrData);
    const wscols6 = [{wch: 16}, {wch: 30}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 30}, {wch: 16}, {wch: 16}, {wch: 16}];
    ws6['!cols'] = wscols6;
    this.sharedService.applyExcelColumnStyle(ws6, 1, wscols6.length);

    const cdnurData = this.sharedService.constructDataFromColumnDefs(this.columnDefs7, this.rowData7);
    const ws7 = XLSX.utils.json_to_sheet(cdnurData);
    const wscols7 = [{wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 16}, {wch: 30}, {wch: 18}, {wch: 16}, {wch: 16}];
    ws7['!cols'] = wscols7;
    this.sharedService.applyExcelColumnStyle(ws7, 1, wscols7.length);

    XLSX.utils.book_append_sheet(wb, ws1, "Summary");
    XLSX.utils.book_append_sheet(wb, ws2, "b2b");
    XLSX.utils.book_append_sheet(wb, ws3, "b2cl");
    XLSX.utils.book_append_sheet(wb, ws4, "b2cs");
    XLSX.utils.book_append_sheet(wb, ws5, "hsn");
    XLSX.utils.book_append_sheet(wb, ws6, "cdnr");
    XLSX.utils.book_append_sheet(wb, ws7, "cdnur");
    XLSX.writeFile(wb, "gstr1.xlsx");
  }

  openGSTFilePopup() {
    const config: MatDialogConfig = {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'custom-dialog-container',
      data: {
        period: this.selectedPeriod,
        file_status: this.file_status,
        summaryData: this.rowData1
        // supplier_territory_id: supp_territory_id
      },
    };
    const dialogRef = this.dialog.open(GSTR1PopupComponent, config);
    dialogRef.componentInstance.gstr1submitted.subscribe(status => {
      if (status) {
        this.dialog.closeAll();
        this.file_status = 'filed';
        this.file_date = localStorage.getItem(this.selectedPeriod.period_name + 'gstr1_file_date');
        this.file_ack = localStorage.getItem(this.selectedPeriod.period_name + 'gstr1_file_ack');
      }
    });
  }

  downloadJSON(){

  }

  viewInvoices() {
    this.router.navigate([this.apiService.redirect_url + "/dms/sec_sale_invoices"]);
  }
}
