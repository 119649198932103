<div>
  <app-page-loader [show]="!pageReady"></app-page-loader>
</div>
<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section" style="border-bottom: 1px solid #D7DDE6;">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>GST Filing</h2>
    </div>
    <div class="body-section w-100" style="padding: 20px 15px;">
      <div class="cardbox">
        <div class="row centeralign">
          <div style="display: inline-block">
            <label class="detailvaluebold1">GST Filing ({{ period.period_name }}) 2024</label>
            <div class="row">
              <span class="detailvalue2">Due Date:</span>
              <span class="detailvalue2"
                    style="color: #E24260; font-weight: 600; margin-left: 5px">{{ period.end_date }}</span>
            </div>
          </div>
          <div style="display: inline-block; margin-right: 20px; position: absolute; right: 30px;" class="statuscard"
               [ngClass]="{'warning': file_status === 'pending',
                         'success': file_status === 'completed'}">
                          <span class="statusindicator"
                                [ngClass]="{'warning': file_status === 'pending',
                         'success': file_status === 'completed'}"></span>
            <span [ngClass]="{'warning': file_status === 'pending',
                         'success': file_status === 'completed'}">{{ file_status }}</span>
          </div>
        </div>
      </div>

      <div class="cardbox" style="margin-top: 20px;">
        <div class="row centeralign" (click)="showStep1 = !showStep1" style="cursor: pointer;">
          <span class="detailvaluebold2">Step 1 - </span>
          <span class="detailvalue2" style="margin-left: 5px;margin-right: 20px;">Login and Authenticate</span>
          <span *ngIf="authenticated" class="ml-1 status2" style="background-color:#E1F0E7; border:#E1F0E7; color: #379862; font-weight: bold;" >Authenticated</span>
          <div *ngIf="!authenticated" style="position: absolute; right: 20px;">
            <img *ngIf="!showStep1" style="position: absolute; right: 30px;height: 8px;"
                 src="/assets/images/down_arrow_black.svg"/>
            <img *ngIf="showStep1" style="position: absolute; right: 30px;height: 8px;"
                 src="/assets/images/up_arrow.svg"/>
          </div>
        </div>
        <div class="mt-3" *ngIf="!authenticated && showStep1">
          <div *ngIf="!otpSent">
            <span class="dialoglabel">GSTIN Username</span> <span style="color: darkred">*</span>
            <div class="row centeralign">
              <div>
                <input
                  class="forminput2"
                  style="display: block; font-size: 14px; margin-top: 5px;width: 200px; height: 40px;"
                  type="text"
                  name="rep_name"
                  [(ngModel)]="filing_details.username"
                  #rep_name="ngModel"
                  placeholder="Enter username"
                  required
                >
                <div *ngIf="rep_name.errors && (rep_name.dirty || rep_name.touched)">
                  <span *ngIf="rep_name.errors.required" class="text-danger">GSTIN Username is required</span>
                </div>
              </div>
              <button
                style=" margin-left:30px; height: 30px;"
                class="btnsmall blue-border-btn"
                [disabled]="!filing_details.username"
                (click)="generateOTP()"
              >
                Generate OTP
              </button>
            </div>

          </div>
          <div *ngIf="otpSent">
            <span class="dialoglabel">One-Time Password (OTP)</span> <span style="color: darkred">*</span>
            <div>
              <input
                [ngClass]="{'forminput2': !invalidOTP,
                         'forminput2error': invalidOTP}"
                style="display: block; font-size: 14px; margin-top: 5px;width: 180px;height: 40px;"
                type="number"
                name="rep_name"
                [(ngModel)]="filing_details.otp"
                #rep_name="ngModel"
                placeholder="Enter OTP"
                required
              >
              <div *ngIf="rep_name.errors && (rep_name.dirty || rep_name.touched)">
                <span *ngIf="rep_name.errors.required" class="text-danger">OTP is required</span>
              </div>
              <div *ngIf="invalidOTP">
                <span class="text-danger">OTP entered is incorrect</span>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <button
                style=" margin-right:15px;"
                class="btn blue-border-btn"
                (click)="generateOTP()"
              >
                RESEND
              </button>
              <button
                class="btn confirm-blue-btn"
                (click)="validateOTP()"
                [disabled]="!filing_details.otp"
              >
                AUTHENTICATE
              </button>
            </div>
          </div>
        </div>

      </div>

      <div class="cardbox" style="margin-top: 20px;">
        <div class="row centeralign" (click)="showStep2 = !showStep2" style="cursor: pointer;">
          <span class="detailvaluebold2">Step 2 - </span>
          <span class="detailvalue2" style="margin-left: 5px;margin-right: 20px;">Save GSTR1</span>
          <span *ngIf="gstrSaved" class="ml-1 status2" style="background-color:#E1F0E7; border:#E1F0E7; color: #379862; font-weight: bold;">GSTR1 Saved</span>
          <div style="position: absolute; right: 20px;">
            <img *ngIf="!showStep2" style="position: absolute; right: 30px;height: 8px;"
                 src="/assets/images/down_arrow_black.svg"/>
            <img *ngIf="showStep2" style="position: absolute; right: 30px;height: 8px;"
                 src="/assets/images/up_arrow.svg"/>
          </div>
        </div>
        <div class="mt-3" *ngIf="showStep2">
          <div>
            <ag-grid-angular id="myGrid2" *ngIf="dataLoaded" style="height: 40vh" class="ag-theme-alpine"
                             [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                             (gridReady)="onGridReady1($event)" [frameworkComponents]="frameworkComponents1"
                             (cellClicked)="onCellClicked1($event)">
            </ag-grid-angular>
          </div>
          <div style="margin-top: 15px;">
            <button
              class="btn confirm-blue-btn"
              (click)="saveGST()"
              [disabled]="!filing_details.otp"
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
      <div class="cardbox" style="margin-top: 20px;">
        <div class="row centeralign" (click)="showStep3 = !showStep3" style="cursor: pointer;">
          <span class="detailvaluebold2">Step 3 - </span>
          <span class="detailvalue2" style="margin-left: 5px;">File Return</span>
          <div style="position: absolute; right: 20px;">
            <img *ngIf="!showStep3" style="position: absolute; right: 30px;height: 8px;"
                 src="/assets/images/down_arrow_black.svg"/>
            <img *ngIf="showStep3" style="position: absolute; right: 30px;height: 8px;"
                 src="/assets/images/up_arrow.svg"/>
          </div>
        </div>
        <div class="mt-3" *ngIf="showStep3">
          <span class="detailvalue2" style="font-size: 12px;">Summary verified and reviewed. Proceed to submit the return</span>
          <div class="mt-3" style="background: #F5F7F9; padding: 20px;">
            <div style="display: flex">
              <div style="flex: 1">
                <span class="detaillabel">Period</span>
                <span class="detailvaluebold" style="display: block">{{ period.period_name }} 2024</span>
              </div>
              <div style="flex: 1">
                <span class="detaillabel">Return</span>
                <span class="detailvaluebold" style="display: block">GSTR 1 ({{ period.period_name }} 2024)</span>
              </div>
              <div style="flex: 1">
              </div>
              <div style="flex: 1">
              </div>
            </div>
            <div style="display: flex; margin-top: 15px;">
              <div style="flex: 1">
                <span class="detaillabel">Authorise Signatory PAN</span>
                <span class="detailvaluebold" style="display: block">{{ pan }}</span>
              </div>
              <div style="flex: 1">
                <span class="detaillabel">Tax Payer Name</span>
                <span class="detailvaluebold" style="display: block">{{ tax_payer }}</span>
              </div>
              <div style="flex: 1">
              </div>
              <div style="flex: 1">
              </div>
            </div>
            <!--<div class="col-md-12 pad0">
              <div class="col-md-12 pt-3 pad0">
                <span class="detaillabel">Period</span>
                <span class="detailvaluebold">{{ period }} 2024</span>
              </div>
              <div class="col-md-12 pt-3 pad0">
                <span class="detaillabel">Return</span>
                <span class="detailvaluebold">GSTR 1 ({{ period }} 2024)</span>
              </div>
              <div class="col-md-12 pt-3 pad0">
              </div>
              <div class="col-md-12 pt-3 pad0">
              </div>
            </div>
            <div class="col-md-12 pad0">
              <div class="col-md-12 pt-3 pad0">
                <span class="detaillabel">Authorise Signatory PAN</span>
                <span class="detailvaluebold">{{ pan }}</span>
              </div>
              <div class="col-md-12 pt-3 pad0">
                <span class="detaillabel">Tax Payer Name</span>
                <span class="detailvaluebold">{{ tax_payer }}</span>
              </div>
              <div class="col-md-12 pt-3 pad0">
              </div>
              <div class="col-md-12 pt-3 pad0">
              </div>
            </div>-->
          </div>
          <div class="mt-4">
            <span class="detailvalue2" style="font-size: 12px;">Please check the following before filing</span>
            <ul style="list-style: disc;margin-top: 10px;">
              <li>Are you running emSinger software as Administrator? Is the digital signature connected to your
                computer?
              </li>
              <li>Is the digital signature connected to your computer?</li>
            </ul>
          </div>
          <div class="mt-3 row" style=" background: #EBF3F9; border: 1px solid #EBF3F9; padding: 20px;">
            <span class="detailvaluebold2" style="color: #0762AD;">Note:</span>
            <span class="detailvalue2" style="color: #0762AD; margin-left: 5px;">The emSinger window might be hidden behind this browser window. Check the emSinger window after you file. </span>
          </div>
          <div style="margin-top: 20px;" class="row">
            <button
              style=" margin-right:15px;"
              class="btn confirm-blue-btn"
              (click)="fileGST()"
            >
              File
            </button>
            <button
              class="btn grey-btn"
              (click)="closeDialog()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
