import {Component, ElementRef, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";
import {SharedService} from "../../_services/shared_service";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp_verification.component.html',
  styleUrls: ['./otp_verification.component.scss']
})

export class OTPVerificationComponent implements OnInit {

  mobile;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  data;
  type;
  apiURL;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private domSanitizer: DomSanitizer,
              private sharedService: SharedService,
              private toastr: ToastrService,
              public dialog: MatDialog) {

  }

  ngOnInit(): void {
    let datas: any = {};
    datas = history.state;
    this.type = datas.type;
    this.data = datas.data;
    if (localStorage.getItem('mobile')) {
      this.mobile = localStorage.getItem('mobile');
    }
    if (this.type === 'meet_attendee') {
      this.apiURL = '/api/res_users/authenticateMeetAttendee';
    }
  }

  focusNextOTPInput(event) {
    console.log(event);
    if (event.key && event.key.length === 1) {
      if (event.srcElement.nextElementSibling) {
        event.srcElement.nextElementSibling.focus();
      }
    }
  }

  focusVerify(event) {
    console.log(event);
    if (event.key && event.key.length === 1) {
      document.getElementById('verifybutton').focus();
    }
  }

  resendOTP() {
    const paramObject: any = {};
    this.otp1 = null;
    this.otp2 = null;
    this.otp3 = null;
    this.otp4 = null;
    paramObject.access_token = localStorage.getItem('resfreshToken');
    if (this.type === 'meet_attendee') {
      const attendee = JSON.parse(localStorage.getItem('attendee_details'));
      paramObject.data = {
        aid: attendee.aid,
        mobile: this.mobile
      };
    } else {

    }
    this.apiService.post('/api/res_users/sendAttendeeOTP', paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data === 'success') {
          this.toastr.success("OTP sent successfully");
        } else {
          this.toastr.error("Failed To Send OTP");
        }
      });
  }

  verifyOTP() {
    const paramObject: any = {};
    const otp = this.otp1.toString() + this.otp2.toString() + this.otp3.toString() + this.otp4.toString();
    paramObject.access_token = localStorage.getItem('resfreshToken');
    if (this.type === 'meet_attendee') {
      const attendee = JSON.parse(localStorage.getItem('attendee_details'));
      paramObject.data = {
        aid: attendee.aid,
        otp
      };
    } else {

    }

    this.apiService.post(this.apiURL, paramObject)
      .subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data) {
          if (this.type === 'meet_attendee') {
            this.attendeeRedirect(res.results.data.url);
          }
        } else {
          if ((res.results.status === 203) && res.results.msg) {
            this.toastr.error(res.results.msg);
          } else {
            this.toastr.error("Failed To Register User");
          }
        }
      });
  }

  attendeeRedirect(url) {
    if (url) {
      this.router.navigate([this.apiService.redirect_url + '/meet_att'], {
        queryParams: {
          auth: url,
          ckey: localStorage.getItem('company_key')
        }
      });
    } else {
      this.toastr.error("Failed To Authenticate User");
    }
  }


}
