<div class="cardbox" style="border: 1px solid #D7DDE6;background: #FFFFFF;position: absolute;
    bottom: 15px;
    top: 15px;
    left: 15px;
    right: 15px;">
  <div>
    <h3 style="text-align: center;">OTP Verification</h3>
    <span style="color: #2D2D2D; font-size: 12px;font-weight: 500;margin-top: 50px; display: block;">To complete your registration, please verify the OTP sent to your mobile number.</span>
    <div class="row" style="margin-top: 30px;">
      <span style="color: #2D2D2D; font-size: 12px;font-weight: 400;">Enter OTP sent to</span>
      <span style="color: #2D2D2D; font-size: 12px;font-weight: 600;margin-left: 5px;">{{mobile}}</span>
    </div>
    <div style="margin-top: 40px; width: 100%;">

      <form novalidate id="ngForm" #otpform="ngForm">
        <div class="custom-input otp" style="display: flex;justify-content: center;">
          <input
            type="number"
            class="form-control otpinput"
            maxlength="1"
            name="otpa"

            [(ngModel)]="otp1"
            #otpa="ngModel"
            (keyup)="focusNextOTPInput($event)"
            required
          />
          <input
            type="number"
            class="form-control otpinput"
            maxlength="1"
            name="otpb"

            [(ngModel)]="otp2"
            #otpb="ngModel"
            (keyup)="focusNextOTPInput($event)"
            required
          />
          <input
            type="number"
            class="form-control otpinput"
            maxlength="1"
            name="otpc"

            [(ngModel)]="otp3"
            #otpc="ngModel"
            (keyup)="focusNextOTPInput($event)"
            required
          />
          <input
            type="number"
            class="form-control otpinput"
            maxlength="1"
            name="otpd"

            [(ngModel)]="otp4"
            #otpd="ngModel"
            (keyup)="focusVerify($event)"
            required

          />
        </div>
      </form>
      <div style="margin-top: 30px;display: flex;justify-content: center;">
        <button
          id="verifybutton"
          mat-button
          class="btn confirm-blue-btn"
          (click)="verifyOTP()"
          form="ngForm"
          [disabled]="otpform.form.invalid"
        >
          Verify
        </button>
        <!-- <div class="row" style="margin-top: 30px;">
           <span style="color: #2D2D2D; font-size: 12px;font-weight: 400;">Didn't receive OTP?</span>
           <span style="color: #2D2D2D; font-size: 12px;font-weight: 600;margin-left: 5px;">Request Again</span>
         </div>-->
      </div>
      <div style="margin-top: 30px;display: flex;justify-content: center;">
        <span style="color: #2D2D2D; font-size: 12px;font-weight: 400;">Didn't receive OTP?</span>
        <span style="color: #2D2D2D; font-size: 12px;font-weight: 600;margin-left: 5px;cursor: pointer"
              (click)="resendOTP()">Request Again</span>
      </div>
    </div>
  </div>
</div>
