import {Component, Inject, OnInit} from "@angular/core";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {ApiService} from "../../_services/api.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {SelectionCellRendererComponent} from "../../SelectionCellRenderer";

@Component({
  selector: 'app-stock-dispute-popup',
  templateUrl: './deviation_order_popup.component.html'
})

export class DeviationOrderPopupComponent implements OnInit {

  API_URL = '';
  dataLoaded = false;
  public columnDefs = [];
  public rowData = [];
  public gridOptions: GridOptions;
  public preloader = false;
  public emptyState = false;
  context;
  public gridColumnApi: any;
  public gridApi: any;
  reason = [];
  editMode = true;

  bindLabel = 'name';
  bindID = 'id';

  constructor(private apiService: ApiService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    console.log(dialogdata);
    this.rowData = dialogdata.productData;
    if (localStorage.getItem('beat_activity_types') != null) {
      const beat_activity_types = JSON.parse(localStorage.getItem('beat_activity_types'));
      if (beat_activity_types && beat_activity_types.length > 0) {
        this.reason = beat_activity_types.filter(x => x.action_type === 'Stock Deviation');
        if (!this.reason || this.reason.length === 0) {
          this.toastr.error("Stock Deviation Reasons not set !!");
          this.closeDialog();
        }
      }
    }
    this.context = {componentParent: this};
    this.gridOptions = {
      rowHeight: 35,
      rowSelection: 'multiple',
      enableRangeSelection: true,
      enableCharts: true,
      chartThemes: ['ag-pastel-dark'],
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      rowClassRules: {
        'ag-grid-grey': (params) => {
          return params.node.rowIndex % 2 === 0;
        },
      }
    } as GridOptions;
    this.configureGrid();
    this.dataLoaded = true;
  }

  ngOnInit(): void {
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: "Code", field: "code", width: 90},
      {headerName: "Name", field: "name", width: 150},
      {headerName: "Current Quantity", field: "quantity", width: 90},
      {headerName: "New Quantity", field: "new_qty", width: 90},
      {headerName: "Deviation", field: "deviation", width: 90},
      {headerName: "Reason", field: "reason", width: 200, cellRenderer: SelectionCellRendererComponent},
      {headerName: "Comments", field: "deviation", width: 200},
    ];
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeAllColumns();
          }
        }
      });
    });

    if (screen.width > 991) {
      this.gridApi.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeAllColumns();
    }
  }

  onCellValueChanged(event) {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  confirm() {

  }
}
