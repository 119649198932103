<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-6 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search for DC">
          </div>
        </div>
        <div class="col-md-6 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li style="font-size: 130%;
    margin-top: 1%;">Van:</li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-template adHost></ng-template>
              </li>
              <li style="font-size: 130%;
    margin-top: 1%;
    padding-right: 10px;">Outlet Type:</li>
              <li style="margin-right: 1px !important;margin-left: 1px !important;" class="margLR">
                <ng-template adHost3></ng-template>
              </li>
              <li>
                <div style="display: inline-block;">
                  <label style="font-size: 120%;
    font-weight: 400;">Credit Days</label>
                </div>
                <div style="display: inline-block;padding-left: 4px;">
                  <input type="text" class="form-control" style="width: 30%;
                                height: 30px;
                                font-size: 120%;" [(ngModel)]="limit" />
                </div>
                <div style="display: inline-block;">
                  <button (click)="run()" style="margin-top: 2.5%;margin-left: -135%;" class="btn blue-btn">GO</button>
                </div>
              </li>
<!--              <li class="margLR" style="margin-left: -8%;">-->
<!--                <div class="date-picker">-->
<!--                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>-->
<!--                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">-->
<!--                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>-->
<!--                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>-->
<!--                </div>-->
<!--              </li>-->
<!--              <li class="margLR">-->
<!--                <app-printicon [gridOption]="gridOptions" [styleGrid]="styleGrid"></app-printicon>-->
<!--              </li>-->
              <li class="margLR" style="margin-left: -14%;">
                <app-excelicon [gridOption]="gridOptions" [title]="'Collections'"></app-excelicon>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div class="component-container">
            <ag-grid-angular id="myGrid2" *ngIf="loaded" style="height: calc(100vh - 160px);" class="ag-theme-alpine"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
