import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {Component} from "@angular/core";

@Component({
  selector: 'app-numeric-cell',
  template: `<input *ngIf="editMode"
    #input
    [(ngModel)]="params.data[params.colDef.field]"
    class="custom-inputbox" style="width: 100%"
  /><span *ngIf="!editMode"  class="element-req">{{value}}</span>`,
})
export class CellEditRendererComponent implements ICellRendererAngularComp {

  public params: any;
  public editMode = false;
  public context;
  public value;
  agInit(params: any): void {
    this.params = params;
    /*if (params.value) {
      this.value = params.value;
    } else {
      if (params.data[params.data.colDef.field]) {
        this.value = params.data[params.data.colDef.field];
      }
    }*/
    if (params.data[params.colDef.field]) {
      this.value = params.data[params.colDef.field];
    }
    if (this.params.context.componentParent) {
      this.context = this.params.context.componentParent;
    } else {
      this.context = this.params?.context?.params?.context?.context?.componentParent;
    }
    if (this.context) {
      this.editMode = this.context.editMode;
    }
  }

  refresh(): boolean {
    return false;
  }
}
