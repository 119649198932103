import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../../../_services/api.service";
import {DatePipe} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../../../../_services/shared_service";
import {ConfirmDialogComponent} from "../../../../components/confirmdialog/confirmdialog.component";

@Component({
  selector: 'app-invoice-payment-popup',
  templateUrl: './invoice_payment_popup.component.html',
  styleUrls: ['./invoice_payment_popup.component.scss']

})

export class InvoicePaymentPopupComponent implements OnInit {
  @Output() markPaid = new EventEmitter<boolean>();
  invoice_id = 0;
  partner_id = 0;
  supplier_id = 0;
  invoiceDetails: any = {};
  customerDetails: any = {};
  paymentDetails: any = {};
  amount_collected = 0;
  public paymentModes = [{id: 'cash', itemName: 'Cash'}, {id: 'cheque', itemName: 'Cheque'}, {id: 'online', itemName: 'Online'}];
  dataLoaded = false;
  todayDate;
  pageReady = false;

  constructor(public apiService: ApiService,
              private elRef: ElementRef,
              private datePipe: DatePipe,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private toastr: ToastrService,
              private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.todayDate = new Date();
    this.invoiceDetails['name'] = dialogdata.invoice_no;
    this.invoice_id = dialogdata.invoice_id;
    this.partner_id = dialogdata.partner_id;
    if (dialogdata.supplier_id) {
      this.supplier_id = dialogdata.supplier_id;
    }
    const date = new Date();
    this.paymentDetails.tot_amount = this.invoiceDetails.outstanding_amount;
    this.paymentDetails.payment_date = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.paymentDetails.cheque_date = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.paymentDetails.payment_type = 'cash';
    this.paymentDetails.state = 'posted';
    this.fetchPartnerInvoiceDetails();
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  chequeTypeSelection() {

  }

  changeDate(dt) {

  }

  fetchPartnerInvoiceDetails() {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.customer_id = this.partner_id;
    paramObject.id = this.invoice_id;
    if (this.supplier_id && this.supplier_id !== 0) {
      paramObject.supplier_id = this.supplier_id;
    }
    this.apiService.post('/api/pwa_dms_connects/get_customer_invoice_outstanding', paramObject)
      .subscribe(res => {
        console.log(res);
        this.pageReady = true;
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data) {
          this.customerDetails = res.results.data.master[0];
          this.invoiceDetails = res.results.data.invoices[0];
          this.paymentDetails.tot_amount = this.invoiceDetails.outstanding_amount;
          this.dataLoaded = true;
        }
      });
  }

  onAmountChange() {

  }

  openConfirmDialog() {
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "650px",
      maxHeight: "250px",
      data: {title: "Collect Payment ?",
        message1: "Total of " + this.paymentDetails.tot_amount + " will be collected",
        message2: "",
        showNoButton: true,
        yesButton: "COLLECT",
        noButton: "CANCEL"}
    };

    const confirmdiaeref = this.dialog.open(ConfirmDialogComponent, config);
    confirmdiaeref.componentInstance.selectedAction.subscribe(data => {
      if (data === 'yes') {
        this.collectPayment();
      } else {

      }
    });
  }

  collectPayment() {
    this.pageReady = false;
    if (this.paymentDetails.payment_type === 'cash') {
      this.paymentDetails.state = 'posted';
      this.paymentDetails.cheque_date = null;
    }
    this.invoiceDetails.cr_used = 0;
    this.invoiceDetails.write_off = 0;
    this.invoiceDetails.amt = parseFloat(this.paymentDetails.tot_amount);
    this.invoiceDetails.balance = parseFloat(this.paymentDetails.outstanding_amount) - parseFloat(this.paymentDetails.tot_amount);

    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.id = this.partner_id;
    paramObject.data = {
      invoices: [this.invoiceDetails],
      amount_collected: parseFloat(this.paymentDetails.tot_amount),
      credits_allocated: 0,
      round_off: 0,
      payment_date: this.paymentDetails.payment_date,
      payment_type: this.paymentDetails.payment_type,
      cheque_no: this.paymentDetails.cheque_no,
      cheque_date: this.paymentDetails.cheque_date,
      branch: this.paymentDetails.branch,
      credit_notes: [],
      state: this.paymentDetails.state,
      comments: this.paymentDetails.comments
    };
    this.apiService.post('/api/pwa_dms_connects/sale_invoice_mark_paid', paramObject)
      .subscribe(res => {
        this.pageReady = true;
        console.log(res);
        // t.stop();
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data) {
          this.toastr.success("Payment Successful");
          this.dialog.closeAll();
          this.markPaid.emit(true);
        } else {
          this.toastr.error("Payment Error");
        }
      });
  }
}
