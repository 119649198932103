import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class PurchaseOrderFulfillmentReportComponent extends ReportBase implements IReport, OnInit {

  /* showCustomFilter = true;
   customFilter: any = {
     name: 'Active',
     key: 'active',
     value: 'true',
     values: [],
     show: true
   };*/

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Purchase Order Fulfillment Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.showDate = true;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/purchaseOrderFulfillment';

    this.columnDefs = [

      {headerName: 'DC', field: 'dc', enableRowGroup: true, width: 80},
      {headerName: 'VAN', field: 'van', enableRowGroup: true, width: 80},
      {headerName: 'PO NO', field: 'po', width: 100, enableRowGroup: true},
      {headerName: 'OUTLET', field: 'outlet', width: 100, enableRowGroup: true},
      {headerName: 'OCODE', field: 'ocode', menuTabs: [], width: 80},
      {headerName: 'OTYPE', field: 'retailer_type', menuTabs: [], width: 100},
      {headerName: 'CHANNEL', field: 'channel', width: 100, enableRowGroup: true},
      {headerName: 'CHAIN', field: 'chain_outlet', menuTabs: [], width: 100},
      {headerName: 'SALE DATE', field: 'sale_date', enableRowGroup: true, width: 150},
      {headerName: 'EXPIRY DATE', field: 'validity_date', enableRowGroup: true, width: 100},
      {headerName: 'INVOICE DATE', field: 'invoice_date', enableRowGroup: true, width: 150},
      {
        headerName: 'DELIVERED  DATE', field: 'delivered_date', enableRowGroup: true, width: 150,
        valueGetter: params => {
          if (params.data && params.data.delivered_date) {
            return params.context.componentParent.datePipe.transform((new Date(params.data.delivered_date + ' UTC')).toString(), 'yyyy-MM-dd');
          }
        }
      },
      {
        headerName: 'AGEING', field: 'ageing', enableRowGroup: true, width: 100,
        valueGetter: params => {
          if (params.data && params.data.invoice_date) {
            const oneDay = 24 * 60 * 60 * 1000;
            let firstDate: any = '';
            if (params.data.delivered_date === null || params.data.delivered_date === undefined || params.data.delivered_date === '') {
              firstDate = new Date();
            } else {
              firstDate = new Date(params.data.delivered_date);
            }

            let secondDate: any = '';
            secondDate = new Date(params.data.invoice_date);
            firstDate = firstDate.setMinutes(firstDate.getMinutes() - firstDate.getTimezoneOffset());
            secondDate = secondDate.setMinutes(secondDate.getMinutes() - secondDate.getTimezoneOffset());
            const diffDays = Math.round((firstDate - secondDate) / (24 * 60 * 60 * 1000));
            // const diffDays = Math.floor((Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate()) - Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate())) / (1000 * 60 * 60 * 24));


            return diffDays;
          }
        }
      },
      {headerName: 'INVOICE NO', field: 'inv_no', enableRowGroup: true, width: 150},
      {headerName: 'PRODUCT', field: 'product', width: 100},
      {headerName: 'P CODE', field: 'pcode', width: 150},
      {headerName: 'TOTAL PO', field: 'po_qty', enableRowGroup: true, width: 150},
      {headerName: 'PO', field: 'line_po', enableRowGroup: true, width: 150},
      {headerName: 'PRE ORDER QTY', field: 'ordered', enableRowGroup: true, width: 80},
      {headerName: 'DELIVERED QTY', field: 'delivered', enableRowGroup: true, width: 80},
      {headerName: 'DELIVERED VALUE', field: 'delivered_value', enableRowGroup: true, width: 100},
    ];

  }

}
