import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {DatePipe} from "@angular/common";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {SharedService} from "../../_services/shared_service";

@Component({
  selector: 'app-gstr2-popup',
  templateUrl: './gstr2_popup.component.html',
  styleUrls: ['./gstr2_popup.component.scss']
})

export class GSTR2PopupComponent implements OnInit {
  @Output() gstr2submitted: EventEmitter<any> = new EventEmitter<any>();
  period;
  file_status;
  showStep1 = true;
  showStep2 = false;
  showStep3 = false;
  filing_details: any = {};
  pan = 'AHAHY1257Y'
  tax_payer = 'Madhuri stores private limited';
  otpSent = false;
  otp = 487910;
  invalidOTP = false;
  authenticated = false;
  matched = false;
  pageReady = true;
  invoiceData = [];

  searchValue: any;
  gridOptions1: GridOptions;
  columnDefs1 = [];
  frameworkComponents1: any = {};
  rowData1 = [];
  public gridColumnApi1: any;
  public gridApi1: any;
  preloader: any;
  dataLoaded: any;
  statusConfig = {
    success: 'success',
    success1: 'success',
    error: 'fail',
    error1: 'fail',
    warning: 'warning',
    warning1: 'warning',
    new: 'new',
    new1: 'new',
    processed: 'processed'
  };
  totalRecords = 0;
  matchedRecords = 0;
  mismatchedRecords = 0;
  missingRecords = 0;
  pendingRecords = 0;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private datePipe: DatePipe,
              private sharedService: SharedService,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.period = dialogdata.period;
    this.file_status = dialogdata.file_status;
    this.invoiceData = dialogdata.invoiceData;
    this.rowData1 = dialogdata.invoiceData;
    this.totalRecords = this.rowData1.length;
    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;

    this.statusConfig.success = 'Matched';
    this.statusConfig.error = 'Mismatch';
    this.statusConfig.new = 'Missing';
    this.statusConfig.warning = 'Pending';

    this.configureGrid();
    this.dataLoaded = true;
  }

  configureGrid() {
    this.columnDefs1 = [
      {headerName: "Invoice No.", field: "invoice_no", width: 80},
      {headerName: "Invoice Date", field: "invoice_date", width: 80},
      {headerName: "Supplier GSTIN", field: "supp_gstin", width: 80},
      {headerName: "Supplier Name", field: "supp_name", width: 80},
      {headerName: "Receiver GSTIN", field: "cust_gstin", width: 80},
      {headerName: "Receiver Name", field: "cust_name", width: 80},
      {headerName: "Place Of Supply", field: "state_code", width: 80},
      {headerName: "Taxable", field: "taxable_amount", width: 80, cellStyle: {textAlign: 'right'}, aggFunc: 'sum'},
      {
        headerName: "IGST", field: "igst_amt", width: 60, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data) {
            if (params.data.igst_amt) {
              return params.data.igst_amt;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: "CGST", field: "cgst_amt", width: 60, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data) {
            if (params.data.cgst_amt) {
              return params.data.cgst_amt;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: "SGST", field: "sgst_amt", width: 60, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data) {
            if (params.data.sgst_amt) {
              return params.data.sgst_amt;
            } else {
              return 0;
            }
          }
        }
      },
      {
        headerName: "Cess", field: "cess_amt", width: 60, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data) {
            if (params.data.cess_amt) {
              return params.data.cess_amt;
            } else {
              return 0;
            }
          }
        }
      },


    ];
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    params.api.setColumnDefs(this.columnDefs1);
    params.api.setRowData(this.rowData1);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi1) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);

    const searchtext = document.getElementById('filter-text-box')['value'];
    if (searchtext && searchtext !== "") {
      this.quickSearch();
    }
  }

  quickSearch() {
    const searchtext = document.getElementById('filter-text-box')['value'];
    this.gridApi1.setQuickFilter(searchtext);
  }

  onCellClicked1(event) {

  }

  generateOTP() {
    this.otpSent = true;
    this.toastr.success("OTP sent");
  }

  validateOTP() {
    this.pageReady = false;
    setTimeout(() => {
      this.pageReady = true;
      if (this.filing_details.otp === this.otp) {
        this.toastr.success("Authenticated Successfully");
        this.showStep1 = false;
        this.showStep2 = true;
        this.authenticated = true;
      } else {
        this.toastr.error("Invalid OTP");
        this.invalidOTP = true;
      }
    }, 500);

  }

  otpKeyEntered() {
    this.invalidOTP = false;
  }

  reconcileMatch() {
    this.pageReady = false;
    this.dataLoaded = false;
    setTimeout(() => {
      this.pageReady = true;
      this.toastr.success("Reconciled Successfully");
      this.generateGovData();
      this.columnDefs1.push(
        {headerName: "Taxable GOV", field: "taxable_amount_gov", width: 60, cellStyle: {textAlign: 'right'},  aggFunc: 'sum',
          cellRenderer(params) {
            if (params.data) {
              if (params.node.rowPinned === 'bottom') {
                return params.value;
              } else {
                if (!params.data.taxable_amount && params.data.taxable_amount_gov) {
                  return `<span style="font-weight: 600; color: #0762AD;">` + params.value + `</span>`
                } else if (params.data.taxable_amount === params.data.taxable_amount_gov) {
                  return `<span style="font-weight: 600; color:  #379862;">` + params.value + `</span>`
                } else {
                  return `<span style="font-weight: 600; color: #E24260;">` + params.value + `</span>`
                }
              }
            }
          }},
        {
          headerName: "IGST GOV", field: "igst_amt_gov", width: 60, cellStyle: {textAlign: 'right'},  aggFunc: 'sum',
          valueGetter(params) {
            if (params.data) {
              if (params.data.igst_amt) {
                return params.data.igst_amt;
              } else {
                return 0;
              }
            }
          },
          cellRenderer(params) {
            if (params.dat) {
              if (params.node.rowPinned === 'bottom') {
                return params.value;
              } else {
                if (!params.data.igst_amt && params.data.igst_amt_gov) {
                  return `<span style="font-weight: 600; color: #0762AD;">` + params.value + `</span>`
                } else if (params.data.igst_amt === params.data.igst_amt_gov) {
                  return `<span style="font-weight: 600; color:  #379862;">` + params.value + `</span>`
                } else {
                  return `<span style="font-weight: 600; color: #E24260;">` + params.value + `</span>`
                }
              }
            }
          }
        },
        {
          headerName: "CGST GOV", field: "cgst_amt_gov", width: 60, cellStyle: {textAlign: 'right'},  aggFunc: 'sum',
          valueGetter(params) {
            if (params.data) {
              if (params.data.cgst_amt) {
                return params.data.cgst_amt;
              } else {
                return 0;
              }
            }
          },
          cellRenderer(params) {
            if (params.data) {
              if (params.node.rowPinned === 'bottom') {
                return params.value;
              } else {
                if (!params.data.cgst_amt && params.data.cgst_amt_gov) {
                  return `<span style="font-weight: 600; color: #0762AD;">` + params.value + `</span>`
                } else if (params.data.cgst_amt === params.data.cgst_amt_gov) {
                  return `<span style="font-weight: 600; color:  #379862;">` + params.value + `</span>`
                } else {
                  return `<span style="font-weight: 600; color: #E24260;">` + params.value + `</span>`
                }
              }
            }
          }
        },
        {
          headerName: "SGST GOV", field: "sgst_amt_gov", width: 60, cellStyle: {textAlign: 'right'},  aggFunc: 'sum',
          valueGetter(params) {
            if (params.data) {
              if (params.data.sgst_amt) {
                return params.data.sgst_amt;
              } else {
                return 0;
              }
            }
          },
          cellRenderer(params) {
            if (params.data) {
              if (params.node.rowPinned === 'bottom') {
                return params.value;
              } else {
                if (!params.data.sgst_amt && params.data.sgst_amt_gov) {
                  return `<span style="font-weight: 600; color: #0762AD;">` + params.value + `</span>`
                } else if (params.data.sgst_amt === params.data.sgst_amt_gov) {
                  return `<span style="font-weight: 600; color:  #379862;">` + params.value + `</span>`
                } else {
                  return `<span style="font-weight: 600; color: #E24260;">` + params.value + `</span>`
                }
              }
            }
          }
        },
        {
          headerName: "Cess GOV", field: "cess_amt_gov", width: 60, cellStyle: {textAlign: 'right'},  aggFunc: 'sum',
          valueGetter(params) {
            if (params.data) {
              if (params.data.cess_amt) {
                return params.data.cess_amt;
              } else {
                return 0;
              }
            }
          },
          cellRenderer(params) {
            if (params.data) {
              if (params.node.rowPinned === 'bottom') {
                return params.value;
              } else {
                if (!params.data.cess_amt && params.data.cess_amt_gov) {
                  return `<span style="font-weight: 600; color: #0762AD;">` + params.value + `</span>`
                } else if (params.data.cess_amt === params.data.cess_amt_gov) {
                  return `<span style="font-weight: 600; color:  #379862;">` + params.value + `</span>`
                } else {
                  return `<span style="font-weight: 600; color: #E24260;">` + params.value + `</span>`
                }
              }
            }
          }
        },
        { headerName: "Status", field: "status", width: 60, cellRenderer: this.sharedService.statusCellRenderer }
      )
      this.matched = true;
      this.dataLoaded = true;
    }, 1000);
  }

  generateGovData() {
    if (this.period.period_name === 'November') {
      this.rowData1.forEach(row => {
        row.taxable_amount_gov = row.taxable_amount;
        row.igst_amt_gov = row.igst_amt;
        row.cgst_amt_gov = row.cgst_amt;
        row.sgst_amt_gov = row.sgst_amt;
        row.cess_amt_gov = row.cess_amt;
        row.status = 'Matched';
      })
    } else {

    }

    this.matchedRecords = this.rowData1.filter(x => x.status === 'Matched').length;
    this.mismatchedRecords = this.rowData1.filter(x => x.status === 'MisMatch').length;
    this.pendingRecords = this.rowData1.filter(x => x.status === 'Pending').length;
    this.missingRecords = this.rowData1.filter(x => x.status === 'Missing').length;
  }

  fileGST() {
    this.pageReady = false;
    setTimeout(() => {
      this.pageReady = true;
      this.toastr.success("GSTR2 Filed Successfully");
      setTimeout(() => {
        localStorage.setItem(this.period.period_name + 'gstr2_file_status', 'filed');
        const date = new Date();
        const file_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        localStorage.setItem(this.period.period_name + 'gstr2_file_date', file_date);
        localStorage.setItem(this.period.period_name + 'gstr2_file_ack', 'AA290121809003T');
        this.gstr2submitted.emit(true);
      }, 1000);
    }, 1000);
  }

}
