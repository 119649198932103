import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class BeatPartnerReportComponent extends ReportBase implements IReport, OnInit {

  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {

    this.sharedService.setHeader('Beat Partner Report');

    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.state = true;
    this.showableFilters.region = true;
    this.showDate = false;

    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/getBeatPartnerReport';
    const inThis = this;
    this.columnDefs = [

      {headerName: 'REGION', field: 'region_name', width: 80},
      {headerName: 'CLUSTER', field: 'cluster_name', width: 80},
      {headerName: 'ZONE', field: 'zone', width: 80},
      {headerName: 'STATE', field: 'state', width: 80},
      {headerName: 'TERRITORY', field: 'terr_name', width: 80},
      {headerName: 'SCODE', field: 'emp_code', width: 80},
      {headerName: 'SALESMAN', field: 'salesman', width: 80},
      {headerName: 'BEAT', field: 'beat', width: 80},
      {headerName: 'RCODE', field: 'r_code', width: 80},
      {headerName: 'PARTNER', field: 'r_name', width: 80},
      {headerName: 'PARTNER TYPE', field: 'r_type', width: 80},
      {headerName: 'PROGRAM TYPE', field: 'prg_type', width: 80},
      {headerName: 'CLASS', field: 'class', width: 80},
      {headerName: 'VISIT FREQUENCY', field: 'vf', width: 80},
      {headerName: 'GSTN', field: 'gstin', width: 80},
      {headerName: 'PAN', field: 'pan', width: 80},
      {headerName: 'MOBILE', field: 'mobile', width: 80},
      {headerName: 'LAT', field: 'lat', width: 80},
      {headerName: 'LNG', field: 'lng', width: 80},
      {headerName: 'CITY', field: 'city', width: 80},
      {headerName: 'ZIP', field: 'zip', width: 80},
      {headerName: 'CREATE DATE', field: 'created_on', width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.created_on) {
              return inThis.datePipe.transform((new Date(params.data.created_on + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.created_on;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'LAST UPDATED', field: 'write_date', width: 80,
        valueGetter: params => {
          if (params.data) {
            if (params.data.write_date) {
              return inThis.datePipe.transform((new Date(params.data.write_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.created_on;
            }
          } else {
            return '';
          }
        }
      },
      {headerName: 'STATUS', field: 'status', width: 80},

    ];

  }



}
