<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div id="overlay"></div>
      <div>
        <app-page-loader [show]="!pageReady"></app-page-loader>
      </div>
      <div class="col-md-12 top-card">
        <div>
          <div >
            <div class="search-list" style="margin-top: 0px;">
              <div class="col-md-12 pad0">
                <div class="col-md-6 col-12 nopadding pl-0" style="margin-bottom: 50px;margin-top: 0px;">
                  <div class="row" style="margin-top: 10px;">
                    <div *ngIf="editMode" class="button-row" style="margin-top: 10px;">
                      <button
                        mat-button
                        style="margin-right:15px;"
                        class="btn teal-btn"
                        (click)="saveDeliveryPlan()"
                        form="ngForm"
                      >
                        SAVE
                      </button>
                      <span>
                         <a class="btn white-btn" (click)="discard()">DISCARD</a>
                      </span>
                    </div>
                    <div *ngIf="!editMode" class="button-row" style="margin-top: 10px;">
                      <span>
                         <a class="btn white-btn" style="margin-right:15px;" (click)="editClick()">EDIT</a>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12 pad0 text-right" style="margin-bottom: 50px;margin-top: 10px;">
                </div>
              </div>
            </div>
            <div>
              <div class="col-md-12 pad0">
                <div class="col-md-10 pl-0 pr-30">
                  <form novalidate id="ngForm" #dpform="ngForm">
                    <div class="col-md-12 pl-0" style="margin-top: 15px;">
                      <div class="col-md-3 pl-0 pr-30">
                        <label style="color:#7D7D7D">PLAN NAME</label>
                        <div style="margin-top: 5px;">
                          <!-- <div *ngIf="edit">
                             <input
                               class="forminput"
                               style="width: 70%;"
                               type="text"
                               name="name"
                               [(ngModel)]="deliveryPlanData.name"
                               #name="ngModel"
                               required
                             >
                             <div *ngIf="name.errors && (name.dirty || name.touched)">
                               <span *ngIf="name.errors.required" class="text-danger">Plan name is required</span>
                             </div>
                           </div>-->
                          <p>{{activeObject.name}}</p>
                        </div>
                      </div>
                      <div class="col-md-3 pl-0 pr-30">
                        <label style="color:#7D7D7D">DELIVERED BY</label>
                        <div style="margin-top: 5px;">
                          <!-- <div *ngIf="edit">
                             <input
                               class="forminput"
                               style="width: 70%;"
                               type="text"
                               name="del_person"
                               [(ngModel)]="deliveryPlanData.del_person"
                               #del_person="ngModel"
                               required
                             >
                             <div *ngIf="del_person.errors && (del_person.dirty || del_person.touched)">
                               <span *ngIf="del_person.errors.required" class="text-danger">Delivered By is required</span>
                             </div>
                           </div>-->
                          <p>{{activeObject.van_name}}</p>
                        </div>
                      </div>
                      <div class="col-md-2 pl-0 pr-20">
                        <label style="color:#7D7D7D">DELIVERY DATE</label>
                        <div style="margin-top: 5px;">
<!--                          <div *ngIf="editMode">-->
<!--                            <input-->
<!--                              class="forminput"-->
<!--                              type="text"-->
<!--                              name="date"-->
<!--                              [(ngModel)]="activeObject.date"-->
<!--                              #date="ngModel"-->
<!--                              [owlDateTime]="dt1"-->
<!--                              [owlDateTimeTrigger]="dt1"-->
<!--                              required-->
<!--                            >-->
<!--                            <owl-date-time (afterPickerClosed)="setStartDate()" [pickerType]="'calendar'"-->
<!--                                           #dt1></owl-date-time>-->
<!--                            <div *ngIf="date.errors && (date.dirty || date.touched)">-->
<!--                              <span *ngIf="date.errors.required" class="text-danger">Delivery Date is required</span>-->
<!--                            </div>-->
<!--                          </div>-->
                          <p>{{activeObject.date}}</p>
                        </div>
                      </div>
                      <div class="col-md-2 pl-0 pr-30">
                        <label style="color:#7D7D7D">DRIVER NAME</label>
                        <div style="margin-top: 5px;">
                          <div *ngIf="editMode">
                            <input
                              class="forminput"
                              style="width: 70%;"
                              type="text"
                              name="del_person"
                              [(ngModel)]="activeObject.del_person"
                              #del_person="ngModel"
                              required
                            >
                            <div *ngIf="del_person.errors && (del_person.dirty || del_person.touched)">
                              <span *ngIf="del_person.errors.required" class="text-danger">Driver Name is required</span>
                            </div>
                          </div>
                          <p *ngIf="!editMode">{{activeObject.del_person}}</p>
                        </div>
                      </div>
                      <div class="col-md-2 pl-0 pr-30">
                        <label style="color:#7D7D7D">DRIVER CONTACT</label>
                        <div style="margin-top: 5px;">
                          <div *ngIf="editMode">
                            <input
                              class="forminput"
                              style="width: 70%;"
                              type="text"
                              name="del_contact"
                              [(ngModel)]="activeObject.del_contact"
                              #del_contact="ngModel"
                              required
                            >
                            <div *ngIf="del_contact.errors && (del_contact.dirty || del_contact.touched)">
                              <span *ngIf="del_contact.errors.required" class="text-danger">Driver Contact is required</span>
                            </div>
                          </div>
                          <p *ngIf="!editMode">{{activeObject.del_contact}}</p>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-12 pl-0" style="margin-top: 15px;">
                      <div class="col-md-3 pl-0 pr-30">
                        <label style="color:#7D7D7D">VEHICLE NUMBER</label>
                        <div style="margin-top: 5px;">
                          <div *ngIf="editMode">
                            <input
                              class="forminput"
                              style="width: 70%;"
                              type="text"
                              name="vehicle"
                              [(ngModel)]="activeObject.vehicle"
                              #vehicle="ngModel"
                              required
                            >
                            <div *ngIf="vehicle.errors && (vehicle.dirty || vehicle.touched)">
                              <span *ngIf="vehicle.errors.required" class="text-danger">Vehicle Number is required</span>
                            </div>
                          </div>
                          <p *ngIf="!editMode">{{activeObject.vehicle}}</p>
                        </div>
                      </div>
                      <div class="col-md-3 pl-0 pr-30">
                        <!--<label style="color:#7D7D7D">ROUTE</label>
                        <div style="margin-top: 5px;">
                          <div *ngIf="edit">
                            <input
                              class="forminput"
                              style="width: 70%;"
                              type="text"
                              name="beats"
                              [(ngModel)]="deliveryPlanData.beats"
                              #beats="ngModel"
                              required
                            >
                            <div *ngIf="beats.errors && (beats.dirty || beats.touched)">
                              <span *ngIf="beats.errors.required" class="text-danger">Route is required</span>
                            </div>
                          </div>
                          <p *ngIf="!edit">{{deliveryPlanData.beats}}</p>
                        </div>-->
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-md-2 pl-0 pr-30">
                  <div class="roundedcard">
                    <div style="padding: 10px;">
                      <div style="margin-bottom: 25px;">
                        <div>
                          <label style="float: left">Invoice Details</label>
                          <label *ngIf="editMode" (click)="openAddInvoicePopup()" style="color: #17A2B8; font-weight: 500; float: right; cursor: pointer">Add Invoices</label>
                        </div>
                      </div>
                      <div style="background-color: #F4F6FC;padding: 10px;margin-top: 10px;">
                        <div class="row">
                          <label style="color: #2D2D2D">Invoices Selected : </label>
                          <label style="color: #183153; font-weight: 500; padding-left: 3px;"> {{invoicecount}}</label>
                        </div>
                        <div class="row">
                          <label style="color: #2D2D2D">Grand Total : </label>
                          <label
                            style="color: #183153; font-weight: 500; padding-left: 3px;"> {{grandtotal.toFixed(2)}}</label>
                        </div>
                        <div class="row">
                          <label style="color: #2D2D2D">Weight : </label>
                          <label
                            style="color: #183153; font-weight: 500; padding-left: 3px;"> {{(weight / 1000).toFixed(2)}}
                            Kg</label>
                        </div>
                        <div class="row">
                          <label style="color: #2D2D2D">Volume : </label>
                          <label
                            style="color: #183153; font-weight: 500; padding-left: 3px;"> {{(volume / 1000).toFixed(2)}}
                            Ltr</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 pad0" style="margin-top: 15px;">
                <div class="search-list">
                  <div class="col-md-6 col-12 nopadding pl-0" style="margin-top: 30px;display: inline-block">
                    <mat-tab-group (selectedTabChange)="changeActiveTab($event)">
                      <mat-tab label="Invoices">
                      </mat-tab>
                      <mat-tab label="Picklist">
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                  <div class="col-md-6 col-12 pad0 text-right" style="margin-top: 30px;">
                    <div class="filters">
                      <ul class="list-unstyled">
                        <li class="margLR" style="margin-left: 10px;">
                          <div class="search" style="display: inline-block;">
                            <span class="search-control"><img src="../../../../../assets/searchicon.png"/></span>
                            <input id="filter-text-box" type="text" placeholder="filter" (input)="quickSearch()"
                                   class="search-control-area"
                                   placeholder="Search">
                          </div>
                        </li>
                        <li class="margLR" style="margin-left: 10px;" *ngIf="activetab==='Picklist'">
                          <app-excelicon [gridOption]="gridOptions" [title]="'Product Report'"></app-excelicon>
                        </li>
                        <li class="margLR" style="margin-left: 10px;" *ngIf="activetab==='Invoices'">
                          <app-excelicon [gridOption]="gridOptions1" [title]="'Invoice Report'"></app-excelicon>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div *ngIf="activetab==='Invoices'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
                  <div class="col-md-12 pad0" style="padding-right: 10px;">
                    <div class="eod-list-card">
                      <div id='printsectiondp2' class="eod-list-card-desc" style="width:100%">
                        <ag-grid-angular *ngIf="dataLoaded1" id="myGrid2" style="height: 38vh" class="ag-theme-balham"
                                         [masterDetail]="true"
                                         [columnDefs]="columnDefs1" [gridOptions]="gridOptions1"
                                         (gridReady)="onGridReady1($event)" [frameworkComponents]="frameworkComponents1"
                                         (cellClicked)="onCellClicked($event)"
                                         [context]="context1">
                        </ag-grid-angular>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="activetab==='Picklist'" class="col-md-12 col-12 nopadding pl-0" style="margin: 10px;">
                  <div class="col-md-12 pad0" style="padding-right: 10px;">
                    <div class="eod-list-card">
                      <div id='printsectiondp1' class="eod-list-card-desc" style="width:100%">
                        <ag-grid-angular *ngIf="dataLoaded" id="myGrid1" style="height: 38vh" class="ag-theme-balham"
                                         [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                                         (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents"
                                         [context]="context">
                        </ag-grid-angular>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="button-row" style="padding-top: 10px;display: block; background: white;">
                  <div style="float: left;margin-left: 15px;margin-bottom: 12px;">
                    <button
                      style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
                      mat-button
                      class="icon-btn-border blue-btn"
                      (click)="printInvoices()"
                    ><i class="fas fa-print"></i>
                      PRINT INVOICES
                    </button>
                    <button
                      style=" vertical-align:bottom;margin-top: 20px; margin-right: 15px;"
                      mat-button
                      class="icon-btn-border blue-btn"
                      (click)="printPicklist()"
                    ><i class="fas fa-print"></i>
                      PRINT PICKLIST
                    </button>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div #PrintingModal class="pol-modal pol-send invoice modal" id="PrintingModal" role="dialog">
  <div class="modal-dialog m1">
    <!-- Modal content-->
    <div class="modal-content m2">
      <div>
        <span class="close" data-dismiss="modal">
            <img src="../../assets/close.png">
          </span>
      </div>
      <div class="m3">

        <div id="print-section-sales">
          <div class="invoice"  *ngFor="let order_detail of all_orders">
            <app-picklist-print *ngIf="printType === 'Picklist'" [order_detail]="order_detail"></app-picklist-print>
            <app-print *ngIf="printType === 'Invoice'" [order_detail]="order_detail"></app-print>
          </div>
        </div>
      </div>

      <div style="margin-top:1%;padding-bottom: 3%;text-align: center;">
        <button [useExistingCss]="true"
                printSectionId="print-section-sales"
                ngxPrint >PRINT</button>
        <button
          #autoprint
          [hidden]="true"
          printSectionId="print-section-sales"
          ngxPrint>Print</button>
      </div>
    </div>
  </div>
</div>
<button #interPrint id="openModalButton1" [hidden]="true" data-toggle="modal" data-target="#PrintingModal"></button>

