import {Component} from "@angular/core";
import {BaseUpload} from "../../pagebase/base_upload/base_upload";

@Component({
  selector: 'app-task-planner-details-upload',
  templateUrl: '../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../pagebase/base_upload/base_upload.scss']

})

export class TaskPlannerDetailsUploadComponent extends BaseUpload {


  task_types = [];
  task_type: any = {};

  setViewParams() {
    this.header = 'Task Planner Details Upload';
    this.sampleText1 = 'DOWNLOAD SAMPLE TEMPLATE';

    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    this.task_type = this.task_types.find(x => x.id.toString() === this.dialogData.type_id.toString());
    this.excel_columns = [
      {C: 'A', H: 'Task ID', V: '1234', required: true},
      {C: 'B', H: 'Task Code', V: 'KA-RCW-OFF-BRAND-PROMOTION-F24Q1', required: true},
      {C: 'C', H: 'Task Name', V: 'Table for Everyone', required: false},
      {C: 'D', H: 'Start Date', V: '01-04-2024', required: false},
      {C: 'E', H: 'End Date', V: '30-06-2024', required: false},
    ];

    const inThis = this;
    if (this.task_type && this.task_type?.param_json?.params) {
      for (let i = 0; i < this.task_type.param_json.params.length; i++) {
        inThis.excel_columns.push({C: inThis.alphabets[i + 5], H: this.task_type.param_json.params[i].name, V: '', required:  this.task_type.param_json.params[i].required});
      }
    }
    this.step1Text = 'Download sample template for reference.';
    this.step2Text = 'Required fields are Task Code and Activity Code';
    this.actionText = 'Add Details to Existing Task Plan';
    this.baseData = this.sampleData1;
    this.dataLoaded = true;
  }

  sampleDownload1() {
    this.downloadSampleData('task_planner_details_template');
  }

  configureGrid() {
    this.API_URL = '/api/tasks/task_plan_details_upload';
    this.columnDefs = [
      {headerName: 'Task ID', field: 'task_id', width: 100},
      {headerName: 'Task Code', field: 'code', width: 250},
      {headerName: 'Task Name', field: 'name', width: 150},
      {headerName: 'Start Date', field: 'start_date', width: 150},
      {headerName: 'End Date', field: 'end_date', width: 150},
      {headerName: 'Cluster', field: 'cluster', width: 100, hide: true},
      {headerName: 'Category', field: 'category', width: 100, hide: true},
      {headerName: 'Brand', field: 'brand', width: 100, hide: true},
      {headerName: 'Channel', field: 'channel', width: 100, hide: true},
      {headerName: 'Type', field: 'type', width: 100, hide: true},
      {headerName: 'Task Name', field: 'name', width: 150, hide: true},
      {headerName: 'Activity Code', field: 'activity_code', width: 100, hide: true},
      {headerName: 'Growth Driver', field: 'growth_driver', width: 100, hide: true},
      {headerName: 'Description', field: 'description', width: 100, hide: true},
      {headerName: 'Quarter', field: 'quarter', width: 100, hide: true},
      {headerName: 'Instructions', field: 'instructions', width: 150, hide: true},
    ];
    const inThis = this;
    if (this.task_type && this.task_type?.param_json?.params) {
      this.task_type.param_json.params.forEach(param => {
        inThis.columnDefs.push({headerName: param.name, field: param.field, width: 100});
      });
    }
  }

  validate() {
    const inThis = this;
    this.validRecords = 0;
    this.errorRecords = 0;
    this.task_types = JSON.parse(localStorage.getItem('task_types'));
    this.task_type = this.task_types.find(x => x.id.toString() === this.dialogData.type_id.toString());
    this.uploaded_data.forEach(data => {
      const indrow: any = {};
      //indrow = {...data};
      indrow.name = data['Task Name'];
      if (!data['Task ID'] || data['Task ID'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Task ID Missing';
      } else {
        if (data['Task ID'] && !this.numPattern.test(data['Task ID'])) {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Invalid Task ID';
        } else {
          indrow.task_id = data['Task ID'];
        }
      }
      if (!data['Task Code'] || data['Task Code'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Task Code Missing';
      } else {
        indrow.code = data['Task Code'];
      }
      if (!data['Start Date'] || data['Start Date'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Start Date Missing';
      } else {
        indrow['start_date'] = this.sharedService.convertExcelStringToDate(data['Start Date']);
        if (!indrow['start_date'] || indrow['start_date'] === '') {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Invalid Start Date';
        }
      }
      if (!data['End Date'] || data['End Date'] === '') {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'End Date Missing';
      } else {
        indrow['end_date'] = this.sharedService.convertExcelStringToDate(data['End Date']);
        if (!indrow['end_date'] || indrow['end_date'] === '') {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Invalid End Date';
        }
      }
      if (indrow.valid === 'Invalid') {
        this.errorRecords++;
      } else {
        indrow.valid = 'Valid';
        if (inThis.task_type && inThis.task_type?.param_json?.params) {
          inThis.task_type.param_json.params.forEach(param => {
            indrow[param.field] = data[param.name];
            if (param.required) {
              if (!indrow[param.field] || indrow[param.field] === '') {
                indrow.valid = 'Invalid';
                indrow.errorMsg = param.name + ' Missing';
              } else {
                inThis.paramValidate(param, indrow);
              }
            } else {
              inThis.paramValidate(param, indrow);
            }
          });
        }
        this.validRecords++;
      }
      this.rowData.push(indrow);
    });
    this.totalRecords = this.rowData.length;
    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
    }
  }
  paramValidate(param, indrow) {
    if (!indrow[param.field] || indrow[param.field] === '') {
      return;
    } else if (param.type) {
      if (param.type === 'integer' && !this.numPattern.test(indrow[param.field])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid ' + param.name + ' ' + param.type + ' format';
      } else if (param.type === 'decimal' && !this.floatPattern.test(indrow[param.field])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid ' + param.name + ' ' + param.type + ' format';
      } else if (param.type === 'selection' && param.data && !param.data.includes(indrow[param.field])) {
        indrow.valid = 'Invalid';
        indrow.errorMsg = 'Invalid ' + param.name;
      } else if (param.type === 'date') {
        indrow[param.field] = this.sharedService.convertExcelStringToDate(indrow[param.field]);
        if (!indrow[param.field] || indrow[param.field] === '') {
          indrow.valid = 'Invalid';
          indrow.errorMsg = 'Invalid ' + param.name;
        }
      }
    } else {
      return;
    }
  }

  async upload() {
    this.uploadStarted = true;
    this.progress = 0;
    let index = 0;
    let lastIndex = 0;
    this.totalRecords = this.rowData.length;
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.data = [];
    this.completedRecords = 0;
    const validrecords = this.rowData.filter(x => x.valid === 'Valid');
    if (validrecords && validrecords.length > 0) {
      do {
        if (index + this.batchSize >= validrecords.length) {
          lastIndex = validrecords.length;
        } else {
          lastIndex = index + this.batchSize;
        }
        const batchData = validrecords.slice(index, lastIndex);
        const datatoprocess = [];
        batchData.forEach(batch => {
          const indrow: any = {};
          indrow.status = 'In Progress';
          indrow.start_date = batch.start_date;
          indrow.end_date = batch.end_date;
          indrow.uid = batch.uid;
          indrow.details = {};
          for (const [key, value] of Object.entries(batch)) {
            indrow[key] = value;
            if (key !== 'valid' && key !== 'code' && key !== 'name' && key !== 'start_date' && key !== 'end_date' && key !== 'task_id' && key !== 'uid') {
              indrow.details[key] = value;
            }
          }
          datatoprocess.push(indrow);
        });
        paramObject.data = datatoprocess;
        await this.progressUpdate(index, lastIndex, 'In Progress');
        const res = await this.apiService.postPromise(this.API_URL, paramObject);
        if (res.hasOwnProperty('results') && res['results'].status === 200) {
          this.errorRecords = 0;
          if (res.results.data.length > 0) {
            this.updateRowStatus(index, res.results.data);
            /*res.results.data.forEach(row => {
              this.updateRowStatus(row);
            });*/
          } else {
            await this.progressUpdate(index, lastIndex, 'Error');
          }
        } else {
          await this.progressUpdate(index, lastIndex, 'Error');
        }
        index = lastIndex;
      } while (index < validrecords.length);
      this.progress = 100;
      this.uploadCompleted = true;
      this.uploadCompleteEmit();
    } else {
      this.toastr.error("No Valid Records to upload");
    }
  }
}
