import {Component, ElementRef, Renderer2} from "@angular/core";
import {ICellRendererAngularComp} from "@ag-grid-community/angular";

@Component({
  selector: 'unlink-einvoice',
  template: `<span><div *ngIf="loader">
                        <img style="height: 24px;position: relative;left: 60px;" src="../assets/images/loader_icon.gif"/>
  </div><div *ngIf="enable_unlink"><button  class="btn red-btn" style="padding: 1px 20px; !important;" (click)="unlink()">Unlink</button></div></span>`,
})
export class UnlinkInvoiceComponent implements ICellRendererAngularComp {
  public params: any;
  public enable_unlink = false;
  public loader = false;

  constructor(private elRef: ElementRef,
              private renderer: Renderer2){}

  agInit(params: any): void {
    this.params = params;
    let field = 'status';
    if (this.params.data.hasOwnProperty('IRN Status')) {
      field = 'IRN Status';
    }
    if (this.params.data[field] === 'error') {
      this.enable_unlink = true;
    } else {
      this.enable_unlink = false;
    }
  }

  unlink() {

    this.loader = true;
    this.enable_unlink = false;
    this.params.context.componentParent.showConfirmDialog("Unlink Einvoice?", "Einvoice link will be removed for this Order", "Unlink", this.params.data, this.params.node);
  }

  refresh(): boolean {
    return false;
  }
}
