<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper dcvanlist">
      <div class="search-list">
        <div class="col-md-6 pad0">
          <div class="search">
            <span class="search-control"><img src="assets/searchicon.png"/></span>
            <input type="text" placeholder="filter" (keyup)="quickSearch()" [(ngModel)]="searchValue" class="search-control-area"
                   placeholder="Search for DC">
          </div>
          <div *ngIf="!dms_enable" style="width: 140px;
                      margin-top: 13%;
                      margin-left: 39%;;display: inline-block;">
            <ng-select [items]="itemList"
                       bindLabel="itemName"
                       multiple="true"
                       maxSelectedItems="1"
                       [(ngModel)]="selectedItems"
                       (ngModelChange)="dcChange($event)"
            >
            </ng-select>
          </div>
        </div>
        <div class="col-md-6 pad0">
          <div class="filters">
            <ul class="list-unstyled">
              <li class="margLR">
                <div class="date-picker">
                  <span class="datepicker-icon"><img src="../../assets/date.png"></span>
                  <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="myDate" [owlDateTimeTrigger]="dt" [owlDateTime]="dt" placeholder="Date">
                  <span class="dropdown-icon"><img src="../../assets/dropdown.png"></span>
                  <owl-date-time (afterPickerClosed)="changeDate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
                </div>
              </li>
              <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                <div style="display: inline-block;">
                  <button
                    class="btn confirm-blue-btn1"
                    style="height: 44px;margin-bottom: 10px;"
                    (click)="run()"
                  >GO
                  </button>
                </div>
              </li>
              <li class="margLR">
                <app-excelicon [gridOption]="gridOptions" [title]="'Sale Return'"></app-excelicon>
              </li>
              <li style="margin-top: 0px;margin-right: 1px !important;margin-left: 1px !important;" data-toggle="modal" data-target="#popupModal" class="margLR">
                <img src="assets/download%20.svg"/>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 pad0">
          <div class="component-container">
            <ag-grid-angular id="myGrid2" *ngIf="loaded" style="height: calc(100vh - 160px);" class="ag-theme-alpine"  [columnDefs]="columnDefs" [gridOptions]="gridOptions"
                             (gridReady)="onGridReady($event)" [frameworkComponents]="frameworkComponents">
            </ag-grid-angular>
            <div *ngIf="preloader">
              <app-loadingstate></app-loadingstate>
            </div>
            <div *ngIf="empty_state">
              <app-emptystate></app-emptystate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="approve-modal modal" id="popupModal" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <span class="close" data-dismiss="modal">
        <img (click)="close()" src="../../assets/close.png">
      </span>
      <div class="modal-body">
        <div class="approve-modal-heading">
          <label style="padding-bottom: 5%;">Large File Download</label>
          <!--          <h1> Kindly update reason</h1>-->
          <div class="date-picker">
            <span class="datepicker-icon"><img src="../../assets/date.png"></span>
            <input [selectMode]="'range'" [readonly]="true" [(ngModel)]="zipDate" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" placeholder="Date">
            <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
          </div>
        </div>
        <div *ngIf="all_dc_flag" #dp id="list1" class="dropdown-check-list" tabindex="100">
          <span (click)="dropDown()" class="anchor">Select DC</span>
          <ul class="items" *ngFor="let option of all_dc">
            <li><input [value]="option['id']" type="checkbox" [(ngModel)]="option.checked" />{{option['itemName']}}</li>
          </ul>
        </div>
        <select *ngIf="!all_dc_flag" style="width: 50%;" name="selectedZipDc" id="aantal" class="form-control" [(ngModel)]="selectedZipDc">
          <option *ngFor="let option of all_dc" [value]="option['id']" >{{option['itemName']}}</option>
        </select>
        <p style="font-size: medium" [style.color]="zipCaption[1] == 0 ? 'green' : 'red'">{{zipCaption[0]}}</p>
        <div class="approve-modal-content">
          <a *ngIf="!zip_loader" style="margin-top: 10%;" (click)="generateZip()" class="btn blue-btn">Generate</a>
          <p *ngIf="!zip_loader && zip_ready" style="margin-top: 8%;font-size: 140%;color: Green">Your request is in progress.Please check report logs after some time.</p>
          <div *ngIf="zip_loader">
            <img style="width:5%;margin-top: 10%;" src="assets/images/loader_icon.gif"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
