import {Component, OnInit} from "@angular/core";
import {PageBase} from "../pagebase/pagebase";
import {MatDialogConfig} from "@angular/material/dialog";
import {ImageGalleryPopupComponent} from "../components/image_gallery_popup/image_gallery_popup.component";
import {AssignServiceEngineerPopupComponent} from "./assign_service_engineer_popup/assign_service_engineer_popup.component";
import {CreateServiceRequestPopupComponent} from "./create_service_request/create_service_request.component";

@Component({
  selector: 'app-service-request-component',
  templateUrl: '../pagebase/pagebasev2.html',
  styleUrls: ['../pagebase/pagebase.scss']
})
export class ServiceRequestComponent extends PageBase implements OnInit {
  showCustomFilter1 = true;
  customFilter1: any = {
    name: 'Status',
    key: 'status',
    value: '',
    values: [],
    show: true
  };
  role_features = [];
  role = '';
  matActionMenuItemDeclaration = [
    {name: 'Assign Service Engineer', feature: 'feature_service_assign_service_engineer'}];

  ngOnInit() {
    this.sharedService.setHeader('Service Request');
    this.customFilter1.values = [
      {id: "", itemName: "All"},
      {id: "new", itemName: "New"},
      {id: "assigned", itemName: "Assigned"},
      {id: "in_progress", itemName: "In  Progress"},
      {id: "on_hold", itemName: "On Hold"},
      {id: "resolved", itemName: "Resolved"},
    ];

    this.tabIndex = 1;
    this.container = 'partner_asset';
    this.showCreate = this.sharedService.ACLcheck('feature_create_service_request');
    this.showActionButton = true;
    this.API_URL = '/api/service_requests/getServiceTicket';

    this.gridOptions.isRowSelectable = params => {
      if (!params.data.service_engineer) {
        return true;
      } else {
        return false;
      }
    };
    this.getAuthorizedActions();
    this.configureGrid();
    this.loadServerData();
  }

  configureGrid() {
    this.columnDefs = [
      {
        headerName: "SERVICE",
        children: [
          {
            headerName: 'ID', field: 'id', width: 80,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true
          },
          {
            headerName: 'PRIORITY', field: 'priority', width: 80,
            valueGetter(params) {
              if (params.data) {
                if (!params.data.priority || params.data.priority === '0') {
                  return 'No Priority';
                } else if (params.data.priority === '1') {
                  return 'Low';
                } else if (params.data.priority === '2') {
                  return 'Medium';
                } else if (params.data.priority === '3') {
                  return 'High';
                }
              }
            }
          },
          {headerName: 'SERVICE NO', field: 'serviceno', width: 50},
          {headerName: 'REQ DATE', field: 'requested_date', width: 50},
          {headerName: 'STATUS', field: 'service_status', width: 50},
          {headerName: 'TYPE', field: 'service_type', width: 80},
          {headerName: 'PURCHASED FROM', field: 'purchased_from', width: 80},
          {headerName: 'DISTRIBUTOR', field: 'dist_name', width: 80},
          {headerName: 'SOURCE', field: 'source', width: 80},
          {headerName: 'REJECT COMMENTS', field: 'reject_comments', width: 80}
        ]
      }, {
        headerName: "ENGINEER",
        children: [
          {headerName: 'NAME', field: 'service_engineer', width: 80},
          {headerName: 'TEAM', field: 'team', width: 80},
          {headerName: 'MOBILE', field: 'assigned_mobile', width: 80},
          {headerName: 'REGION', field: 'region', width: 80},
          {headerName: 'STATE', field: 'state', width: 80}
        ]
      }, {
        headerName: "ASSIGNED",
        children: [
          {headerName: 'DATE', field: 'assigned_on', width: 80},
          {headerName: 'USER', field: 'assigned_user', width: 80},
          {headerName: 'MOBILE', field: 'assigned_mobile', width: 80},
        ]
      }, {
        headerName: "REFERRER",
        children: [
          {headerName: 'TYPE', field: 'referrer_type', width: 80},
          {headerName: 'NAME', field: 'referrer_name', width: 80},
          {headerName: 'Mobile', field: 'referrer_mobile', width: 80},
        ]
      }, {
        headerName: "CUSTOMER",
        children: [
          {headerName: 'NAME', field: 'customer_name', width: 80},
          {headerName: 'MOBILE', field: 'customer_mobile', width: 50},
          {headerName: 'ADDRESS', field: 'address', hide: true, width: 80},
          {headerName: 'CITY', field: 'city', width: 80}
        ]
      }, {
        headerName: "CLOSURE",
        children: [
          {headerName: 'DATE', field: 'service_closed', width: 80},
          {headerName: 'IMAGES', field: 'image_count', width: 80},
          {headerName: 'TAT(DAYS)', field: 'closing_days', width: 50},
          {headerName: 'DELAY FROM', field: 'delayed_on', width: 80},
          {headerName: 'DELAY REASON', field: 'delayed_reason', hide: true, width: 80},
          {headerName: 'OTP VERIFIED', field: 'otp_verified', width: 80},
          {headerName: 'FSR REPORT', field: 'fsr_report', width: 80},
          {headerName: 'SE COMMENTS', field: 'se_comments', width: 80},
          {headerName: 'CUSTOMER COMMENTS', field: 'customer_comments', width: 80},
          {headerName: 'CLOSING REASON', field: 'closing_reason', width: 80},
          {headerName: 'AMOUNT', field: 'service_amt', width: 80},
          {headerName: 'RCA', field: 'rca', width: 80},
          {headerName: 'PREVENTION', field: 'prevention', width: 80},
          {headerName: 'CORRECTION', field: 'correction', width: 80},
        ]
      },
    ];
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    const filteredRows = [];
    const row = filteredRowData.find(x => x.id === event.data.id);
    if (column === 'image') {
      if (row.image_count && parseInt(row.image_count, 10) > 0) {
        this.showImagePopup(row);
      }
    } else {
      for (const key in filteredRowData) {
        filteredRows.push(filteredRowData[key].data);
      }
      if (filteredRows !== null && filteredRows.length > 0) {
        this.storeServiceData(filteredRows);
      }
      localStorage.setItem('id', event.data.id);
      const index = JSON.parse(localStorage.getItem("service_ids")).indexOf(event.data.id);
      localStorage.setItem('index', index);
      this.router.navigate([this.apiService.redirect_url + '/service/request/details'], {
        state: {
          viewtype: 'detail',
          id: event.data.id
        }
      });
    }
  }

  storeServiceData(data) {
    const ids = [];
    for (const key in data) {
      ids.push(data[key].id);
    }
    localStorage.setItem('service_ids', JSON.stringify(ids));
  }

  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Service Request');
    } else {
      if (menuitem.name === 'Assign Service Engineer') {
        this.openAssignServiceEngineerPopup(rows);
      }
    }
  }

  showImagePopup(row) {
    const gallery = [];
    row.gallery.forEach(image => {
      gallery.push({
        store_fname: image,
        container: 'asset_gallery'
      });
    });
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          gallery
        }
      };

      const creatediaeref = this.dialog.open(ImageGalleryPopupComponent, config);
    }
  }

  openCreateDialog() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "650px",
        height: "auto",
        maxHeight: '100%',
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(CreateServiceRequestPopupComponent, config);
      creatediaeref.componentInstance.createServiceEvent.subscribe(data => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container'
      };
      const creatediaeref = this.dialog.open(CreateServiceRequestPopupComponent, config);
      creatediaeref.componentInstance.createServiceEvent.subscribe(data => {
        this.loadServerData();
      });
    }
  }
  validateServiceEngineerAssignRows(rows) {
    let valid = true;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i]?.se_id) {
        valid = false;
        break;
      }
    }
    return valid;
  }
  openAssignServiceEngineerPopup(rows) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "650px",
        height: "auto",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          rows
        }
      };
      const creatediaeref = this.dialog.open(AssignServiceEngineerPopupComponent, config);
      creatediaeref.componentInstance.serviceExeAssignedEvent.subscribe(data => {
        this.loadServerData();
      });
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
          rows
        }
      };
      const creatediaeref = this.dialog.open(AssignServiceEngineerPopupComponent, config);
      creatediaeref.componentInstance.serviceExeAssignedEvent.subscribe(data => {
        this.loadServerData();
      });
    }
  }

}
