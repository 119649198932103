<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>{{title}}</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <div class="mt-4">
          <span class="dialoglabel">{{message1}}</span>
          <div style="margin-top: 10px;">
            <ng-select [items]="selectData"
                       appendTo="body"
                       bindLabel="{{bindLabel}}"
                       bindValue="{{bindValue}}"
                       class="custom"
                       (ngModelChange)="itemChange($event)"
                       [(ngModel)]="selectedItem">
              >
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="button-row" style="float: right; margin: 15px 30px 15px 0px;">
      <button
        mat-button
        style=" margin-right:15px;"
        class="btn grey-btn"
        (click)="closeDialog()"
      >
        Cancel
      </button>
      <button
        mat-button
        class="btn confirm-blue-btn"
        [disabled]="!selectedItem"
        (click)="performAction()"
      >
        {{yesButton}}
      </button>
    </div>
  </div>
</section>
