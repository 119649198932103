import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {DatePipe} from "@angular/common";
import {GridOptions} from "@ag-grid-enterprise/all-modules";
import {SharedService} from "../../_services/shared_service";

@Component({
  selector: 'app-gstr1-popup',
  templateUrl: './gstr1_popup.component.html',
  styleUrls: ['./gstr1_popup.component.scss']
})

export class GSTR1PopupComponent implements OnInit {
  @Output() gstr1submitted: EventEmitter<any> = new EventEmitter<any>();
  period;
  file_status;
  showStep1 = true;
  showStep2 = false;
  showStep3 = false;
  filing_details: any = {};
  pan = 'AHAHY1257Y'
  tax_payer = 'Madhuri stores private limited';
  otpSent = false;
  otp = 253950;
  invalidOTP = false;
  authenticated = false;
  gstrSaved = false;
  pageReady = true;
  summaryData = [];

  searchValue: any;
  gridOptions1: GridOptions;
  columnDefs1 = [];
  frameworkComponents1: any = {};
  rowData1 = [];
  public gridColumnApi1: any;
  public gridApi1: any;
  preloader: any;
  dataLoaded: any;

  apiDetails = {
    api_key: '',
    gstin: '',
    app_key: '',
    authtoken: '',
    sek: '',
    rtnprd: ''
  }
  supplier_id;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private datePipe: DatePipe,
              private sharedService: SharedService,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.period = dialogdata.period;
    this.file_status = dialogdata.file_status;
    this.summaryData = dialogdata.summaryData;
    this.rowData1 = dialogdata.summaryData;
    this.supplier_id = dialogdata.supplier_id;
    this.gridOptions1 = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        enableRowGroup: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
      /* onColumnVisible: this.onColumnVisible,
       onDragStopped : this.onDragStopped*/
    } as GridOptions;


    this.apiDetails.rtnprd = this.datePipe.transform(this.period.start_date, 'MMyyyy');
    console.log(this.apiDetails.rtnprd);

    this.configureGrid();
    this.dataLoaded = true;
  }

  configureGrid() {
    this.columnDefs1 = [
      {headerName: "Description", field: "desc", width: 200},
      {headerName: "Total Invoices", field: "tot_inv", width: 100, aggFunc: 'sum'},
      {headerName: "Taxable", field: "tot_taxable", width: 100, aggFunc: 'sum'},
      {headerName: "IGST", field: "igst_amt", width: 100, aggFunc: 'sum'},
      {headerName: "CGST", field: "cgst_amt", width: 100, aggFunc: 'sum'},
      {headerName: "SGST", field: "sgst_amt", width: 100, aggFunc: 'sum'},
      {headerName: "CESS", field: "cess_amt", width: 100, aggFunc: 'sum'},
      {headerName: "Invoice Value", field: "tot_amt", width: 100, aggFunc: 'sum'},
    ];
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onGridReady1(params) {
    this.gridOptions1.api.showLoadingOverlay();
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
    params.api.setColumnDefs(this.columnDefs1);
    params.api.setRowData(this.rowData1);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (this.gridApi1) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      params.api.sizeColumnsToFit();
    } else {
      params.columnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(params.api, params.columnApi);
      params.api.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);

    const searchtext = document.getElementById('filter-text-box')['value'];
    if (searchtext && searchtext !== "") {
      this.quickSearch();
    }
  }

  quickSearch() {
    const searchtext = document.getElementById('filter-text-box')['value'];
    this.gridApi1.setQuickFilter(searchtext);
  }

  onCellClicked1(event) {

  }

  generateOTP() {
    this.pageReady = false;

    setTimeout(() => {
      this.toastr.success("OTP sent");
      this.pageReady = true;
      this.otpSent = true;
    }, 1000);
    /*const paramObject = {};
    paramObject['access_token'] = localStorage.getItem('resfreshToken');
    this.apiService.post('/api/gstn_gateways/request_otp', paramObject)
      .subscribe(async res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.toastr.success("OTP sent");
          this.apiDetails.api_key = res['results'].data.api_key;
          this.apiDetails.gstin = res['results'].data.gstin;
          this.apiDetails.app_key = res['results'].data.app_key;
        } else {
          this.toastr.error("Failed to send OTP");
        }
      });*/
  }

  validateOTP() {
    this.pageReady = false;
    setTimeout(() => {
      this.pageReady = true;
      if (this.filing_details.otp === this.otp) {
        this.toastr.success("Authenticated Successfully");
        this.showStep1 = false;
        this.showStep2 = true;
        this.authenticated = true;
      } else {
        this.toastr.error("Invalid OTP");
        this.invalidOTP = true;
      }
    }, 500);
    /*const paramObject = {};
    paramObject['access_token'] = localStorage.getItem('resfreshToken');
    paramObject['token'] = this.apiDetails.api_key;
    paramObject['gstin'] = this.apiDetails.gstin;
    paramObject['ref_key'] = localStorage.getItem('company_key');
    paramObject['username'] = this.filing_details.username;
    paramObject['otp'] = this.filing_details.otp;

    this.apiService.post('/api/gstn_gateways/get_authentication', paramObject)
      .subscribe(async res => {
        console.log(res);
        this.pageReady = true;
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.toastr.success("Authenticated Successfully");
          this.apiDetails.authtoken = res['results'].data.authtoken;
          this.apiDetails.sek = res['results'].data.sek;
          this.showStep1 = false;
          this.showStep2 = true;
          this.authenticated = true;
        } else {
          this.toastr.error("Failed to send OTP");
          this.invalidOTP = true;
        }
      });*/

  }

  otpKeyEntered() {
    this.invalidOTP = false;
  }

  saveGST() {
    this.pageReady = false;
    setTimeout(() => {
     this.toastr.success("GSTR1 Saved Successfully");
      this.pageReady = true;
      this.gstrSaved = true;
      this.showStep2 = false;
      this.showStep3 = true;
    }, 1000);
    /*const paramObject = {};
    paramObject['access_token'] = localStorage.getItem('resfreshToken');
    paramObject['payload'] = {
      "gstin": "27ABACS0877M1ZW",
      "fp": "112024",
      "gt": 3782969.01,
      "cur_gt": 3782969.01,
      "b2b": [
        {
          "ctin": "01AABCE2207R1Z5",
          "inv": [
            {
              "inum": "S008400",
              "idt": "18-11-2024",
              "val": 729248.16,
              "pos": "06",
              "rchrg": "N",
              "etin": "01AABCE5507R1C4",
              "inv_typ": "R",
              "diff_percent": 0.65,
              "itms": [
                {
                  "num": 1,
                  "itm_det": {
                    "rt": 5,
                    "txval": 10000,
                    "iamt": 325,
                    "csamt": 500
                  }
                }
              ]
            }
          ]
        }
      ]
    }
    paramObject['gstin'] = this.apiDetails.gstin;
    paramObject['rtnprd'] = this.apiDetails.rtnprd;
    paramObject['username'] = this.filing_details.username;
    paramObject['app_key'] = this.apiDetails.app_key;
    paramObject['authtoken'] = this.apiDetails.authtoken;
    paramObject['sek'] = this.apiDetails.sek;

    this.apiService.post('/api/gstn_gateways/save_gstr1', paramObject)
      .subscribe(async res => {
        console.log(res);
        this.pageReady = true;
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.toastr.success("GSTR1 Saved Successfully");
          this.gstrSaved = true;
          this.showStep2 = false;
          this.showStep3 = true;
          //localStorage.setItem(this.period.period_name + 'gstr1_file_ack', res['results'].data.reference_id);
        } else {
          this.toastr.error("Failed to save GSTR1");
        }
      });*/
  }

  fileGST() {
    this.pageReady = false;
    setTimeout(() => {
      this.pageReady = true;
      this.toastr.success("GSTR1 Filed Successfully");
      setTimeout(() => {
        localStorage.setItem(this.period.period_name + this.supplier_id + 'gstr1_file_status', 'filed');
        const file_date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        localStorage.setItem(this.period.period_name + this.supplier_id + 'gstr1_file_date', file_date);
        let val = Math.floor(1000 + Math.random() * 9000);
        if (this.supplier_id = 23534) {
          val = 4238
        }
        localStorage.setItem(this.period.period_name + this.supplier_id + 'gstr1_file_ack', 'AA290121809' + val.toString());
        this.gstr1submitted.emit(true);
      }, 1000);
    }, 1000);
  }

}
