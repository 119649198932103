<section class="dialog-section">
  <div class="container-padding">
    <div class="header-section">
      <button (click)="closeDialog()" class="close-dialog">
        <i class="fal fa-times fa-2x"></i>
      </button>
      <h2>Create New Retail Asset Request</h2>
    </div>
    <div class="body-section w-100" style="background-color: #F5F7F9; padding: 20px 15px;">
      <div>
        <ol class="steps">
          <li id="li_retailer" class="done" style="width:120px;"><span style="margin-right:-30px;">Retailer Details</span></li>
          <li id="li_asset" class="todo" style="width:200px;"><span style="margin-right:-30px;">Asset Details</span></li>
          <li id="li_po" class="todo" style="width:200px;"><span style="margin-right:-30px;">PO Details</span></li>
        </ol>
        <div style="margin-top: 65px;padding-top: 20px;border-top: 1px solid #E1E5EC;">
          <div *ngIf="state === 'retailer'">
            <div class="row">
              <span class="dialogsteplabel">Step 1: </span>
              <span class="dialogstepspan" style="margin-left: 5px;"> Retailer Details</span>
            </div>
            <div class="row" style="margin-top: 20px;">
              <span class="dialogstepspan" style="margin-right: 5px;color: #0762AD;">{{selectedRetailerCount}}</span>
              <span class="dialogsteplabel"> Retailers Selected</span>
            </div>
            <div class="row" style="margin-top: 20px; cursor: pointer;" (click)="bulkAddRetailerPopup()" >
              <img style="height: 16px;cursor: pointer;" src="../../../assets/additem.svg"/>
              <span style="color: #0762AD;cursor: pointer;font-size: 12px; font-weight: 600;margin-left: 6px;">Bulk Retailer Select</span>
            </div>
          </div>
          <div *ngIf="state === 'asset'">
            <div class="row">
              <span class="dialogsteplabel">Step 2: </span>
              <span class="dialogstepspan" style="margin-left: 5px;"> Asset Details</span>
            </div>
            <div style="margin-top: 20px">
              <span class="dialoglabel">Asset Type</span>
              <div style="margin-top: 10px;">
                <ng-select [items]="assetTypeList"
                           bindLabel="name"
                           bindValue="id"
                           class="custom"
                           (ngModelChange)="assetTypeChange($event)"
                           [(ngModel)]="assetDetails.type">
                  >
                </ng-select>
              </div>
            </div>
            <div style="margin-top: 20px">
              <span class="dialoglabel">Brand</span>
              <div style="margin-top: 10px;">
                <ng-select [items]="productBrandList"
                           bindLabel="name"
                           bindValue="id"
                           class="custom"
                           (ngModelChange)="assetBrandChange($event)"
                           [(ngModel)]="assetDetails.brand_id">
                  >
                </ng-select>
              </div>
            </div>
          </div>
          <div *ngIf="state === 'po'">
            <div class="row">
              <span class="dialogsteplabel">Step 3: </span>
              <span class="dialogstepspan" style="margin-left: 5px;"> PO Details</span>
            </div>
            <div style="margin-top: 20px;">
              <span class="dialoglabel">PO Number</span>
              <span class="requiredspan"> *</span>
              <input
                class="forminputwhitebg"
                style="display: block; font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="po_no"
                [(ngModel)]="assetDetails.po_no"
                #po_no="ngModel"
                placeholder="Enter PO Number"
                required
              >
              <div *ngIf="po_no.errors && (po_no.dirty || po_no.touched)">
                <span *ngIf="po_no.errors.required" class="text-danger">PO Number is required</span>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <span class="dialoglabel">PO Date</span>
              <div class="date-picker-form">
                <input [selectMode]="'single'"
                       [readonly]="true" [(ngModel)]="assetDetails.po_date"
                       [owlDateTimeTrigger]="dt"
                       [owlDateTime]="dt" placeholder="Date"
                       name="date"
                       #date="ngModel"
                       style="margin-top: 5px;width: 100%;">
                <div style=" position: absolute;right: 20px;top: 12px;">
                  <span style="padding-left: 5px;"><i class="far fa-calendar-alt"></i></span>
                </div>
                <owl-date-time (afterPickerClosed)="changePODate(dt)" [pickerType]="'calendar'" #dt></owl-date-time>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <span class="dialoglabel">PO Amount</span>
              <span class="requiredspan"> *</span>
              <input
                class="forminputwhitebg"
                style="display: block; font-size: 14px; margin-top: 5px;width: 100%;"
                type="text"
                name="po_amount"
                [(ngModel)]="assetDetails.po_amount"
                #po_amount="ngModel"
                placeholder="Enter PO Amount"
                required
              >
              <div *ngIf="po_amount.errors && (po_amount.dirty || po_amount.touched)">
                <span *ngIf="po_amount.errors.required" class="text-danger">PO Amount is required</span>
              </div>
            </div>
            <!--<div class="mt-4">
              <input
                type="checkbox"
                name="name"
                class="mr-3"
                [(ngModel)]="assetDetails.pre_approve_feasability"
                #einv="ngModel"
                style="height: 16px;width: 16px;vertical-align: middle;"
              >
              <label class="dialoglabel" style="display: inline-block;margin-top: 4px; vertical-align: middle">Feasability Pre Approved</label>
            </div>
            <div class="mt-2">
              <input
                type="checkbox"
                name="name"
                class="mr-3"
                [(ngModel)]="assetDetails.pre_approve_budget"
                #einv="ngModel"
                style="height: 16px;width: 16px;vertical-align: middle;"
              >
              <label class="dialoglabel" style="display: inline-block;margin-top: 4px; vertical-align: middle">Budget Pre Approved</label>
            </div>-->
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-row" style="margin-top: 20px;">
      <div *ngIf="state !== 'retailer'" style="display: inline-block; left: 0px;">
        <button
          mat-button
          style=" margin-left:15px;"
          class="btn blue-border-btn"
          (click)="backClick()"
        >
         Go Back
        </button>
      </div>
      <div style="position: absolute;display: inline-block; right: 0px;">
        <button
          mat-button
          style=" margin-right:15px;"
          class="btn grey-btn"
          (click)="closeDialog()"
        >
          Cancel
        </button>
        <button
          *ngIf="state!=='po'"
          mat-button
          style=" margin-right:15px;"
          type="submit"
          [disabled] = "(state === 'asset' && (!assetDetails.type || !assetDetails.brand_id) || (state === 'retailer' && (!retailerList || retailerList.length === 0)))"
          class="btn confirm-blue-btn"
          (click)="nextClick()"
        >
          Next
        </button>
        <button
          *ngIf="state === 'po'"
          mat-button
          style=" margin-right:15px;"
          type="submit"
          [disabled] = "!assetDetails.po_no || !assetDetails.po_date || !assetDetails.po_amount"
          class="btn confirm-blue-btn"
          (click)="createAssetRequest()"
        >
          Create
        </button>
      </div>

    </div>
  </div>
</section>
