import {Component, OnInit, ElementRef, Renderer2, ComponentFactoryResolver, ViewChild} from '@angular/core';
import { GridOptions } from "../../../node_modules/@ag-grid-enterprise/all-modules";
import { ApiService} from "../_services/api.service";
import { Router, ActivatedRoute  } from '@angular/router';
import { ColorCellRenderer } from "../colorCell.component";
import { DatePipe } from '@angular/common';
import {SharedService} from "../_services/shared_service";

@Component({
  selector: 'app-outlet-aggregate-product-report',
  templateUrl: './outlet-aggregate-product-report.component.html',
  styleUrls: ['./outlet-aggregate-product-report.component.scss']
})
export class OutletAggregateProductReportComponent implements OnInit {
  @ViewChild('dp') dp: ElementRef<HTMLElement>;
  user;
  res_data;
  user_data = {
    "start_date":null,
    "access_token": null,
    "url": null,
    "end_date": null,
    "offset": null,
    dc_id: 0,
    out_type: 0,
    zip_flag: false
  };

  private gridApi;
  private gridColumnApi;
  public columnDefs = [];
  public searchValue;
  private pinnedBottomRowData;
  private pinnedTottomRowData;
  public frameworkComponents;
  public rowData = [];
  public navigate;
  public defaultColDef;
  public sideBar;
  private rowHeight;
  public gridOptions: GridOptions;
  public datavalue;
  public data = [];
  public loaded = false;
  public preloader = false;
  public empty_state = false;
  public title_name: string = "DC LIST";
  public styleGrid;
  public myDate = [];
  public collapse_disable;
  public report_flag;
  public default = true;
  public dcList = [{id: 0, itemName: 'DC', checked: false}];
  public selectedDC = [{id: 0, itemName: 'DC'}];
  public typeList = [{id: 0, itemName: 'Out Type'}];
  public selectedType = [{id: 0, itemName: 'Out Type'}];
  public zipDate = [];
  public zip_loader = false;
  public zip_ready = false;
  public zipCaption = ["", 0];
  public selectedZipDc = "0";
  public zip_url = ""
  public all_dc_flag = false;
  public role_features = [];
  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef:ElementRef,
              private sharedService: SharedService,
              private renderer: Renderer2,
              private datePipe: DatePipe,
              private componentFactoryResolver: ComponentFactoryResolver) {

    this.myDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.myDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.zipDate[0] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.zipDate[1] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.user_data.start_date = this.myDate[0];
    this.user_data.end_date = this.myDate[1];
    this.user_data.access_token = localStorage.getItem('resfreshToken');
    this.user_data.url = localStorage.getItem('private_url');
    this.report_flag = this.route.snapshot.params["report_flag"];
    this.dcList = this.dcList.concat(JSON.parse(localStorage.getItem('all_dcs')));
    this.typeList = this.typeList.concat(JSON.parse(localStorage.getItem('all_out_types')));
    this.role_features = JSON.parse(localStorage.getItem('role_features')).web_module_ids.filter(function (existed) {
      return 'feature_all_dc_filter' === existed.name;
    });

    if (this.role_features.length > 0) {
      this.all_dc_flag = true;
    }

    localStorage.setItem('user_data', JSON.stringify(this.user_data));

    this.gridOptions = <GridOptions>{ rowHeight: 28,
      rowStyle: { 'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left' },
      rowSelection: 'single',
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressHorizontalScroll: false,
      suppressAggFuncInHeader: true,
      pivotMode: true,
      groupDefaultExpanded: 9,
      groupHideOpenParents: true,
      groupMultiAutoColumn: true,
      autoGroupColumnDef: {
        cellClass: this.getIndentClass,
        minWidth: 250,
        flex: 1,
        cellRendererParams: {
          suppressCount: true
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      }};

  }

  dcChange(event) {
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.dc_id = event[0].id;
      } else {
        this.user_data.dc_id = 0;
      }
    }
  }

  typeChange(event) {
    if (event.length > 0) {
      if (event[0].id !== 0) {
        this.user_data.out_type = event[0].id;
      } else {
        this.user_data.out_type = 0;
      }
    }
  }

  rowGroupCallback(params) {
    return params.node.key;
  }

  getIndentClass(params) {
    var indent = 0;
    var node = params.node;
    while (node && node.parent) {
      indent++;
      node = node.parent;
    }
    return ['indent-' + indent];
  }

  onBtnExportDataAsExcel() {
    this.gridOptions.api.exportDataAsExcel({
      processRowGroupCallback: this.rowGroupCallback,
    });
  }

  run() {
    var oneDay = 24 * 60 * 60 * 1000;
    var firstDate = this.myDate[0];
    var secondDate = this.myDate[1];
    var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    if (diffDays > 0) {
      window.alert('Please select 1 Day Data ');
    }else {
      this.outprodreport();
    }

  }

  dropDown() {

    let el: HTMLElement = this.dp.nativeElement;
    if (el.classList.contains('visible'))
      this.renderer.removeClass(el, 'visible');
    else
      this.renderer.addClass(el, 'visible');
  }

  close() {
    this.zip_loader = false;
    this.zip_ready = false;
  }

  generateZip() {
    debugger;
    this.zip_loader = true;
    this.zip_ready = false;
    this.user_data.zip_flag = true;
    this.user_data.start_date = this.datePipe.transform(this.zipDate[0], 'yyyy-MM-dd');
    this.user_data.end_date = this.datePipe.transform(this.zipDate[1], 'yyyy-MM-dd');
    if (this.all_dc_flag) {
      this.user_data.dc_id = this.dcList.reduce((a, o) => (o.checked && a.push(o.id), a), [])[0];
    }else {
      this.user_data.dc_id = parseInt(this.selectedZipDc,10);
    }
    var oneDay = 24 * 60 * 60 * 1000;
    var firstDate = this.zipDate[0];
    var secondDate = this.zipDate[1];
    var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    if (this.user_data.dc_id == 0 && diffDays > 8) {
      window.alert('Please Select 1 week data');
      this.zip_loader = false;
    } else if (diffDays > 30) {
      window.alert('Please Select 1 month data');
      this.zip_loader = false;
    } else if (!this.all_dc_flag && parseInt(this.selectedZipDc, 10) == 0 && diffDays > 7) {
      window.alert('Please Select 1 week data for all DC');
      this.zip_loader = false;
    } else {
      this.res_data = this.apiService.outProdReport(this.user_data);
      this.res_data.subscribe(res => {
        if (res.hasOwnProperty('results') && (res.results.status == 200)) {
          this.zip_ready = true;
        }
        this.zip_loader = false;
      }, error => {
        console.log(error);
        this.zip_loader = false;
      });
    }
  }

  outprodreport() {
    this.loaded = false;
    this.preloader = true;
    this.empty_state = false;
    this.columnDefs = [];
    let rowdata = [];
    let datePipe = this.datePipe;
    this.user_data.zip_flag = false;

    this.res_data = this.apiService.outProdReport(this.user_data);
    this.res_data.subscribe(res => {
        console.log(res);
        if (res.hasOwnProperty('results') && (res['results'].status == 200) && (res['results'].data.length > 0)) {
          res['results'].data.forEach(function (data) {
            var pushData = {
              "DC": data.dc,
              "Beat": data.beat,
              "Code": data.code,
              "Outlet": data.outlet,
              "Channel": data.channel,
              "Type": data.out_type,
              "Status": data.out_status,
              "Total PC": parseInt(data.total_pc,10),
              "Sales Call": parseInt(data.sales_call,10),
              "Return Calls": parseInt(data.return_calls,10),
              "Product": data.product,
              "Category": data.category,
              "Sale QTY": parseInt(data.sale_qty,10),
              "Sales": parseFloat(data.sale_amt),
              "Grand Total": parseFloat(data.grand_total.toFixed(3)),
              "Replace QTY": parseInt(data.damage_qty,10),
              "Replace Sale": parseFloat(data.damage_amt),
              "Return QTY": parseInt(data.return_qty,10),
              "Return Sale": parseFloat(parseFloat(data.return_amt).toFixed(3))
            };

            rowdata.push(pushData);
          });

          this.data = rowdata[0];
          this.rowData = rowdata;


          for (var key in this.data) {
            if (this.data.hasOwnProperty(key)) {
              // this.columnDefs.push({
              //   headerName: key, field: key, sortable: true, filter: true, resizable: true,
              //   enableRowGroup: true,enablePivot: true,enableValue: true,aggFunc: 'sum'
              // });
              if (key == 'DC' || key == 'Beat' || key == 'Code' || key == 'Outlet' || key == 'Type' || key == 'Status' || key == 'Channel' ) {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: true,  resizable: true,
                  enableRowGroup: true,rowGroup: true,width:70,pinned: 'left'
                });
              }else if (key == 'Sale QTY' || key == 'Sales' || key == 'Replace QTY' || key == 'Replace Sale' || key == 'Grand Total' ||
                key == 'Return QTY' || key == 'Return Sale') {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: true, resizable: true,width:100,
                  aggFunc: 'sum',enableValue: true
                });
              }else if (key == 'Product') {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: true, resizable: true,width:120,
                  enablePivot: true,pivot: true
                });
              }else if (key == 'Category') {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: true, resizable: true,width:120,
                  enablePivot: true,pivot: false
                });
              }else if(key == 'Total PC' || key == 'Sales Call' || key == 'Return Calls') {
                this.columnDefs.push({
                  headerName: key, field: key, sortable: true, filter: true, resizable: true,width:100,
                  enableRowGroup: true,rowGroup: true
                });
              }
            }
          }
          this.loaded = true;

        }else {
          this.preloader = false;
          this.empty_state = true;
        }

      },
      error => {
        console.log(error);
        this.preloader = false;
      });
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.setRowData(this.rowData);
    this.preloader = false;

    if (screen.width > 991) {
      // this.gridOptions.api.sizeColumnsToFit();
    }
    else {
      this.gridColumnApi.autoSizeColumns();
    }
    // this.gridApi.setDomLayout("autoHeight");
    window.addEventListener('resize', function() {
      setTimeout(function() {
        if (screen.width > 991) {
          // params.api.sizeColumnsToFit();
        }else {
          params.columnApi.autoSizeColumns();
        }
      });
    });

    // this.gridColumnApi.autoSizeColumns();


    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  ngOnInit() {
    this.sharedService.setHeader('Outlet Product Report');
    this.collapse_disable = localStorage.getItem('collapse_disabled');
    this.renderer[this.collapse_disable == "true" ? 'addClass' : 'removeClass'](this.elRef.nativeElement.querySelector('.main-wrapper'), 'active');
  }

  quickSearch() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  changeDate(dt) {

    this.user_data.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
    this.user_data.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
  }
}
