import {Component, Input, OnInit} from '@angular/core';
import {AgChartOptions, AgChartTheme, AgPieSeriesOptions} from "@ag-grid-enterprise/all-modules";
declare var $: any;

@Component({
  selector: 'app-track-detail',
  templateUrl: './track_detail.component.html',
  styleUrls: ['./track_detail.component.scss']
})

export class TrackDetailComponent implements OnInit {

  @Input() rowData: [];
  @Input() MapViewMetaData: {};
  @Input() filteredData: any [];
  agm_map;
  mapData: any = [];

  zoom = 1;
  markerType = 'marker';
  latitude = 1.1;
  longitude = 1.1;
  title1 = 'Test map';
  rowIndex = 0;
  chartData = [
    {
      name: "Visited",
      value: 10,
      extra: {
        code: "us"
      }
    },
    {
      name: "Productive",
      value: 4,
      extra: {
        code: "fr"
      }
    },
    {
      name: "Not Visited",
      value: 6,
      extra: {
        code: "uk"
      }
    }, ];
  view: any[] = [500, 200];
  colorScheme: any = {
    domain: ['#0762AD', '#379862', '#E24260',  '#F6A82C']
  };
  myTheme: AgChartTheme = {
    palette: {
      fills: ['#0762AD', '#E24260', '#379862', '#F6A82C'],
      strokes: ["black"],
    },
    overrides: {
      common: {
        title: {
          fontSize: 24,
        },
      },
      bar: {
        series: {
          label: {
            enabled: true,
            color: "black",
          },
          strokeWidth: 1,
        },
      },
    },
  };
  options: AgChartOptions;
  testData = [{lat: this.latitude, lng: this.longitude, name: 'tet'}];
  public start_url = {
    url: '/assets/start.svg',
    scaledSize: {
      width: 30,
      height: 30
    }
  };
  public amber_marker = {
    url: '/assets/map/warning_orange.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public grey_marker = {
    url: '/assets/images/Grey.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public teel_marker = {
    url: '/assets/images/Teel.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };


  public green_marker = {
    url: '/assets/map/green.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };
  loaded = false;
  showAlertCard = false;
  showActivityCard = false;

  ngOnInit(): void {
    this.configureMapData();
   /* this.options = {
      data: this.chartData,
      series: [
        {
          type: 'donut',
          calloutLabelKey: 'asset',
          angleKey: 'amount',
          innerRadiusRatio: 0.7,
        }
      ],

      legend: {
        position: 'right', // 'top', 'right', 'left',
      }
    };*/
    this.loaded = true;
  }

  configureMapData() {
    this.mapData = [];
    if (this.filteredData && this.filteredData.length > 0) {
      this.filteredData.forEach(data => {
        if (data[this.MapViewMetaData['latitude']] && data[this.MapViewMetaData['latitude']] !== "" && data[this.MapViewMetaData['longitude']] && data[this.MapViewMetaData['longitude']] !== "") {
          data.lat = parseFloat(data[this.MapViewMetaData['latitude']]);
          data.long = parseFloat(data[this.MapViewMetaData['longitude']]);
          this.mapData.push(data);
        }
      });
    }
    console.log(this.mapData);
  }


  onMapReady(event) {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    this.agm_map = event;

    for (const mm of this.mapData) {
      if (mm['lat'] && mm['long']) {
        bounds.extend(new google.maps.LatLng(mm['lat'], mm['long']));
      }
    }
    this.agm_map.fitBounds(bounds);

  }

  movePrevious() {

  }

  moveNext() {

  }

  alertCardClick() {
    this.showAlertCard = !this.showAlertCard;
  }

  activityCardClick() {
    this.showActivityCard = !this.showActivityCard;
  }

  formatDataLabel(value) {
    return value;
    /*if (value) {
      if (this.chartData?.value_type === 'currency') {
        value = parseFloat(value);
        if (value >= 10000000) {
          return (value / 10000000).toFixed(2) + 'Cr';
        } else if (value >= 100000) {
          return (value / 100000).toFixed(2) + 'L';
        } else if (value >= 1000) {
          return (value / 1000).toFixed(2) + 'K';
        } else {
          return value;
        }
      } else {
        return value;
      }
    } else {
      return 0;
    }*/
  }


}
