import {PageBase} from "../../../pagebase/pagebase";
import {Component, OnInit} from "@angular/core";
import {ErrorHandler} from "../../../error/error-handler";
import {ErrortooltipComponent} from "../../../errortooltip";
import {PrintInvoiceComponent} from "../../../printInvoice.component";
import {PrintClickRenderer} from "../../../printClickRenderer.component";
import {GenerateInvoiceComponent} from "../../../generateInvoice.component";


@Component({
  selector: 'app-van-sale-invoice-list',
  templateUrl: '../../../pagebase/pagebase.html',
  styleUrls: ['../../../pagebase/pagebase.scss']
})

export class VanSaleInvoiceListComponent extends PageBase implements OnInit, IPage {

  customFilter: any = {
    name: 'Invoice Status',
    key: 'status',
    value: 'draft',
    values: [],
    show: true
  };

  matActionMenuItemDeclaration = [{name: 'Create Invoice'}, {name: 'Confirm Order'}]; //, feature: 'action_invoice_create'

  ngOnInit(): void {

    this.sharedService.setHeader('Vansale Invoices');
    this.showUpload = true;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'Not Created', itemName: 'Not Created'},
      {id: 'draft', itemName: 'New'},
      {id: 'cancelled', itemName: 'Cancelled'},
      {id: 'completed', itemName: 'Complete'},
      {id: 'error', itemName: 'Error'},
    ];
    this.checkAndLoadPreviousSate();

    this.showCustomFilter = true;
    this.showActionButton = true;
    this.showDate = true;

    this.statusConfig.new = 'draft';
    this.statusConfig.warning = 'processing';
    this.statusConfig.warning1 = 'Not Created';
    this.statusConfig.success = 'completed';
    this.statusConfig.error = 'cancelled';
    this.statusConfig.error1 = 'error';

    this.gridOptions.getRowStyle = this.getRowStyleFunc;
    this.frameworkComponents = {
      errortooltipComponent: ErrortooltipComponent
    };

    this.gridOptions.isRowSelectable = params => {
      if (params.data) {
        if (params.data.state === 'cancel') {
          return false;
        } else {
          return true;
        }
      }
    };
    this.getAuthorizedActions();
    this.checkAccessControl();
    this.configureGrid();
    this.loadServerData();
  }

  getRowStyleFunc(params) {
    if (params.data && params.data.id === 'TOTAL') {
      return {'font-weight': 'bold'};
    }
  }

  configureGrid() {
    this.API_URL = '/api/pwa_van_sales_connects/get_van_sale_orders';

    const OrderStatusCellClassRules = {
      'rag-green-outer': (params) => params.value === 'sale',
      'rag-amber-outer': (params) => params.value === 'confirmed',
      'rag-red-outer': (params) => params.value === 'cancel',
    };

    this.columnDefs = [
      {
        headerName: "ID",
        field: "id",
        width: 25,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: params => {
          if (params.data) {
            if (params.data.state === 'cancel') {
              return false;
            } else {
              return true;
            }
          }
        }
      },
      {headerName: "Order Number", field: "order_no", width: 80},
      {headerName: "Date", field: "date", width: 40},
      {headerName: "Created By", field: "created_by", width: 70},
      {headerName: "Code", field: "code", width: 50},
      {headerName: "Customer", field: "outlet", width: 70},
      {headerName: "Type", field: "o_type", width: 50},
      {headerName: "Bill To", field: "bt_name", width: 50, hide: true},
      {headerName: "Ship To", field: "st_name", width: 50, hide: true},
      {
        headerName: "Sub Total",
        field: "amount_untaxed",
        width: 50,
        aggFunc: 'sum',
        cellStyle: {textAlign: 'right'},
        valueFormatter: params => this.formatNumber(params.value)
      },
      {
        headerName: "Total",
        field: "amount_total",
        width: 50,
        hide: true,
        aggFunc: 'sum',
        cellStyle: {textAlign: 'right'}
      },
      {headerName: "Salesman", field: "salesman", width: 40},
      {headerName: "Emp Code", field: "emp_code", width: 30, hide: true},
      {
        headerName: "Order Status", field: "state", width: 40,
        cellClassRules: OrderStatusCellClassRules,
        valueGetter: params => {
          if (params.data && params.data.hasOwnProperty('state')) {
            if (params.data.state === 'sale') {
              return 'Confirmed';
            } else if (params.data.state === 'draft') {
              return 'Draft';
            } else if (params.data.state === 'cancel') {
              return 'Cancel';
            } else {
              return params.data.state;
            }
          }
        },
        cellRenderer(params) {
          let displayElem = '';
          if (params.data) {
            if (params.value === 'Confirmed') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #379862;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else if (params.value === 'Cancel') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #E24260;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else if (params.value === 'Draft') {
              displayElem = '<span style="height: 8px;width: 8px;margin-right: 5px;background-color: #F6A82C;border-radius: 50%; display: inline-block;"></span><span>' + params.value + '</span>';
            } else {
              if (params.value) {
                displayElem = '<span>' + params.value + '</span>';
              }
            }

            return displayElem;
          }
        }
      },
      {
        headerName: "Invoice Status",
        field: "inv_status",
        width: 60,
        cellRenderer: this.sharedService.statusCellRenderer
      },
      {
        headerName: "Invoice Comments", field: "inv_comments", width: 80, wrapText: true, autoHeight: true,
        cellRenderer(params) {
          if (params.data) {
            if(params.data.inv_comments && params.data.inv_comments.includes("Generated")) {
              return params.data.inv_comments;
            } else {
              if(params.data.inv_comments && params.data.inv_comments.includes("error")) {
                try {
                  const jsonval = JSON.parse(params.data.inv_comments);
                  if (jsonval && jsonval?.error?.errorBody && jsonval.error?.errorBody.length > 0) {
                    let comments = ``;
                    jsonval.error.errorBody.forEach(comm => {
                      comments += `<span style="display: block;line-height: 20px;">` + comm.ErrorMessage + `</span>`
                    })
                    return comments;
                  }
                } catch (e) {
                }
              }
            }
          }
        }
      },
      {headerName: "Origin", field: "origin", width: 40},
      {headerName: "Note", field: "note", width: 40},
      {headerName: "Supplier", field: "sup_name", width: 40, hide: true},
      {headerName: "Beat", field: "beat", width: 30},
      {headerName: "Territory", field: "territory", width: 30, hide: true},
      {headerName: "Cluster", field: "cluster", width: 30, hide: true},
      {headerName: "State", field: "geo_state", width: 30, hide: true},
      {
        headerName: 'GENERATE IRN', field: 'IRN', pinned: 'right',
        cellRenderer: GenerateInvoiceComponent, width: 65
      },
      {
        headerName: 'PRINT', field: 'PRINT', pinned: 'right',
        headerComponent: PrintInvoiceComponent,
        cellRenderer: PrintClickRenderer, width: 50
      }
    ];

    this.getRowId = (params) => params.data.id;
  }


  printAll(rows) {
    this.appComponent.printAll(rows, 'id', '/api/pwa_connects/get_order_print_details', null);
  }


  onCellClicked(event) {
    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    const filteredRowData = this.gridApi.rowModel.rowsToDisplay;
    if (column === 'PRINT') {
      this.appComponent.printAll([event.data], 'id', '/api/pwa_connects/get_order_print_details', null);
    } else {
      if (filteredRowData !== null && filteredRowData.length > 0) {
        const ids = [];
        filteredRowData.forEach(r => {
          ids.push(r.data.id);
        });
        localStorage.setItem('active_ids', JSON.stringify(ids));
        this.router.navigate([this.apiService.redirect_url + '/vansales/invoices/detail'], {
          relativeTo: this.route,
          queryParams: {id: rows[0].id}
        });
      }
    }
  }

  checkAccessControl() {
    this.showCreate = true;
  }

  openCreateDialog() {

    localStorage.setItem('active_ids', JSON.stringify([]));
    this.router.navigate([this.apiService.redirect_url + '/dms/sale_order'], {
      relativeTo: this.route,
      queryParams: {id: 0}
    });
  }

  showUploadPopup() {
    // this.uploadSaleOrdersClick();
    /*if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "auto",
        maxWidth: "90%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(SaleOrderBulkUploadComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        panelClass: 'custom-dialog-container',
        data: {
        }
      };
      const creatediaeref = this.dialog.open(SaleOrderBulkUploadComponent, config);
    }*/
  }

  //
  // uploadSaleOrdersClick() {
  //   const supplier_id = JSON.parse(localStorage.getItem('user')).partner_id;
  //   const config: MatDialogConfig = {
  //     maxWidth: '100vw',
  //     maxHeight: '100vh',
  //     height: '100%',
  //     width: '100%',
  //     panelClass: 'full-screen-modal',
  //     data: {
  //       upload_type: 'sales_order_upload_v1',
  //       title: "Upload Sales Order",
  //       message: "You are Sale Order ",
  //       active_id: supplier_id,
  //       instruction: "Column 1: Outlet_code <br> Column 2: action (ADD/REMOVE), Click Here to download .",
  //     },
  //   };
  //
  //   const dialogRef = this.dialog.open(SaleOrderUploadComponent, config);
  //   dialogRef.componentInstance.uploadData.subscribe(status => {
  //     if (status === 'success') {
  //       this.dialog.closeAll();
  //       window.location.reload();
  //     }
  //   });
  // }


  onMenuItemSelected(menuitem) {
    const rows = this.gridApi.getSelectedRows();
    const supplier_ids = [];
    const supplier_territory_ids = [];
    let valid = true;
    if (rows === null || rows === undefined || rows.length === 0) {
      this.toastr.error('Please select at least 1 Order');
    } else if (rows.length > 100) {
      this.toastr.error('Please select a maximum of 100 order : ' + rows.length + ' selected');
    } else {
      const ids = [];

      if (menuitem.name === 'Create Invoice') {
        rows.forEach(r => {
          if (r.state === 'sale') {
            ids.push(r.id);
            if (!supplier_ids.includes(r.supplier_id)) {
              supplier_ids.push(r.supplier_id);
            }
            if (!supplier_territory_ids.includes(r.supplier_territory_id)) {
              supplier_territory_ids.push(r.supplier_territory_id);
            }
          }
        });

        if (ids.length > 0) {
          if (supplier_ids.length <= 0) {
            this.toastr.warning('Supplier id Missing');
          }
          if (supplier_ids.length > 1) {
            this.toastr.warning('More then One Supplier Selected');
          }
          if (supplier_territory_ids.length <= 0) {
            this.toastr.warning('Supplier Territory id Missing');
          }
          if (supplier_territory_ids.length > 1) {
            this.toastr.warning('More then One Supplier Territory Selected');
          }

          //this.createBulkInvoiceClick(ids, supplier_ids[0]);
        } else {
          this.toastr.warning('Please select only CONFIRMED Orders');
        }
      } else if (menuitem.name === 'Confirm Order') {
        rows.forEach(r => {
          if (r.state === 'draft') {
            ids.push(r.id);
          }
          if (!supplier_ids.includes(r.supplier_id)) {
            supplier_ids.push(r.supplier_id);
          }
          if (!supplier_territory_ids.includes(r.supplier_territory_id)) {
            supplier_territory_ids.push(r.supplier_territory_id);
          }
        });

        if (ids.length > 0) {
          if (supplier_ids.length <= 0) {
            this.toastr.warning('Supplier id Missing');
          }
          if (supplier_ids.length > 1) {
            this.toastr.warning('More then One Supplier Selected');
          }
          if (supplier_territory_ids.length <= 0) {
            this.toastr.warning('Supplier Territory id Missing');
          }
          if (supplier_territory_ids.length > 1) {
            this.toastr.warning('More then One Supplier Territory Selected');
          }

          this.confirmOrdersClick(ids, supplier_territory_ids[0]);
        } else {
          this.toastr.warning('Please select only DRAFT Orders');
        }
      }
    }
  }


  /* createBulkInvoiceClick(order_ids, supp_id) {
     const config: MatDialogConfig = {
       maxWidth: '100vw',
       maxHeight: '100vh',
       height: '100%',
       width: '100%',
       panelClass: 'full-screen-modal',
       data: {
         ids: order_ids,
         supplier_id: supp_id,
         // supplier_territory_id: supp_territory_id
       },
     };

     const dialogRef = this.dialog.open(BulkInvoiceComponent, config);
     dialogRef.componentInstance.bulkInvoiceCreate.subscribe(status => {
       if (status === 'success') {
         this.dialog.closeAll();
         window.location.reload();
       }
     });
   }*/


  confirmOrdersClick(order_ids, supplier_territory_id) {
    if (order_ids.length > 0) {
      const userData = {};
      userData['access_token'] = this.userData.access_token;
      userData['order_ids'] = order_ids;
      userData['supplier_territory_id'] = supplier_territory_id;

      this.apiService.post('/api/pwa_dms_connects/confirm_orders', userData)
        .subscribe(res => {
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {

              const rowsToUpdate = [];
              res.results.data.forEach(res_id => {
                const ord = this.rowData.find(item => item.id === res_id['id']);
                rowsToUpdate.push(this.updateImmutableObject(ord, {
                  state: res_id['state'],
                  inv_status: res_id['inv_status']
                }));
              });

              this.gridApi.applyTransactionAsync({update: rowsToUpdate}, () => {
              });

              this.toastr.success(rowsToUpdate.length + ' orders confirmed!');

            } else if (res.hasOwnProperty('results') && (res.results.status === 203)) {
              this.handleError(ErrorHandler.getErrorObject(res.results.msg));
            } else {
              this.handleError(ErrorHandler.getErrorObject('Something Went Wrong, Contact support'));
            }
          },
          error => {
            this.handleError(ErrorHandler.getErrorObject(error));
          });

    } else {
      this.toastr.success('All orders are already confirmed!');
      return;
    }
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setColumnDefs(this.columnDefs);
    params.api.setRowData(this.rowData);
    this.preloader = false;
    window.addEventListener('resize', () => {
      setTimeout(() => {
        if (params.api) {
          if (screen.width > 991) {
            params.api.sizeColumnsToFit();
          } else {
            params.columnApi.autoSizeColumns();
          }
        }
      });
    });
    if (screen.width > 991) {
      this.gridOptions.api.sizeColumnsToFit();
    } else {
      this.gridColumnApi.autoSizeColumns();
    }

    setTimeout(() => {
      const pinnedBottomData = this.sharedService.generatePinnedBottomData(this.gridApi, this.gridColumnApi);
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  onFilterChanged($event) {
    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);
  }

  generateinvoice(params, node) {
    const instances = node.beans.gridApi.getCellRendererInstances({columns: ['IRN'], rowNodes: [node]});
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.orders = [params];
    this.apiService.post('/api/pwa_connects/regenerate_irn', paramObject)
      .subscribe(res => {
        if (res.hasOwnProperty('results') && (res['results'].statusCode === 200)) {
          if (res['results'].failures.length === 0) {
            this.toastr.success('Generation Success');
            this.loadServerData();
          } else {
            this.toastr.error(res['results'].msg);
          }
          instances.forEach(instance => {
            instance.loader = false;
            instance.enable_regenerate = true;
          });
        } else {
          this.toastr.error('IRN Generation Failed');
          instances.forEach(instance => {
            instance.loader = false;
            instance.enable_regenerate = true;
          });
        }
      }, error => {
        console.log(error);
        if (error.error && error.error.error == undefined) {
          this.toastr.error('IRN Generation Failed');
        } else {
          this.toastr.error("IRN Generation Failed: " + error.error.error.message);
        }
        instances.forEach(instance => {
          instance.loader = false;
          instance.enable_regenerate = true;
        });
      });

  }

}
