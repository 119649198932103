import {ChangeDetectorRef, ComponentFactoryResolver, ElementRef, OnInit, Renderer2} from "@angular/core";
import {ApiService} from "../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {SharedService} from "../_services/shared_service";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {DomSanitizer} from "@angular/platform-browser";
import {AppComponent} from "../app.component";
import {LocalforageService} from "../_services/localforage.service";

declare var $: any;
declare var google: any;

export abstract class MapBase implements OnInit {

  API_URL = '';
  agm_map;
  styles: any[] = [
    {
      elementType: "geometry",
      stylers: [{color: "#f5f5f5"}],
    },
    {
      elementType: "labels.icon",
      stylers: [{color: "#bdbdbd"}],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{color: "#616161"}],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{color: "#f5f5f5"}],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{color: "#bdbdbd"}],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{color: "#eeeeee"}],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{color: "#757575"}],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{color: "#e5e5e5"}],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{color: "#9e9e9e"}],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{color: "#ffffff"}],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [{color: "#757575"}],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{color: "#dadada"}],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [{color: "#616161"}],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{color: "#9e9e9e"}],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{color: "#e5e5e5"}],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{color: "#eeeeee"}],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{color: "#c9c9c9"}],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{color: "#9e9e9e"}],
    },
  ];
  zoom = 1;
  public start_marker = {
    url: '/assets/map/start.svg',
    scaledSize: {
      width: 30,
      height: 30
    }
  };
  public end_marker = {
    url: '/assets/map/end.svg',
    scaledSize: {
      width: 30,
      height: 30
    }
  };
  public alert_marker = {
    url: '/assets/map/warning_orange.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public red_warning_marker = {
    url: '/assets/map/warning_red.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public grey_marker = {
    url: '/assets/images/Grey.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public teel_marker = {
    url: '/assets/images/Teel.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };

  public green_marker = {
    url: '/assets/map/green.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };
  public green_tick_marker = {
    url: '/assets/map/green_tick.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };
  public blue_marker = {
    url: '/assets/map/blue.svg',
    scaledSize: {
      width: 30,
      height: 30,
      anchor: {x: 19, y: 19}
    },
    labelOrigin: {x: 12, y: 45},
  };
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: [],
      payment_mode: ''
    },
    user_ids: [],
    track_ids: [],
    customFilter: {},
    searchableFilter: 0,
    multiFilter: {}
  };
  chartcolorScheme: any = {
    domain: ['#0762AD', '#379862', '#E24260', '#F6A82C']
  };
  trackColorScheme: any = [
    '#0762AD',
    '#379862',
    '#B53438',
    '#FF5733',
    '#0E81C3',
    '#17A2B8',
    '#3D8A62',
    '#555A67',
    '#586869',
    '#6A4C93',
    '#757BC8',
    '#87A330',
    '#87A330',
    '#A0522D',
    '#BD1C85',
    '#CF6A87',
    '#E24260',
    '#E89221',
    '#FFDB5B',
    '#0B5E8E',
    '#2094A8',
    '#3E775E',
    '#4C8A3F',
    '#607275',
    '#6C5D89',
    '#8869A6',
    '#9E4D2C',
    '#D26965',
    '#F08545',
    '#C3A14A',
    '#F2C75C'
  ];
  dateSelectMode = 'range';
  myDate: any;
  periodID = 0;
  active_ids = [];
  activeItems = [];
  activeID = 0;
  rowIndex = 0;
  listLength = 0;
  dataLoaded = false;
  searchableFilter: any = {
    name: "User",
    bindLabel: "user_name",
    bindValue: "id",
    value: 0,
    values: []
  };
  apiData: any = [];
  selectedPeriod = {};
  dateFilterType: any = 'range';
  activeRow: any = {};
  colorMode = 'track';
  goClicked = false;
  constructor(public apiService: ApiService,
              protected router: Router,
              protected route: ActivatedRoute,
              public elRef: ElementRef,
              protected renderer: Renderer2,
              public datePipe: DatePipe,
              public sharedService: SharedService,
              public dialog: MatDialog,
              public toastr: ToastrService,
              private componentFactoryResolver: ComponentFactoryResolver,
              protected changeDetector: ChangeDetectorRef,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              protected _localCacheService: LocalforageService) {

    this.userData.access_token = localStorage.getItem('resfreshToken');
    this.selectedPeriod = JSON.parse(localStorage.getItem('activePeriod'));
  }

  loadCacheValues() {
    if (localStorage.getItem('activeItems')) {
      this.activeItems = JSON.parse(localStorage.getItem('activeItems'));
    } else {
      this.activeItems = [];
    }
    if (localStorage.getItem('active_ids')) {
      this.active_ids = JSON.parse(localStorage.getItem('active_ids'));
    } else {
      this.active_ids = [];
    }
    if (localStorage.getItem('activeID')) {
      this.activeID = parseInt(localStorage.getItem('activeID'), 10);
    } else {
      this.activeID = 0;
    }
    if (this.active_ids && this.active_ids) {
      this.rowIndex = this.active_ids.indexOf(this.activeID);
    } else {
      this.rowIndex = 0;
    }
    if (localStorage.getItem('activeRow')) {
      this.activeRow = JSON.parse(localStorage.getItem('activeRow'));
    } else {
      this.activeRow = {};
    }
    this.listLength = this.active_ids.length;
    if ( this.dateFilterType === 'period' && localStorage.getItem('activePeriod')) {
      this.periodID = JSON.parse(localStorage.getItem('activePeriod'));
      this.setPeriod(this.selectedPeriod);
    } else if (localStorage.getItem('activeDate')) {
      this.myDate = localStorage.getItem('activeDate');
    } else {
      const date = new Date();
      if (this.dateSelectMode === 'single') {
        this.myDate = this.datePipe.transform(date, 'yyyy-MM-dd');
        this.userData.start_date = this.myDate;
        this.userData.end_date = this.myDate;
      } else {
        this.myDate = [];
        this.myDate[0] = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
        this.myDate[1] = this.datePipe.transform(date, 'yyyy-MM-dd');
        this.userData.start_date = this.myDate[0];
        this.userData.end_date = this.myDate[1];
      }
    }
  }
  ngOnInit() {
  }

  getMarker(item) {
    if (item['icon']) {
      if (item['icon'] === 'start_marker') {
        return this.start_marker;
      } else if (item['icon'] === 'end_marker') {
        return this.end_marker;
      } else if (item['icon'] === 'alert_marker') {
        return this.alert_marker;
      } else if (item['icon'] === 'blue_marker') {
        return this.blue_marker;
      } else if (item['icon'] === 'green_tick_marker') {
        return this.green_tick_marker;
      } else if (item['icon'] === 'red_warning_marker') {
        return this.red_warning_marker;
      } else {
        return this.green_marker;
      }
    } else {
      return this.green_marker;
    }
  }

  getPolyLineColor(item) {
    if (this.colorMode === 'user') {
      return item.user_color;
    } else if (this.colorMode === 'beat') {
      return item.beat_color;
    } else {
      return item.track_color;
    }
  }

  setValuestoDefault() {

  }

  loadMapData() {
    this.goClicked = true;
    this.appComponent.pageReady = false;
    this.apiData = [];
    this.dataLoaded = false;
    this.setValuestoDefault();
    if (this.dateSelectMode === 'single') {
      this.userData.start_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    } else {
      this.userData.start_date = this.datePipe.transform(this.myDate[0], 'yyyy-MM-dd');
      this.userData.end_date = this.datePipe.transform(this.myDate[1], 'yyyy-MM-dd');
    }
    if (!this.userData.searchableFilter && this.searchableFilter && this.searchableFilter.values && this.searchableFilter.values.length > 0) {
      this.userData.searchableFilter = this.searchableFilter.values[0][this.searchableFilter['id']];
      this.searchableFilter.value = this.searchableFilter.values[0][this.searchableFilter['id']];
    }
    this.apiService.post(this.API_URL, this.userData)
      .subscribe(async res => {
        console.log(res);
        this.appComponent.pageReady = true;
        this.dataLoaded = true;
        if (res.hasOwnProperty('results') && (res['results'].status === 200)) {
          this.apiData = res.results.data;
          this.postProcessData();
        }
      }, error => {
        console.log(error);
        this.appComponent.pageReady = true;
      });
  }

  postProcessData() {

  }

  setSearchableFilter(searchableFilterData) {
    this.activeID = searchableFilterData;
    this.userData.searchableFilter = this.activeID;
    this.rowIndex = this.active_ids.indexOf(this.activeID);
  }

  setPeriod(selectedPeriod: any) {
    this.selectedPeriod = selectedPeriod;
    this.myDate = [];
    this.myDate[0] = selectedPeriod.start_date;
    this.myDate[1] = selectedPeriod.end_date;
    this.userData.start_date = selectedPeriod.start_date;
    this.userData.end_date = selectedPeriod.end_date;
    this.periodID = selectedPeriod.id;
    this.userData.period_id = this.periodID;

  }

  popuplateSearchableFilter() {
    if (this.activeID) {
      const userObject = this.searchableFilter.values.find(x => x.id === this.activeID);
      if (userObject) {
        this.searchableFilter.value = userObject.id;
      } else {
        this.searchableFilter.value = this.searchableFilter.values[0].id;
      }
    } else {
      this.searchableFilter.value = this.searchableFilter.values[0].id;
    }
    this.userData.searchableFilter = this.searchableFilter.value;
  }

  moveNext() {
    const curIndex = this.active_ids.indexOf(this.activeID);
    if (curIndex >= 0 && curIndex < (this.active_ids.length - 1)) {
      this.rowIndex = curIndex + 1;
      this.activeID = this.active_ids[this.rowIndex];
      this.popuplateSearchableFilter();
      this.loadMapData();
    }
  }

  movePrevious() {
    const curIndex = this.active_ids.indexOf(this.activeID);
    if (curIndex > 0) {
      this.rowIndex = curIndex - 1;
      this.activeID = this.active_ids[this.rowIndex];
      this.popuplateSearchableFilter();
      this.loadMapData();
    }
  }

  onMouseOver(infoWindow, event) {
    infoWindow.open();
  }

  onMouseOut(infoWindow, event) {
    infoWindow.close();
  }

  markerClick(infoWindow) {
  }
}
