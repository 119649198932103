<div  class="map" id="map1" style="margin-top: 20px;border: 1px solid #D7DDE6;border-radius: 5px;">
  <div style="height: 100%;">
    <div class="col-md-12 pad0" style="height: 100%;">
      <div class="col-md-9 pr-0">
        <div style="height: 70vh;">
          <agm-map (mapReady)="onMapReady($event)"
                   [zoom]="zoom" >
            <ng-container *ngFor="let item of mapData; let i = index">
              <agm-marker *ngIf="i%3 === 0" [latitude]="item.lat" [longitude]="item.long" [iconUrl]="start_url" >
                <agm-info-window
                  [latitude]="item.lat"
                  [longitude]="item.long">
              <span style="font-size: large;float: left"><b>Name:</b>
                {{item.name}}
              </span><br/><br/>
                </agm-info-window>
              </agm-marker>
              <agm-marker *ngIf="!item['icon_type']" [latitude]="item.lat" [longitude]="item.long" [iconUrl]="green_marker">
                <agm-info-window
                  [latitude]="item.lat"
                  [longitude]="item.long">
              <span style="font-size: large;float: left"><b>Name:</b>
                {{item.name}}
              </span><br/><br/>
                </agm-info-window>
              </agm-marker>
              <agm-marker *ngIf="item.color === 'grey'" [latitude]="item.lat" [longitude]="item.long" [iconUrl]="grey_marker">
                <agm-info-window
                  [latitude]="item.lat"
                  [longitude]="item.long">
              <span style="font-size: large;float: left"><b>Name:</b>
                {{item.name}}
              </span><br/><br/>
                </agm-info-window>
              </agm-marker>
              <agm-marker *ngIf="item.color === 'green'" [latitude]="item.lat" [longitude]="item.long" [iconUrl]="green_marker">
                <agm-info-window
                  [latitude]="item.lat"
                  [longitude]="item.long">
              <span style="font-size: large;float: left"><b>Name:</b>
                {{item.name}}
              </span><br/><br/>
                </agm-info-window>
              </agm-marker>
            </ng-container>
          </agm-map>
        </div>

      </div>
      <div class="col-md-3 pl-0 pr-0">
        <div style="background: white;padding: 10px;">
          <div>
            <div class="row centeralign">
              <span class="detailvaluebold">Bhavesh Kumar</span>
              <div style="position: absolute;right: 30px;">
                <div class="button-row">
            <span (click)="movePrevious()"><i
              [ngClass]="{'disabledspan': rowIndex === 0,
                         'enabledspan': rowIndex >0}"
              class="fal fa-arrow-circle-left fa-2x"></i></span>
                  <span
                    style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{ rowIndex + 1 }}
                    /{{ filteredData.length }}</span>
                  <span (click)="moveNext()"><i
                    [ngClass]="{'disabledspan': rowIndex === filteredData.length-1,
                         'enabledspan': rowIndex < filteredData.length-1}"
                    class="fal fa-arrow-circle-right fa-2x"></i></span>
                </div>
              </div>
            </div>
            <div class="mt-4" style="display: flex">
              <div style="flex: 1; margin-right: 10px;">
                <div  style="border-radius: 3px;padding: 10px;background: #F5F7F9;height: 55px;">
                  <div style="display: inline-block; margin-right: 5px;">
                    <img style="height: 16px;margin-bottom: 30px;" src="/assets/icons/beat.svg">
                  </div>
                  <div style="display: inline-block">
                    <label class="detaillabel4" style="display: block">Distance Travelled</label>
                    <span class="detailvaluebold2" style="color: #0762AD;">5.9 Kms</span>
                  </div>
                </div>
              </div>
              <div style="flex: 1;">
                <div  style="border-radius: 3px;padding: 10px;background: #F5F7F9;height: 55px;">
                  <div style="display: inline-block; margin-right: 5px;">
                    <img style="height: 16px;margin-bottom: 30px;" src="/assets/icons/clock.svg">
                  </div>
                  <div style="display: inline-block">
                    <label class="detaillabel4" style="display: block">Total Time Spent</label>
                    <span class="detailvaluebold2" style="color: #0762AD;">5hr 31 mins</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4" style="border-radius: 3px;padding: 10px;background: #F5F7F9;height: 200px;">
              <span class="detailvaluebold">Bangalore south- 0980</span>
              <div *ngIf="loaded" style="height: 95%">
                <ngx-charts-pie-chart
                  [scheme]="colorScheme"
                  [results]="chartData"
                  [gradient]=true
                  [legend]=true
                  [legendTitle]="''"
                  [legendPosition]="'right'"
                  [labels]=false
                  [doughnut]=true
                >
                </ngx-charts-pie-chart>
               <!-- <ag-charts-angular
                  [options]="options"
                ></ag-charts-angular>-->
              </div>
            </div>
            <div class="mt-4">
              <ul style="list-style: disc;padding-left: 0px;">
                <li class="detailli">
                  <span class="dialoglabel" style="margin-left: 12px;">Beat Jump </span>
                  <span class="detailvaluebold2" style="margin-left: 10px;">2 outlets</span>
                </li>
                <li class="detailli">
                  <span class="dialoglabel" style="margin-left: 12px;">Start Time </span>
                  <span class="detailvaluebold2" style="margin-left: 10px;">2024-11-27 12:33</span>
                </li>
                <li class="detailli">
                  <span class="dialoglabel" style="margin-left: 12px;">End Time </span>
                  <span class="detailvaluebold2" style="margin-left: 10px;">2024-11-27 18:01</span>
                </li>
              </ul>
            </div>
            <div class="mt-4" style="border-radius: 3px;padding: 10px;background: #F5F7F9;">
              <div class="row centeralign" style="cursor: pointer" (click)="alertCardClick()">
                <span style="color: #2D2D2D; font-size: 14px;font-weight: 600;">Alerts</span>
                <img *ngIf="!showAlertCard" style="position: absolute; right: 30px;height: 8px;" src="/assets/images/down_arrow_black.svg"/>
                <img *ngIf="showAlertCard" style="position: absolute; right: 30px;height: 8px;" src="/assets/images/up_arrow.svg"/>
              </div>
            </div>

            <div class="mt-4" style="border-radius: 3px;padding: 10px;background: #F5F7F9;">
              <div class="row centeralign" style="cursor: pointer" (click)="activityCardClick()">
                <span style="color: #2D2D2D; font-size: 14px;font-weight: 600;">Activity</span>
                <img *ngIf="!showActivityCard" style="position: absolute; right: 30px;height: 8px;" src="/assets/images/down_arrow_black.svg"/>
                <img *ngIf="showActivityCard" style="position: absolute; right: 30px;height: 8px;" src="/assets/images/up_arrow.svg"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
