import {Component, ElementRef, Inject, OnInit} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../../_services/shared_service";
import {ApproveRejectCommentsPopupComponent} from "../../components/approve_reject_comments_popup/approve_reject_comments_popup.component";

@Component({
  selector: 'app-feasibility-approve-popup',
  templateUrl: './feasibility_approve_popup.component.html',
  styleUrls: ['./feasibility_approve_popup.component.scss']

})

export class FeasibilityApprovePopupComponent implements OnInit {

  assetDetails: any = {};
  surveyDetails: any = {};
  surveyQuestions: any = [];

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public datePipe: DatePipe,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {
    this.assetDetails = dialogdata.assetDetails;
    this.surveyDetails = dialogdata.surveyDetails;
    this.surveyQuestions = dialogdata.surveyQuestions;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  openFeasibilityRejectPopup() {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "50%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title: "Reject Survey",
          message1: "Are you sure you want to reject the feasibility survey?",
          message2: "Please provide a detailed reason for the rejection"
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: 'auto',
        maxWidth: "95%",
        maxHeight: "100%",
        panelClass: 'custom-dialog-container',
        data: {
          title: "Reject Survey",
          message1: "Are you sure you want to reject the feasibility survey?",
          message2: "Please provide a detailed reason for the rejection"
        }
      };
      const creatediaeref = this.dialog.open(ApproveRejectCommentsPopupComponent, config);
    }
  }

}
