import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {CellEditingStartedEvent, CellEditingStoppedEvent, GetRowIdFunc, GetRowIdParams, GridOptions, RowNode} from "@ag-grid-enterprise/all-modules";
import {DMSBasePage} from "../../pagebase/components/DMSBasePage";
import {SharedService} from "../../_services/shared_service";
import {DatePipe} from "@angular/common";
import {LocalforageService} from "../../_services/localforage.service";
import {UOMProductCellRendererComponent} from "../../uomProductCellRenderer";
import {AppComponent} from "../../app.component";


@Component({
  selector: 'app-bulk-product-select-popup',
  templateUrl: './bulk_product_select_popup.component.html',
  styleUrls: ['./bulk_product_select_popup.component.scss']

})

export class BulkProductSelectPopupComponent extends DMSBasePage implements OnInit {

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  API_URL = '';
  userData = {
    start_date: null,
    end_date: null,
    period_id: 0,
    access_token: null,
    url: null,
    offset: null,
    offsetID: 0,
    flag: true,
    type: '',
    filterData: {
      date: null,
      access_token: null,
      url: null,
      last_date: null,
      offset: null,
      flag: true,
      out_type: -1,
      salesmanID: 0,
      policyID: 0,
      outlet_type: 0,
      travel_type_id: 0,
      class_type: null,
      program_type: 0,
      status: null,
      max_claim: false,
      trax_outlet: false,
      non_trax_outlet: false,
      exclude_trax_audit: false,
      region_id: 0,
      cluster_id: 0,
      team_id: 0,
      territory_id: 0,
      dc_id: 0,
      displayData: []
    },
    customFilter: {}
  };
  productData = [];
  dataLoaded: any;

  statusConfig = {
    success: 'Valid',
    success1: 'Success',
    error: 'Invalid',
    error1: 'Error',
    warning: 'warning',
    warning1: 'warning'
  };
  apiData = [];
//  prodlist = [];
  prodLines = [];
  cartMode = false;
  columnsWithAggregation = [];
  filteredLength = 0;
  totalLength = 0;
  filterModel;
  categoryList: any = {
    name: 'Category',
    key: 'category',
    value: '',
    values: [{id: 0, itemName: 'All'}]
  };
  familyList: any = {
    name: 'Family',
    key: 'family',
    value: '',
    values: [{id: 0, itemName: 'All'}]
  };
  brandList: any = {
    name: 'Brand',
    key: 'brand',
    value: '',
    values: [{id: 0, itemName: 'All'}]
  };
  showSave = false;

  cartItems = [];

  constructor(public dialog: MatDialog,
              public apiService: ApiService,
              public sharedService: SharedService,
              public toastr: ToastrService,
              public elRef: ElementRef,
              public datePipe: DatePipe,
              protected route: ActivatedRoute,
              protected _localCacheService: LocalforageService,
              public router: Router,
              public domSanitizer: DomSanitizer,
              public appComponent: AppComponent,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    super(apiService, sharedService, elRef, datePipe, _localCacheService, toastr, route, dialog, router, domSanitizer, appComponent);
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      singleClickEdit: true,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheader',
        filter: true,
        sortable: true,
        resizable: true
      },
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        } else if (params.data && params.data.quantity > 0) {
          return {background: '#F3F6F8'};
        } else {
          return {background: 'white'};
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
    } as GridOptions;
    // this.prodlist = dialogdata.prodlist;
    this.rowData = dialogdata.productList;
    this.UOMMasterList = dialogdata.UOMMasterList;
    // this.prodList = dialogdata.productList;
    this.cartItems = [];
    this.activeObject = dialogdata.activeObject;
    this.OPERATION_MODE = dialogdata.OPERATION_MODE;
    if (this.rowData) {
      this.populateFilters();
      this.totalLength = this.rowData.length;
      this.cartItems = this.rowData.filter(x => x.quantity > 0);
    }
    // console.log(this.rowData);
    this.showSave = false;
  }

  ngOnInit(): void {
    // this.loadMasterDataForView(() => {
    //   this.loadPartnersDataForEdit(() => {
    //     this.loadProducts(this.activeObject['partner_id'], this.activeObject['distributor_id'], this.activeObject['supplier_territory_id'], this.activeObject['pricelist_id'], () => {
    //       if (this.prodList.length > 0) {
    //         const product_ids = [...new Set(this.prodList.map(item => item.p_id))];
    //         this.get_customer_available_stocks(this.activeObject.partner_id, this.activeObject.territory_id, product_ids, (err, result) => {
    //
    //           this.prodList.forEach(prd => {
    //             const prdItem = result.find(item => item.product_id === prd.p_id);
    //             let currentStock = 0;
    //             if (prdItem) {
    //               currentStock = prdItem.stock;
    //             }
    //             this.addProductLine(prd.p_id, currentStock, 0);
    //           });
    //
    //           this.configureGrid();
    //           this.pageReady = true;
    //         });
    //       }
    //     });
    //   });
    // });

    this.configureGrid();
    this.pageReady = true;
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.p_id;
  }

  configureGrid() {
    let uom_columnsCount = 0;
    this.rowData.forEach( prod => {
      const defaultUOM = this.UOMMasterList.find(u => u.id === prod.default_uom_id);
      // const baseUom = DMSBasePage.GetBaseUOMbyUOM(this.UOMMasterList, prod.default_uom_id);
      // if (baseUom) {
      //   prod.quantity = prod.quantity * baseUom.factor;
      //   prod.uom_name = baseUom.name;
      //   prod.price_unit = parseFloat(prod['unit_price']) * (defaultUOM.factor * baseUom.factor_inv);
      //   prod.default_uom_id = baseUom.id;
      // }
      const categ_uoms = DMSBasePage.GetQuantityBreakupsByInputUOM(this.UOMMasterList, prod.quantity, prod.default_uom_id);
      let uomindex = 0;
      categ_uoms.forEach(u => {

        prod['PACK ' + (uomindex + 1) + ' QTY'] = u.qty;
        prod['PACK ' + (uomindex  + 1) + ' NAME'] = u.name;
        prod['PACK ' + (uomindex + 1) + ' SIZE' ] = u.size;
        //
        // prod['uom_' + uomindex] = u.qty;
        // prod['uom_' + uomindex + '_factor_inv'] = u.size;
        // prod['uom_name_' + uomindex] = u.name;
        uomindex++;
      });
      prod['pack_count'] = uomindex;
      if (uom_columnsCount < uomindex) {
        uom_columnsCount = uomindex;
      }
    });

    this.columnsWithAggregation = ['pack', 'units', 'quantity', 'scheme_discount', 'price_subtotal', 'price_total'];
    this.columnDefs = [
      {headerName: 'Products', children: [
          {headerName: 'UID', field: 'p_id', hide: true},
          {headerName: 'Code', field: 'code', maxWidth: 100},
          {headerName: 'Product', field: 'product', width: 120},
          {headerName: 'Category', field: 'category', width: 80, hide: true},
          {headerName: 'Family', field: 'family', width: 120, hide: true},
          {headerName: 'Brand', field: 'brand', width: 80, hide: true},
          // {headerName: 'Quantity', field: 'quantity', width: 40, menuTabs: [], cellStyle: {textAlign: 'right'}},
          {headerName: 'Price', field: 'price_unit', width: 40, menuTabs: [], cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.data.price_unit)},
        ]},
      {
        headerName: 'Packs', children: []
      },
      {
        headerName: '', children: [
          {
            headerName: 'Total Qty', field: 'quantity', width: 40, menuTabs: [], filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'right'}
          },
          {headerName: 'Sub Total', field: 'price_subtotal', width: 50, menuTabs: [], cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.data.price_subtotal)},
          {
            headerName: 'Total', field: 'price_total', width: 50, menuTabs: [], cellStyle: {textAlign: 'right'}, valueFormatter: params => this.formatNumber(params.data.price_total),
            filter: 'agNumberColumnFilter', hide: true,
            filterParams: {
              includeBlanksInEquals: false,
              includeBlanksInLessThan: false,
              includeBlanksInGreaterThan: false,
              includeBlanksInRange: false,
              equals: false,
              lessThan: false,
              greaterThan: false,
              inRange: false
            }
          },
          {
            headerName: 'Status', field: 'status', maxWidth: 80, menuTabs: [], cellStyle: {textAlign: 'center'},
            cellRenderer(params) {
              if (params.data) {
                if (params.data.code === 'TOTAL') {
                  return '';
                } else {
                  let displayElem = '';
                  if (params.data.priority) {
                    if (params.data.priority === '5') {
                      displayElem = displayElem + ' <img style="width:16px;" src="../../../assets/images/mustsell_product.svg"/>';
                    } else if (params.data.priority === '4') {
                      displayElem = displayElem + ' <img style="width:16px;" src="../../../assets/images/focus_product.svg"/>';
                    } else {
                      return '';
                    }
                  } else {
                    return '';
                  }
                  return displayElem;
                }
              }
            }
          },
          {
            headerName: "", headerClass: 'myagheader', field: "action", cellStyle: {textAlign: 'right'}, menuTabs: [], maxWidth: 50,
            cellRenderer(params) {
              if (params.data.code === 'TOTAL') {
                return '';
              } else if (params.data && params.data.price_total > 0) {
                return ' <span title="Delete" style="color: #E24260; font-weight: 500; cursor: pointer">Clear</span>';
              } else {
                return '';
              }
            }
          },
        ]
      },
      // {headerName: 'Pack', field: 'pack', width: 60, menuTabs: [], editable: this.checkPackEditable, cellEditor: 'agNumberCellEditor', cellRenderer: UOMProductCellRendererComponent},



      // {headerName: 'Scheme', field: 'scheme_code', width: 80, hide: true},
      // {headerName: 'Scheme Dis', field: 'scheme_discount', width: 50, valueFormatter: params => this.formatNumber(params.data.scheme_discount), hide: true},
      // {headerName: 'Disc%', field: 'discount', width: 50, editable: true, cellEditor: UOMProductNumericEditorComponent, hide: true},
      //
    ];

    for (let i = 0; i < uom_columnsCount; i++) {

      this.columnDefs[1].children.push({headerName: 'PACK ' + (i + 1) + ' QTY', field: 'PACK ' + (i + 1) + ' QTY', width: 20,
                          editable: this.checkUOMEditable, cellStyle: {textAlign: 'right'}, cellEditor: 'agNumberCellEditor', cellRenderer: UOMProductCellRendererComponent});
      this.columnDefs[1].children.push({headerName: 'PACK ' + (i + 1) + ' NAME', field: 'PACK ' + (i + 1) + ' NAME', width: 40});

    }
    this.dataLoaded = true;
  }


  checkUOMEditable(params) {
    if (params && params.data) {
      const field_name = params.colDef.field.split(' ');
      if (params.data[field_name[0] + ' ' + field_name[1] + ' SIZE'] && params.data[field_name[0] + ' ' + field_name[1] + ' SIZE'] > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  //
  // addProductLine(productId, currentStock, quantity) {
  //   const prod = this.prodList.find(item => item.p_id === productId);
  //
  //   if (prod === undefined || prod === null) {
  //     this.toastr.error('unable to filter selected product');
  //     return 0;
  //   }
  //
  //   const inputValue = DMSBasePage.ConvertInputsToBaseUOM(this.UOMMasterList, quantity, prod.default_uom_id, 0, prod);
  //   const baseprice = inputValue.base_price;
  //   const unitPrice = inputValue.price_unit;
  //   quantity = inputValue.quantity;
  //
  //   if (inputValue.error !== '') {
  //     this.toastr.error(inputValue.error);
  //     return 0;
  //   }
  //
  //   const line_subtotal = baseprice * parseInt(quantity, 10);
  //   let unitMeasure = 1;
  //   if (prod.hasOwnProperty('param_json')) {
  //     if (prod.param_json.hasOwnProperty('Unit Measure')) {
  //       unitMeasure = prod.param_json['Unit Measure'];
  //     }
  //   }
  //
  //   const vol = inputValue.vol;
  //   const wgt = inputValue.wgt;
  //
  //   //TODO: exclude promo/foc items
  //   const prod_match_index = this.cartItems.findIndex(item => item.product_id === productId && item.type === 'normal');
  //   let line = {};
  //   if (prod_match_index > -1) {
  //     this.cartItems[prod_match_index].stock = currentStock;
  //
  //   } else {
  //     // Insert New Row
  //
  //     const line_config_json = {base_price: baseprice};
  //     const disc_details = {};
  //     let line_discount = 0;
  //     if (parseFloat(prod['extra_margin']) > 0) {
  //       disc_details['user_dis'] = prod['extra_margin'];
  //       line_discount = parseFloat(prod['extra_margin']);
  //     }
  //     if (parseFloat(String(this.cash_dis)) > 0) {
  //       this.cash_dis = parseFloat(String(this.cash_dis));
  //       disc_details['cash_dis'] = this.cash_dis;
  //       line_discount = line_discount + this.cash_dis;
  //     }
  //     if (line_discount > 0) {
  //       line_config_json['disc_details'] = disc_details;
  //     } else {
  //       delete line_config_json['disc_details'];
  //     }
  //
  //
  //     const unique_id = prod.id;
  //     line = {
  //       uid: unique_id,
  //       line_id: 0,
  //       type: 'normal',
  //       stock: currentStock,
  //       display_name: prod['display_name'],
  //       code: prod['code'],
  //       name: prod['product'],
  //       categ_id: prod['categ_id'],
  //       product_id: productId,
  //       unit_measure: unitMeasure,
  //       tax_amount: parseFloat(prod['tax_amount']),
  //       uom_id: inputValue.uom_id,
  //       default_uom_id: prod.default_uom_id,
  //       pack_name: inputValue.pack_name,
  //       uom_name: inputValue.uom_name,
  //       price_unit: unitPrice,
  //       base_price: baseprice,
  //       discount:  line_discount,
  //       config_json: line_config_json,
  //       trade_disc: 0,
  //       fixed_cost: 0,
  //       scheme_discount: 0,
  //       quantity: parseInt(quantity, 10),
  //       reusable_qty: parseInt(quantity, 10),
  //       price_subtotal: line_subtotal, //Price_subtotal to be recomputed after scheme and discount computation
  //       price_tax: 0,
  //       price_total: line_subtotal,
  //       tax_name: prod['tax_name'],
  //       taxmaster_id: prod['taxmaster_id'],
  //       factor_inv: prod['factor_inv'],
  //       factor: prod['factor'],
  //       volume: vol,
  //       weight: wgt
  //     };
  //
  //     // if (line['mid'] === undefined || line['mid'].length === 0) {
  //     line['mid'] = this.get_mid(this.cartItems.length);
  //     // }
  //     this.cartItems.push(line);
  //
  //     return unique_id;
  //   }
  // }


  populateFilters() {
    let categList = [];
    let brandList = [];
    let familyList = [];

    categList = this.unique(this.rowData, ['category']);
    brandList = this.unique(this.rowData, ['brand']);
    familyList = this.unique(this.rowData, ['family']);

    if (categList) {
      categList.forEach(row => {
        this.categoryList.values.push({id: row.category_id, itemName: row.category});
      });
    }
    if (brandList) {
      brandList.forEach(row => {
        this.brandList.values.push({id: row.brand_id, itemName: row.brand});
      });
    }
    if (familyList) {
      familyList.forEach(row => {
        this.familyList.values.push({id: row.family_id, itemName: row.family});
      });
    }
    this.categoryList.value = this.categoryList.values[0];
    this.familyList.value = this.familyList.values[0];
    this.brandList.value = this.brandList.values[0];
  }

  unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values());
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.rowData);
    this.filteredLength = this.gridOptions.api["rowModel"].rowsToDisplay.length;
    this.preloader = false;
    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });

    this.gridApi.sizeColumnsToFit();

    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');

    setTimeout(() => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }, 500);

  }


  generatePinnedBottomData() {
    // generate a row-data with null values
    const result = {};

    this.gridColumnApi.getAllGridColumns().forEach(item => {
      result[item.colId] = null;
    });
    return this.calculatePinnedBottomData(result);
  }

  calculatePinnedBottomData(target: any) {
    //list of columns fo aggregation
    this.columnsWithAggregation.forEach(element => {
      this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
        if (rowNode.data[element]) {
          if (Number(rowNode.data[element]) % 1 !== 0) {
            target[element] += Number(parseFloat(rowNode.data[element]).toFixed(2));
          } else {
            target[element] += Number(rowNode.data[element]);
          }
        }
      });
      if (target[element]) {
        if (target[element] % 1 !== 0) {
          target[element] = `${target[element].toFixed(2)}`;
        }
      }
    });
    if (this.cartItems && this.cartItems.length > 0) {
      target[Object.keys(this.cartItems[0])[0]] = 'TOTAL';
    }
    return target;
  }
  onCellEditingStarted(event: CellEditingStartedEvent) {
    this.showSave = false;
  }
  onCellEditingStopped(event: CellEditingStoppedEvent) {
    console.log(event);

    let base_qty = 0;
    for  (let i = 0 ; i < event.data.pack_count; i++) {
      if (event.data['PACK ' + (i + 1) + ' QTY'] && event.data['PACK ' + (i + 1) + ' SIZE']) {
        base_qty = base_qty + (parseInt(String(event.data['PACK ' + (i + 1) + ' QTY']), 10) * parseInt(String(event.data['PACK ' + (i + 1) + ' SIZE']), 10));
      }
    }
    // if (event.data['units']) {
    //   base_qty = base_qty + parseInt(String(event.data['units']), 10);
    // }
    event.data.quantity = base_qty;

    // if (event.column.getColId() === 'pack') {
    //   event.data.quantity = (parseInt(String(event.data.pack), 10) * event.data.packSize) + parseInt(event.data.units, 10);
    // } else {
    //   event.data.quantity = (parseInt(String(event.data.pack), 10) * event.data.packSize) + parseInt(String(event.data.units), 10);
    // }
    event.data.price_subtotal = parseFloat(event.data.base_price) * parseInt(event.data.quantity, 10);
    // const prod_match = this.cartItems.find(item => item.product_id === event.data.product_id);
    // if (!prod_match) {
    //   let cartlength = 0;
    //   if (this.cartItems) {
    //     cartlength = this.cartItems.length;
    //   }
    //   // event.data['uid'] = cartlength + 10;
    // }
    // this.cartItems = this.prodList.filter(x => x.quantity > 0);
    if (!event.data['mid']) {
      event.data['mid'] = this.get_mid(event.data['p_id']);
    }
    // const schme_tax_res = this.compute_scheme_and_tax_without_setData(event.data['uid'], this.cartItems);
    // this.cartItems = schme_tax_res.items;
    // this.activeObject['tax_notes'] = schme_tax_res.tax_notes;
    // TODO: What about tax
    // if (this.cartItems) {
    //   this.cartItems = this.cartItems.filter(x => x.quantity > 0);
    // }
    this.cartItems = this.rowData.filter(x => x.quantity > 0);
    if (this.cartItems && this.cartItems.length > 0) {
      this.showSave = true;
    } else {
      this.showSave = false;
    }
    this.gridApi.applyTransactionAsync({update: this.rowData}, () => {
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    });
  }

  onCellClicked($event) {
    const column = $event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'action') {
      const row = this.cartItems.find(x => x.product_id === rows[0].product_id);
      row.quantity = 0;
      row.price_subtotal = 0;
      row.price_subtotal = 0;
      this.cartItems = this.prodList.filter(x => x.quantity > 0);
      this.gridApi.setRowData(this.cartItems);
      const pinnedBottomData = this.generatePinnedBottomData();
      this.gridApi.setPinnedBottomRowData([pinnedBottomData]);
    }
  }

  saveCart() {
    this.cartItems = this.rowData.filter(x => x.mid !== undefined);
    this.submit.emit(this.cartItems);
  }

  async switchMode() {
    const qty_filterComponent = await this.gridApi.getFilterInstance("quantity");
    this.cartMode = !this.cartMode;
    if (this.cartMode) {
      this.filterModel = this.gridApi.getFilterModel();
      await this.gridApi.setFilterModel(
        {quantity: {type: 'greaterThan', filter: 0}}
      );
    } else {
      await this.gridApi.setFilterModel(this.filterModel);
      await qty_filterComponent.setModel(null);
    }
    await this.gridApi.onFilterChanged();
    this.filteredLength = this.gridOptions.api["rowModel"].rowsToDisplay.length;
    this.cartItems = this.rowData.filter(x => x.quantity > 0);
  }

  onFilterChange(event, field) {
    const fields = [];
    let allFlag = false;
    event.forEach(item => {
      fields.push(item.itemName);
      if (item.itemName === 'All') {
        allFlag = true;
      }
    });
    this.applyFilter(field, fields, allFlag);
  }

  applyFilter(field, values, allFlag) {
// set filter model and update
    if (this.gridApi) {
      const filterComponent = this.gridApi.getFilterInstance(field);
      if (!allFlag) {
        filterComponent.setModel({
          values
        });
      } else {
        filterComponent.setModel(null);
      }
// refresh rows based on the filter (not automatic to allow for batching multiple filters)
      this.gridApi.onFilterChanged();
      this.filteredLength = this.gridOptions.api["rowModel"].rowsToDisplay.length;
    }
  }

  onFilterChanged(event) {
    if (this?.gridOptions?.api) {
      this.filteredLength = this.gridOptions.api["rowModel"].rowsToDisplay.length;
    }
  }

  formatNumber(number) {
    if (number) {
      number = parseFloat(number).toFixed(this.apiService.decimalPolicy);
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return '';
    }
  }


  quickSearch() {
    this.gridApi.setQuickFilter(document.getElementById('filter-text-box')['value']);
    this.filteredLength = this.gridOptions.api["rowModel"].rowsToDisplay.length;
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
