<div class="common-wrapper">
  <div class="common-container">
    <div class="main-wrapper">
      <div class="search-list row">
        <div class="col-md-12 pad0">
          <div class="col-md-5 pad0">
            <div style="display: inline">

            </div>
          </div>
          <div class="col-md-7 pad0">
            <div class="filters">
              <ul class="list-unstyled">
                <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                  <app-components-searchablefilter [containerStyle]="'custom2'" [searchableFilter]="searchableFilter"
                                                   (onsetSearchableFilter)="setSearchableFilter($event)"></app-components-searchablefilter>
                </li>
                <li
                  style="margin-right: 3px !important;margin-left: 3px !important;" class="margLR">
                  <app-components-period-dropdownv2 (onsetPeriod)="setPeriod($event)"></app-components-period-dropdownv2>
                </li>
                <li style="margin-left:5px;margin-right:5px !important;" class="margLR">
                  <div style="display: inline-block;">
                    <button
                      class="btn confirm-blue-btn1"
                      style="height: 35px;width:45px;margin-left: 5px;margin-right:5px;margin-bottom:4px;font-size: 12px !important;padding: 8px 12px !important;border-radius: 5px;"
                      (click)="loadMapData()"
                    >GO
                    </button>
                  </div>
                </li>
                <li>
                  <div class="greywhitebox row centeralign">
                    <div [matMenuTriggerFor]="moredropwdown" class="menuIcon"
                         title="Legend" style="display: inline-block;margin-left: 5px;">
                      <span title="Legend"><img style="height: 14px;" src="/assets/icons/legend.svg"></span>
                      <mat-menu #moredropwdown="matMenu">
                        <div style="padding: 15px;">
                          <ng-container *ngFor="let item of legendData; let i = index">
                            <div style="margin-bottom: 8px;">
                              <img style="margin-right: 10px;height: 15px;" src="{{item.image}}">
                              <span style="font-size: 12px; color: #5D5D5D; font-weight: 400;">{{ item.name }}</span>
                            </div>
                          </ng-container>
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!goClicked" style="text-align: center;margin-top: 80px;">
        <img src="/assets/images/placeholder.png" style="display: block;
                        margin-left: auto;
                        margin-right: auto;
                        width: 25%;"/>
        <p style="display: block; color: #A2ACB9; font-size: 15px;">Kindly select the required User and Period and hit go to
          view Track Details</p>
      </div>
      <div *ngIf="goClicked && dataLoaded" class="map" id="map1"
           style="margin-top: 10px;border: 1px solid #D7DDE6;border-radius: 5px;height: calc(100vh - 157px);">
        <div style="height: 100%;">
          <div class="col-md-12 pad0" style="height: 100%;">
            <div class="col-md-9 pr-0">
              <div style="height: calc(100vh - 160px);">
                <agm-map *ngIf="dataLoaded" (mapReady)="onMapReady($event)"
                         [zoom]="zoom" [styles]="styles">
                  <div *ngIf="!hideAllMarkers">
                    <ng-container *ngFor="let item of markerData; let i = index">
                      <agm-marker *ngIf="item.lat && item.long" [latitude]="item.lat" [longitude]="item.long"
                                  [iconUrl]="getMarker(item)" (mouseOver)="onMouseOver(infoWindow, $event)"
                                  (mouseOut)="onMouseOut(infoWindow, $event)">
                        <agm-info-window #infoWindow
                                         [latitude]="item.lat"
                                         [longitude]="item.long">
                          <span *ngIf="item['icon'] === 'start_marker'" class="detailvaluebold2">Start</span>
                          <span *ngIf="item['icon'] === 'end_marker'" class="detailvaluebold2">End</span>
                          <span *ngIf="item.activity" class="detailvaluebold2">{{ item.activity }}</span>
                          <div *ngIf="item.activity" class="row centeralign" style="margin-top: 5px;">
                            <span class="detaillabel4">Type</span>
                            <span class="detailvalue3" style="margin-left: 5px;">{{ item.activity_type }}</span>

                            <span class="detaillabel4" style="margin-left: 20px;">Time</span>
                            <span class="detailvalue3" style="margin-left: 5px;">{{ item.time }}</span>
                          </div>
                          <div
                            *ngIf="!item.activity && (item['icon'] === 'start_marker' || item['icon'] === 'end_marker')"
                            class="row centeralign" style="margin-top: 5px;">
                            <span class="detailvalue3">{{ item.time }}</span>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                    </ng-container>
                  </div>
                  <div *ngIf="showRetailerMarkers && !hideAllMarkers">
                    <ng-container *ngFor="let item of retailerData; let i = index">
                      <agm-marker *ngIf="item.lat && item.long" [latitude]="item.lat" [longitude]="item.long"
                                  [iconUrl]="getMarker(item)" (mouseOver)="onMouseOver(infoWindow, $event)"
                                  (mouseOut)="onMouseOut(infoWindow, $event)">
                        <agm-info-window #infoWindow
                                         [latitude]="item.lat"
                                         [longitude]="item.long">
                          <span class="detailvaluebold2">{{ item.ret_name }}</span>
                          <div class="row centeralign" style="margin-top: 5px;">
                            <span class="detaillabel4">Code</span>
                            <span class="detailvalue3" style="margin-left: 5px;">{{ item.ret_code }}</span>

                            <span class="detaillabel4" style="margin-left: 20px;">Type</span>
                            <span class="detailvalue3" style="margin-left: 5px;">{{ item.ret_type }}</span>
                          </div>
                        </agm-info-window>
                      </agm-marker>
                    </ng-container>
                  </div>
                  <div *ngIf="dayWiseTracks && dayWiseTracks.length > 0">
                    <ng-container *ngFor="let day of dayWiseTracks; let i = index">
                      <agm-polyline *ngIf="day.tracks && day.tracks.length > 0" [strokeColor]="day.color">
                        <ng-container *ngFor="let track of day.tracks">
                          <agm-polyline-point [latitude]="track.lat" [longitude]="track.long">
                          </agm-polyline-point>
                        </ng-container>
                      </agm-polyline>
                    </ng-container>
                  </div>

                </agm-map>
              </div>

            </div>
            <div class="col-md-3 pl-0 pr-0">
              <div
                style="background: white;padding: 10px;height: calc(100vh - 160px); overflow-y: auto;overflow-x: hidden;">
                <div>
                  <div class="row centeralign">
                    <span class="detailvaluebold">{{ summaryData['user'] }}</span>
                   <!-- <div style="margin-left: auto;">
                      <div class="button-row">
                        <span (click)="movePrevious()"><i
                          [ngClass]="{'disabledspan': rowIndex === 0,
                                     'enabledspan': rowIndex >0}"
                          class="fal fa-arrow-circle-left fa-2x"></i></span>
                        <span
                          style="margin-left: 5px; margin-right: 5px; margin-bottom: 2px;font-size: 12px;">{{ rowIndex + 1 }}
                          /{{ listLength }}</span>
                        <span (click)="moveNext()"><i
                          [ngClass]="{'disabledspan': rowIndex === listLength-1,
                               'enabledspan': rowIndex < listLength-1}"
                          class="fal fa-arrow-circle-right fa-2x"></i></span>
                      </div>
                    </div>-->
                  </div>
                  <div class="row centeralign" style="margin-top: 5px;">
                    <span class="detaillabel4">Code</span>
                    <span class="detailvalue3" style="margin-left: 5px;">{{ summaryData.emp_code }}</span>

                    <span class="detaillabel4" style="margin-left: 20px;">Role</span>
                    <span class="detailvalue3" style="margin-left: 5px;">{{ summaryData.role }}</span>
                  </div>
                  <div class="mt-4" style="border-radius: 3px;padding: 10px;background: #F5F7F9;">
                    <div class="row centeralign">
                      <span class="detaillabel4">Beats Assigned</span>
                      <span class="detailvaluebold3" style="margin-left: auto">{{ totalBeats }}</span>
                    </div>
                    <div class="row centeralign" style="margin-top: 5px;">
                      <span class="detaillabel4">Beats Covered</span>
                      <span class="detailvaluebold3" style="margin-left: auto">{{ coveredBeats }}</span>
                    </div>
                    <div class="row centeralign" style="margin-top: 5px;">
                      <span class="detaillabel4">Total Retailers</span>
                      <span class="detailvaluebold3" style="margin-left: auto">{{ retailerData.length }}</span>
                    </div>
                    <div class="row centeralign" style="margin-top: 5px;">
                      <span class="detaillabel4">Field Days</span>
                      <span class="detailvaluebold3" style="margin-left: auto">{{ fieldDays }}</span>
                    </div>
                    <!-- <div class="row centeralign" style="margin-top: 5px;">
                       <span class="detaillabel4">Attendance</span>
                       <span class="detailvaluebold3" style="margin-left: auto">{{ activeRow['present'] }} / {{activeRow['total_working_days']}}</span>
                     </div>-->

                  </div>
                  <div class="mt-4" style="border-radius: 3px;padding: 10px;background: #F5F7F9;height: 200px;">
                    <span class="detailvaluebold">Retailer Coverage</span>
                    <div *ngIf="dataLoaded" style="height: 95%">
                      <ngx-charts-pie-chart
                        [scheme]="chartcolorScheme"
                        [results]="chartData"
                        [gradient]=true
                        [legend]=true
                        [legendTitle]="''"
                        [legendPosition]="'right'"
                        [labels]=false
                        [doughnut]=true
                      ><ng-template #tooltipTemplate let-model="model">
                        <div style="padding: 4px;">{{ model.name}}</div>
                      </ng-template>
                      </ngx-charts-pie-chart>
                      <!-- <ag-charts-angular
                         [options]="options"
                       ></ag-charts-angular>-->
                    </div>
                  </div>
                  <div class="mt-4">
                    <div class="row centeralign" style="margin-bottom: 10px;">
                      <span class="detaillabel2">Hide All Markers</span>
                      <div style="display: inline-block;margin-left: auto;">
                        <label class="switch">
                          <input
                            type="checkbox"
                            name="showAlert"
                            [(ngModel)]="hideAllMarkers"
                            #showAlert="ngModel"
                            [checked]="hideAllMarkers"

                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
