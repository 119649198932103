import {Component, OnInit} from '@angular/core';
import {ReportBase} from '../_common/reportbase';
import {MatDialogConfig} from "@angular/material/dialog";
import {ProgressPopupComponent} from "../../components/progress_popup/progress_popup.component";
import {GenerateDraftInvoiceComponent} from "../../generateDraftInvoice.component";
import {GenerateDraftInvoiceHeaderComponent} from "../../generateDraftInvoiceHeader.component";
import {ImagePopupComponent} from "../../components/image_popup/image_popup.component";

@Component({
  selector: 'app-volume-distribution-component',
  templateUrl: '../_common/reportbase.html',
  styleUrls: ['../_common/reportbase.scss']
})

export class SalesandReturnDetailedReportComponent extends ReportBase implements IReport, OnInit {

  showRegenerate = false;
  setTitle() {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.sharedService.setHeader('Sales and Return Detailed Report');
    if (this.sharedService.ACLcheck('feature_download_json')) {
      this.showDownloadJSON = true;
    }
    if (this.sharedService.ACLcheck('feature_generate_draft_invoice')) {
      this.showRegenerate = true;
    }
    this.showableFilters.salesman = true;
    this.showableFilters.team = true;
    this.showableFilters.cluster = true;
    this.showableFilters.region = true;
    this.customFilter.values = [
      {id: '', itemName: 'All'},
      {id: 'true', itemName: 'Active'},
      {id: 'false', itemName: 'Inactive'}
    ];
    this.context = {componentParent: this};
    this.showProgress = true;
    this.showProgressCustomText = true;

    this.loadInBatches(50000, 'line_id');
    this.gridOptions.getRowStyle = params => {
      if (params.data && params.data.price_subtotal < 0) {
        return {background: '#F8D1C8'};
      }
    };
    this.frameworkComponents['regenerateRenderer'] = GenerateDraftInvoiceComponent;
    this.frameworkComponents['regenerateHeadercomponent'] = GenerateDraftInvoiceHeaderComponent;

    this.configureGrid();
  }

  configureGrid() {
    this.API_URL = '/api/pwa_reports/salesandReturnDetailedReport';
    const inThis = this;
    this.columnDefs = [

      {
        headerName: 'REGION', field: 'region', width: 80,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {headerName: 'DC', field: 'cluster', width: 80},
      {headerName: 'VAN', field: 'user', width: 100},
      {headerName: 'SALES AREA CODE', field: 'sales_area_code', width: 100},
      {headerName: 'BEAT', field: 'beat', width: 100},
      {headerName: 'PRICELIST', field: 'pricelist', width: 100},
      {headerName: 'OUTLET', field: 'outlet', width: 100},
      {headerName: 'OCODE', field: 'outlet_code', width: 100},
      {headerName: 'OUTLET STATUS', field: 'out_status', width: 100},
      {headerName: 'GSTIN', field: 'retailer_gstin', width: 100},
      {headerName: 'OUTLET TYPE', field: 'outlet_type', width: 100},
      {headerName: 'CHANNEL', field: 'channel', width: 100},
      {
        headerName: 'Return', field: 'return', width: 100,
        valueGetter: params => {
          if (params.data && params.data.return) {
            return params.data.return.toString().toUpperCase();
          }
        }
      },
      {
        headerName: 'PLANNED DATE', field: 'order_date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.order_date) {
              return inThis.datePipe.transform((new Date(params.data.order_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.order_date;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'DELIVERED DATE', field: 'delivered_date', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.delivered_date) {
              return inThis.datePipe.transform((new Date(params.data.delivered_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
            } else {
              return params.data.delivered_date;
            }
          } else {
            return '';
          }
        }
      },
      {
        headerName: 'ORDERED QTY', field: 'ordered', width: 100,
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('ordered') && params.data.ordered !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.ordered).toFixed(3)));
          }
        }
      },
      {
        headerName: 'DELIVERED QTY', field: 'delivered', width: 100,
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('delivered') && params.data.delivered !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.delivered).toFixed(3)));
          }
        }
      },
      {headerName: 'PRODUCT CODE', field: 'product_code', width: 100},
      {headerName: 'PRODUCT NAME', field: 'product_name', width: 100},
      {headerName: 'PRODUCT CATEGORY', field: 'category', width: 100},
      {headerName: 'BRAND', field: 'brand', width: 100},
      {headerName: 'HSN', field: 'hsn', width: 100},
      {
        headerName: 'MRP', field: 'mrp', width: 100,
        valueGetter: this.configValueGetter('mrp')
      },
      {
        headerName: 'BASIC RATE', field: 'basic_rate', width: 100
      },
      {headerName: 'UNIT PRICE', field: 'ut_price', width: 100},
      {headerName: 'INVOICE ID', field: 'invoice_id', width: 100},
      {headerName: 'INVOICE', field: 'invoice_no', width: 100},
      {headerName: 'COMMENTS', field: 'comments', width: 100},
      {headerName: 'LINE ID', field: 'line_id', width: 100},
      {headerName: 'SCHEME NAME', field: 'scheme_name', width: 100},
      {headerName: 'SCHEME TYPE', field: 'scheme_type', width: 100},
      {
        headerName: 'MARGIN', field: 'o_mr', width: 100,
        valueGetter: this.configValueGetter('o_mr')
      },
      {
        headerName: 'ORDERED SUBTOTAL', field: 'ordered_subtotal', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('ordered_subtotal') && params.data.ordered_subtotal !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.ordered_subtotal).toFixed(3)));
          }
        }
      },
      {
        headerName: 'ORDERED SCHEME DISC', field: 'ordered_scheme_disc', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('ordered_scheme_disc') && params.data.ordered_scheme_disc !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.ordered_scheme_disc).toFixed(3)));
          }
        }
      },
      {
        headerName: 'ORDERED DISC', field: 'ordered_disc', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('ordered_disc') && params.data.ordered_disc !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.ordered_disc).toFixed(3)));
          }
        }
      },
      {
        headerName: 'ORDERED TAX', field: 'ordered_tax', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('amount_tax') && params.data.amount_tax !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.amount_tax).toFixed(3)));
          }
        }
      },
      {
        headerName: 'ORDERED TOTAL', field: 'ordered_total', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('ordered_total') && params.data.ordered_total !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.ordered_total).toFixed(3)));
          }
        }
      },
      {
        headerName: 'DELIVERED SUBTOTAL', field: 'delivered_subtotal', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('delivered_subtotal') && params.data.delivered_subtotal !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.delivered_subtotal).toFixed(3)));
          }
        }
      },
      {
        headerName: 'DELIVERED SCHEME DISC', field: 'del_scheme_disc', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('del_scheme_disc') && params.data.del_scheme_disc !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.del_scheme_disc).toFixed(3)));
          }
        }
      },
      {
        headerName: 'DELIVERED DISC', field: 'del_disc', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('del_disc') && params.data.del_disc !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.del_disc).toFixed(3)));
          }
        }
      },
      {
        headerName: 'DELIVERED TAX', field: 'delivered_tax', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('delivered_tax') && params.data.delivered_tax !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.delivered_tax).toFixed(3)));
          }
        }
      },
      {headerName: 'TAX', field: 'tax', width: 100},
      {headerName: 'TOTAL TAX(%)', field: 'tax_amount', width: 100, cellStyle: {textAlign: 'right'}},
      {
        headerName: 'CGST', field: 'cgst', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('tax_notes') && params.data.tax_notes) {
            let jsonValue = params.data.tax_notes;
            if (params.data.tax_notes.constructor !== Object) {
              try {
                jsonValue = JSON.parse(params.data.tax_notes);
              } catch (e) {
              }
            }
            let val = 0.0;
            if (jsonValue.hasOwnProperty('CGST')) {
              if (jsonValue['CGST'].hasOwnProperty('amt')) {
                val = jsonValue['CGST'].amt;
              } else {
                val = Math.abs(parseFloat(parseFloat(jsonValue['CGST'].split('(')[0]).toFixed(3)));
              }

            }
            return val;
          }
        }
      },
      {
        headerName: 'IGST', field: 'team_name', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('tax_notes') && params.data.tax_notes) {
            let jsonValue = params.data.tax_notes;
            if (params.data.tax_notes.constructor !== Object) {
              try {
                jsonValue = JSON.parse(params.data.tax_notes);
              } catch (e) {
              }
            }
            let val = 0.0;
            if (jsonValue.hasOwnProperty('IGST')) {
              if (jsonValue['IGST'].hasOwnProperty('amt')) {
                val = jsonValue['IGST'].amt;
              } else {
                val = Math.abs(parseFloat(parseFloat(jsonValue['IGST'].split('(')[0]).toFixed(3)));
              }
            }
            return val;
          }
        }
      },
      {
        headerName: 'SGST', field: 'team_name', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('tax_notes') && params.data.tax_notes) {
            let jsonValue = params.data.tax_notes;
            if (params.data.tax_notes.constructor !== Object) {
              try {
                jsonValue = JSON.parse(params.data.tax_notes);
              } catch (e) {
              }
            }
            let val = 0.0;
            if (jsonValue.hasOwnProperty('SGST')) {
              if (jsonValue['SGST'].hasOwnProperty('amt')) {
                val = jsonValue['SGST'].amt;
              } else {
                val = Math.abs(parseFloat(parseFloat(jsonValue['SGST'].split('(')[0]).toFixed(3)));
              }
            }
            return val;
          }
        }
      },
      {
        headerName: 'VAT', field: 'team_name', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('tax_notes') && params.data.tax_notes) {
            let jsonValue = params.data.tax_notes;
            if (params.data.tax_notes.constructor !== Object) {
              try {
                jsonValue = JSON.parse(params.data.tax_notes);
              } catch (e) {
              }
            }
            let val = 0.0;
            if (jsonValue.hasOwnProperty('VAT')) {
              if (jsonValue['VAT'].hasOwnProperty('amt')) {
                val = jsonValue['VAT'].amt;
              } else {
                val = Math.abs(parseFloat(parseFloat(jsonValue['VAT'].split('(')[0]).toFixed(3)));
              }
            }
            return val;
          }
        }
      },
      {
        headerName: 'DELIVERED TOTAL', field: 'delivered_total', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('delivered_total') && params.data.delivered_total !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.delivered_total).toFixed(3)));
          }
        }
      },
      {
        headerName: 'ADDITIONAL COST', field: 'fixed_cost', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('fixed_cost') && params.data.fixed_cost !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.fixed_cost).toFixed(3)));
          }
        }
      },
      {
        headerName: 'TCS/TDS', field: 'tcs', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data && params.data.hasOwnProperty('tcs') && params.data.tcs !== null) {
            return Math.abs(parseFloat(parseFloat(params.data.tcs).toFixed(3)));
          }
        }
      },
      {
        headerName: 'GRAND TOTAL', field: 'grand_total', width: 100, cellStyle: {textAlign: 'right'}, aggFunc: 'sum',
        valueGetter(params) {
          if (params.data) {
            if (params.data.hasOwnProperty('delivered_total') && params.data.delivered_total !== null &&
              params.data.hasOwnProperty('tcs') && params.data.tcs !== null) {
              return parseFloat(String(Math.abs(parseFloat(parseFloat(params.data.delivered_total).toFixed(3))) +
                Math.abs(parseFloat(parseFloat(params.data.tcs).toFixed(3))))).toFixed(3);
            } else {
              return 0;
            }
          }
        }
      },
      {headerName: 'PAYMENT STATUS', field: 'p_status', width: 100},
      {headerName: 'PAYMENT MODE', field: 'mode', width: 100},
      {headerName: 'ORDER TYPE', field: 'order_type', width: 100},
      {headerName: 'TYPE', field: 'type', width: 100},
      {headerName: 'APPROVAL STATUS', field: 'approved', width: 100},
      {headerName: 'APPROVAL BY', field: 'approved_by', width: 100},
      {
        headerName: 'APPROVED DATE ', field: 'approved_date', width: 100,
        valueGetter: params => {
          if (params.data && params.data.approved_date) {
            return inThis.datePipe.transform((new Date(params.data.approved_date + ' UTC')).toString(), 'yyyy-MM-dd HH:mm:ss');
          }
        }
      },
      {headerName: 'PO', field: 'po', width: 100},
      {headerName: 'IRN STATUS', field: 'IRN Status', width: 100},
      {headerName: 'IRN', field: 'irn', width: 100},
      {headerName: 'haseinvoice', field: 'has_einvoice', width: 100},
      {headerName: 'GRN', field: 'grn', width: 100},
      {headerName: 'VAN STATUS', field: 'van_status', width: 100},
      {
        headerName: 'DELIVERED', field: 'd_status', width: 100,
        valueGetter: params => {
          if (params.data) {
            if (params.data.d_status === 'delivered') {
              return 'true';
            } else {
              return 'false';
            }
          }
        }
      },
      {
        headerName: "Bill", field: "bill_store_fname", menuTabs: [], width: 50,
        cellRenderer(params) {
          if (params.data) {
            if (params.data.bill_store_fname == null || params.data.bill_store_fname === "") {
              return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
            } else {
              return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
            }
          }
        }
      },
      {
        headerName: "POD", field: "pod_store_fname", menuTabs: [], width: 50,
        cellRenderer(params) {
          if (params.data) {
            if (params.data.pod_store_fname == null || params.data.pod_store_fname === "") {
              return '<img style="padding:2px;" src="./../../../assets/images/bill-disable.png"></img>';
            } else {
              return '<img style="padding:2px;" src="./../../../assets/images/bill.png"></img>';
            }
          }
        }
      },
      /*{
        headerName: 'PRINT', field: 'PRINT', resizable: true, pinned: 'right',
        cellRenderer: PrintClickRenderer, width: 80
      }*/
    ];

    if (this.showRegenerate) {
      this.columnDefs.push({
        headerName: 'REGENERATE', field: 'REGENERATE',
        headerComponent: "regenerateHeadercomponent",
        cellRenderer: "regenerateRenderer", pinned: 'right', width: 120
      });
    }

  }

  async regenerate(params, node) {
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    params.draft = true;
    paramObject.orders = [params];
    paramObject.regenerate = false;

    this.apiService.post('/api/einvoice_orders/generate_drafteinvoice', paramObject)
      .subscribe(res => {
          if (res.hasOwnProperty('result') && (res.result.statusCode === 200)) {
            console.log(res);
            this.toastr.success("E-Invoice generate success!!");
            this.loadServerData();
          } else {
            this.preloader = false;
            this.toastr.error("E-Invoice generate failed!!");
          }
        },
        error => {
          //console.log(error);
          this.toastr.error("E-Invoice generate failed!!");
          this.preloader = false;
        });
  }

  async regenerateAll(params, node) {
    this.API_URL = '/api/einvoice_orders/generate_drafteinvoice';
    for (const key in params) {
      params[key]['draft'] = true;
    }
    const paramObject: any = {};
    paramObject.access_token = localStorage.getItem('resfreshToken');
    paramObject.state = '';
    paramObject.comments = '';
    paramObject.sapVerifyTypes = '';
    paramObject.orders = params;
    paramObject.regenerate = false;
    const config: MatDialogConfig = {
      width: "100vw",
      height: "100vh",
      maxWidth: "410px",
      maxHeight: "160px",
      /* disableClose: true,*/
      data: {
        title: " Generating Invoice Data",
        user_data: paramObject,
        API_URL: this.API_URL,
        data: params,
        dataname: "orders",
        batchSize: 100,
        rowData: params,
        type: 'generateinvoice'
      }
    };

    const confirmdiaeref = this.dialog.open(ProgressPopupComponent, config);

    confirmdiaeref.componentInstance.progressStatus.subscribe(data => {
      if (data.err !== null && data.err !== '') {
        this.toastr.error(data.err);
      } else if (data.successCount === 0) {
        this.toastr.error('Error Generating Invoice Data');
      } else if (data.failedCount > 0) {
        this.toastr.warning('Success : ' + data.successCount + "Failed : " + data.failedCount);
      } else {
        this.toastr.success(" Generated Invoice Data: " + data.successCount + " records");
        //window.location.reload();
        this.API_URL = '/api/pwa_reports/salesandReturnDetailedReport';
        this.loadServerData();
      }
    });
  }

  downloadJSON() {
    const orders = [];
    const rows = this.gridApi.getSelectedRows();
    if (rows === null || rows.length === 0) {
      this.toastr.error("Please select any row to download");
    } else {
      rows.forEach(row => {
        if (row['IRN Status'] === 'draft') {
          orders.push(row['id']);
        }

      });
      const paramObject: any = {};
      paramObject.access_token = localStorage.getItem('resfreshToken');
      paramObject.orders = orders;

      this.apiService.post('/api/einvoice_orders/getDraftJson', paramObject)
        .subscribe(res => {
            if (res.hasOwnProperty('results') && (res.results.status === 200)) {
              console.log(res);
              this.toastr.success("Downloaded");
              const theJSON = JSON.stringify(res.results.data[0].json_agg);
              const file = new Blob([theJSON], {type: 'text/json'});
              this.download(file, this.datePipe.transform(new Date(), 'yyyy-MM-dd') + "-einvoice.json");
            } else {
              this.preloader = false;
              this.toastr.error("Failed to download JSON!!");
            }
          },
          error => {
            //console.log(error);
            this.toastr.error("Failed to download JSON!!");
            this.preloader = false;
          });
    }

  }

  download(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      const  url = URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  onCellClicked(event) {

    const column = event.column.getColId();
    const rows = this.gridApi.getSelectedRows();
    if (column === 'pod_store_fname' || column === 'bill_store_fname') {
      if (rows != null && rows.length > 0) {
        if (column === 'pod_store_fname' && rows[0].pod_store_fname != null && rows[0].pod_store_fname !== "") {
          this.showImagePopup(rows[0].pod_store_fname, 'sale_order_gallery');
        } else if (column === 'bill_store_fname' && rows[0].bill_store_fname != null && rows[0].bill_store_fname !== "") {
          this.showImagePopup(rows[0].bill_store_fname, 'sale_order');
        }
      } else {

      }
    } else if (column === 'PRINT') {
      this.generatePrintData(column, rows, 'id');
    }
  }

  showImagePopup(store_fname, container) {
    if (screen.width > 991) {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "70%",
        maxHeight: "95%",
        data: {
          store_fname,
          container
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    } else {
      const config: MatDialogConfig = {
        width: "100vw",
        height: "100vh",
        maxWidth: "90%",
        maxHeight: "65%",
        data: {
          store_fname,
          container
        }
      };

      const creatediaeref = this.dialog.open(ImagePopupComponent, config);
    }
  }

  configValueGetter(field: string) {
    return (params) => {
      if (params.data && params.data.config_json) {
        try {
          let config_json = params.data.config_json;
          if (params.data.config_json.constructor !== Object) {
            config_json = JSON.parse(params.data.config_json);
          }
          if (config_json.hasOwnProperty(field)) {
            return parseFloat(config_json[field]).toFixed(2);
          }
        } catch (e) {
        }
      } else {
        return '';
      }
    };
  }

}
