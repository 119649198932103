import {Component} from "@angular/core";
import {BaseUpload} from "../../../pagebase/base_upload/base_upload";
import {GetRowIdParams, ITooltipParams} from "@ag-grid-enterprise/all-modules";
import {DetailPageBase} from "../../../pagebase/DetailPageBase";
import {DMSBasePage} from "../../../pagebase/components/DMSBasePage";


@Component({
  selector: 'app-sale-order-bulk-upload',
  templateUrl: '../../../pagebase/base_upload/base_upload.html',
  styleUrls: ['../../../pagebase/base_upload/base_upload.scss']

})

export class SaleOrderBulkUploadComponent extends BaseUpload {

  defaultSupplier;
  OPERATION_MODE = 'S';

  ordersList = [];

  setViewParams() {
    this.API_URL = '/api/pwa_dms_connects/save_order';
    this.header = 'Sale Order Upload';
    this.sampleText1 = 'DOWNLOAD TEMPLATE WITH DATA';
    this.sampleText2 = 'DOWNLOAD TEMPLATE';
    this.excel_columns = [{C: 'A', H: 'Date', V: '01/01/2023', required: false},
      {C: 'B', H: 'Order No', V: 'O-1231', required: false},
      {C: 'C', H: 'Customer Code', V: 'CUST001', required: true},
      {C: 'D', H: 'Customer Name', V: 'Customer', required: false},
      {C: 'E', H: 'Product Code', V: 'P001', required: true},
      {C: 'F', H: 'Product Name', V: 'Product', required: false},
      {C: 'G', H: 'Quantity', V: '10', required: true},
      {C: 'H', H: 'UOM', V: 'Bottles', required: false},
      {C: 'I', H: 'Unit Price', V: '59.00', required: false},
      {C: 'J', H: 'Subtotal', V: '590.00', required: false},
    ];
    this.step1Text = 'Download template for reference.';
    this.step2Text = 'Required fields are Customer code, Product Code, Quantity';
    this.actionText = 'Add or edit Order info in the template';
    this.gridOptions.defaultColDef['menuTabs'] = [];
  }

  async fetchBaseData() {
    this.loadMasterDataForUpload(() => {
      this.defaultSupplier = this.getDefaultSupplier();

      const supplier_territory_id = this.defaultSupplier.territory_id;
      const sec_pricelist_id = this.defaultSupplier.sec_pricelist_id;

      this.loadProducts(this.defaultSupplier.id, supplier_territory_id, sec_pricelist_id, () => {
        this.dataLoaded = true;
      });
    });
  }

  sampleDownload1() {
    const data = [];
    const indRow: any = {};
    this.excel_columns.forEach(key => {
      indRow[key.H] = key.V;
    });
    if (this.customerList && this.customerList.length > 0) {
      indRow['Customer Code'] = this.customerList[0].code;
      indRow['Customer Name'] = this.customerList[0].name;
    }
    if (this.prodList && this.prodList.length > 0) {
      indRow['Product Code'] = this.prodList[0].code;
      indRow['Product Name'] = this.prodList[0].product;
    }
    data.push(indRow);
    this.downloadExcel(data, 'sale_order_data');
  }

  sampleDownload2() {
    this.downloadSampleData('sale_order_template');
  }

  configureGrid() {

    const ValidationCellClassRules = {
      "cell-pass": params => params.value === 'Valid',
      "cell-fail": params => params.value !== 'Valid'
    };

    const OrderStatusCellClassRules = {
      "cell-progress": params => params.value === 'IN PROGRESS',
      "cell-fail": params => params.value === 'ERROR',
      "cell-pass": params => params.value === 'SUCCESS'
    };

    this.gridOptions.getRowId = (params: GetRowIdParams) => {
      return params.data.uid;
    };
    this.columnDefs = [{headerName: 'UID', field: 'uid', width: 60, hide: true},
      {
        headerName: 'Date', field: 'date_order', width: 80,
        tooltipValueGetter: (params: ITooltipParams) => {
          if (params.data.date_order) {
            const dayDiff = (new Date(params.data.date_order).getTime() - (new Date()).getTime()) / (1000 * 3600 * 24);
            if (dayDiff < -7) {
              return "Date provided is older then a week";
            } else if (dayDiff > 1) {
              return "Date provided is greater then today";
            }
          }
        },
        cellStyle(params) {
          if (params.data.date_order) {
            const dayDiff = (new Date(params.data.date_order).getTime() - (new Date()).getTime()) / (1000 * 3600 * 24);
            if (dayDiff < -7) {
              return {color: '#fa561b'};
            } else if (dayDiff > 1) {
              return {color: '#3eb205'};
            }
          }
        }
      },
      {headerName: 'Order No', field: 'origin', width: 100},
      {headerName: 'Customer Code', field: 'customer_code', width: 80},
      {headerName: 'Customer ID', field: 'partner_id', width: 80},
      {
        headerName: 'Customer Name', field: 'customer_name', width: 100,
        tooltipValueGetter: (params: ITooltipParams) => {
          if (params.data.sys_customer_name.trim() !== params.value.replace("'", '"')) {
            return params.data.sys_customer_name;
          }
        },
        cellStyle(params) {
          if (params.data.sys_customer_name.trim() !== params.value.replace("'", '"')) {
            return {color: '#fa561b'};
          }
        }
      },
      {headerName: 'Customer Name', field: 'sys_customer_name', width: 100, hide: true},
      {headerName: 'Beat', field: 'beat_plan_name', width: 80},
      {headerName: 'Product Code', field: 'product_code', width: 80},
      {headerName: 'Product ID', field: 'product_id', width: 80},
      {
        headerName: 'Product Name', field: 'product_name', width: 100,
        tooltipValueGetter: (params: ITooltipParams) => {
          if (params.data.sys_product_name && params.data.sys_product_name.trim() !== params.value.replace("'", '"')) {
            return params.data.sys_product_name;
          }
        },
        cellStyle(params) {
          if (params.data.sys_product_name && params.data.sys_product_name.trim() !== params.value.replace("'", '"')) {
            return {color: '#fa561b'};
          }
        }
      },
      {headerName: 'Product Name', field: 'sys_product_name', width: 70, hide: true},
      {headerName: 'Quantity', field: 'quantity', width: 70, cellClass: 'number-cell'},
      {
        headerName: 'UOM', field: 'uom_name', width: 90,
        cellStyle(params) {
          if (params.data.sys_uom_name !== params.value) {
            return {color: '#fa561b'};
          }
        },
        tooltipValueGetter: (params: ITooltipParams) => {
          if (params.data.sys_uom_name !== params.value) {
            return "'" + params.data.sys_uom_name + "' is the default UOM for selected product.";
          }
        },
      },
      {headerName: 'UOM', field: 'sys_uom_name', width: 50, hide: true},
      {
        headerName: 'Unit Price', field: 'price_unit', width: 80, cellClass: 'number-cell',
        valueFormatter: params => this.formatNumber(params.data.price_unit),
        tooltipValueGetter: (params: ITooltipParams) => {
          if (params.data.sys_unit_price !== params.value) {
            return params.data.sys_unit_price;
          }
        },
        cellStyle(params) {
          if (params.data.sys_unit_price !== params.value) {
            return {color: '#fa561b'};
          }
        }
      },
      {headerName: 'SYS Unit Price', field: 'sys_unit_price', width: 80, cellClass: 'number-cell', valueFormatter: params => this.formatNumber(params.data.sys_unit_price), hide: true},
      {headerName: 'Sub Total', field: 'price_subtotal', width: 80, cellClass: 'number-cell', valueFormatter: params => this.formatNumber(params.data.price_subtotal)},
      {headerName: 'Discount', field: 'discount', width: 80, valueFormatter: params => this.formatNumber(params.data.discount), hide: true},
      {headerName: 'Scheme Discount', field: 'scheme_discount', width: 80, valueFormatter: params => this.formatNumber(params.data.scheme_discount)},
      {headerName: 'Tax', field: 'price_tax', width: 80, valueFormatter: params => this.formatNumber(params.data.price_tax)},
      {headerName: 'Total', field: 'price_total', width: 80, cellClass: 'number-cell', valueFormatter: params => this.formatNumber(params.data.price_total)},
      {
        headerName: 'Order ID', field: 'order_id', width: 80,
        tooltipValueGetter: (params: ITooltipParams) => {
          if (params.data.valid === "Exists") {
            return "Order with Same order reference no already Exists";
          }
        },
        cellStyle(params) {
          if (params.data.valid === "Exists") {
            return {color: '#fa561b'};
          }
        }
      }];
  }

  getDefaultSupplier() {
    const defaultSupplier = this.supplierList[0];
    // const sList = this.supplierMasterList.filter(item => item.default === true);
    // if (sList.length > 0) {
    //   defaultSupplier = sList[0];
    // } else if (this.supplierMasterList.length > 0) {
    //   defaultSupplier = this.supplierMasterList[0];
    // }

    if (!defaultSupplier) {
      this.toastr.error('Default supplier Not Found Contact support');
      return false;
    } else if (defaultSupplier.territory_id === undefined || defaultSupplier.territory_id <= 0) {
      this.toastr.error('Default Territory not configured for selected Supplier:' + defaultSupplier.name + ', Contact support for more information');
      return false;
    } else if (defaultSupplier.territory_id === undefined || defaultSupplier.territory_id <= 0) {
      this.toastr.error('Default Secondary pricelist not configured for selected Supplier:' + defaultSupplier.name + ', Contact support for more information');
      return false;
    } else {
      return defaultSupplier;
    }
  }

  async validate() {
    this.rowData = [];
    let rowNo = 0;

    if (this.customerList === undefined) {
      this.toastr.error('Unable to load customer, re-login and try again');
    }

    if (this.uploaded_data.length > 0) {
      this.uploaded_data.forEach(exlRow => {

        const line = this.sharedService.toLowerCaseProps(exlRow);
        const exl_row = {};
        exl_row['uid'] = rowNo++;
        exl_row['date_order'] = this.getFieldValue(line, 'date_order');
        if (!exl_row['date_order']) {
          exl_row['date_order'] = this.getFieldValue(line, 'date');
        }
        if (!exl_row['date_order']) {
          exl_row['date_order'] = this.getFieldValue(line, 'orderdate');
        }
        if (exl_row['date_order']) {
          exl_row['date_order'] = this.sharedService.convertExcelStringToDate(exl_row['date_order']);
        } else {
          exl_row["date_order"] = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        }

        exl_row['origin'] = this.getFieldValue(line, 'origin');
        if (!exl_row['origin']) {
          exl_row['origin'] = this.getFieldValue(line, 'orderno');
        }
        if (!exl_row['origin']) {
          exl_row['origin'] = this.getFieldValue(line, 'orderref');
        }

        exl_row['customer_code'] = this.getFieldValue(line, 'customer_code');
        if (!exl_row['customer_code']) {
          exl_row['customer_code'] = this.getFieldValue(line, 'customercode');
        }

        exl_row['customer_name'] = this.getFieldValue(line, 'customer_name');
        if (!exl_row['customer_name']) {
          exl_row['customer_name'] = this.getFieldValue(line, 'customername');
        }

        exl_row['product_code'] = this.getFieldValue(line, 'product_code');
        if (!exl_row['product_code']) {
          exl_row['product_code'] = this.getFieldValue(line, 'productcode');
        }

        exl_row['product_name'] = this.getFieldValue(line, 'product_name');
        if (!exl_row['product_name']) {
          exl_row['product_name'] = this.getFieldValue(line, 'productname');
        }


        exl_row['quantity'] = this.getFieldValue(line, 'product_uom_qty');
        if (!exl_row['quantity']) {
          exl_row['quantity'] = this.getFieldValue(line, 'qty');
        }
        if (!exl_row['quantity']) {
          exl_row['quantity'] = this.getFieldValue(line, 'quantity');
        }
        if (exl_row['quantity']) {
          exl_row['quantity'] = parseInt(exl_row['quantity'], 10);
        }

        exl_row['uom_name'] = this.getFieldValue(line, 'uom');

        exl_row['price_unit'] = this.getFieldValue(line, 'unitprice');
        if (!exl_row['price_unit']) {
          exl_row['price_unit'] = this.getFieldValue(line, 'price');
        }
        if (exl_row['price_unit']) {
          exl_row['price_unit'] = parseFloat(exl_row['price_unit']);
        }

        exl_row['price_subtotal'] = this.getFieldValue(line, 'subtotal');
        if (exl_row['price_subtotal']) {
          exl_row['price_subtotal'] = parseFloat(exl_row['price_subtotal']);
          exl_row['price_total'] = parseFloat(exl_row['price_subtotal']);
        }

        exl_row['emp_code'] = this.getFieldValue(line, 'emp_code');

        this.rowData.push(this.validateRow(exl_row));
      });
    }

    if (this.gridOptions && this.gridOptions.api) {
      this.gridOptions.api.setRowData(this.rowData);
      this.gridOptions.api.setColumnDefs(this.columnDefs);
      this.gridOptions.api.sizeColumnsToFit();
    }

    await this.validate_origin_and_unitprice();

    this.apply_scheme_and_tax();

    console.log(this.rowData);

    this.upload_loader = false;

    this.totalRecords = this.rowData.length;
    this.errorRecords = this.rowData.filter(item => item.status !== 'Valid').length;
    this.validRecords = this.rowData.filter(item => item.status === 'Valid').length;

  }

  validateRow(exl_row) {
    exl_row['status'] = 'INVALID';
    if (exl_row['customer_code'] === undefined || exl_row['customer_code'] === '') {
      exl_row['status'] = 'INVALID';
      exl_row['errorMsg'] = 'Invalid Customer Code';
      return exl_row;
    }
    const cust_obj = this.customerList.find(item => item.code === exl_row['customer_code']);
    if (!cust_obj) {
      exl_row['status'] = 'INVALID';
      exl_row['errorMsg'] = 'Customer Not Found';
      return exl_row;
    } else {
      exl_row['partner_id'] = cust_obj.id;
      exl_row['mid'] = this.sharedService.get_mid(exl_row['uid'] + '_' + cust_obj.id);
      exl_row['sys_customer_name'] = cust_obj.name;
      exl_row['pricelist_id'] = cust_obj.pricelist_id;


      if (cust_obj.territory_id) {
        exl_row['territory_id'] = cust_obj.territory_id;
      } else {
        exl_row['territory_id'] = this.defaultSupplier.territory_id;
      }
      if (exl_row['pricelist_id'] === undefined) {
        if (this.defaultSupplier.sec_pricelist_id > 0) {
          exl_row['pricelist_id'] = this.defaultSupplier.sec_pricelist_id;
        }
      }
      if (cust_obj.beats) {
        exl_row['beat_plan_name'] = cust_obj.beats[0].name;
        exl_row['beat_plan_id'] = cust_obj.beats[0].id;
        exl_row['user_id'] = cust_obj.beats[0].salesman_id;
      }

      if (exl_row['customer_name'] === undefined || exl_row['customer_name'] === '') {
        exl_row['customer_name'] = cust_obj.name;
      }

    }

    const prod_obj = this.prodList.find(item => item.code === exl_row['product_code']);
    if (!prod_obj) {
      exl_row['status'] = 'INVALID';
      exl_row['errorMsg'] = 'Product Not Found';
      return exl_row;
    } else {

      const base_uom = DMSBasePage.GetBaseUOMbyCategory(this.UOMMasterList, prod_obj.uom_categ_id);
      exl_row['product_id'] = prod_obj.p_id;
      exl_row['product_uom'] = base_uom.id;
      exl_row['sys_product_name'] = prod_obj.product;
      exl_row['sys_uom_name'] = base_uom.name;
      exl_row['sys_unit_price'] = prod_obj.unit_price;
      exl_row['tax'] = prod_obj.tax;
      exl_row['price_include'] = prod_obj.price_include;
      exl_row['taxmaster_id'] = prod_obj.taxmaster_id;

      if (exl_row['product_name'] === undefined || exl_row['product_name'] === '') {
        exl_row['product_name'] = prod_obj.product;
      }
    }

    if (exl_row['price_unit'] === undefined || exl_row['price_unit'] === 0) {
      exl_row['price_unit'] = prod_obj.unit_price;
    }

    if (exl_row['uom_name'] === undefined || exl_row['uom_name'] === "") {
      exl_row['uom_name'] = exl_row['sys_uom_name'];
    } else if (exl_row['sys_uom_name'] !== exl_row['uom_name']) {
      exl_row['status'] = 'INVALID';
      exl_row['errorMsg'] = 'UOM MISMATCH, instead use ' + exl_row['sys_uom_name'];
      return exl_row;
    }

    if (!exl_row['quantity'] || parseInt(exl_row['quantity'], 10) <= 0) {
      exl_row['status'] = 'INVALID';
      exl_row['errorMsg'] = 'Quantity Not Found';
      return exl_row;
    }

    // exl_row['price_subtotal'] = parseFloat(exl_row['unit_price']) * parseInt(exl_row['quantity'], 10);

    // if (exl_row['tax'] && exl_row['tax'] > 0) {
    //   exl_row['price_tax'] = parseFloat(exl_row['price_subtotal']) * (parseFloat(exl_row['tax']) / 100);
    //   exl_row['total'] = exl_row['price_subtotal'] + exl_row['price_tax'];
    // } else {
    //   exl_row['price_tax'] = 0;
    //   exl_row['total'] = parseFloat(exl_row['price_subtotal']);
    // }
    exl_row['price_tax'] = 0;
    if (!exl_row['price_subtotal'] || exl_row['price_subtotal'] === 0) {
      exl_row['price_subtotal'] = exl_row['price_unit'] * parseInt(exl_row['quantity'], 10);
      exl_row['price_total'] = exl_row['price_unit'] * parseInt(exl_row['quantity'], 10);
    }
    //Price_subtotal to be recomputed after scheme and discount computation
    exl_row['errorMsg'] = '';
    exl_row['status'] = 'Valid';
    return exl_row;
  }

  async validate_origin_and_unitprice() {

    const data_to_verify = this.rowData.filter(item => item.status === 'Valid');
    const unique_origins = [...new Set(data_to_verify.map(item => item.origin))];
    if (unique_origins) {
      this.userData['origins'] = unique_origins;
      this.userData['distributor_id'] = this.defaultSupplier.id;
      const orgin_res = await this.apiService.postPromise('/api/pwa_dms_connects/get_orders_for_origin', this.userData);
      if (orgin_res.hasOwnProperty('results') && (orgin_res.results.status === 200)) {
        //const rowsToUpdate = [];
        orgin_res.results.data.forEach(ord => {
          this.rowData.forEach(line => {
            if (line.origin === ord.origin) {
              /*const updatedItem = this.sharedService.updateImmutableObject(line, {status: "EXISTS", order_id: ord.id, errorMsg: ''});
              rowsToUpdate.push(updatedItem);*/
              line.status = "Exists";
              line.order_id = ord.id;
            }
          });
          if (this.gridOptions && this.gridOptions.api) {
            this.gridOptions.api.setRowData(this.rowData);
            this.gridOptions.api.setColumnDefs(this.columnDefs);
            this.gridOptions.api.sizeColumnsToFit();
          }

        });
      }
    } else {
      if (this.gridOptions && this.gridOptions.api) {
        this.gridOptions.api.setRowData(this.rowData);
        this.gridOptions.api.setColumnDefs(this.columnDefs);
        this.gridOptions.api.sizeColumnsToFit();
      }
    }
  }

  apply_scheme_and_tax() {
    this.ordersList = [];
    const data_to_upload = this.rowData.filter(item => item.status === 'Valid');
    const unique_customers = [...new Set(data_to_upload.map(item => item.partner_id))];
    for (const customer_id of unique_customers) {
      const cust_lines = data_to_upload.filter(item => item.partner_id === customer_id);
      const unique_order_refs = [...new Set(cust_lines.map(item => item.origin))];

      const eligibleSchemes = this.filterEligibleSchemes(this.schemesMasterList, customer_id);

      for (const origin of unique_order_refs) {
        let order_lines = [];
        order_lines = cust_lines.filter(item => item.origin === origin);

        if (order_lines.length > 0) {

          let order = {};
          order['id'] = 0;
          order['date_order'] = order_lines[0].date_order;
          order['partner_id'] = customer_id;
          order['mid'] = this.sharedService.get_mid(customer_id);
          order['user_id'] = order_lines[0].user_id;
          order['partner_invoice_id'] = order_lines[0].partner_id;
          order['partner_shipping_id'] = order_lines[0].partner_id;
          order['territory_id'] = order_lines[0].territory_id;
          order['origin'] = order_lines[0].origin;
          order['client_order_ref'] = order_lines[0].origin;
          order['beat_plan_id'] = order_lines[0].beat_plan_id;
          order['amount_untaxed'] = 0;
          order['amount_tax'] = 0;
          order['amount_total'] = 0;
          order['lines'] = [];
          order['period_id'] = DetailPageBase.get_current_period_id(order['date_order']);

          const inProgressRows = [];

          order_lines.forEach(line => {
            order['lines'].push(line);
          });

          order = this.compute_scheme_and_tax(order, this.TaxMasterList, eligibleSchemes, this.prodList, this.UOMList);
          this.ordersList.push(order);

          order_lines.forEach(gridRow => {

            const updatedItem = order['lines'].find(item => item.mid === gridRow.mid);
            if (updatedItem) {
              gridRow['scheme_code'] = updatedItem['scheme_code'];
              gridRow['scheme_discount'] = updatedItem['scheme_discount'];
              gridRow['price_subtotal'] = updatedItem['price_subtotal'];
              gridRow['price_tax'] = updatedItem['price_tax'];
              gridRow['price_total'] = updatedItem['price_total'];
              gridRow['tax_name'] = updatedItem['tax_name'];
            }
          });


        }
      }
    }
  }

  async upload() {
    this.errorRecords = 0;
    let completed = 0;

    for (const order of this.ordersList) {
      const inProgressRows = [];

      for (const line of order.lines) {
        const row = this.rowData[line.uid];
        const updatedItem = this.sharedService.updateImmutableObject(row, {order_status: "IN PROGRESS"});
        inProgressRows.push(updatedItem);
      }
      this.gridOptions.api.applyTransactionAsync({update: inProgressRows}, () => {
      });

      this.saveOrder(order, (res) => {
        completed++;
        const updatedRows = [];
        if (res.hasOwnProperty('results') && (res.results.status === 200) && res.results.data) {
          console.log(res);
          //Update the grid lines with new orderid, line_id and status and do refresh
          const o_id = res.results.data.id;
          const line_mids = [...new Set(res.results.data.lines.map(item => item.mid))];
          line_mids.forEach(mid => {
            const row = this.rowData.find(item => item.mid === mid);
            const updatedItem = this.sharedService.updateImmutableObject(row, {status: "Success", errorMsg: '', order_id: o_id});
            updatedRows.push(updatedItem);
          });
          order.id = o_id;

        } else {
          console.log(res);
          inProgressRows.forEach(line => {
            const updatedItem = this.sharedService.updateImmutableObject(line, {status: "Error", errorMsg: res.results.errmsg});
            updatedRows.push(updatedItem);
            this.errorRecords++;
          });
        }
        this.gridOptions.api.applyTransactionAsync({update: updatedRows}, () => {
        });
      });

    }
    this.uploadCompleted = true;
  }

  async saveOrder(order, callback) {
    // order.id = 0;
    // order.name = order.origin;
    order.order_policy = 'manual';
    order.type = this.OPERATION_MODE;
    order.state = 'draft';
    order.order_type = 'sale';
    order.note = 'upload from excel pwa';
    order.d_status = 'new';
    order.p_status = 'new';
    order.invoice_status = 'to invoice';
    order.invoice_no = '';
    order.po = '';
    order.payment_mode = 'credit';
    order.has_einvoice = false;
    order.date_delivery = order.date_order;
    order.approved = false;
    order.tax_source = 0;
    order.discount = 0;
    order.tax_notes = '{}';

    order['amount_untaxed'] = 0;
    order['amount_tax'] = 0;
    order['amount_total'] = 0;

    if (!order.territory_id) {
      order.territory_id = this.defaultSupplier.territory_id;
    }
    if (!order.pricelist_id) {
      order.pricelist_id = 1;
    }

    order.supplier_territory_id = this.defaultSupplier.territory_id;
    order.distributor_id = this.defaultSupplier.id;
    order.supplier_code = this.defaultSupplier.code;
    order.inv_supplier_id = this.defaultSupplier.id;

    const gridRows = order['lines'];
    order['lines'] = [];

    gridRows.forEach(line => {

      order['lines'].push(
        {
          uid: line.uid,
          mid: line.mid,
          order_partner_id: line.partner_id,
          name: line.product_name,
          type: 'normal',
          product_id: line.product_id,
          price_unit: line.price_unit,
          product_uom_qty: line.quantity,
          product_uom: line.product_uom,
          qty_delivered: line.quantity,
          qty_invoiced: 0,
          scheme_discount: line.scheme_discount,
          scheme_id: line.scheme_id,
          discount: 0,
          price_subtotal: line.price_subtotal,
          taxmaster_id: line.taxmaster_id,
          price_tax: line.price_tax,
          tax_source: 0,
          price_total: line.price_subtotal + line.price_tax,
          fixed_cost: 0,
          trade_disc: 0
        });
      if (line.price_subtotal) {
        order['amount_untaxed'] += parseFloat(line.price_subtotal);
        order['amount_tax'] += parseFloat(line.price_tax);
        order['amount_total'] += parseFloat(line.total);
      }

    });

    this.userData['data'] = order;
    const res = await this.apiService.postPromise('/api/pwa_dms_connects/save_order', this.userData);
    return callback(res);
  }
}
