import {Component, ElementRef, EventEmitter, Inject, OnInit, Output} from "@angular/core";
import {ApiService} from "../../_services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ToastrService} from "ngx-toastr";
import {SharedService} from "../../_services/shared_service";
import {CellEditingStartedEvent, CellEditingStoppedEvent, GridOptions} from "@ag-grid-enterprise/all-modules";
import {CellEditRendererComponent} from "../../CellEditRendererComponent";
import {DateEditRendererComponent} from "../../DateEditorComponent";

@Component({
  selector: 'app-service-product-popup',
  templateUrl: './service_product_popup.component.html',
  styleUrls: ['./service_product_popup.component.scss']

})

export class ServiceProductPopupComponent implements OnInit {

  @Output() submitClicked = new EventEmitter<any>();
  productList = [];
  selectedProducts = [];
  editMode = true;
  gridOptions: GridOptions;
  styleGrid: any;
  columnDefs = [];
  frameworkComponents: any = {};
  context: any;
  public getRowId;
  public gridColumnApi: any;
  public gridApi: any;
  selectedProduct;
  todayDate;

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private elRef: ElementRef,
              public datePipe: DatePipe,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<any>,
              private domSanitizer: DomSanitizer,
              private toastr: ToastrService,
              private sharedService: SharedService,
              @Inject(MAT_DIALOG_DATA) public dialogdata: any) {

    this.productList = dialogdata.productList;
    if (dialogdata.selectedProducts && dialogdata.selectedProducts.length > 0) {
      this.selectedProducts = dialogdata.selectedProducts;
    }
    this.context = {componentParent: this};
    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.gridOptions = {
      rowHeight: 40,
      rowStyle: {'border-bottom': '#f4f6fc 10px solid', 'text-align': 'left'},
      rowSelection: 'multiple',
      groupSelectsChildren: true,
      enableRangeSelection: true,
      enableCharts: true,
      animateRows: true,
      suppressAggFuncInHeader: true,
      suppressColumnVirtualisation: true,
      pivotMode: false,
      pagination: false,
      singleClickEdit: true,
      context: {componentParent: this},
      defaultColDef: {
        headerClass: 'myagheaderwhitebold',
        filter: true,
        sortable: true,
        resizable: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      statusBar: {
        statusPanels: [
          {statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left'},
          {statusPanel: 'agFilteredRowCountComponent'},
        ]
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
        ],
        position: 'right',
        defaultToolPanel: '',
      },
      defaultExcelExportParams: {
        autoConvertFormulas: true,
        processCellCallback: (params) => {
          const field = params.column.getColDef().field;
          if (params.value === null || params.value === undefined) {
            return params.value;
          } else if (typeof params.value === 'string') {
            return field === 'url' ? `=HYPERLINK("${params.value}")` : params.value;
          } else if (typeof params.value === 'object' && params.value.hasOwnProperty('count')) {
            return params.value.count;
          } else {
            return params.value;
          }
        },
      },
      excelStyles: [
        {
          id: 'hyperlinks',
          font: {
            underline: 'Single',
            color: '#358ccb',
          },
        },
      ],
      getRowStyle: params => {
        if (params.node.rowPinned === 'bottom') {
          return {'font-weight': 'bold'};
        }
      }
    } as GridOptions;
    this.configureGrid();
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onGridReady(params) {
    this.gridOptions.api.showLoadingOverlay();

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    const noOfColumns = this.gridColumnApi.getAllDisplayedColumns().length;
    params.api.setRowData(this.selectedProducts);
    //this.preloader = false;
    this.gridApi.setDomLayout('autoHeight');
    // tslint:disable-next-line:only-arrow-functions
    window.addEventListener('resize', function() {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    this.gridApi.sizeColumnsToFit();
    this.styleGrid = this.elRef.nativeElement.querySelector('#myGrid1');
  }

  configureGrid() {
    this.columnDefs = [
      {headerName: 'Sl. No.', field: 'serial_no', editable: true, cellEditor: 'agTextCellEditor', cellRenderer: CellEditRendererComponent},
      {headerName: 'Product', field: 'name'},
      {headerName: 'Qty', field: 'qty', cellStyle: {textAlign: 'right'}, editable: true, cellEditor: 'agNumberCellEditor', cellRenderer: CellEditRendererComponent},
      {headerName: 'Batch No.', field: 'batch_no', editable: true, cellEditor: 'agTextCellEditor', cellRenderer: CellEditRendererComponent},
      {
        headerName: 'Warranty', field: 'warranty', editable: true, cellEditor: "agRichSelectCellEditor",
        cellRenderer(params) {
          let displayElem = '';
          displayElem = `<span style="height: 30px;width: 100%;border: 1px solid #E0E7FF;border-radius: 2px;background-color: rgba(224, 231, 255, 0.2);padding: 2px;
    text-align: center;
    font-size: 15px;
    color: black; display: inline-block;">` + params.value + `</span>`;

          return displayElem;
        },
        cellEditorParams: {
          values: [true, false],
        }
      },
      {headerName: 'Invoice Date', field: 'invoice_date', editable: true, cellRenderer: DateEditRendererComponent},
      {headerName: 'Invoice By', field: 'invoiced_by', editable: true, cellEditor: 'agTextCellEditor', cellRenderer: CellEditRendererComponent},
      {headerName: 'Invoice No', field: 'invoice_no', editable: true, cellEditor: 'agTextCellEditor', cellRenderer: CellEditRendererComponent},
      {headerName: 'PO No.', field: 'po_no', editable: true, cellEditor: 'agTextCellEditor', cellRenderer: CellEditRendererComponent},
      {
        headerName: '',
        field: 'delete',
        width: 50, pinned: 'right',
        menuTabs: [],
        cellRenderer(params) {
          if (params.data) {
            return '<span title="Delete" style="color: #E24260"><i class="fas fa-trash-alt delete" data-action-type="delete"></i></span>';
          }
        },
      }
    ];
  }

  addProductClick() {
    const prod_match = this.selectedProducts.find(item => item.product_id === this.selectedProduct);
    if (prod_match) {
      this.toastr.error("Product already added");
    } else {
      const prod = this.productList.find(item => item.id === this.selectedProduct);
      this.selectedProducts.push(
        {
          product_id: prod.id,
          name: prod.name,
          template_id: prod.product_tmpl_id,
          product_categ_id: prod.categ_id,
          qty: 1,
          serial_no: '',
          batch_no: '',
          warranty: true,
          invoice_date: this.todayDate,
          invoiced_by: 0,
          invoice_no: '',
          po_no: ''
        }
      );
      this.gridApi.setRowData(this.selectedProducts);
    }
  }

  productSelectorChange() {
    console.log(this.selectedProduct);
  }

  onCellEditingStarted(event: CellEditingStartedEvent) {

  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {

  }

  submitClick() {
    this.dialogRef.close();
    this.submitClicked.emit(this.selectedProducts);
  }

  onCellClicked(event) {
    const column = event.column.getColId();
    if (column === 'delete') {
      this.selectedProducts.splice(event.rowIndex, 1);
      this.gridApi.setRowData(null);
      this.gridApi.setRowData(this.selectedProducts);
    }
  }
}
