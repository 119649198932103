import {ICellRendererAngularComp} from "@ag-grid-community/angular";
import {Component, ElementRef, Renderer2} from "@angular/core";

@Component({
  selector: 'regenerate-click',
  template: `<span><div *ngIf="loader">
                        <img style="height: 24px;position: relative;left: 60px;" src="../assets/images/loader_icon.gif"/>
  </div><div *ngIf="enable_cancel"><button class="btn reject-btn" style="padding: 1px 20px;" (click)="cancel()">Cancel</button></div></span>`,
})
export class EwayCancelComponent implements ICellRendererAngularComp {
  public params: any;
  public enable_cancel = false;
  public loader = false;
  constructor(private elRef: ElementRef,
              private renderer: Renderer2) {
  }

  agInit(params: any): void {
    this.params = params;
    const daydiff = (new Date().getTime() - (new Date(this.params.data['ewb_date'])).getTime()) / (1000 * 3600 * 24);
    if (daydiff > 2 || this.params.data.status === 'cancelled' || this.params.data.type !== 'Invoiced') {
      this.enable_cancel = false;
    } else {
      this.enable_cancel = true;
    }
  }

  cancel() {
    this.loader = true;
    this.enable_cancel = false;
    this.params.context.componentParent.cancelEway(this.params.data, this.params.node);
  }
  refresh(): boolean {
    return false;
  }
}
